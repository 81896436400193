import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  groupEventsQuery,
  personnelQuery,
} from '@youshift/shared/hooks/queries';
import {
  EventStatus,
  EventStatusFilter,
  EventTypeFilter,
  type SpecialEvent,
  type User,
} from '@youshift/shared/types';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import {
  useApproveEvent,
  useRejectEvent,
} from '@youshift/shared/hooks/mutations';
import { dateToString } from '@youshift/shared/utils';

import Wrapper from '../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../utils/checks';
import i18n from '../../../utils/i18n';
import Alert from '../../../components/FormFeedback/Alert';

type ManagerEventCenterLoader = {
  events: SpecialEvent[];
  users: Record<number, User>;
};

export const managerEventCenterLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<ManagerEventCenterLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    const events = await preLoadQuery(queryClient, groupEventsQuery());
    const { users } = await preLoadQuery(queryClient, personnelQuery());
    return { events, users };
  };

function RequestTable({
  requests,
  isPending,
  handleApprove,
  handleReject,
  users,
}: {
  requests: SpecialEvent[];
  isPending: boolean;
  handleApprove: (id: number) => void;
  handleReject: (id: number) => void;
  users: Record<number, User>;
}) {
  const { t } = useTranslation();
  const locale = i18n.language;
  return (
    <div className="overflow-x-auto max-w-full">
      <table className="table-fixed w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('generic.name')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.type')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.startDate')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.endDate')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.reason')}
            </th>
            {/* <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
            {t('eventCenter.attachments')}
          </th> */}
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {isPending ? '' : t('eventCenter.status')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {requests
            .sort(
              (a, b) =>
                new Date(a.start).getTime() - new Date(b.start).getTime(),
            )
            .map(request => (
              <tr key={request.id_special_event}>
                <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {`${users[request.id_user].firstname} ${users[request.id_user].lastname}`}
                </td>
                <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {t(`eventCenter.${request.type}`)}
                </td>
                <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {dateToString(request.start, 'long', locale)}
                </td>
                <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {dateToString(request.end, 'long', locale)}
                </td>
                <td className="px-3 sm:px-6 py-4 break-words max-w-min text-sm text-gray-500">
                  {request.comment}
                </td>
                {/* <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.attachments > 0 && (
                <span className="inline-flex items-center px-2 sm:px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  <PaperClipIcon
                    className="mr-1 h-4 w-4"
                    aria-hidden="true"
                  />
                  {request.attachments}
                </span>
              )}
            </td> */}
                <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {isPending ? (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleApprove(request.id_special_event)}
                        className="bg-green-600 text-white p-1 rounded focus:ring-green-500"
                        aria-label="Approve request"
                      >
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                      <button
                        onClick={() => handleReject(request.id_special_event)}
                        className="bg-red-600 text-white p-1 rounded focus:ring-red-500"
                        aria-label="Reject request"
                      >
                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                  ) : (
                    <span
                      className={`text-xs font-medium rounded-full px-2 py-1 ${
                        request.status === EventStatus.APPROVED
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {t(`eventCenter.${request.status}`)}
                    </span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default function ManagerEventCenter() {
  const { events, users } = useLoaderData() as ManagerEventCenterLoader;
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] =
    useState<EventStatusFilter>('allStatuses');
  const [typeFilter, setTypeFilter] = useState<EventTypeFilter>('allTypes');

  const [eventsState, setEventsState] = useState(events);

  const [error, setError] = useState<boolean | string>(false);
  const [success, setSuccess] = useState<boolean | string>(false);

  const filteredRequests = useMemo(
    () =>
      eventsState.filter(
        request =>
          (statusFilter === 'allStatuses' || request.status === statusFilter) &&
          (typeFilter === 'allTypes' || request.type === typeFilter),
      ),
    [eventsState, statusFilter, typeFilter],
  );

  const pendingRequests = useMemo(
    () =>
      filteredRequests.filter(
        request => request.status === EventStatus.PENDING,
      ),
    [filteredRequests],
  );

  const processedRequests = useMemo(
    () =>
      filteredRequests.filter(
        request => request.status !== EventStatus.PENDING,
      ),
    [filteredRequests],
  );

  const queryClient = useQueryClient();
  const approveEvent = useApproveEvent(queryClient, {
    onSuccess: (data, variables) => {
      setSuccess(false);
      const { id_special_event } = data || variables;
      setEventsState(prevState =>
        prevState.map(event =>
          event.id_special_event === Number(id_special_event)
            ? { ...event, status: EventStatus.APPROVED }
            : event,
        ),
      );

      setError(false);
      setSuccess(t('eventCenter.successApprove'));
    },
    onError: () => {
      setError(false);
      setSuccess(false);
      setError(t('generic.error'));
    },
  });

  const rejectEvent = useRejectEvent(queryClient, {
    onSuccess: (data, variables) => {
      const { id_special_event } = data || variables;
      setEventsState(prevState =>
        prevState.map(event =>
          event.id_special_event === Number(id_special_event)
            ? { ...event, status: EventStatus.REJECTED }
            : event,
        ),
      );

      setError(false);
      setSuccess(t('eventCenter.successReject'));
    },
    onError: () => {
      setSuccess(false);
      setError(t('generic.error'));
    },
  });

  const handleApprove = (id: number) => {
    approveEvent.mutate({ id_special_event: id });
  };

  const handleReject = (id: number) => {
    rejectEvent.mutate({ id_special_event: id });
  };

  // const Dropdown = ({
  //   options,
  //   selected,
  //   onSelect,
  //   label,
  // }: {
  //   options: EventType[] | EventStatus[];
  //   selected: EventType | EventStatus;
  //   onSelect: (option: EventType | EventStatus) => void;
  //   label: string;
  // }) => (
  //   <Menu
  //     as="div"
  //     className="relative inline-block text-left w-full sm:w-auto mb-2 sm:mb-0"
  //   >
  //     <Menu.Button className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
  //       {t(`eventCenter.${selected}`)}
  //       <ChevronDownIcon className="w-5 h-5 ml-2" aria-hidden="true" />
  //     </Menu.Button>
  //     <Menu.Items className="absolute left-0 sm:right-0 w-full sm:w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
  //       <div className="py-1">
  //         {options.map(option => (
  //           <Menu.Item key={option}>
  //             {({ active }) => (
  //               <button
  //                 onClick={() => onSelect(option)}
  //                 className={`${
  //                   active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
  //                 } block px-4 py-2 text-sm`}
  //               >
  //                 {t(`eventCenter.${option}`)}
  //               </button>
  //             )}
  //           </Menu.Item>
  //         ))}
  //       </div>
  //     </Menu.Items>
  //   </Menu>
  // );

  return (
    <Wrapper>
      <div className="mx-auto">
        <h1 className="text-2xl sm:text-3xl font-extrabold text-gray-900 text-start mb-2">
          {t('eventCenter.eventCenter')}
        </h1>
        <p className="text-lg sm:text-xl text-gray-600 text-start mb-6 sm:mb-8 px-4">
          {t('eventCenter.description')}
        </p>
        {error && <Alert text={error} success={false} />}
        {success && <Alert text={success} success={true} />}
        <div className="bg-white shadow sm:rounded-lg mb-6 sm:mb-8">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              {t('eventCenter.pendingRequests')}
            </h2>
            {/* <div className="flex flex-col sm:flex-row sm:space-x-4">
              <Dropdown
                options={Object.values(EVENT_TYPES)}
                selected={typeFilter}
                onSelect={setTypeFilter}
                label={t('eventCenter.allTypes')}
              />
            </div> */}
          </div>
          {pendingRequests.length ? (
            <RequestTable
              requests={pendingRequests}
              isPending
              handleApprove={handleApprove}
              handleReject={handleReject}
              users={users}
            />
          ) : (
            <p className="text-md px-4 sm:px-6 text-gray-900 pb-4">
              {t('eventCenter.noPendingRequests')}
            </p>
          )}
        </div>

        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              {t('eventCenter.processedRequests')}
            </h2>
            {/* <div className="flex flex-col sm:flex-row sm:space-x-4">
              <Dropdown
                options={Object.values(EVENT_STATUSES).filter(
                  status => status !== 'pending',
                )}
                selected={statusFilter}
                onSelect={setStatusFilter}
                label={t('eventCenter.allStatuses')}
              />
              <Dropdown
                options={Object.values(EVENT_TYPES)}
                selected={typeFilter}
                onSelect={setTypeFilter}
                label={t('eventCenter.allTypes')}
              />
            </div> */}
          </div>
          {processedRequests.length ? (
            <RequestTable
              requests={processedRequests}
              isPending={false}
              handleApprove={handleApprove}
              handleReject={handleReject}
              users={users}
            />
          ) : (
            <p className="text-md px-4 sm:px-6 text-gray-900 pb-4">
              {t('eventCenter.noProcessedRequests')}
            </p>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
