/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable max-len */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { classNames, dateToString } from '@youshift/shared/utils';
import {
  useCancelRequestMutation,
  useRejectResponseMutation,
  useAcceptResponseMutation,
  useCancelResponseMutation,
  useRevertResponseMutation,
} from '@youshift/shared/hooks/mutations';
import {
  ExchangeResponseType,
  ExchangeRequestStatus,
  ExchangeResponseStatus,
  ExchangeResponse,
} from '@youshift/shared/types';

import AcceptIcon from '../../../assets/AcceptIcon';
import ExchangeIcon from '../../../assets/ExchangeIcon';
import Alert from '../../../components/FormFeedback/Alert';
import ConfirmReject from './ConfirmReject';
import { useShiftExchangeContext } from './ShiftExchangeLayout';

function UserShiftExchangeMyRequests() {
  const data = useShiftExchangeContext();

  // const [my_requests, feed, shiftOutputs, allShifts] = useOutletContext();
  // const { completed, requests, responses } = my_requests;
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);
  const [idRequest, setIdRequest] = useState<number | null>(null);
  const [idResponse, setIdResponse] = useState<number | null>(null);

  const { t } = useTranslation();

  const statusOrder = {
    [ExchangeResponseStatus.PENDING_DOCTOR]: 1,
    [ExchangeResponseStatus.PENDING_MANAGER]: 2,
    [ExchangeResponseStatus.REJECTED_DOCTOR]: 3,
    [ExchangeResponseStatus.REJECTED_MANAGER]: 4,
    [ExchangeResponseStatus.CANCELED_SYSTEM]: 5,
    [ExchangeResponseStatus.CANCELED]: 6,
    [ExchangeResponseStatus.APPROVED]: 7,
  };

  const cancelRequestMutation = useCancelRequestMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      setSuccess(t('user.shiftExchange.successCancelRequest'));
      setError(null);
    },
    onError: () => {
      setError(t('user.shiftExchange.errorCancelRequest'));
      setSuccess(null);
    },
  });

  const rejectResponseMutation = useRejectResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      setSuccess(t('user.shiftExchange.successRejectResponse'));
      setError(null);
      setConfirmRejectOpen(false);
    },
    onError: () => {
      setError(t('user.shiftExchange.errorRejectResponse'));
      setSuccess(null);
    },
  });

  const acceptResponseMutation = useAcceptResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      setSuccess(t('user.shiftExchange.successAcceptResponse'));
      setError(null);
    },
    onError: () => {
      setError(t('user.shiftExchange.errorAcceptResponse'));
      setSuccess(null);
    },
  });

  const cancelResponseMutation = useCancelResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      setSuccess(t('user.shiftExchange.successCancelResponse'));
      setError(null);
    },
    onError: () => {
      setError(t('user.shiftExchange.errorCancelResponse'));
      setSuccess(null);
    },
  });

  const revertResponseMutation = useRevertResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      setSuccess(t('user.shiftExchange.successRevertAcceptance'));
      setError(null);
    },
    onError: () => {
      setError(t('user.shiftExchange.errorRevertAccepetance'));
      setSuccess(null);
    },
  });

  return (
    <>
      {error ? <Alert text={error} success={false} /> : null}
      {success ? <Alert text={success} success /> : null}
      <div className="my-6 mx-2 min-w-[45%] flex flex-col gap-4">
        {confirmRejectOpen ? (
          <ConfirmReject
            open={confirmRejectOpen}
            setOpen={setConfirmRejectOpen}
            id_request={idRequest}
            id_response={idResponse}
            rejectResponseMutation={rejectResponseMutation}
          />
        ) : null}
        {/* MY REQUESTS */}
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-teal-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-teal-400 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                <span>{t('user.shiftExchange.myPendingRequests')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                className="sm:px-4 px-1 pb-2 sm:text-sm text-xs text-gray-500"
                id="myExchanges-requests"
              >
                <div className="flex flex-row justify-between sm:text-xl text-md text-black mb">
                  <h3>{t('user.shiftExchange.shiftsIOffer')}</h3>
                  <h3>{t('user.shiftExchange.responses')}</h3>
                </div>
                {Object.values(data).every(
                  itr => Object.keys(itr.my_requests.ongoing).length === 0,
                ) ? (
                  <p className="mt-6 text-center text-gray-600">
                    {t('user.shiftExchange.emptyRequests')}
                  </p>
                ) : null}
                {Object.entries(data).map(([id_itr, itr]) =>
                  Object.entries(itr.my_requests.ongoing).map(
                    ([id_request, { my_request, candidate_responses }]) => {
                      const request_shift_assignment =
                        itr.my_shift_assignments[my_request.id_request];
                      const request_section_slot =
                        itr.section_slots[
                          request_shift_assignment.id_section_slot
                        ];
                      const request_section =
                        itr.sections[request_section_slot.id_section];
                      return (
                        <div className="flex flex-row mt-5 items-center pb-4 border-b border-gray-500">
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%] sm:mr-4">
                            <div className="flex sm:flex-row flex-col justify-between items-center">
                              <div>
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {dateToString(
                                      request_section_slot.start,
                                      'weekday-hour',
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p> {request_section.name}</p>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="mt-2 sm:mt-0 inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:ml-3 sm:w-auto"
                                onClick={() =>
                                  cancelRequestMutation.mutate(id_request)
                                }
                              >
                                {t('generic.cancel')}
                              </button>
                            </div>
                            <p className="my-3">
                              <span className="font-semibold">
                                {t('generic.justification')}
                              </span>{' '}
                              {my_request.reason}
                            </p>
                            <div className="flex flex-row gap-1">
                              {my_request.allow_one_for_zero ? (
                                <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                  <ArrowPathIcon className="h-3" />
                                </div>
                              ) : null}
                              {my_request.allow_one_for_one ? (
                                <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                  <ArrowUpTrayIcon className="rotate-90 h-3" />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex flex-col gap-3">
                            {/* map over responses for each request */}
                            {Object.values(candidate_responses)
                              .sort(
                                (a: ExchangeResponse, b: ExchangeResponse) =>
                                  statusOrder[a.status] - statusOrder[b.status],
                              )
                              .map((response: ExchangeResponse) => {
                                const respondent_us =
                                  itr.other_users[response.id_respondent];
                                return (
                                  <div className="flex flex-row items-center">
                                    {response.type ===
                                    ExchangeResponseType.ONE_FOR_ONE ? (
                                      <>
                                        <div className="hidden sm:block">
                                          <ExchangeIcon
                                            status={response.status}
                                            small={false}
                                          />
                                        </div>
                                        <div className="block sm:hidden">
                                          <ExchangeIcon
                                            status={response.status}
                                            small
                                          />
                                        </div>
                                      </>
                                    ) : response.status ===
                                        ExchangeResponseStatus.REJECTED_DOCTOR ||
                                      response.status ===
                                        ExchangeResponseStatus.REJECTED_MANAGER ? (
                                      <>
                                        <div className="hidden sm:block">
                                          <AcceptIcon
                                            status="rejected_right"
                                            small={false}
                                          />
                                        </div>
                                        <div className="block sm:hidden">
                                          <AcceptIcon
                                            status="rejected_right"
                                            small
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="hidden sm:block">
                                          <AcceptIcon
                                            status="pending_right"
                                            small={false}
                                          />
                                        </div>
                                        <div className="block sm:hidden">
                                          <AcceptIcon
                                            status="pending_right"
                                            small
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:ml-4 ml-2 my-auto">
                                      <div className="flex flex-row gap-2 items-center my-3">
                                        <div className="flex flex-col justify-between items-start">
                                          {response.type ===
                                          ExchangeResponseType.ONE_FOR_ONE ? (
                                            <>
                                              <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                                <ArrowPathIcon className="sm:h-5 h-3" />
                                              </div>
                                              {(() => {
                                                const response_shift_assignment =
                                                  itr.my_shift_assignments[
                                                    response.id_response
                                                  ];
                                                const response_section_slot =
                                                  itr.section_slots[
                                                    response_shift_assignment
                                                      .id_section_slot
                                                  ];
                                                const response_section =
                                                  itr.sections[
                                                    response_section_slot
                                                      .id_section
                                                  ];
                                                return (
                                                  <div className="flex sm:flex-row flex-col justify-between sm:gap-2">
                                                    <div className="flex flex-row items-center gap-1">
                                                      <CalendarDaysIcon className="h-4 mt-0.5" />
                                                      <p>
                                                        {dateToString(
                                                          response_section_slot.start,
                                                          'weekday-hour',
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="flex flex-row items-center gap-1">
                                                      <MapPinIcon className="h-4" />
                                                      <p>
                                                        {response_section.name}
                                                      </p>
                                                    </div>
                                                  </div>
                                                );
                                              })()}
                                            </>
                                          ) : (
                                            <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                              <ArrowUpTrayIcon className="rotate-90 sm:h-5 h-3" />
                                            </div>
                                          )}
                                          <div className="flex flex-row items-center gap-1">
                                            <UserIcon className="h-4" />
                                            <p>
                                              {`${respondent_us.firstname} ${respondent_us.lastname}`}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex flex-col justify-between items-center">
                                        {response.status ===
                                        ExchangeResponseStatus.PENDING_DOCTOR ? (
                                          <div className="flex sm:flex-row flex-col gap-3">
                                            <button
                                              type="button"
                                              className="border border-green-600 bg-green-200 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm hover:bg-green-100 sm:w-auto"
                                              onClick={() =>
                                                acceptResponseMutation.mutate({
                                                  id_request,
                                                  id_response:
                                                    response.id_response,
                                                })
                                              }
                                            >
                                              {response.type ===
                                              ExchangeResponseType.ONE_FOR_ONE
                                                ? t('user.shiftExchange.trade')
                                                : t('user.shiftExchange.give')}
                                            </button>
                                            <button
                                              type="button"
                                              className="border border-red-600 bg-red-200 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm hover:bg-red-100 sm:w-auto"
                                              onClick={() => {
                                                setConfirmRejectOpen(true);
                                                setIdRequest(
                                                  my_request.id_request,
                                                );
                                                setIdResponse(
                                                  response.id_response,
                                                );
                                              }}
                                            >
                                              {t('generic.reject')}
                                            </button>
                                          </div>
                                        ) : response.status ===
                                          ExchangeResponseStatus.PENDING_MANAGER ? (
                                          <div className="flex flex-col gap-2">
                                            <button
                                              type="button"
                                              className="inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                                              onClick={() =>
                                                revertResponseMutation.mutate({
                                                  id_request,
                                                  id_response:
                                                    response.id_response,
                                                })
                                              }
                                            >
                                              {t(
                                                'user.shiftExchange.revertAccept',
                                              )}
                                            </button>
                                          </div>
                                        ) : null}
                                      </div>
                                      {response.status ===
                                      ExchangeResponseStatus.PENDING_MANAGER ? (
                                        <p className="text-xs text-orange-400 mt-2">
                                          {t(
                                            'generic.shiftExchange.pendingManager',
                                          )}
                                        </p>
                                      ) : response.status ===
                                        ExchangeResponseStatus.REJECTED_MANAGER ? (
                                        <p className="text-xs text-red-400">
                                          {t(
                                            'generic.shiftExchange.rejectedManager',
                                          )}
                                        </p>
                                      ) : response.status ===
                                        ExchangeResponseStatus.REJECTED_DOCTOR ? (
                                        <p className="text-xs text-red-400">
                                          {t(
                                            'generic.shiftExchange.rejectedYou',
                                          )}
                                        </p>
                                      ) : response.status ===
                                        ExchangeResponseStatus.CANCELED_SYSTEM ? (
                                        <p className="text-xs text-red-400">
                                          {t(
                                            'generic.shiftExchange.canceledSystem',
                                          )}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    },
                  ),
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* MY RESPONSES */}
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-teal-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-teal-400 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                <span>{t('user.shiftExchange.myPendingResponses')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                className="sm:px-4 px-1 pt-2 pb-2 sm:text-sm text-xs text-gray-500"
                id="myExchanges-responses"
              >
                <div className="flex flex-row justify-between sm:text-xl text-xs text-black">
                  <h3>{t('user.shiftExchange.shiftsIOffer')}</h3>
                  <h3>{t('user.shiftExchange.shiftsIRequest')}</h3>
                </div>
                {Object.values(data).every(
                  itr => Object.keys(itr.my_responses.ongoing).length === 0,
                ) ? (
                  <p className="mt-6 text-center text-gray-600">
                    {t('user.shiftExchange.emptyResponses')}
                  </p>
                ) : null}
                {Object.entries(data).map(([id_itr, itr]) =>
                  Object.entries(itr.my_responses.ongoing).map(
                    ([id_response, { request, my_response }]) => {
                      const request_shift_assignment =
                        itr.other_users_requestable_shift_assigments[
                          request.id_shift_assignment
                        ];
                      const request_section_slot =
                        itr.section_slots[
                          request_shift_assignment.id_section_slot
                        ];
                      const request_section =
                        itr.sections[request_section_slot.id_section];

                      const requestor_user =
                        itr.other_users[request.id_requestor];
                      return (
                        <div className="flex flex-row items-center mt-5">
                          <div
                            className={classNames(
                              my_response.type ===
                                ExchangeResponseType.ONE_FOR_ONE
                                ? 'border-slate-200'
                                : 'border-slate-400 border-dashed',
                              'border shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%]  flex items-center',
                            )}
                          >
                            <div className="flex flex-row justify-between items-center">
                              {my_response.type ===
                              ExchangeResponseType.ONE_FOR_ONE
                                ? (() => {
                                    const response_section_slot =
                                      itr.section_slots[
                                        itr.my_shift_assignments[
                                          my_response.id_shift_assignment!
                                        ].id_section_slot
                                      ];
                                    const response_section =
                                      itr.sections[
                                        response_section_slot.id_section
                                      ];

                                    return (
                                      <div className="flex flex-col justify-between items-start mr-3">
                                        <div className="flex flex-row items-start gap-1">
                                          <CalendarDaysIcon className="h-4 mt-0.5" />
                                          <p>
                                            {dateToString(
                                              response_section_slot.start,
                                              'weekday-hour',
                                            )}
                                          </p>
                                        </div>
                                        <div className="flex flex-row items-start gap-1">
                                          <MapPinIcon className="h-4" />
                                          <p>{response_section.name}</p>
                                        </div>
                                      </div>
                                    );
                                  })()
                                : null}

                              {my_response.status ===
                                ExchangeResponseStatus.PENDING_MANAGER ||
                              my_response.status ===
                                ExchangeResponseStatus.PENDING_DOCTOR ? (
                                <button
                                  type="button"
                                  className="inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                                  onClick={() =>
                                    cancelResponseMutation.mutate({
                                      id_request: request.id_request,
                                      id_response: my_response.id_response,
                                    })
                                  }
                                >
                                  {t('generic.cancel')}
                                </button>
                              ) : null}
                            </div>
                          </div>
                          {my_response.type ===
                          ExchangeResponseType.ONE_FOR_ONE ? (
                            <>
                              <div className="hidden sm:block">
                                <ExchangeIcon
                                  status={my_response.status}
                                  small={false}
                                />
                              </div>
                              <div className="block sm:hidden">
                                <ExchangeIcon
                                  status={my_response.status}
                                  small
                                />
                              </div>
                            </>
                          ) : my_response.status ===
                              ExchangeResponseStatus.REJECTED_DOCTOR ||
                            my_response.status ===
                              ExchangeResponseStatus.REJECTED_MANAGER ||
                            my_response.status ===
                              ExchangeResponseStatus.CANCELED_SYSTEM ? (
                            <>
                              <div className="hidden sm:block">
                                <AcceptIcon
                                  status="rejected_left"
                                  small={false}
                                />
                              </div>
                              <div className="block sm:hidden">
                                <AcceptIcon status="rejected_left" small />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="hidden sm:block">
                                <AcceptIcon
                                  status="pending_left"
                                  small={false}
                                />
                              </div>
                              <div className="block sm:hidden">
                                <AcceptIcon status="pending_left" small />
                              </div>
                            </>
                          )}
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%] sm:mr-4 flex items-center">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row gap-2 items-center my-3">
                                {my_response.type ===
                                ExchangeResponseType.ONE_FOR_ONE ? (
                                  <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                    <ArrowPathIcon className="sm:h-5 h-3" />
                                  </div>
                                ) : (
                                  <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                    <ArrowUpTrayIcon className="-rotate-90 sm:h-5 h-3" />
                                  </div>
                                )}
                                <div className="flex flex-col justify-between items-start">
                                  <div className="flex sm:flex-row flex-col justify-between sm:gap-2">
                                    <div className="flex flex-row items-center gap-1">
                                      <CalendarDaysIcon className="h-4 mt-0.5" />
                                      <p>
                                        {dateToString(
                                          request_section_slot.start,
                                          'weekday-hour',
                                        )}
                                      </p>
                                    </div>
                                    <div className="flex flex-row items-center gap-1">
                                      <MapPinIcon className="h-4" />
                                      <p>{request_section.name}</p>
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center gap-1">
                                    <UserIcon className="h-4" />
                                    <p>
                                      {`${requestor_user.firstname} ${requestor_user.lastname}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {my_response.status ===
                              ExchangeResponseStatus.PENDING_MANAGER ? (
                                <p className="text-xs text-orange-400">
                                  {t('generic.shiftExchange.pendingManager')}
                                </p>
                              ) : my_response.status ===
                                ExchangeResponseStatus.PENDING_DOCTOR ? (
                                <p className="text-xs text-orange-400">
                                  {t('generic.shiftExchange.pendingDoctor')}
                                </p>
                              ) : my_response.status ===
                                ExchangeResponseStatus.REJECTED_MANAGER ? (
                                <p className="text-xs text-red-400">
                                  {t('generic.shiftExchange.rejectedManager')}
                                </p>
                              ) : my_response.status ===
                                ExchangeResponseStatus.REJECTED_DOCTOR ? (
                                <p className="text-xs text-red-400">
                                  {t('generic.shiftExchange.rejectedDoctor')}
                                </p>
                              ) : my_response.status ===
                                ExchangeResponseStatus.CANCELED_SYSTEM ? (
                                <p className="text-xs text-red-400">
                                  {t('generic.shiftExchange.canceledSystem')}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    },
                  ),
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* MY COMPLETED REQUESTS AND RESPONSES */}
        <Disclosure>
          {({ open }) => (
            <div>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-teal-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-teal-400 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                <span>{t('generic.shiftExchange.completed')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="sm:px-4 px-1 pt-2 pb-2 text-xs sm:text-sm text-gray-500">
                <div className="flex flex-row justify-between sm:text-xl text-md text-black">
                  <h3>{t('user.shiftExchange.myShifts')}</h3>
                  <h3>{t('user.shiftExchange.shiftsIGave')}</h3>
                </div>
                {Object.values(data).every(
                  itr => Object.values(itr.my_requests.completed).length === 0,
                ) &&
                Object.values(data).every(
                  itr => Object.values(itr.my_responses.completed).length === 0,
                ) ? (
                  <p className="mt-6 text-center text-gray-600">
                    {t('user.shiftExchange.emptyCompleted')}
                  </p>
                ) : null}
                {/* User's completed requests */}
                {Object.entries(data).map(([id_itr, itr]) =>
                  Object.entries(itr.my_requests.completed).map(
                    ([id_request, { my_request, approved_response }]) => {
                      const request_shift_assignment =
                        itr.my_shift_assignments[
                          my_request.id_shift_assignment
                        ];
                      const request_section_slot =
                        itr.section_slots[
                          request_shift_assignment.id_section_slot
                        ];
                      const request_section =
                        itr.sections[request_section_slot.id_section];
                      const respondent_user =
                        itr.other_users[approved_response.id_respondent];

                      return approved_response.type ===
                        ExchangeResponseType.ONE_FOR_ONE ? (
                        (() => {
                          const response_shift_assignment =
                            itr.my_shift_assignments[
                              approved_response.id_shift_assignment!
                            ];
                          const response_section_slot =
                            itr.section_slots[
                              response_shift_assignment.id_section_slot
                            ];
                          const response_section =
                            itr.sections[response_section_slot.id_section];

                          return (
                            <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                              <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                                <div className="flex flex-col justify-between items-start">
                                  <div className="flex flex-row items-start gap-1">
                                    <CalendarDaysIcon className="h-4 mt-0.5" />
                                    <p>
                                      {dateToString(
                                        request_section_slot.start,
                                        'weekday-hour',
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex flex-row items-start gap-1">
                                    <MapPinIcon className="h-4" />
                                    <p>{request_section.name}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="hidden sm:block mx-auto">
                                <ExchangeIcon status="approved" small={false} />
                              </div>
                              <div className="block sm:hidden mx-auto">
                                <ExchangeIcon status="approved" small />
                              </div>
                              <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                                <div className="flex flex-col justify-between items-start">
                                  <div className="flex flex-row items-start gap-1">
                                    <CalendarDaysIcon className="h-4 mt-0.5" />
                                    <p>
                                      {dateToString(
                                        response_section_slot.start,
                                        'weekday-hour',
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex flex-row items-start gap-1">
                                    <MapPinIcon className="h-4" />
                                    <p>{response_section.name}</p>
                                  </div>
                                  <div className="flex flex-row items-center gap-1">
                                    <UserIcon className="h-4" />
                                    <p>
                                      {`${respondent_user.firstname} ${respondent_user.lastname}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })()
                      ) : (
                        <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                          <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4 h-full flex items-center" />
                          <div className="hidden sm:block mx-auto">
                            <AcceptIcon
                              status="completed_right"
                              small={false}
                            />
                          </div>
                          <div className="block sm:hidden mx-auto">
                            <AcceptIcon status="completed_right" small />
                          </div>
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {dateToString(
                                    request_section_slot.start,
                                    'weekday-hour',
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>{request_section.name}</p>
                              </div>
                              <div className="flex flex-row items-center gap-1">
                                <UserIcon className="h-4" />
                                <p>
                                  {`${respondent_user.firstname} ${respondent_user.lastname}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  ),
                )}
                {/* User's completed responses */}
                {Object.entries(data).map(([id_itr, itr]) =>
                  Object.entries(itr.my_responses.completed).map(
                    ([id_response, { request, my_response }]) => {
                      const request_section_slot =
                        itr.section_slots[request.id_shift_assignment];
                      const request_section =
                        itr.sections[request_section_slot.id_section];
                      const respondent_user =
                        itr.other_users[request.id_requestor];

                      return my_response.type ===
                        ExchangeResponseType.ONE_FOR_ONE ? (
                        (() => {
                          const response_section_slot =
                            itr.section_slots[my_response.id_shift_assignment!];
                          const response_section =
                            itr.sections[response_section_slot.id_section];

                          return (
                            <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                              <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                                <div className="flex flex-col justify-between items-start">
                                  <div className="flex flex-row items-start gap-1">
                                    <CalendarDaysIcon className="h-4 mt-0.5" />
                                    <p>
                                      {dateToString(
                                        request_section_slot.start,
                                        'weekday-hour',
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex flex-row items-start gap-1">
                                    <MapPinIcon className="h-4" />
                                    <p>{request_section.name}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="hidden sm:block">
                                <ExchangeIcon
                                  status={my_response.status}
                                  small
                                />
                              </div>
                              <div className="block sm:hidden">
                                <ExchangeIcon
                                  status={my_response.status}
                                  small
                                />
                              </div>
                              <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                                <div className="flex flex-col justify-between items-start">
                                  <div className="flex flex-row items-start gap-1">
                                    <CalendarDaysIcon className="h-4 mt-0.5" />
                                    <p>
                                      {dateToString(
                                        response_section_slot.start,
                                        'weekday-hour',
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex flex-row items-start gap-1">
                                    <MapPinIcon className="h-4" />
                                    <p>{response_section.name}</p>
                                  </div>
                                  <div className="flex flex-row items-center gap-1">
                                    <UserIcon className="h-4" />
                                    <p>
                                      {`${respondent_user.firstname} ${respondent_user.lastname}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })()
                      ) : (
                        // approved, or rejected/canceled one-for-zero
                        <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {dateToString(
                                    request_section_slot.start,
                                    'weekday-hour',
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>{request_section.name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="hidden sm:block">
                            <AcceptIcon status="completed_left" small />
                          </div>
                          <div className="block sm:hidden">
                            <AcceptIcon status="completed_left" small />
                          </div>
                          <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4 h-full flex items-center">
                            <div className="flex flex-row items-center gap-1">
                              <UserIcon className="h-4" />
                              <p>
                                {`${respondent_user.firstname} ${respondent_user.lastname}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  ),
                )}
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      </div>
    </>
  );
}

export default UserShiftExchangeMyRequests;
