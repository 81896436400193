// Import Chart.js
import { Chart, Tooltip } from 'chart.js';

Chart.register(Tooltip);

// Define Chart.js default settings
Chart.defaults.font.family = '"Inter", sans-serif';
Chart.defaults.font.weight = '500';
Chart.defaults.plugins.tooltip.borderWidth = 1;
Chart.defaults.plugins.tooltip.displayColors = false;
Chart.defaults.plugins.tooltip.mode = 'nearest';
Chart.defaults.plugins.tooltip.intersect = false;
Chart.defaults.plugins.tooltip.position = 'nearest';
Chart.defaults.plugins.tooltip.caretSize = 0;
Chart.defaults.plugins.tooltip.caretPadding = 20;
Chart.defaults.plugins.tooltip.cornerRadius = 4;
Chart.defaults.plugins.tooltip.padding = 8;

// Register Chart.js plugin to add a bg option for chart area
Chart.register({
  id: 'chartAreaPlugin',
  // eslint-disable-next-line object-shorthand
  beforeDraw: chart => {
    if (
      chart.config.options.chartArea
      && chart.config.options.chartArea.backgroundColor
    ) {
      const ctx = chart.canvas.getContext('2d');
      const { chartArea } = chart;
      ctx.save();
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      // eslint-disable-next-line max-len
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top,
      );
      ctx.restore();
    }
  },
});

export const chartColors = {
  textColor: {
    light: '#94A3B8', // Tailwind slate-400
    dark: '#64748B', // Tailwind slate-500
  },
  gridColor: {
    light: '#F1F5F9', // Tailwind slate-100
    dark: '#1E293B', // Tailwind slate-700
  },
  backdropColor: {
    light: '#FFFFFF', // Tailwind white
    dark: '#1E293B', // Tailwind slate-800
  },
  tooltipTitleColor: {
    light: '#1E293B', // Tailwind slate-800
    dark: '#F1F5F9', // Tailwind slate-100
  },
  tooltipBodyColor: {
    light: '#1E293B', // Tailwind slate-800
    dark: '#F1F5F9', // Tailwind slate-100
  },
  tooltipBgColor: {
    light: '#FFFFFF', // Tailwind white
    dark: '#334155', // Tailwind slate-700
  },
  tooltipBorderColor: {
    light: '#E2E8F0', // Tailwind slate-200
    dark: '#475569', // Tailwind slate-600
  },
  chartAreaBg: {
    light: '#F8FAFC', // Tailwind slate-50
    // For the dark mode chart area background, we manually convert the Tailwind slate-900 color to an RGBA value and adjust the opacity.
    dark: 'rgba(15, 23, 42, 0.24)', // Tailwind slate-900 with 24% opacity
  },
};
