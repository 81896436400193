import { TrashIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { slotLabelsQuery } from '@youshift/shared/hooks/queries';
import {
  ColorName,
  getShadeMap,
  getWeekdayInitials,
} from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { YSButton } from '../../../../../components/Buttons';
import DragCloseDrawer from '../../../../../components/DragCloseDrawer';
import CreateSlotType, {
  AddShiftTypeProps,
} from '../../../../../components/ItrConfig/CreateSlotType';
import EditShiftLabels from '../../../../../components/ItrConfig/EditShiftLabels';
import { SectionSlot } from '../../../../../components/SectionSlot';
import type {
  ArrangedSectionSlot,
  SectionSlotTemplate,
} from '../CreateNewSection';

type CreateShiftTemplateProps = {
  sectionSlotsTemplate: SectionSlotTemplate[];
  addShiftType: (props: AddShiftTypeProps) => void;
  sectionColor: ColorName;
  deleteSlot: (slotToDelete: ArrangedSectionSlot) => void;
  calculateEndTime: (startTime: string, durationMinutes: number) => string;
};

function CreateShiftTemplate({
  sectionSlotsTemplate,
  addShiftType,
  sectionColor,
  deleteSlot,
  calculateEndTime,
}: CreateShiftTemplateProps) {
  const [createShiftOpen, setCreateShiftOpen] = useState(false);
  const { idItr: id } = useParams();
  const { data: shiftLabels } = useQuery(slotLabelsQuery(id ?? ''));
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const days = getWeekdayInitials(t, currentLocale);

  const arrangedSectionSlots = useMemo(() => {
    const rows: ArrangedSectionSlot[][] = [];
    const orderedSlots: ArrangedSectionSlot[] = sectionSlotsTemplate
      .map(slot => {
        const [hours, minutes] = slot.start_time.split(':').map(Number);
        let start_position = slot.day_idx * 1440 + hours * 60 + minutes;
        // Ensure that start_position is always at least 1
        const end_position = start_position + slot.duration_minutes;
        if (start_position === 0) {
          start_position = 1;
        }
        const end_time = calculateEndTime(
          slot.start_time,
          slot.duration_minutes,
        );
        const arrangedSlot: ArrangedSectionSlot = {
          ...slot,
          start_position,
          end_position,
          end_time,
          spanning: false,
        };
        return arrangedSlot;
      })
      .sort((a, b) => {
        // First, compare by day_idx
        if (a.start_position !== b.start_position) {
          return a.start_position - b.start_position;
        }
        // If start_time is also the same, compare by duration_minutes
        return a.end_position - b.end_position;
      });
    // Iterate over each slot
    for (const slot of orderedSlots) {
      let placed = false;
      for (const row of rows) {
        if (row[row.length - 1].end_position <= slot.start_position) {
          row.push(slot);
          placed = true;
          break;
        }
      }
      if (!placed) {
        rows.push([slot]);
      }
    }
    return rows;
  }, [calculateEndTime, sectionSlotsTemplate]);

  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  if (shiftLabels === undefined) {
    return null;
  }

  return (
    <div className="px-8 rounded-lg w-full mx-auto">
      <div className="flex flex-row gap-3 items-center">
        <YSButton
          variant="outline-primary"
          onClick={() => setCreateShiftOpen(true)}
        >
          {t('manager.servicesConfig.newShiftSlot')}
        </YSButton>
        {shiftLabels.length ? (
          <EditShiftLabels
            shiftLabels={shiftLabels}
            sectionColor={sectionColor}
          />
        ) : null}
      </div>
      <div className="grid auto-cols-auto auto-rows-min mb-2 pb-2 grid-cols-7 mt-4">
        {days.map(day => (
          <div
            className="border text-white p-1 rounded-md bg-blue-600"
            key={day}
          >
            <p className="text-center font-bold">{day}</p>
          </div>
        ))}
        {arrangedSectionSlots.map((row, i) => (
          <div
            key={i}
            className="grid col-span-7"
            style={{ gridTemplateColumns: `repeat(${10080}, 1fr` }}
          >
            {row.map(slot => {
              const id_label = slot.id_slot_label;
              const shade = shadeMap[id_label];
              const icon = shiftLabels.find(
                label => label.id_slot_label === id_label,
              )?.icon;
              return (
                <SectionSlot
                  slot={slot}
                  sectionColor={sectionColor}
                  shade={shade}
                  onClick={deleteSlot}
                  Icon={TrashIcon}
                  labelIcon={icon}
                />
              );
            })}
          </div>
        ))}
      </div>
      <DragCloseDrawer
        open={createShiftOpen}
        setOpen={setCreateShiftOpen}
        modalHeight={42}
      >
        <CreateSlotType
          setOpen={setCreateShiftOpen}
          days={days}
          addShiftType={addShiftType}
          shiftLabels={shiftLabels}
        />
      </DragCloseDrawer>
    </div>
  );
}

export default CreateShiftTemplate;
