import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateEvent } from '@youshift/shared/hooks/mutations';
import { EventType, SpecialEvent } from '@youshift/shared/types';

import { YSButton } from '../../../components/Buttons';
import Alert from '../../../components/FormFeedback/Alert';

type NewRequestModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setRequests: Dispatch<SetStateAction<SpecialEvent[]>>;
  setError: Dispatch<SetStateAction<boolean | string>>;
};

export default function NewRequestModal({
  open,
  setOpen,
  setRequests,
  setError,
}: NewRequestModalProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    type: '',
    start: '',
    end: '',
    comment: '',
  });

  const [formError, setFormError] = useState<string | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const queryClient = useQueryClient();
  const createEvent = useCreateEvent(queryClient, {
    onSuccess: (data, variables, context) => {
      setRequests(prevRequests => [...prevRequests, data.event]);
      setOpen(false);
      setError(false);
      setFormError(null);
      setFormData({
        type: '',
        start: '',
        end: '',
        comment: '',
      });
    },
    onError: () => {
      setFormError(t('generic.error'));
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { start, end } = formData;
    const startDate = new Date(`${start}T00:00:00Z`);
    const endDate = new Date(`${end}T23:59:59Z`);

    if (startDate > endDate) {
      setFormError(t('eventCenter.startDateError'));
      return;
    }

    // prepare data with UTC times
    const formDataWithDateTime = {
      ...formData,
      start: startDate.toISOString(), // Ensures UTC format
      end: endDate.toISOString(), // Ensures UTC format
    };

    console.log(formDataWithDateTime);
    createEvent.mutate(formDataWithDateTime);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t('eventCenter.modalTitle')}
                </Dialog.Title>
                <p className="mt-2 text-sm text-gray-500">
                  {t('eventCenter.modalSubtitle')}
                </p>

                <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                  <div>
                    <label
                      htmlFor="type"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('eventCenter.type')}
                    </label>
                    <select
                      id="type"
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    >
                      <option value="">{t('eventCenter.selectType')}</option>
                      {Object.values(EventType).map(type => (
                        <option key={type} value={type}>
                          {t(`eventCenter.${type}`)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="start"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('eventCenter.startDate')}
                      </label>
                      <input
                        type="date"
                        id="start"
                        name="start"
                        value={formData.start}
                        onChange={handleInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="end"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('eventCenter.endDate')}
                      </label>
                      <input
                        type="date"
                        min={formData.start}
                        id="end"
                        name="end"
                        value={formData.end}
                        onChange={handleInputChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t('eventCenter.reason')}
                    </label>
                    <textarea
                      id="comment"
                      name="comment"
                      rows={3}
                      value={formData.comment}
                      onChange={handleInputChange}
                      placeholder={t('eventCenter.justify')}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                    />
                  </div>

                  <div className="mt-6">
                    <YSButton
                      type="submit"
                      disabled={
                        !formData.type ||
                        !formData.start ||
                        !formData.end ||
                        !formData.comment
                      }
                    >
                      {t('eventCenter.sendRequest')}
                    </YSButton>
                  </div>
                  {formError && <Alert text={formError} success={false} />}
                </form>

                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
