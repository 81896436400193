import { Dialog, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '@youshift/shared/api';
import { useDeleteUserMutation } from '@youshift/shared/hooks/mutations';
import { User } from '@youshift/shared/types';

import Alert from '../FormFeedback/Alert';

export default function DeleteUser({
  open,
  setOpen,
  setSuccess,
  user,
  setUser,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSuccess: (success: string | null) => void;
  user: User;
  setUser: (user: User | null) => void;
}) {
  const cancelButtonRef = useRef(null);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const deleteUserMutation = useDeleteUserMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['personnel'] });
      setSuccess(t('manager.team.deleteUser.success'));
      setError(null);
      setOpen(false);
      setUser(null);
    },
    onError: error => {
      setError(t('manager.team.deleteUser.error'));
      setSuccess(null);
    },
  });

  const saveChanges = async () => {
    deleteUserMutation.mutate({ id_user: user.id });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                    >
                      {t('manager.team.deleteUser.deleteDialog', {
                        firstName: user.firstname,
                        lastName: user.lastname,
                      })}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm">
                        {t('manager.team.deleteUser.confirmDeleteDialog', {
                          firstName: user.firstname,
                          lastName: user.lastname,
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    {t('manager.team.deleteUser.delete')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
