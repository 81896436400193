import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import bubbles from '../../assets/Logo/youshift_bubbles.png';

export default function NoData({ text, description }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <img src={bubbles} className="w-16" />
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {text}
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">{description}</p>
      <div className="mt-10">
        <button
          onClick={() => navigate(-1)}
          className="text-sm font-semibold leading-7 text-blue-600"
        >
          <span aria-hidden="true">&larr;</span>
          {t('generic.back')}
        </button>
      </div>
    </main>
  );
}
