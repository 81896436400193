import { classNames } from '@youshift/shared/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  NavLink,
  Navigate,
  Outlet,
  useParams,
} from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import {
  assignmentToolQuery,
  shiftAssignmentsQuery,
  AssignmentsResponse,
  itrUsersQuery,
  ItrUsersResponse,
  personnelQuery,
  sectionSlotsQuery,
  slotLabelsQuery,
} from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  Iteration,
  ItrStatuses,
  SectionsWithSlots,
  ShiftAssignment,
  SlotLabel,
  User,
  UserRole,
} from '@youshift/shared/types';
import { iterationQuery } from '@youshift/shared/hooks/jsQueries';

import { useItrContext } from './IterationLayout';
import { requireApproved, requireManager } from '../utils/checks';

type AssignmentLoader = AssignmentsResponse & {
  itrUsers: ItrUsersResponse;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  shift_assignments: ShiftAssignment[];
  sectionsWithSlots: SectionsWithSlots;
  shiftLabels: SlotLabel[];
  iteration: Iteration;
};
// TODO: this is not being used. figure out other views of assignment
export const iterationAssignmentLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<AssignmentLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const { idItr } = params;
    const { special_events, user_preferences, pref_slots } = await preLoadQuery(
      queryClient,
      assignmentToolQuery(idItr),
    );
    const shift_assignments = await preLoadQuery(
      queryClient,
      shiftAssignmentsQuery(idItr),
    );
    const sectionsWithSlots = await preLoadQuery(
      queryClient,
      sectionSlotsQuery(idItr),
    );
    const itrUsers = await preLoadQuery(queryClient, itrUsersQuery(idItr));
    const { users, roles } = await preLoadQuery(queryClient, personnelQuery());
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    return {
      sectionsWithSlots,
      shift_assignments,
      special_events,
      user_preferences,
      pref_slots,
      itrUsers,
      users,
      roles,
      shiftLabels,
      iteration,
    };
  };

export default function IterationAssignmentLayout() {
  const { status, locked, nameAndDate } = useItrContext();
  const { idItr: id } = useParams();
  const { t } = useTranslation();

  const tabs = useMemo(
    () => [
      { name: t('generic.calendar'), href: 'manual' },
      { name: t('manager.iterationLayouts.person'), href: 'person' },
      { name: t('manager.iterationLayouts.global'), href: 'all' },
      { name: t('manager.iterationLayouts.summary'), href: 'summary' },
    ],
    [t],
  );

  return (
    <>
      {/* {status === 'running-algo' ? <Navigate to="../running" replace /> : null}
      {status === 'failed-algo' ? <Navigate to="../failed" replace /> : null} */}
      {status === ItrStatuses.INITIALIZATION ? (
        <Navigate to="../initialization/roles" replace />
      ) : null}
      {status === ItrStatuses.CONFIGURATION ? (
        <Navigate to="../configuration/roles" replace />
      ) : null}
      {/* <div className="block">
        <nav
          className="isolate flex divide-x rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              id={tab.href}
              className={classNames(
                '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                tab.href === 'recorded-data'
                  ? 'bg-teal-400/50 hover:bg-teal-400/40 [&.active]:border-b-teal-500'
                  : '',
                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
              )}
            >
              <span>{tab.name}</span>
            </NavLink>
          ))}
        </nav>
      </div> */}
      <Outlet context={[locked]} />
    </>
  );
}
