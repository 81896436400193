/* eslint-disable no-else-return */

import { Params } from 'react-router-dom';

export const parseIterationDates = (
  start_day: string,
  end_day: string,
  itr_type: string,
) => {
  const start_date = new Date(start_day);
  const end_date = new Date(end_day);

  if (itr_type === 'MONTH') {
    const year = start_date.getUTCFullYear();
    const month = start_date.toLocaleString('default', {
      month: 'long',
      timeZone: 'GMT',
    });
    return `${month} ${year}`;
  } else {
    const start_day = start_date.toLocaleString('default', {
      day: 'numeric',
      month: 'long',
      timeZone: 'GMT',
    });
    const adjustedEndDate = new Date(end_date);
    adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

    const end_day = adjustedEndDate.toLocaleString('default', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'GMT',
    });
    return `${start_day} - ${end_day}`;
  }
};

export function getIdFromParams(params: Params): string {
  return params.id ?? '';
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// Helper function to get the day of the week (0 for Sunday, 6 for Saturday)
export function getDayOfWeek(date: Date) {
  return date.getUTCDay();
}

// Helper function to add days to a date
export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setUTCDate(result.getUTCDate() + days);
  return result;
}

// Helper function to subtract days from a date
export function subtractDays(date: Date, days: number) {
  return addDays(date, -days);
}

export function getDifferenceInHours(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    end.getMilliseconds() - start.getMilliseconds();

  // Convert milliseconds to hours
  const differenceInHours = Math.ceil(
    differenceInMilliseconds / (1000 * 60 * 60),
  );

  return differenceInHours;
}

export function getCustomDateRange(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference to adjust the start to the previous Monday and end to the next Sunday
  const startDayOfWeek = getDayOfWeek(start);
  const endDayOfWeek = getDayOfWeek(end);

  const startAdjustment = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; // Adjust Sunday (0) to be the previous Monday
  const endAdjustment = endDayOfWeek === 0 ? 0 : 7 - endDayOfWeek; // Adjust Sunday (0) to stay the same

  const adjustedStart = subtractDays(start, startAdjustment);
  const adjustedEnd = addDays(end, endAdjustment);

  const daysMatrix = [];
  let currentWeek = [];
  let currentDay = adjustedStart;

  while (currentDay <= adjustedEnd) {
    currentWeek.push(new Date(currentDay));
    if (currentWeek.length === 7) {
      daysMatrix.push(currentWeek);
      currentWeek = [];
    }
    currentDay = addDays(currentDay, 1);
  }

  // Add the last week if it wasn't completed
  if (currentWeek.length > 0) {
    daysMatrix.push(currentWeek);
  }
  return daysMatrix;
}

// react-native-big-calendar will convert any date coming from the backend to
// local timezone if we pass it as is. Because our dates are "absolute" or
// agnostic to timezones, this function "removes" the GMT marker so that the
// library interprets the date as local time without changing it.
// From Sat, 01 Nov 2025 00:00:00 GMT it returns new Date('2025-11-01T00:00:00')

export function backendToLocalDate(
  date: string,
): [number, number, number, number, number] {
  // // Parse the input string as a Date object (interpreted as UTC because of "GMT")
  // const utcDate = new Date(date);

  // // Format the Date object as a local time string (without timezone info)
  // const year = utcDate.getFullYear();
  // const month = String(utcDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  // const day = String(utcDate.getDate()).padStart(2, '0');
  // const hours = String(utcDate.getHours()).padStart(2, '0');
  // const minutes = String(utcDate.getMinutes()).padStart(2, '0');
  // const seconds = String(utcDate.getSeconds()).padStart(2, '0');

  // // Create a new Date string in "local time" format
  // const localDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  // // Return a new Date object based on the local date string
  // return new Date(localDateString);
  // Parse the date as UTC
  const utcDate = new Date(date);

  // Extract components in zero-based month format
  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth(); // Zero-based month (0 = January, 11 = December)
  const day = utcDate.getUTCDate();
  const hour = utcDate.getUTCHours();
  const minute = utcDate.getUTCMinutes();

  return [year, month, day, hour, minute];
}
