/* eslint-disable max-len */
import { Disclosure, Switch } from '@headlessui/react';
import { CursorArrowRippleIcon } from '@heroicons/react/24/outline';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import EventSquare from '../../../components/Calendars/EventSquare';
import ShiftOutputLegend from '../../../components/Calendars/ShiftOutputLegend';
import { bgColor, textColor } from '../../../utils/constantsLegacy';
import { iconTypes } from '../../../utils/constants';

export default function Global() {
  const [
    data,
    allShifts,
    setSelectedParticipant,
    setSelectedSection,
    setSelectedSlot,
    setOpen,
  ] = useOutletContext();
  const [showPreferences, setShowPreferences] = useState(false);

  const textColors = {};
  const bgColors = {};

  const getRoles = () => {
    const roles = {};
    data.itr_prefs.forEach(person => {
      const { role } = person.user;
      if (!roles[role.id]) {
        roles[role.id] = { name: role.name };
      }
    });
    return roles;
  };
  const rolesDict = getRoles();
  const [filteredRoles, setFilteredRoles] = useState(Object.keys(rolesDict));
  const [filteredShifts, setFilteredShifts] = useState(
    Object.keys(data.shifts).map(Number),
  );

  const handleFilterEdit = (id, type) => {
    if (type === 'shift') {
      setFilteredShifts(prev => (prev.includes(id)
        ? prev.filter(shiftId => shiftId !== id)
        : [...prev, id]));
    }

    if (type === 'role') {
      setFilteredRoles(prev => (prev.includes(id)
        ? prev.filter(roleId => roleId !== id)
        : [...prev, id]));
    }
  };

  const resetFilters = () => {
    setFilteredRoles(Object.keys(rolesDict));
    setFilteredShifts(Object.keys(data.shifts).map(Number));
  };

  const { t } = useTranslation();

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length];
    bgColors[key] = bgColor[index % bgColor.length];
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const scrollContainerRef = useRef(null);

  const scrollRight = () => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.scrollLeft += 100;
  };

  const scrollLeft = () => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.scrollLeft -= 100;
  };

  const parseWeekDay = start => {
    const weekdays = [
      t('calendars.sunday'),
      t('calendars.monday'),
      t('calendars.tuesday'),
      t('calendars.wednesday'),
      t('calendars.thursday'),
      t('calendars.friday'),
      t('calendars.saturday'),
    ];

    const date = new Date(start);
    const weekday = weekdays[date.getUTCDay()];
    const day = date.getUTCDate();

    return `${weekday} ${day}`;
  };

  const parseSlotsOff = () => {
    const slotsOff = {};
    data.slots.forEach(slot => {
      Object.keys(slot.shift_outputs)?.forEach(userId => {
        const output = slot.shift_outputs[userId];
        if (output.active && filteredShifts.includes(output.id_shift)) {
          if (!slotsOff[userId]) {
            slotsOff[userId] = new Set();
          }
          output.slots_off.forEach(slotOff => slotsOff[userId].add(slotOff.id_slot));
        }
      });
    });
    return slotsOff;
  };

  const slotsOff = parseSlotsOff();

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-row justify-between mt-8 mb-6 align-middle">
        <Switch.Group as="div" className="flex items-center gap-1">
          <Switch
            checked={showPreferences}
            onChange={() => setShowPreferences(!showPreferences)}
            className={classNames(
              showPreferences ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                showPreferences ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
            <span className="font-medium text-gray-900">
              {t('manager.iterationVerification.showPreferences')}
            </span>
          </Switch.Label>
        </Switch.Group>
        <div className="flex flex-row items-center gap-1">
          <CursorArrowRippleIcon className="text-teal-600 w-4" />
          <p className="text-gray-600 text-sm">
            {t(
              'manager.iterationVerification.shiftReassignmentGlobalExplanation',
            )}
          </p>
        </div>
      </div>
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="grid items-center border-b border-t border-gray-200"
      >
        <h2 id="filter-heading" className="sr-only">
          {t('manager.iterationVerification.filter')}
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="flex space-x-6 divide-x divide-gray-200 text-sm items-center">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {t('manager.iterationVerification.filter')}
              </Disclosure.Button>
            </div>
            <div className="pl-6 flex flex-row justify-between items-center w-full">
              <div>
                {filteredShifts.length
                  === Object.keys(data.shifts).length ? null : (
                  <p>
                    {t('generic.shifts')}
                    {': '}
                    {' '}
                    {filteredShifts
                      .map(id => data.shifts[id].name)
                      .join(', ')}
                  </p>
                )}
                {filteredRoles.length
                  === Object.keys(rolesDict).length ? null : (
                  <p>
                    {t('generic.roles')}
                    {': '}
                    {' '}
                    {filteredRoles.length === Object.keys(rolesDict).length
                      ? t('generic.all')
                      : filteredRoles
                        .map(id => rolesDict[id].name)
                        .join(', ')}
                  </p>
                )}
              </div>
              {filteredShifts.length !== Object.keys(data.shifts).length
                || filteredRoles.length !== Object.keys(rolesDict).length ? (
                <button
                  type="button"
                  className="text-gray-500 bg-gray-100 p-1.5 rounded-md"
                  onClick={() => resetFilters()}
                >
                  {t('manager.iterationVerification.reset')}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-5">
          <div className="mx-auto grid grid-cols-2 max-w-7xl gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <fieldset>
              <legend className="block font-medium">
                {t('generic.shifts')}
              </legend>
              <div className="pb-3 grid grid-cols-2 items-center">
                {Object.values(data.shifts).map(shift => (
                  <div
                    key={shift.id_shift}
                    className="flex items-center text-base sm:text-sm mt-2 md:mt-4"
                  >
                    <input
                      id={shift.id_shift}
                      name={shift.id_shift}
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      checked={filteredShifts.includes(shift.id_shift)}
                      onChange={() => handleFilterEdit(shift.id_shift, 'shift')}
                    />
                    <label
                      htmlFor={shift.id_shift}
                      className="ml-3 min-w-0 flex-1 text-gray-600"
                    >
                      {shift.name}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            <fieldset>
              <legend className="block font-medium">
                {t('generic.roles')}
              </legend>
              <div className="pb-3 grid grid-cols-2 items-center">
                {Object.entries(rolesDict).map(([id, { name }]) => (
                  <div
                    key={id}
                    className="flex items-center text-base sm:text-sm mt-2 md:mt-4"
                  >
                    <input
                      id={id}
                      name={name}
                      type="checkbox"
                      className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                      checked={filteredRoles.includes(id)}
                      onChange={() => handleFilterEdit(id, 'role')}
                    />
                    <label
                      htmlFor={id}
                      className="ml-3 min-w-0 flex-1 text-gray-600"
                    >
                      {name}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </Disclosure.Panel>
      </Disclosure>
      <div className="flex flex-row items-end justify-between">
        <ShiftOutputLegend />
        <div className="flex flex-row items-center justify-end gap-2">
          <button
            className="text-gray font-bold py-0 px-0 rounded mt-1"
            onClick={scrollLeft}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 bg-blue-500 text-white rounded"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <button
            className="text-gray font-bold py-0 px-0 rounded mt-1"
            onClick={scrollRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 bg-blue-500 text-white rounded"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto h-[65vh]" ref={scrollContainerRef}>
        <table className="min-w-full">
          <tbody>
            {/* DATES (first row) */}
            <tr className="sticky top-0 z-10">
              <th className="sticky left-0 z-10 bg-blue-100 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                {t('generic.date')}
              </th>
              {data.slots.map(slot => (
                <td
                  key={slot.id_slot}
                  className={classNames(
                    slot.start.slice(0, 3) === 'Sun' ? 'border-r' : '',
                    'whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium cursor-pointer text-gray-900 border-b border-gray-300 bg-white',
                  )}
                  onClick={() => {
                    setSelectedSlot(slot.id_slot);
                    setSelectedSection(null);
                    setSelectedParticipant(null);
                    setOpen(true);
                  }}
                >
                  {parseWeekDay(slot.start)}
                </td>
              ))}
            </tr>
            {/* USERS rows */}
            {data.itr_prefs
              .filter(person => filteredRoles.includes(String(person.user.role.id)))
              .sort((a, b) => `${a.user.firstname} ${a.user.lastname}`.localeCompare(
                `${b.user.firstname} ${b.user.lastname}`,
              ))
              .map(person => (
                <tr>
                  <th
                    className="sticky left-0 z-10 cursor-pointer bg-opacity-90 py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 border-b border-r bg-white"
                    key={person.id_user}
                    onClick={() => {
                      setSelectedParticipant(person.id_user);
                      setSelectedSlot(null);
                      setSelectedSection(null);
                      setOpen(true);
                    }}
                  >
                    {`${person.user.firstname} ${person.user.lastname}`}
                  </th>
                  {data.slots.map(slot => {
                    const isNotFiltered = filteredShifts.includes(
                      slot.shift_outputs[person.id_user]?.id_shift,
                    );
                    const output = isNotFiltered && slot.shift_outputs[person.id_user];
                    const slotOff = slotsOff[person.id_user]?.has(slot.id_slot);
                    const pref = slot.slot_prefs[person.id_user];
                    return (
                      <td
                        key={pref?.id_slot_pref}
                        className={classNames(
                          slot.start.slice(0, 3) === 'Sun' ? 'border-r' : '',
                          `${!output || !output.active ? 'cursor-pointer' : ''} whitespace-nowrap mx-auto text-center py-2 text-gray-900 border-b border-gray-200`,
                        )}
                        onClick={() => {
                          if (!output || !output.active) {
                            setSelectedParticipant(person.id_user);
                            setSelectedSlot(slot.id_slot);
                            setSelectedSection(null);
                            setOpen(true);
                          }
                        }}
                      >
                        <div className="flex flex-col gap-3">
                          {output && output.active ? (
                            <button
                              className={`items-center text-center rounded-md ${bgColors[output.id_shift]} ${textColors[output.id_shift]} py-2 px-1.5 text-gray-600 cursor-pointer`}
                              onClick={() => {
                                setSelectedParticipant(person.id_user);
                                setSelectedSlot(slot.id_slot);
                                setSelectedSection(output.id_shift);
                                setOpen(true);
                              }}
                            >
                              <div className="relative flex flex-row justify-center items-center gap-0.5">
                                <IconDisplay type={output?.type} />
                                {data.shifts[output.id_shift].acronym
                                  || data.shifts[output.id_shift].name}
                                {output.point_award ? (
                                  <span className="w-4 h-4 absolute -top-4 -right-2 bg-green-600 rounded-xl flex items-center justify-center">
                                    <p className=" text-white text-xxs text-center margin-auto align-middle ">
                                      {output.point_award}
                                    </p>
                                  </span>
                                ) : null}
                              </div>
                            </button>
                          ) : slotOff ? (
                            <EventSquare e="X" small />
                          ) : null}
                          {showPreferences ? (
                            pref.preference === 'P'
                              && pref.points === 0 ? null : (
                              <EventSquare
                                e={pref.preference}
                                points={pref.points}
                                justPoints
                                small
                              />
                            )
                          ) : null}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function IconDisplay({ type }) {
  const iconConfig = iconTypes[type];

  if (!iconConfig) return null;

  const { bgColor, Icon } = iconConfig;

  return (
    <p
      className={`absolute -top-4 -left-3 w-4 h-4 p-0.5 text-center ${bgColor} rounded-xl text-sm text-white`}
    >
      <Icon />
    </p>
  );
}
