/* eslint-disable max-len */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';
import { UserRole } from '@youshift/shared/types';
import { PersonnelReturn } from '@youshift/shared/hooks/queries';

import { usePersonnelContext } from '../../../layouts/TeamLayout';
import Alert from '../../../components/FormFeedback/Alert';
import EditRoles from '../../../components/Team/EditRoles';
import SearchBar from '../../../utils/searchBar';

function countUsersWithRole(
  personnel: PersonnelReturn,
  roleId: number,
): number {
  return Object.values(personnel.users).filter(
    user => user.id_user_role === roleId,
  ).length;
}

export default function Roles() {
  const personnel = usePersonnelContext();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<UserRole | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [filteredRoles, setFilteredRoles] = useState<UserRole[]>([]); // Used for search functionality

  const filterFunction = (item: UserRole, searchTerm: string) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase());

  useEffect(() => {
    if (personnel) {
      setFilteredRoles(Object.values(personnel.roles)); // Initially showing only accessible roles
    }
  }, [personnel]);

  return (
    <div className="mt-6 px-4 sm:px-6 lg:px-8">
      {open && (
        <EditRoles
          open={open}
          setOpen={setOpen}
          role={role}
          setRole={setRole}
          setSuccess={setSuccess}
          roles={Object.values(personnel?.roles) || []}
        />
      )}
      {personnel && Object.values(personnel.roles).length > 0 ? (
        <div className="flex flex-row items-center justify-between -ml-6">
          <SearchBar
            data={Object.values(personnel?.roles) || []}
            setFilteredData={setFilteredRoles}
            placeholder={t('manager.team.roles.searchRole')}
            noResultsMessage={t('manager.team.roles.noResults')}
            filterFunction={filterFunction}
          />
          <div className="sm:mt-0 sm:ml-16 mb-8">
            <button
              type="button"
              className="block rounded-md bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => {
                setRole(null);
                setOpen(true);
              }}
            >
              {t('manager.team.roles.addRole')}
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            {t('manager.team.roles.noRolesTitle')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {t('manager.team.roles.noRolesSubtitle')}
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => {
                setRole(null);
                setOpen(true);
              }}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {t('manager.team.roles.addRole')}
            </button>
          </div>
        </div>
      )}
      {success ? <Alert success text={success} /> : null}
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {filteredRoles.length > 0 && (
              <table className="min-w-full divide-y divide-gray-300 whitespace-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      {t('generic.firstName')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('generic.description')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('manager.team.roles.privateInfo')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('manager.team.roles.numberOfWorkers')}
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">{t('generic.edit')}</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredRoles
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(role => (
                      <tr key={role.id}>
                        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {role.name}
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-500">
                          {role.description}
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-500">
                          {role.sensitive_info}
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-500">
                          {countUsersWithRole(personnel, role.id)}
                        </td>
                        <td className="relative  py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <button
                            onClick={() => {
                              setRole(role);
                              setOpen(true);
                            }}
                            type="button"
                            className="text-blue-600 hover:text-blue-900"
                          >
                            {t('generic.edit')}
                            <span className="sr-only">,{role.name}</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
