import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { useState } from 'react';

export default function Alert({ success, text }) {
  const [shown, setShown] = useState(true);

  const bg = success
    ? 'bg-green-50 border-green-400'
    : 'border-yellow-400 bg-yellow-50';
  const bg2 = success
    ? 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50'
    : 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50';
  return shown ? (
    <div className={`rounded-md my-1 border-l-4 p-4 ${bg} my-2`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {success ? (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          ) : (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <p
            className={`text-sm font-medium ${success ? 'text-green-800' : 'text-yellow-800'}`}
          >
            {text}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${bg2}`}
              onClick={() => setShown(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
