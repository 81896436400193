import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from 'react-router-dom';

import logo from '../assets/Logo/logo_lq.png';

export default function NotFound({ text, description }) {
  const { t } = useTranslation();
  const errors = {
    404: {
      text: t('notFound.404.text'),
      description: t('notFound.404.description'),
    },
    500: {
      text: t('notFound.500.text'),
      description: t('notFound.500.description'),
    },
    403: {
      text: t('notFound.403.text'),
      description: t('notFound.403.description'),
    },
    400: {
      text: t('notFound.400.text'),
      description: t('notFound.400.description'),
    },
  };
  const error = useRouteError();
  return (
    <main className="grid min-h-full place-items-center bg-white px-8 py-24 sm:py-32 lg:px-20">
      <div className="text-center">
        <img className="h-24 w-auto my-0 mx-auto" src={logo} alt="YouShift" />
        <p className="text-base font-semibold text-blue-600">
          {error?.response?.status || ''}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {text
            || errors[error?.response?.status]?.text
            || t('notFound.generic.text')}
        </h1>
        <p className="mt-6 md:text-base text-sm leading-7 text-gray-600 ring-1 ring-inset lg:mx-24 ring-blue-600 rounded-lg px-8 py-6 bg-blue-100">
          {description
            || errors[error?.response?.status]?.description
            || t('notFound.generic.description')}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/login"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('generic.backHome')}
          </Link>
          <Link to="/contact" className="text-sm font-semibold text-gray-900">
            {t('generic.contactUs')}
            {' '}
            <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
