/* eslint-disable max-len */
import { Menu, Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { classNames } from '@youshift/shared/utils';
import { preLoadQuery } from '@youshift/shared/hooks';
import { personnelQuery } from '@youshift/shared/hooks/queries';


import { GroupStatsTable } from '../../components/Stats/GroupStatsTable';
import {
  calculatePercentages,
  calculateStatsPerShift,
  calculateStatsPerSlot,
  collectEventsAndVacations,
  createBaseShifts,
  createShiftLookup,
  mapItrIdToNameItrInfo,
  toggleItrSelection,
} from '../../components/Stats/utils';
import { requireApproved, requireManager } from '../../utils/checks';

export const getGroupStats = () => ({
  queryKey: ['stats'],
  queryFn: async () => request({ url: 'manager/fetch_group_stats', method: 'get' }),
});
export const groupStatsLoader = queryClient => async () => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  const query2 = getGroupStats();
  const wow = queryClient.getQueryData(query2.queryKey)
    ?? (await queryClient.fetchQuery(query2));

  const { personnel } = await preLoadQuery(queryClient, personnelQuery());

  return {
    stats: wow,
    personnel
  };
};

export default function GroupStats() {
  const { personnel, stats } = useLoaderData();
  const [selectedChain, setSelectedChain] = useState(Object.keys(stats)[0]);
  const {
    chain_name,
    itr_data: { itr_info, user_stats },
    shifts,
    slot_types,
  } = stats?.[selectedChain] ?? {};
  const [selectedItrs, setSelectedItrs] = useState(
    new Set(Object.keys(itr_info)),
  );

  const users = useMemo(() => {
    const simplified = {};
    personnel?.users.forEach(user => {
      
          simplified[user.id] = {
        name: `${user.firstname} ${user.lastname}`,
        role: personnel.roles[user.id_user_role].name,
      };
    });
    return simplified;
  }, [personnel]);

  const { t } = useTranslation();
  const itrIdToName = mapItrIdToNameItrInfo(itr_info);
  const shiftLookup = createShiftLookup(shifts);
  const baseShifts = createBaseShifts(shifts, shiftLookup);

  const [selectedShifts, setSelectedShifts] = useState(
    new Set(Object.keys(baseShifts)),
  );

  const { baselines, perUserCounts, userImbalances } = useMemo(() => {
    const baselineCounts = {};
    const perUserCounts = {};

    const getUserCurrentRole = userId => personnel.personnel.find(role => role.users.some(user => user.id === Number(userId)))?.id
      || user_stats[userId][user_stats[userId].length - 1]?.itr_prefs
        ?.id_user_role;

    Object.keys(user_stats).forEach(userId => {
      const roleId = getUserCurrentRole(userId);
      Object.keys(user_stats[userId])
        .filter(itrId => selectedItrs.has(itrId))
        .forEach(itrId => {
          if (user_stats[userId][itrId].itr_prefs.partaker) {
            Object.keys(user_stats[userId][itrId]?.shift_counts)
              .filter(shiftId => selectedShifts.has(String(shiftLookup[shiftId])))
              .forEach(shiftId => {
                const baseShiftId = shiftLookup[shiftId];
                if (!baselineCounts[roleId]) {
                  baselineCounts[roleId] = {};
                }
                if (!baselineCounts[roleId][baseShiftId]) {
                  baselineCounts[roleId][baseShiftId] = {
                    partakers: 0,
                    counts: 0,
                  };
                }
                baselineCounts[roleId][baseShiftId].partakers += 1;
                baselineCounts[roleId][baseShiftId].counts
                  += user_stats[userId][itrId].shift_counts[shiftId];

                if (
                  !Object.prototype.hasOwnProperty.call(perUserCounts, userId)
                ) {
                  perUserCounts[userId] = {};
                }
                if (
                  !Object.prototype.hasOwnProperty.call(
                    perUserCounts[userId],
                    baseShiftId,
                  )
                ) {
                  perUserCounts[userId][baseShiftId] = {
                    partakers: 0,
                    counts: 0,
                  };
                }
                perUserCounts[userId][baseShiftId].partakers += 1;
                perUserCounts[userId][baseShiftId].counts
                  += user_stats[userId][itrId].shift_counts[shiftId];
              });
          }
        });
    });
    const baselines = {};
    Object.keys(baselineCounts).forEach(roleId => {
      baselines[roleId] = {};
      Object.keys(baselineCounts[roleId]).forEach(shiftId => {
        const { partakers, counts } = baselineCounts[roleId][shiftId];
        baselines[roleId][shiftId] = counts / partakers || 0;
      });
    });
    const userImbalances = {};
    Object.keys(user_stats).forEach(userId => {
      const roleId = getUserCurrentRole(userId);
      userImbalances[userId] = 0;

      Object.keys(perUserCounts[userId] || {}).forEach(baseShiftId => {
        const perUserShiftCount = perUserCounts[userId][baseShiftId]?.counts
          / perUserCounts[userId][baseShiftId]?.partakers || 0;
        const baselineShiftCount = baselines[roleId][baseShiftId] || 0;
        userImbalances[userId] += baselineShiftCount - perUserShiftCount;
      });
    });

    return { baselines, perUserCounts, userImbalances };
  }, [user_stats, personnel, selectedItrs, shiftLookup, selectedShifts]);

  const statsPerUser = useMemo(() => {
    const statsPerUser = {};
    Object.entries(user_stats).forEach(([userId, itrStats]) => {
      const { events, vacations } = collectEventsAndVacations(
        selectedItrs,
        itrStats,
      );
      const { positivePercentage, negativePercentage } = calculatePercentages(
        selectedItrs,
        itrStats,
      );
      statsPerUser[userId] = {
        statsPerSlot: calculateStatsPerSlot(
          selectedItrs,
          itrStats,
          t,
          slot_types,
        ),
        statsPerShift: calculateStatsPerShift(
          selectedItrs,
          itrStats,
          shiftLookup,
          baseShifts,
        ),
        events: events.length,
        vacations: vacations.length,
        positivePercentage,
        negativePercentage,
      };
    });
    return statsPerUser;
  }, [baseShifts, selectedItrs, shiftLookup, slot_types, t, user_stats]);

  const toggleShiftSelection = shiftId => {
    const updatedSelectedShifts = new Set(selectedShifts);
    if (updatedSelectedShifts.has(shiftId)) {
      updatedSelectedShifts.delete(shiftId);
    } else {
      updatedSelectedShifts.add(shiftId);
    }
    setSelectedShifts(updatedSelectedShifts);
  };

  // if (isLoading) {
  //   return <p>Loading...</p>
  // }

  return (
    <div className="space-y-6 md:mt-16 px-2 sm:px-4 max-w-none">
      <div className="flex flex-row justify-between items-center">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
          {t('manager.stats.title')}
        </h3>
        <div className="flex flex-row gap-2">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                {chain_name}
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Menu.Items
              transition
              className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                {Object.entries(stats).map(([id, chain]) => (
                  <Menu.Item key={id}>
                    {({ focus }) => (
                      <button
                        onClick={() => {
                          setSelectedItrs(
                            new Set(Object.keys(stats[id].itr_data.itr_info)),
                          );
                          setSelectedChain(id);
                        }}
                        className={classNames(
                          focus ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm font-medium text-gray-900 text-start',
                        )}
                      >
                        {chain.chain_name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
          <Popover as="div" className="relative inline-block text-left">
            <div>
              <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                <span>{t('generic.iterations')}</span>
                {Object.keys(itr_info).length === selectedItrs.size ? (
                  <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                    {t('generic.all')}
                  </span>
                ) : null}
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>

            <Popover.Panel
              transition
              className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <form className="space-y-4">
                {Object.entries(itrIdToName).map(([id, name]) => (
                  <div key={id} className="flex items-center">
                    <input
                      id={`filter-${id}`}
                      name={`${id}[]`}
                      defaultValue={id}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={selectedItrs.has(id)}
                      onChange={() => toggleItrSelection(id, selectedItrs, setSelectedItrs)}
                    />
                    <label
                      htmlFor={`filter-${id}`}
                      className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                    >
                      {name}
                    </label>
                  </div>
                ))}
              </form>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <p className="text-xs text-gray-500">
        Esta pantalla aún está en desarrollo. Si algo no te cuadra o tienes
        sugerencias, no dudes en escribirnos por WhatsApp al 672 354 376 o email
        tech@you-shift.com
      </p>
      <GroupStatsTable
        users={users}
        statsPerUser={statsPerUser}
        baseShifts={baseShifts}
        baselines={baselines}
        perUserCounts={perUserCounts}
        userImbalances={userImbalances}
        selectedShifts={selectedShifts}
        toggleShiftSelection={toggleShiftSelection}
      />
    </div>
  );
}
