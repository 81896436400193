import { useTranslation } from 'react-i18next';

export default function Cookies() {
  const { t } = useTranslation();

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 text-justify">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t('cookies.title')}
        </h1>
        <p className="mt-6 leading-8">{t('cookies.content1')}</p>
        <p className="mt-6 leading-8">{t('cookies.content2')}</p>
        <p className="mt-6 leading-8">{t('cookies.content3')}</p>
        <p className="mt-6 leading-8">{t('cookies.content4')}</p>
        <p className="mt-6 leading-8">{t('cookies.lastUpdated')}</p>
      </div>
    </div>
  );
}
