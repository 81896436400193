import { LinkIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { classNames } from '@youshift/shared/utils';

import Alert from '../../components/FormFeedback/Alert';
import MergeGroups from './MergeGroups';
import NewOrg from './NewOrg';

export default function Tools() {
  const [newOrgOpen, setNewOrgOpen] = useState(false);
  const [mergeGroupsOpen, setMergeGroupsOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const actions = [
    {
      title: 'Crear nueva organización',
      onClick: () => setNewOrgOpen(true),
      icon: PlusCircleIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
    },
    {
      title: 'Merge dos grupos',
      onClick: () => setMergeGroupsOpen(true),
      icon: LinkIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },
  ];

  return (
    <>
      {success ? <Alert success text={success} /> : null}
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 mt-[15vh]">
        {newOrgOpen ? (
          <NewOrg
            open={newOrgOpen}
            setOpen={setNewOrgOpen}
            setSuccess={setSuccess}
          />
        ) : null}
        {mergeGroupsOpen ? (
          <MergeGroups
            open={mergeGroupsOpen}
            setOpen={setMergeGroupsOpen}
            setSuccess={setSuccess}
          />
        ) : null}
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500',
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white',
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <button onClick={action.onClick} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </button>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Doloribus dolores nostrum quia qui natus officia quod et
                dolorem. Sit repellendus qui ut at blanditiis et quo et
                molestiae.
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
