/* eslint-disable */

import React, { useState } from 'react';
import {
  CheckBadgeIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  MinusIcon,
  PlusIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import type { Section, SectionSlot, User } from '@youshift/shared/types';
import { parseSectionSlotDateTime } from '@youshift/shared/utils';
import { Disclosure } from '@headlessui/react';

import { AssignmentCheckErrors } from '../../pages/Manager/ManualAssignment';
import ErrorBox from './ErrorBox';
import { AssignmentCheckErrorType } from '../../utils/assignment_checks/types';
import i18n from '../../utils/i18n';
import UserReqTable from './UserReqTable';
import TableDisclosure from './TableDisclosure';

type Props = {
  errors: AssignmentCheckErrors;
  incompatibilitiesNames: Record<string, string>;
  rulesNames: Record<string, string>;
  users: Record<number, User>;
  sectionSlots: Record<number, SectionSlot>;
  sections: Record<number, Section>;
};

interface IconDetails {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconColor: string;
}

const errorToIconMap: Record<string, IconDetails> = {
  user_req_deficit: {
    icon: MinusIcon,
    iconColor: '#F87171',
  },
  user_req_surplus: {
    icon: PlusIcon,
    iconColor: '#FBBF24',
  },
  cross_group_incompatibility: {
    icon: UsersIcon,
    iconColor: '#F87621',
  },
  single_group_incompatibility_deficit: {
    icon: UserMinusIcon,
    iconColor: '#3EB489',
  },
  single_group_incompatibility_surplus: {
    icon: UserPlusIcon,
    iconColor: '#787171',
  },
};

const PostChecksDisplay: React.FC<Props> = ({
  errors,
  users,
  incompatibilitiesNames,
  rulesNames,
  sectionSlots,
  sections,
}) => {
  const { t } = useTranslation();
  const locale = i18n.language;

  const [activeTab, setActiveTab] = useState('rulesAndIncompatibilities');

  const userFullNameMap: Record<string | number, string> = Object.fromEntries(
    Object.entries(users).map(([userId, user]) => [
      userId,
      `${user.firstname} ${user.lastname.slice(0, 1)}.`,
    ]),
  );

  const dateTimeOfSlotId = (slotId: string): string => {
    const sectionSlot = sectionSlots[Number(slotId)];
    return parseSectionSlotDateTime(sectionSlot.start, sectionSlot.end, locale);
  };

  // converts list of ids to comma separated names (max 5)
  const idsListToNames = (ids: string[] | number[]): string =>
    ids
      .map((userId: string | number) => userFullNameMap[userId])
      .slice(0, 5)
      .join(', ') + (ids.length > 5 ? ', ...' : '');

  return (
    <div>
      {/* Tab Navigation */}
      <div className="grid grid-cols-2 mb-4 gap-2">
        <button
          onClick={() => setActiveTab('rulesAndIncompatibilities')}
          className={`tab-button ${
            activeTab === 'rulesAndIncompatibilities' ? 'active' : ''
          } px-2 py-1 border-b-2 font-semibold text-sm ${
            activeTab === 'rulesAndIncompatibilities'
              ? 'border-blue-500'
              : 'border-gray-200'
          }`}
        >
          {t('manager.assignmentErrors.schedulingErrors')}
        </button>
        <button
          onClick={() => setActiveTab('userAssignments')}
          className={`tab-button ${
            activeTab === 'userAssignments' ? 'active' : ''
          } px-2 py-1 border-b-2 font-semibold text-sm ${
            activeTab === 'userAssignments'
              ? 'border-blue-500'
              : 'border-gray-200'
          }`}
        >
          {t('manager.assignmentErrors.userErrors')}
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'rulesAndIncompatibilities' && (
        <div>
          {/* User Requirement Rules */}
          <h2 className="font-semibold mb-2 text-md">{t('generic.rules')}</h2>
          {Object.entries(errors.user_req_rules).map(([reqId, ruleErrors]) => {
            const noErrors = Object.keys(ruleErrors).length === 0;
            const ruleName = rulesNames[Number(reqId)];
            return (
              <TableDisclosure
                ruleErrors={ruleErrors}
                name={ruleName}
                userFullNameMap={userFullNameMap}
                noErrors={noErrors}
              />
            );
          })}

          <h2 className="font-semibold mb-2 text-md">
            {t('generic.incompatibilities')}
          </h2>
          {/* Single Group Incompatibilities */}
          {Object.values(errors.single_group_incompatibilities).length === 0 &&
          Object.values(errors.cross_group_incompatibilities).length === 0 ? (
            <p>{t('manager.incomp.noGroups')}</p>
          ) : null}
          {Object.entries(errors.single_group_incompatibilities).map(
            ([incompId, incompErrors]) => {
              const noErrors = Object.keys(incompErrors).length === 0;
              const incompName = incompatibilitiesNames[incompId];
              return (
                <TableDisclosure
                  singleIncompErrors={incompErrors}
                  name={incompName}
                  noErrors={noErrors}
                  userFullNameMap={userFullNameMap}
                  sections={sections}
                  sectionSlots={sectionSlots}
                />
              );
            },
          )}
          {/* Cross Group Incompatibilities */}
          {Object.entries(errors.cross_group_incompatibilities).map(
            ([incompId, slots]) => {
              const noErrors = Object.keys(slots).length === 0;
              const incompName = incompatibilitiesNames[incompId];
              return (
                <TableDisclosure
                  crossIncompErrors={slots}
                  name={incompName}
                  noErrors={noErrors}
                  userFullNameMap={userFullNameMap}
                  sections={sections}
                  sectionSlots={sectionSlots}
                />
              );
            },
          )}
        </div>
      )}

      {activeTab === 'userAssignments' && (
        <div>
          {Object.entries(userFullNameMap)
            .sort(([userIdA, fullNameA], [userIdB, fullNameB]) => {
              const hasErrorA = !!errors.user_assignments?.[Number(userIdA)];
              const hasErrorB = !!errors.user_assignments?.[Number(userIdB)];

              // users with errors go first
              if (hasErrorA && !hasErrorB) return -1;
              if (!hasErrorA && hasErrorB) return 1;

              // then alphabetically
              return fullNameA.localeCompare(fullNameB);
            })
            .map(([userId, fullName]) => {
              const userErrors = errors.user_assignments?.[Number(userId)];
              return (
                <TableDisclosure
                  userErrors={userErrors}
                  name={fullName}
                  userFullNameMap={userFullNameMap}
                  noErrors={Object.keys(userErrors || {}).length === 0}
                  sections={sections}
                  sectionSlots={sectionSlots}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default PostChecksDisplay;
