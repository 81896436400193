import React from 'react';
import {
  MinusIcon,
  PlusIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import type { Section, SectionSlot } from '@youshift/shared/types';

import { SingleGroupIncompatibilityErrors } from '../../pages/Manager/ManualAssignment';
import SectionSlotChip from '../Calendars/SectionSlotChip';

type Props = {
  incompErrors: SingleGroupIncompatibilityErrors[number];
  userFullNameMap: Record<number, string>;
  sections: Record<number, Section>;
  sectionSlots: Record<number, SectionSlot>;
};

const SingleIncompTable: React.FC<Props> = ({
  incompErrors,
  userFullNameMap,
  sections,
  sectionSlots,
}) => {
  const deficits = incompErrors.single_group_incompatibility_deficit || {};
  const surpluses = incompErrors.single_group_incompatibility_surplus || {};

  const deficitSlots = Object.entries(deficits);
  const surplusSlots = Object.entries(surpluses);

  const { t } = useTranslation();

  // If no deficits or surpluses, render nothing (or you could return null)
  if (deficitSlots.length === 0 && surplusSlots.length === 0) {
    return null;
  }

  return (
    <div className="">
      {/* Deficit Table */}
      {deficitSlots.length > 0 && (
        <div className="my-2">
          <div className="flex flex-row items-center">
            <UserMinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-teal-500" />
            <h3 className="text-base font-semibold p-2">
              {t(
                'manager.assignmentErrors.single_group_incompatibility_deficit_title',
              )}
            </h3>
          </div>
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-1 py-1 text-left">{t('generic.slot')}</th>
                    <th className="px-1 py-1 text-center">
                      {t('manager.assignmentErrors.assigned')}
                    </th>
                    <th className="px-1 py-1 text-center">
                      {t('generic.min')}
                    </th>
                    <th className="px-1 py-1 text-center">
                      {t('manager.assignmentErrors.missing')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {deficitSlots.map(
                    ([slotId, { assigned_users, min_simult }]) => {
                      const sectionSlot = sectionSlots[Number(slotId)];
                      const section = sections[sectionSlot.id_section];
                      const missing = min_simult - assigned_users.length;
                      return (
                        <tr key={slotId} className="hover:bg-gray-50">
                          <td className="px-1 py-1 text-sm font-medium text-gray-900">
                            <SectionSlotChip
                              small
                              sectionName={section?.acronym}
                              start={sectionSlot?.start}
                              end={sectionSlot?.end}
                            />
                          </td>
                          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_users.length}
                          </td>
                          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {min_simult}
                          </td>
                          <td className="px-1 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {missing}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Surplus Table */}
      {surplusSlots.length > 0 && (
        <div className="">
          <div className="flex flex-row items-center">
            <UserPlusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-gray-500" />
            <h3 className="text-base font-semibold p-2">
              {t(
                'manager.assignmentErrors.single_group_incompatibility_surplus_title',
              )}
            </h3>
          </div>
          <div className="mb-6 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-1 py-1 text-left">{t('generic.slot')}</th>
                    <th className="px-1 py-1 text-center">
                      {t('manager.assignmentErrors.assigned')}
                    </th>
                    <th className="px-1 py-1 text-center">
                      {t('generic.max')}
                    </th>
                    <th className="px-1 py-1 text-center">
                      {t('manager.assignmentErrors.excess')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {surplusSlots.map(
                    ([slotId, { assigned_users, max_simult }]) => {
                      const sectionSlot = sectionSlots[Number(slotId)];
                      const section = sections[sectionSlot.id_section];
                      const excess = assigned_users.length - max_simult;
                      return (
                        <tr key={slotId} className="hover:bg-gray-50">
                          <td className="px-1 py-1 text-sm font-medium text-gray-900">
                            <SectionSlotChip
                              small
                              sectionName={section.name}
                              start={sectionSlot.start}
                              end={sectionSlot.end}
                            />
                          </td>
                          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_users.length}
                          </td>
                          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {max_simult}
                          </td>
                          <td className="px-1 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {excess}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleIncompTable;
