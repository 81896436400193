/* eslint-disable jsx-a11y/control-has-associated-label */
import { Switch } from '@headlessui/react';
import {
  CursorArrowRippleIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { bgColor, textColor } from '../../../utils/constantsLegacy';

export default function Day() {
  const [
    data,
    allShifts,
    setSelectedParticipant,
    setSelectedSection,
    setSelectedSlot,
    setOpen,
    setOpenExport,
    setDataExport,
    setSuccess,
    slotSubsets,
    nameAndDate,
  ] = useOutletContext();

  const [showNeeds, setShowNeeds] = useState(false);

  const { t } = useTranslation();

  const textColors = {};
  const bgColors = {};

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length];
    bgColors[key] = bgColor[index % bgColor.length];
  });

  const parsedShiftNeeds = () => {
    const shiftNeeds = {};
    allShifts.shifts_needs.forEach(shiftNeed => {
      const { id_slot } = shiftNeed;
      Object.keys(shiftNeed.shift_needs).forEach(shiftId => {
        if (!shiftNeeds[id_slot]) {
          shiftNeeds[id_slot] = {};
        }
        shiftNeeds[id_slot][shiftId] = {
          min: shiftNeed.shift_needs[shiftId].min_need,
          max: shiftNeed.shift_needs[shiftId].max_need,
        };
      });
    });
    return shiftNeeds;
  };

  const shiftNeeds = parsedShiftNeeds();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const outputsByShift = data.slots.map(slot => {
    const slotOutputs = {};

    Object.values(slot.shift_outputs).forEach(output => {
      const shiftId = output.id_shift;

      if (!output.active) {
        return;
      }

      if (!slotOutputs[shiftId]) {
        slotOutputs[shiftId] = [];
      }

      const { user } = data.itr_prefs.find(e => e.id_user === output.id_user);

      slotOutputs[shiftId].push({
        id_user: output.id_user,
        id_output: output.id_output,
        id_slot: output.id_slot,
        shift_name: data.shifts[shiftId].name,
        name: `${user.firstname} ${user.lastname}`,
        role: user.role,
        sink: data.shifts[shiftId].sink,
      });
    });

    return {
      start: slot.start,
      id_slot: slot.id_slot,
      slot_outputs: slotOutputs,
    };
  });

  const parseWeekDay = start => {
    const weekdays = [
      t('calendars.sunday'),
      t('calendars.monday'),
      t('calendars.tuesday'),
      t('calendars.wednesday'),
      t('calendars.thursday'),
      t('calendars.friday'),
      t('calendars.saturday'),
    ];

    const date = new Date(start);
    const weekday = weekdays[date.getUTCDay()];
    const day = date.getUTCDate();

    return `${weekday} ${day}`;
  };

  const scrollContainerRef = useRef(null);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-row justify-between mt-8 mb-6 align-middle">
        <div className="flex flex-row gap-4">
          <Switch.Group as="div" className="flex items-center gap-1">
            <Switch
              checked={showNeeds}
              onChange={() => setShowNeeds(!showNeeds)}
              className={classNames(
                showNeeds ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showNeeds ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
              <span className="font-medium text-gray-900">
                {t('manager.iterationVerification.showNeeds')}
              </span>
            </Switch.Label>
          </Switch.Group>
          <button
            className="rounded-md bg-teal-500 hover:bg-teal-400 p-2.5 text-white my-3 flex self-end font-medium items-center flex-row gap-1.5"
            onClick={() => {
              setOpenExport(true);
              setDataExport({ outputs: outputsByShift, name: nameAndDate });
            }}
          >
            <PrinterIcon className="w-5" />
            {t('manager.iterationVerification.exportToPDF')}
          </button>
        </div>
        <div className="flex flex-row items-center gap-1">
          <CursorArrowRippleIcon className="text-teal-600 w-4" />
          <p className="text-gray-600 text-sm">
            {t('manager.iterationVerification.shiftReassignmentDayExplanation')}
          </p>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto h-[65vh]" ref={scrollContainerRef}>
        <table className="min-w-full">
          <tbody>
            {outputsByShift.map(slot => (
              <tr key={slot.id_slot} className="">
                <th
                  className="sticky cursor-pointer bg-white border-b border-r max-w-xs whitespace-nowrap left-0 z-10 py-4 pl-4 pr-3 text-right text-sm font-semibold"
                  onClick={() => {
                    setSelectedSlot(slot.id_slot);
                    setSelectedSection(null);
                    setSelectedParticipant(null);
                    setOpen(true);
                  }}
                >
                  {parseWeekDay(slot.start)}
                </th>
                {Object.values(slot.slot_outputs).length === 0 ? (
                  <td
                    onClick={() => {
                      setSelectedSlot(slot.id_slot);
                      setSelectedSection(null);
                      setSelectedParticipant(null);
                      setOpen(true);
                    }}
                    className="cursor-pointer"
                  />
                ) : (
                  <td className="border-b">
                    {Object.entries(slot.slot_outputs).map(
                      ([shiftId, shift]) => (
                        <div
                          key={shiftId}
                          className="flex flex-row gap-8 my-2 items-center"
                        >
                          <div className="flex flex-col">
                            <button
                              className={`${textColors[shiftId]} p-3 rounded-md`}
                              onClick={() => {
                                setSelectedSection(Number(shiftId));
                                setSelectedSlot(null);
                                setSelectedParticipant(null);
                                setOpen(true);
                              }}
                            >
                              {shift[0].shift_name}
                            </button>
                            {showNeeds && !shift[0].sink ? (
                              <p className="text-center font-light text-xs -mt-3">{`${shiftNeeds[slot.id_slot][shiftId]?.min}-${shiftNeeds[slot.id_slot][shiftId]?.max}`}</p>
                            ) : null}
                          </div>
                          {shift.map(person => (
                            <button
                              className={`${bgColors[shiftId]} ${textColors[shiftId]} p-3 rounded-md max-w-xs text-center content-center self-center`}
                              key={person.id_user}
                              onClick={() => {
                                setSelectedParticipant(person.id_user);
                                setSelectedSlot(slot.id_slot);
                                setSelectedSection(Number(shiftId));
                                setOpen(true);
                              }}
                            >
                              {person.name}
                              {/* <p>{person.role.name}</p> */}
                            </button>
                          ))}
                        </div>
                      ),
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
