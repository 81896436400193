import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  AssignmentsResponse,
  IncompatibilitiesResponse,
  ItrUsersResponse,
  extendedUserReqRulesQuery,
  assignmentToolQuery,
  incompatibilitiesQuery,
  iterationQuery,
  itrUsersQuery,
  personnelQuery,
  sectionSlotsQuery,
  shiftAssignmentsQuery,
  slotLabelsQuery,
} from '@youshift/shared/hooks/queries';
import {
  CrossGroupIncompatibilityRule,
  Iteration,
  PreferenceSlot,
  Rule,
  Section,
  SectionSlot,
  SectionSlotsDict,
  SectionsWithSlots,
  ShiftAssignment,
  SingleGroupIncompatibilityRule,
  SlotLabel,
  SpecialEvent,
  SpecialEventWithoutDates,
  User,
  UserPreference,
  UserReqRule,
  UserRole,
} from '@youshift/shared/types';
import { dateToString, getShadeMap } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';
import {
  CheckBadgeIcon,
  ChevronLeftIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/FormFeedback/Alert';
import Virgueria from '../../components/ManualAssignment/Virgueria';
import VerticalDragCloseDrawer from '../../components/VerticalDragCloseDrawer';
import Wrapper from '../../components/Wrapper';
import {
  checkAssignmentOnBlockingUserPreference,
  checkAssignmentOnSpecialEvent,
  checkAssignmentRestPeriod,
  checkAssignmentRests24Hours,
  checkCrossGroupIncomp,
  checkSingleGroupIncomp,
  checkUserReqs,
  findOverlappingSectionSlots,
} from '../../utils/assignment_checks/assignment_checks';
import {
  AssignmentCheckErrorType,
  AssignmentOnJustifiedBlockErrorContext,
  AssignmentOnPersonalBlockErrorContext,
  AssignmentOnSpecialEventErrorContext,
  AssignmentRestPeriodViolationErrorContext,
  AssignmentRests24HoursErrorContext,
  CrossGroupIncompErrorContext,
  SingleGroupIncompDeficitErrorContext,
  SingleGroupIncompSurplusErrorContext,
  UserReqDeficitErrorContext,
  UserReqSurplusErrorContext,
} from '../../utils/assignment_checks/types';
import { requireApproved, requireManager } from '../../utils/checks';
import PostChecksDisplay from '../../components/ManualAssignment/PostChecksDisplay';
import ShiftLabelLegend from '../../components/Calendars/ShiftLabelLegend';
import FilterDropdown from '../../components/FilterDropdown';
import UsersFilterDropdown from '../../FilterDropdownWithSubgroups';

type ManualAssignmentLoader = AssignmentsResponse & {
  itrUsers: ItrUsersResponse;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  sectionsWithSlots: SectionsWithSlots;
  shiftLabels: SlotLabel[];
  iteration: Iteration;
  incompatibilities: IncompatibilitiesResponse;
  allUserReqs: Record<number, UserReqRule>;
};

interface AssignmentsMap {
  byUser: Record<number, ShiftAssignment[]>;
  bySectionSlot: Record<number, ShiftAssignment[]>;
}

interface PreferencesMap {
  byUser: Record<number, UserPreference[]>;
  byPreferenceSlot: Record<number, UserPreference[]>;
  bySectionSlotbyUser: Record<number, Record<number, UserPreference>>;
}

interface EventsMap {
  byUser: Record<number, SpecialEvent[]>;
  // date in format dd/mm/yyyy, special event without dates; only event info as the date is the key already
  byDate: Record<string, SpecialEventWithoutDates[]>;
}

// EPA: Events, Preferences, Assignments
export interface Epa {
  events: EventsMap;
  preferences: PreferencesMap;
  assignments: AssignmentsMap;
}

// Define key types
type UserId = number;
type SectionSlotId = number;
type RequirementRuleId = number;
type IncompatibilityId = number;
type SlotId = number;

export type UserRequirementRuleErrors = {
  [id_req_rule: RequirementRuleId]: Partial<{
    [AssignmentCheckErrorType.USER_REQ_DEFICIT]: {
      [id_user: UserId]: UserReqDeficitErrorContext;
    };
    [AssignmentCheckErrorType.USER_REQ_SURPLUS]: {
      [id_user: UserId]: UserReqSurplusErrorContext;
    };
  }>;
};

export type SingleGroupIncompatibilityErrors = {
  [id_incompatibility: IncompatibilityId]: Partial<{
    [AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT]: {
      [id_slot: SlotId]: SingleGroupIncompDeficitErrorContext;
    };
    [AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS]: {
      [id_slot: SlotId]: SingleGroupIncompSurplusErrorContext;
    };
  }>;
};

export type CrossGroupIncompatibilityErrors = {
  [id_incompatibility: IncompatibilityId]: {
    [id_slot: SlotId]: CrossGroupIncompErrorContext;
  };
};

export type UserAssignmentErrors = {
  [id_user: UserId]: {
    [id_section_slot: SectionSlotId]: Partial<{
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT]: AssignmentOnSpecialEventErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK]: AssignmentOnJustifiedBlockErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK]: AssignmentOnPersonalBlockErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION]: AssignmentRestPeriodViolationErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_RESTS_24_HOURS]: AssignmentRests24HoursErrorContext;
    }>;
  };
};

// Main type
export type AssignmentCheckErrors = {
  /**
   * Errors related to user requirement rules.
   * Key: id_req_rule
   * Value: Error types mapped to users and their contexts.
   */
  user_req_rules: UserRequirementRuleErrors;

  /**
   * Errors from single group incompatibilities.
   * Key: id_incompatibility
   * Value: Error types mapped to slots and their contexts.
   */
  single_group_incompatibilities: SingleGroupIncompatibilityErrors;

  /**
   * Errors from cross-group incompatibilities.
   * Key: id_incompatibility
   * Value: Slots mapped to their error contexts.
   */
  cross_group_incompatibilities: CrossGroupIncompatibilityErrors;

  /**
   * Errors related to user assignments.
   * Key: id_user
   * Value: Section slot assignments mapped to error types and their contexts.
   */
  user_assignments: UserAssignmentErrors;
};

/* eslint-disable no-param-reassign */
/**
 * Helper function that emulates Python's defaultdict behavior for the user_assignments error dictionary.
 * Ensures that nested objects exist at the given userId and sectionSlotId paths before attempting to access them.
 * This avoids having to check for undefined at each level when adding errors.
 *
 * @param user_assignments - The user assignments error dictionary to populate
 * @param userId - The ID of the user to ensure exists in the dictionary
 * @param sectionSlotId - The ID of the section slot to ensure exists for the user
 */
function populateUserAssignmentErrorsDict(
  user_assignments: UserAssignmentErrors,
  userId: number,
  sectionSlotId: number,
): void {
  if (!(userId in user_assignments)) {
    user_assignments[userId] = {};
  }
  if (!(sectionSlotId in user_assignments[userId])) {
    user_assignments[userId][sectionSlotId] = {};
  }
}
/* eslint-enable no-param-reassign */

export const manualAssignmentLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<ManualAssignmentLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const { idItr } = params;
    const { special_events, user_preferences, pref_slots } = await preLoadQuery(
      queryClient,
      assignmentToolQuery(idItr),
    );
    // Load shift assignments.
    await preLoadQuery(queryClient, shiftAssignmentsQuery(idItr));

    const itrUsers = await preLoadQuery(queryClient, itrUsersQuery(idItr));
    const { users, roles } = await preLoadQuery(queryClient, personnelQuery());
    const sectionsWithSlots = await preLoadQuery(
      queryClient,
      sectionSlotsQuery(idItr),
    );
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    const incompatibilities = await preLoadQuery(
      queryClient,
      incompatibilitiesQuery(idItr),
    );
    const allUserReqs = await preLoadQuery(
      queryClient,
      extendedUserReqRulesQuery(idItr),
    );

    return {
      incompatibilities,
      allUserReqs,
      sectionsWithSlots,
      // From AssignmentTool query.
      special_events,
      user_preferences,
      pref_slots,
      // From ItrUsers query.
      itrUsers,
      users,
      roles,
      shiftLabels,
      iteration,
    };
  };

export default function ManualAssignment() {
  const { idItr: id } = useParams();
  const { t } = useTranslation();
  const {
    special_events: events,
    user_preferences: userPreferences,
    pref_slots: preferenceSlots,
    itrUsers,
    users,
    roles,
    iteration,
    sectionsWithSlots,
    shiftLabels,
    allUserReqs,
    incompatibilities,
  } = useLoaderData() as ManualAssignmentLoader;

  const [selectedPeople, setSelectedPeople] = useState(
    new Set(Object.keys(itrUsers)),
  );
  const [selectedSections, setSelectedSections] = useState(
    new Set(sectionsWithSlots.map(({ section }) => String(section.id_section))),
  );
  const [showNeeds, setShowNeeds] = useState(false);
  const [showRestPeriod, setShowRestPeriod] = useState(false);

  // MOSTRAR NEEDS DEFICIT
  const { data: shiftAssignments } = useQuery(shiftAssignmentsQuery(id || ''));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  const [error, setError] = useState<string | null>(null);

  const overlappingSlots: Record<number, number[]> = useMemo(() => {
    const all_section_slots: SectionSlot[] = sectionsWithSlots.flatMap(
      ({ section_slots }) => Object.values(section_slots),
    );

    const overlaps: Record<number, number[]> = {};
    all_section_slots.forEach(slot => {
      overlaps[slot.id_section_slot] = findOverlappingSectionSlots(
        slot,
        all_section_slots.filter(
          s => s.id_section_slot !== slot.id_section_slot,
        ),
      );
    });
    return overlaps;
  }, [sectionsWithSlots]);

  const assignmentsMap: AssignmentsMap = useMemo(() => {
    const byUser: Record<number, ShiftAssignment[]> = {};
    const bySectionSlot: Record<number, ShiftAssignment[]> = {};

    shiftAssignments?.forEach(assignment => {
      const { id_user, id_section_slot } = assignment;
      if (!bySectionSlot[id_section_slot]) {
        bySectionSlot[id_section_slot] = [];
      }
      if (!byUser[id_user]) {
        byUser[id_user] = [];
      }
      byUser[id_user].push(assignment);
      bySectionSlot[id_section_slot].push(assignment);
    });
    return { byUser, bySectionSlot };
  }, [shiftAssignments]);

  const preferencesMap: PreferencesMap = useMemo(() => {
    const byUser: Record<number, UserPreference[]> = {};
    const byPreferenceSlot: Record<number, UserPreference[]> = {};

    // Map UserPreference to SectionSlot and User. There can be at most one UserPreference per SectionSlot per User.
    // Outer key is SectionSlot, inner key is User.
    const bySectionSlotbyUser: Record<
      number,
      Record<number, UserPreference>
    > = {};
    Object.values(userPreferences).forEach(userPrefs =>
      userPrefs.forEach(pref => {
        const { id_user, id_pref_slot } = pref;
        if (!byUser[id_user]) {
          byUser[id_user] = [];
        }
        byUser[id_user].push(pref);

        if (!byPreferenceSlot[id_pref_slot]) {
          byPreferenceSlot[id_pref_slot] = [];
        }
        byPreferenceSlot[id_pref_slot].push(pref);
        // Get all section slots associated with this preference slot.
        const associated_section_slots: number[] =
          preferenceSlots[id_pref_slot].section_slots;
        // Expand the preference to all section slots associated with this preference slot.
        associated_section_slots.forEach(id_section_slot => {
          if (!bySectionSlotbyUser[id_section_slot]) {
            bySectionSlotbyUser[id_section_slot] = {};
          }
          bySectionSlotbyUser[id_section_slot][id_user] = pref;
        });
      }),
    );
    return { byUser, byPreferenceSlot, bySectionSlotbyUser };
  }, [userPreferences, preferenceSlots]);

  // 3 al 10
  const eventsMap: EventsMap = useMemo(() => {
    const byUser: Record<number, SpecialEvent[]> = {};
    const byDate: Record<string, SpecialEventWithoutDates[]> = {};
    events.forEach(event => {
      const { id_user, start, end, ...rest } = event;
      const end_date = new Date(end);

      if (!byUser[id_user]) {
        byUser[id_user] = [];
      }
      byUser[id_user].push(event);

      for (
        let date = new Date(start);
        date <= end_date;
        date.setDate(date.getDate() + 1)
      ) {
        const dateString = dateToString(date, 'dd/mm/yyyy');

        if (!byDate[dateString]) {
          byDate[dateString] = [];
        }
        // push a copy of the event with the current date as the start_date
        // we'll only be using the start date to check if a a user had an event in a given day
        byDate[dateString].push({ id_user, ...rest });
      }
    });
    return { byUser, byDate };
  }, [events]);

  const epa: Epa = {
    events: eventsMap,
    preferences: preferencesMap,
    assignments: assignmentsMap,
  };

  // Flatten sections with slots into dictionary of section slots
  const section_slots_dict: Record<number, SectionSlot> = useMemo(
    () =>
      sectionsWithSlots.reduce<SectionSlotsDict>(
        (acc, { section_slots }) => ({
          ...acc,
          ...section_slots,
        }),
        {},
      ),
    [sectionsWithSlots],
  );

  const sectionsDict: Record<number, Section> = useMemo(
    () =>
      sectionsWithSlots.reduce<Record<number, Section>>((acc, { section }) => {
        acc[section.id_section] = section;
        return acc;
      }, {}),
    [sectionsWithSlots],
  );

  const postExecChecks = () => {
    console.log('Computing errors...');
    const errors: AssignmentCheckErrors = {
      user_req_rules: {},
      single_group_incompatibilities: {},
      cross_group_incompatibilities: {},
      user_assignments: {},
    };
    // User Requirement Rules checks.
    Object.entries(allUserReqs).forEach(([id_req_rule_str, req_rule]) => {
      const id_req_rule: number = Number(id_req_rule_str);
      if (!errors.user_req_rules[id_req_rule]) {
        errors.user_req_rules[id_req_rule] = {};
      }

      Object.entries(req_rule.user_reqs).forEach(([id_user_str, user_req]) => {
        const id_user = Number(id_user_str);
        let single_user_assignments: ShiftAssignment[] = [];
        if (id_user in assignmentsMap.byUser) {
          single_user_assignments = assignmentsMap.byUser[id_user];
        }
        const user_req_error = checkUserReqs(
          user_req,
          req_rule.section_slots,
          single_user_assignments,
        );
        if (user_req_error) {
          const errorTypeObj =
            errors.user_req_rules[id_req_rule][user_req_error.type] ||
            (errors.user_req_rules[id_req_rule][user_req_error.type] = {});
          errorTypeObj[id_user] = user_req_error.context;
        }
      });
    });

    // Single Group Incompatibilities checks.
    Object.entries(incompatibilities?.single_group_incomp).forEach(
      ([id_incompatibility_str, single_group_incompatibility]) => {
        const id_incompatibility = Number(id_incompatibility_str);

        errors.single_group_incompatibilities[id_incompatibility] = {};

        // Incompatibilities are applied on a per-slot basis (i.e. slots are independent of each other).
        single_group_incompatibility.section_slots.forEach(id_slot_str => {
          const id_slot = Number(id_slot_str);
          // Assignment of users to the slot itself.
          let assignments_on_single_slot: ShiftAssignment[] = [];
          if (id_slot in assignmentsMap.bySectionSlot) {
            assignments_on_single_slot = assignmentsMap.bySectionSlot[id_slot];
          }
          // Assignment of users to overlapping slots.
          let assignments_on_overlapping_slots: ShiftAssignment[] = [];
          if (id_slot in overlappingSlots) {
            // Only get overlapping slots with higher IDs to avoid double counting
            const relevantOverlappingSlots = overlappingSlots[id_slot].filter(
              overlappingId => overlappingId > id_slot,
            );
            if (relevantOverlappingSlots.length > 0) {
              assignments_on_overlapping_slots =
                relevantOverlappingSlots.flatMap(
                  id_slot => assignmentsMap.bySectionSlot[id_slot] || [],
                );
            }
          }
          const single_group_incomp_error = checkSingleGroupIncomp(
            /* assignments_on_single_slot= */ assignments_on_single_slot,
            /* assignments_on_overlapping_slots= */ assignments_on_overlapping_slots,
            /* single_group_incomp_rule= */ single_group_incompatibility,
          );
          if (single_group_incomp_error) {
            const errorTypeObj =
              errors.single_group_incompatibilities[id_incompatibility][
                single_group_incomp_error.type
              ] ||
              (errors.single_group_incompatibilities[id_incompatibility][
                single_group_incomp_error.type
              ] = {});

            errorTypeObj[id_slot] = single_group_incomp_error.context;
          }
        });
      },
    );

    // Cross Group Incompatibilities checks.
    Object.entries(incompatibilities?.cross_group_incomp).forEach(
      ([id_incompatibility_str, cross_group_incompatibility]) => {
        const id_incompatibility = Number(id_incompatibility_str);
        errors.cross_group_incompatibilities[id_incompatibility] = {};
        // Incompatibilities are applied on a per-slot basis (i.e. slots are independent of each other).
        cross_group_incompatibility.section_slots.forEach(id_slot_str => {
          const id_slot = Number(id_slot_str);
          let assignments_on_single_slot: ShiftAssignment[] = [];
          if (id_slot in assignmentsMap.bySectionSlot) {
            assignments_on_single_slot = assignmentsMap.bySectionSlot[id_slot];
          }

          // Assignment of users to overlapping slots.
          let assignments_on_overlapping_slots: ShiftAssignment[] = [];
          if (id_slot in overlappingSlots) {
            // Only get overlapping slots with higher IDs to avoid double counting
            const relevantOverlappingSlots = overlappingSlots[id_slot].filter(
              overlappingId => overlappingId > id_slot,
            );
            if (relevantOverlappingSlots.length > 0) {
              assignments_on_overlapping_slots =
                relevantOverlappingSlots.flatMap(
                  id_slot => assignmentsMap.bySectionSlot[id_slot] || [],
                );
            }
          }
          const cross_group_incomp_error = checkCrossGroupIncomp(
            /* assignments_on_single_slot= */ assignments_on_single_slot,
            /* assignments_on_overlapping_slots= */ assignments_on_overlapping_slots,
            /* cross_group_incomp_rule= */ cross_group_incompatibility,
          );
          if (cross_group_incomp_error) {
            errors.cross_group_incompatibilities[id_incompatibility][id_slot] =
              cross_group_incomp_error.context;
          }
        });
      },
    );

    // User assignment checks (only run for users that have been assigned to any section_slot)
    for (const [id_user_str, single_user_shift_assignments] of Object.entries(
      assignmentsMap.byUser,
    )) {
      const id_user = Number(id_user_str);

      for (const shift_assignment of single_user_shift_assignments) {
        const section_slot: SectionSlot =
          section_slots_dict[shift_assignment.id_section_slot];

        let special_events_for_user: SpecialEvent[] = [];
        if (id_user in eventsMap.byUser) {
          special_events_for_user = eventsMap.byUser[id_user];
        }
        // Check if the shift_assignment overlaps with any special events for the user.
        const special_event_error = checkAssignmentOnSpecialEvent(
          shift_assignment,
          section_slot,
          special_events_for_user,
        );
        if (special_event_error) {
          // Create user assignment error object if it doesn't exist.
          populateUserAssignmentErrorsDict(
            errors.user_assignments,
            id_user,
            section_slot.id_section_slot,
          );

          errors.user_assignments[id_user][section_slot.id_section_slot][
            AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT
          ] = special_event_error.context;
        }
        // Check if the shift_assignment overlaps with any blocking user preferences for the user.
        const preference_error = checkAssignmentOnBlockingUserPreference(
          shift_assignment,
          section_slot,
          preferencesMap.bySectionSlotbyUser[section_slot.id_section_slot]?.[
            id_user
          ],
        );
        if (preference_error) {
          // Create user assignment error object if it doesn't exist.
          populateUserAssignmentErrorsDict(
            errors.user_assignments,
            id_user,
            section_slot.id_section_slot,
          );
          errors.user_assignments[id_user][section_slot.id_section_slot][
            preference_error.type
          ] = preference_error.context;
        }
        // Check if the shift assignment has its rest period honored.
        const rest_period_error = checkAssignmentRestPeriod(
          shift_assignment,
          single_user_shift_assignments,
          section_slots_dict,
        );
        if (rest_period_error) {
          // Create user assignment error object if it doesn't exist.
          // Create user assignment error object if it doesn't exist.
          populateUserAssignmentErrorsDict(
            errors.user_assignments,
            id_user,
            section_slot.id_section_slot,
          );
          errors.user_assignments[id_user][section_slot.id_section_slot][
            AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION
          ] = rest_period_error.context;
        }
        // Check if the shift assignment has an assignment within 24 hours of its end time (i.e. double shift).
        const rests_24_hours_error = checkAssignmentRests24Hours(
          shift_assignment,
          single_user_shift_assignments,
          section_slots_dict,
        );
        if (rests_24_hours_error) {
          // Create user assignment error object if it doesn't exist.
          populateUserAssignmentErrorsDict(
            errors.user_assignments,
            id_user,
            section_slot.id_section_slot,
          );
          errors.user_assignments[id_user][section_slot.id_section_slot][
            AssignmentCheckErrorType.SHIFT_ASSIGNMENT_RESTS_24_HOURS
          ] = rests_24_hours_error.context;
        }
      }
    }
    return errors;
  };

  const errors = postExecChecks();

  return (
    <Wrapper mt="mt-8">
      {error ? <Alert text={error} success={false} /> : null}
      {drawerOpen ? null : (
        <button
          onClick={() => setDrawerOpen(true)}
          className={`fixed ${errors ? 'bg-red-200 border-red-800' : 'bg-teal-200 border-teal-800'} text-gray-800 right-0 border  shadow-md rounded-md p-3`}
        >
          {Object.values(errors).some(
            errorDict => Object.values(errorDict).length > 0,
          ) ? (
            <>
              <ChevronLeftIcon className="w-7 h-7" />
              <ExclamationCircleIcon className="text-red-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
            </>
          ) : (
            <>
              <ChevronLeftIcon className="w-7 h-7" />
              <CheckBadgeIcon className="text-teal-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
            </>
          )}
        </button>
      )}
      <VerticalDragCloseDrawer open={drawerOpen} setOpen={setDrawerOpen}>
        <PostChecksDisplay
          errors={errors}
          users={users}
          incompatibilitiesNames={Object.fromEntries([
            ...Object.entries(incompatibilities.single_group_incomp).map(
              ([id, rule]) => [id, rule.name],
            ),
            ...Object.entries(incompatibilities.cross_group_incomp).map(
              ([id, rule]) => [id, rule.name],
            ),
          ])}
          rulesNames={Object.fromEntries(
            Object.entries(allUserReqs).map(([id, userReqRule]) => [
              userReqRule.rule.id_rule,
              userReqRule.rule.name,
            ]),
          )}
          sectionSlots={section_slots_dict}
          sections={sectionsDict}
        />
      </VerticalDragCloseDrawer>
      <div className="flex flex-row justify-between">
        <ShiftLabelLegend labels={shiftLabels} />
        <div className="flex flex-row gap-2 lg:gap-4 items-center">
          <div className="flex items-center pr-2 lg:pr-4 border-r border-r-gray-400">
            <input
              id="show-min-needs"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              checked={showNeeds}
              onChange={() => setShowNeeds(!showNeeds)}
            />
            <label
              htmlFor="show-min-needs"
              className="ml-3 text-sm font-medium text-gray-900"
            >
              {t('manager.iterationVerification.showNeeds')}
            </label>
          </div>

          <div className="flex items-center pr-2 lg:pr-4 border-r border-r-gray-400">
            <input
              id="show-libranza"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              checked={showRestPeriod}
              onChange={() => setShowRestPeriod(!showRestPeriod)}
            />
            <label
              htmlFor="show-libranza"
              className="ml-3 text-sm font-medium text-gray-900"
            >
              {t('manager.iterationVerification.showRestPeriod')}
            </label>
          </div>

          <div className="pr-2 lg:pr-4 border-r border-r-gray-400">
            <UsersFilterDropdown
              users={users}
              roles={roles}
              selectedUsers={selectedPeople}
              onSelectionChange={setSelectedPeople}
            />
          </div>
          <FilterDropdown
            label={t('generic.sections')}
            items={sectionsWithSlots.map(({ section }) => ({
              id: String(section.id_section),
              name: section.name,
            }))}
            selectedItems={selectedSections}
            onSelectionChange={setSelectedSections}
          />
        </div>
      </div>
      <Virgueria
        start={iteration.start_day}
        end={iteration.end_day}
        sectionsWithSlots={sectionsWithSlots
          .filter(({ section }) =>
            selectedSections.has(String(section.id_section)),
          )
          .map(({ section, section_slots }) => ({ ...section, section_slots }))}
        shadeMap={shadeMap}
        onClick={() => {}}
        version="virgueria"
        epa={epa}
        users={Object.fromEntries(
          Object.entries(users).filter(([userId]) =>
            Object.keys(itrUsers).includes(userId),
          ),
        )}
        setError={setError}
        labels={shiftLabels}
        showNeeds={showNeeds}
        showRestPeriod={showRestPeriod}
        selectedPeople={selectedPeople}
      />
    </Wrapper>
  );
}
