"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeRecords = exports.eventBgColor = exports.formatSlotDuration = exports.getShadeMap = exports.getCustomDateRange = exports.getDifferenceInMinutes = exports.getDifferenceInHours = exports.subtractDays = exports.addDays = exports.getDayOfWeek = exports.divideSlotsIntoWeeks = exports.arrangeSectionSlots = exports.getWeekdayInitials = exports.dateToString = exports.parseSectionSlotDateTime = exports.getFirstDayOfWeek = exports.classNames = exports.parseIterationDates = void 0;
var types_1 = require("../types");
/* eslint-disable no-else-return */
var parseIterationDates = function (start_day, end_day, itr_type) {
    var start_date = new Date(start_day);
    var end_date = new Date(end_day);
    if (itr_type === "MONTH") {
        var year = start_date.getUTCFullYear();
        var month = start_date.toLocaleString("default", {
            month: "long",
            timeZone: "GMT",
        });
        return "".concat(month, " ").concat(year);
    }
    else {
        var start_day_1 = start_date.toLocaleString("default", {
            day: "numeric",
            month: "long",
            timeZone: "GMT",
        });
        var adjustedEndDate = new Date(end_date);
        adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);
        var end_day_1 = adjustedEndDate.toLocaleString("default", {
            day: "numeric",
            month: "long",
            year: "numeric",
            timeZone: "GMT",
        });
        return "".concat(start_day_1, " - ").concat(end_day_1);
    }
};
exports.parseIterationDates = parseIterationDates;
function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(" ");
}
exports.classNames = classNames;
var getFirstDayOfWeek = function (locale) {
    switch (locale) {
        case "en-US":
        case "en":
            return 7;
        default:
            return 1;
    }
};
exports.getFirstDayOfWeek = getFirstDayOfWeek;
var parseSectionSlotDateTime = function (start_date, end_date, locale) {
    var start = new Date(start_date);
    var end = new Date(end_date);
    var startDateTime = start.toLocaleString(locale, {
        day: "numeric",
        month: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "GMT",
    });
    var endTime = end.toLocaleString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "GMT",
    });
    return "".concat(startDateTime, " - ").concat(endTime);
};
exports.parseSectionSlotDateTime = parseSectionSlotDateTime;
var dateToString = function (dateInput, format, locale) {
    var date = new Date(dateInput);
    switch (format) {
        case "only-day":
            return date.toLocaleString(undefined, {
                day: "2-digit",
                timeZone: "UTC",
            });
        case "weekday":
            return date.toLocaleString(locale, {
                weekday: "short",
                day: "2-digit",
                timeZone: "UTC",
            });
        case "weekday-hour":
            return date.toLocaleString(locale, {
                weekday: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "UTC",
            });
        case "hh:mm":
            return date.toLocaleString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "UTC",
            });
        case "dd/mm/yyyy":
            return date.toLocaleDateString(locale, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                timeZone: "UTC",
            });
        case "long":
            return date.toLocaleDateString(undefined, {
                day: "numeric",
                month: "long",
                weekday: "long",
                timeZone: "UTC",
            });
    }
};
exports.dateToString = dateToString;
var getWeekdayInitials = function (t, locale) {
    var days = [
        t("calendars.mo"),
        t("calendars.tu"),
        t("calendars.we"),
        t("calendars.th"),
        t("calendars.fr"),
        t("calendars.sa"),
        t("calendars.su"),
    ];
    var firstDay = (0, exports.getFirstDayOfWeek)(locale);
    if (firstDay === 1) {
        return days;
    }
    else {
        return days.slice(-1).concat(days).slice(0, -1);
    }
};
exports.getWeekdayInitials = getWeekdayInitials;
var arrangeSectionSlots = function (slots) {
    var slotArray = Object.values(slots).map(function (slot) { return (__assign(__assign({}, slot), { start: new Date(slot.start), end: new Date(slot.end) })); });
    // Sort the slots by their start time
    slotArray.sort(function (a, b) {
        if (a.start.getTime() - b.start.getTime() === 0) {
            return b.end.getTime() - a.end.getTime(); // Longer duration first
        }
        return a.start.getTime() - b.start.getTime();
    });
    // Array to hold the rows, each row is an array of non-overlapping slots
    var rows = [];
    // Iterate over each slot
    for (var _i = 0, slotArray_1 = slotArray; _i < slotArray_1.length; _i++) {
        var slot = slotArray_1[_i];
        var placed = false;
        // Try to place the slot in an existing row
        for (var _a = 0, rows_1 = rows; _a < rows_1.length; _a++) {
            var row = rows_1[_a];
            if (row[row.length - 1].end <= slot.start) {
                row.push(slot);
                placed = true;
                break;
            }
        }
        // If the slot couldn't be placed in any existing row, create a new row
        if (!placed) {
            rows.push([slot]);
        }
    }
    return rows;
};
exports.arrangeSectionSlots = arrangeSectionSlots;
var divideSlotsIntoWeeks = function (weeks, slots) {
    var slotsByWeek = weeks.map(function () { return []; });
    Object.keys(slots).forEach(function (slotKey) {
        var slot = slots[slotKey];
        var startTime = new Date(slot.start);
        var endTime = new Date(slot.end);
        for (var weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
            var week = weeks[weekIndex];
            var weekStart = new Date(week[0]);
            var weekEnd = weekIndex < weeks.length - 1
                ? new Date(weeks[weekIndex + 1][0])
                : addDays(new Date(week[6]), 1);
            if (weekStart <= startTime && startTime < weekEnd) {
                if (endTime <= weekEnd) {
                    // Non-spanning slot: starts and ends within the week
                    slotsByWeek[weekIndex].push(__assign(__assign({}, slot), { spanning: false }));
                }
                else {
                    // Spanning slot: split the slot
                    var splitSlot1 = __assign(__assign({}, slot), { spanning: "start" });
                    // const splitSlot2: SectionSlotWithSpanning = {
                    //   ...slot,
                    //   start: String(weekEnd),
                    //   spanning: "end",
                    // };
                    slotsByWeek[weekIndex].push(splitSlot1);
                    // if (weekIndex < weeks.length - 1) {
                    //   slotsByWeek[weekIndex + 1].push(splitSlot2);
                    // }
                }
                break; // Exit the loop since the slot has been handled
            }
        }
    });
    return slotsByWeek;
};
exports.divideSlotsIntoWeeks = divideSlotsIntoWeeks;
// Helper function to get the day of the week (0 for Sunday, 6 for Saturday)
function getDayOfWeek(date) {
    return date.getUTCDay();
}
exports.getDayOfWeek = getDayOfWeek;
// Helper function to add days to a date
function addDays(date, days) {
    var result = new Date(date);
    result.setUTCDate(result.getUTCDate() + days);
    return result;
}
exports.addDays = addDays;
// Helper function to subtract days from a date
function subtractDays(date, days) {
    return addDays(date, -days);
}
exports.subtractDays = subtractDays;
function getDifferenceInHours(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    // Calculate the difference in milliseconds
    var differenceInMilliseconds = end.getTime() - start.getTime();
    // Convert milliseconds to hours
    var differenceInHours = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60));
    return differenceInHours;
}
exports.getDifferenceInHours = getDifferenceInHours;
function getDifferenceInMinutes(startDate, endDate) {
    // Calculate the difference in milliseconds
    var start = new Date(startDate);
    var end = new Date(endDate);
    var differenceInMilliseconds = end.getTime() - start.getTime();
    // Convert milliseconds to minutes
    var differenceInMinutes = differenceInMilliseconds / (1000 * 60);
    return differenceInMinutes;
}
exports.getDifferenceInMinutes = getDifferenceInMinutes;
function getCustomDateRange(startDate, endDate, locale) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    // Calculate the difference to adjust the start to the previous Monday and end to the next Sunday
    var startDayOfWeek = getDayOfWeek(start);
    var endDayOfWeek = getDayOfWeek(end);
    var firstDayOfWeek = (0, exports.getFirstDayOfWeek)(locale);
    // Adjust the start date to the beginning of the week
    var startAdjustment = firstDayOfWeek === 7
        ? startDayOfWeek // Sunday is the first day
        : startDayOfWeek === 0
            ? 6 // Adjust Sunday (0) to the previous Monday (first day is Monday)
            : startDayOfWeek - 1;
    // Adjust the end date to the end of the week
    var endAdjustment = firstDayOfWeek === 7
        ? (6 - endDayOfWeek + 7) % 7 // Calculate days to the next Saturday
        : (7 - endDayOfWeek) % 7; // Calculate days to the next Sunday
    var adjustedStart = subtractDays(start, startAdjustment);
    var adjustedEnd = addDays(end, endAdjustment);
    var daysMatrix = [];
    var currentWeek = [];
    var currentDay = adjustedStart;
    while (currentDay <= adjustedEnd) {
        currentWeek.push(new Date(currentDay));
        if (currentWeek.length === 7) {
            daysMatrix.push(currentWeek);
            currentWeek = [];
        }
        currentDay = addDays(currentDay, 1);
    }
    // Add the last week if it wasn't completed
    if (currentWeek.length > 0) {
        daysMatrix.push(currentWeek);
    }
    console.log(daysMatrix);
    return daysMatrix;
}
exports.getCustomDateRange = getCustomDateRange;
function getShadeMap(shiftLabels) {
    var map = {};
    shiftLabels.forEach(function (label) {
        map[label.id_slot_label] = label.shade;
    });
    return map;
}
exports.getShadeMap = getShadeMap;
function formatSlotDuration(minutes) {
    var days = Math.floor(minutes / (24 * 60));
    minutes %= 24 * 60;
    var hours = Math.floor(minutes / 60);
    minutes %= 60;
    var result = [];
    if (days > 0) {
        result.push("".concat(days, " d"));
    }
    if (hours > 0) {
        result.push("".concat(hours, " h"));
    }
    if (minutes > 0 || (days === 0 && hours === 0)) {
        result.push("".concat(minutes, " min"));
    }
    return result.join(" ");
}
exports.formatSlotDuration = formatSlotDuration;
var eventBgColor = function (e, b, points, maxPoints) {
    if (e === types_1.UserPreferenceType.BLOCKED) {
        return b ? "rgba(255, 217, 84, 0.5)" : "rgba(255, 217, 84, 1)";
    }
    if (e === types_1.UserPreferenceType.UNAVAILABLE) {
        return b ? "rgba(57, 208, 255, 0.5)" : "rgba(57, 208, 255, 1)";
    }
    if (e === types_1.EventType.HOLIDAY) {
        return b ? "rgba(151, 71, 255, 0.5)" : "rgba(151, 71, 255, 1)";
    }
    if (Object.values(types_1.EventType).includes(e)) {
        return b ? "rgba(255, 84, 217, 0.5)" : "rgba(255, 84, 217, 1)";
    }
    if (Number(points) >= 0) {
        return b && points !== undefined && maxPoints
            ? "rgba(0, 157, 113, ".concat(Math.ceil((points / maxPoints) * 10) / 10, ")")
            : "rgba(0, 157, 113, 1)";
    }
    if (Number(points) < 0) {
        return b && points && maxPoints
            ? "rgba(255, 48, 48, ".concat(Math.ceil(-(points / maxPoints) * 10) / 10, ")")
            : "rgba(255, 48, 48, 1)";
    }
    return "#FFFF";
};
exports.eventBgColor = eventBgColor;
/**
 * Merges nested records from a data structure into a single flat record.
 *
 * @param data - A record where each value contains another record at the specified key
 * as well as many other records.
 * @param key - The key in each nested object containing the records to merge
 * @returns A single merged record combining all nested records at the specified key
 *
 * @example
 * const data = {
 *   1: { requests: { 100: {...}, 101: {...} } },
 *   2: { requests: { 102: {...}, 103: {...} } }
 * };
 * const merged = mergeRecords(data, 'requests');
 * // Result: { 100: {...}, 101: {...}, 102: {...}, 103: {...} }
 */
var mergeRecords = function (data, key) {
    var merged = {};
    for (var id in data) {
        // required to avoid type error guard-for-in
        if (Object.prototype.hasOwnProperty.call(data, id)) {
            merged = __assign(__assign({}, merged), data[id][key]);
        }
    }
    return merged;
};
exports.mergeRecords = mergeRecords;
// const mergeOtherUserRequests = (): Record<number, ExchangeRequest> => {
//   // Iterate over the 'itrs' object
//   let allOtherUserRequests: Record<number, ExchangeRequest> = {};
//   for (const id_itr in data) {
//     // required to avoid type error guard-for-in
//     if (Object.prototype.hasOwnProperty.call(data, id_itr)) {
//       allOtherUserRequests = {
//         ...allOtherUserRequests,
//         ...data[id_itr].other_users_pending_requests,
//       };
//     }
//   }
//   return allOtherUserRequests;
// };
