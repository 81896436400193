import { Switch } from '@headlessui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, LoaderFunctionArgs } from 'react-router-dom';
import { useMutation, useQueryClient, QueryClient } from '@tanstack/react-query';
import { request } from '@youshift/shared/api';
import { loadUserQuery } from '@youshift/shared/hooks/jsQueries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { User, Organization } from '@youshift/shared/types';
import { useUpdateNotificationSettingsMutation } from '@youshift/shared/hooks/mutations';


import Alert from '../components/FormFeedback/Alert';
import { useAuth } from '../utils/globalContext';
import { LanguageSelector } from '../utils/i18n';
import ChangePasswordPopup from './Auth/ChangePasswordPopup';


type AuthContextType = {
  removeToken: () => void;
} | null;

// Loader function to load user profile data from the query client
export const profileLoader = (queryClient: QueryClient) => async () => {
  const user = await preLoadQuery(queryClient, loadUserQuery());
  return user;
};


// Profile component definition
export default function Profile() {
  const auth = useAuth() as AuthContextType; // Hook to manage authentication, guaranteed non-null
  const user = useLoaderData() as User; // Load user data
  const navigate = useNavigate(); // Hook to navigate between routes
  const [passwordSuccess, setPasswordSuccess] = useState<boolean>(false); // State to track password change success

  const queryClient: QueryClient = useQueryClient(); // Query client instance to manage server state

  const { t } = useTranslation(); // Hook to manage translations

  const [open, setOpen] = useState<boolean>(false); // State to control the visibility of the password change popup
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(
    user.shift_exchange_notifications ?? false, // Provide a default value of false if undefined
  );

  if (!auth) {
    throw new Error('Authentication context is not available');
  }


  const { removeToken } = auth;

  // Function to log out the user
  const logOut = () => {
    removeToken(); // Remove authentication token
    queryClient.clear(); // Clear all cached queries
    navigate('/'); // Navigate to the home page
  };

  // Mutation to update notification settings
  const { mutate } = useUpdateNotificationSettingsMutation(queryClient, {
    onMutate: async newNotificationsSetting => {
      setNotificationsEnabled(newNotificationsSetting); // Temporarily set the new notification setting
    },
    onError: (err, newNotificationsSetting, context) => {
      setNotificationsEnabled(!newNotificationsSetting); // Revert to the previous state
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] }); // Invalidate profile query to refetch updated data
    },
  });



  return (
    <>
      <ChangePasswordPopup
        open={open}
        setOpen={setOpen}
        setSuccess={setPasswordSuccess}
      />
      <form>
        <div className="space-y-12 sm:space-y-16 md:mt-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {t('profile.personalInfo')}
            </h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              {t('profile.title')}
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              {/* First name field */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  {t('generic.firstName')}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    value={user.firstname || ''}
                    placeholder={user.firstname || ''}
                    autoComplete="given-name"
                    disabled
                    className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Last name field */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  {t('generic.lastName')}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    value={user.lastname || ''}
                    placeholder={user.lastname || ''}
                    disabled
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Email field */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  {t('generic.email')}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    disabled
                    value={user.email || ''}
                    placeholder={user.email || ''}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Organization field */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="org"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  {t('generic.organization')}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    id="org"
                    name="org"
                    type="text"
                    autoComplete="text"
                    value={user.org?.name || ''}
                    placeholder={user.org?.name || ''}
                    disabled
                    className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Password field */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  {t('generic.password')}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0 flex flex-row gap-3">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    placeholder="•••••••••••"
                    disabled
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-500 border-blue-600 border border-solid hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(true)}
                  >
                    {t('generic.edit')}
                  </button>
                </div>
                {passwordSuccess ? (
                  <Alert success text={passwordSuccess} />
                ) : null}
              </div>

              {/* Language selector field */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="language"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  {t('profile.language')}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <LanguageSelector />
                </div>
              </div>
            </div>

            {/* Notification switch for shift exchange notifications */}
            <ul role="list" className="mt-2 divide-y divide-gray-200">
              <Switch.Group
                as="li"
                className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6"
              >
                <div className="flex flex-col">
                  <Switch.Label
                    as="p"
                    className="text-sm font-medium leading-6 text-gray-900"
                    passive
                  >
                    {t('profile.shiftExchangeNotifications')}
                  </Switch.Label>
                  <Switch.Description className="text-sm text-gray-500">
                    {t('profile.shiftExchangeNotificationsDescription')}
                  </Switch.Description>
                </div>
                <Switch
                  checked={notificationsEnabled}
                  onChange={() => mutate(!notificationsEnabled)}
                  className={`${notificationsEnabled ? 'bg-teal-500' : 'bg-gray-200'} relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 ml-4`}
                >
                  <span
                    aria-hidden="true"
                    className={`${notificationsEnabled ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </Switch.Group>
            </ul>
          </div>
        </div>

        {/* Log out button */}
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={logOut}
            type="button"
          >
            {t('profile.logOut')}
          </button>
        </div>
      </form>
    </>
  );
}
