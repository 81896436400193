import { ColorName, getFirstDayOfWeek } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useCreateSectionMutation } from '@youshift/shared/hooks/mutations';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { SlotLabel } from '@youshift/shared/types';
import { preLoadQuery } from '@youshift/shared/hooks';
import { slotLabelsQuery } from '@youshift/shared/hooks/queries';

import { YSButton } from '../../../../components/Buttons';
import { requireApproved, requireManager } from '../../../../utils/checks';
import VerticalProgressBar from '../../../../components/VerticalProgressBar';
import CreateShiftTemplate from './NewSections/CreateShiftTemplate';
import SectionDetails from './NewSections/SectionDetails';
import { AddShiftTypeProps } from '../../../../components/ItrConfig/CreateSlotType';

export type SectionSlotTemplate = {
  start_time: string;
  duration_minutes: number;
  day_idx: number;
  id_slot_label: number;
  rest_period: number;
  min_need: number;
  max_need: number;
  id?: number;
  id_pref_slot?: number;
};

export type ArrangedSectionSlot = {
  start_position: number;
  end_position: number;
  end_time: string;
  day_idx?: number;
  spanning: false | 'start' | 'end';
} & Omit<SectionSlotTemplate, 'day_idx'>;

function calculateEndTime(startTime: string, durationMinutes: number) {
  // Split the start time into hours and minutes
  const [startHours, startMinutes] = startTime.split(':').map(Number);

  // Convert start time to total minutes from midnight
  const totalMinutes = startHours * 60 + startMinutes + durationMinutes;

  // Calculate the end time in hours and minutes, ensuring it wraps around if over 24 hours
  const endHours = Math.floor(totalMinutes / 60) % 24;
  const endMinutes = totalMinutes % 60;

  // Format the end time back to 'HH:MM'
  const formattedEndHours = endHours.toString().padStart(2, '0');
  const formattedEndMinutes = endMinutes.toString().padStart(2, '0');

  return `${formattedEndHours}:${formattedEndMinutes}`;
}

type NewSectionLoader = { labels: SlotLabel[] };
export const newSectionLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<NewSectionLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const labels = await preLoadQuery(
      queryClient,
      slotLabelsQuery(params.idItr),
    );
    return { labels };
  };

export function CreateNewSection() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { idItr: id } = useParams();
  const navigate = useNavigate();
  const currentLocale = i18n.language;

  const children = [
    { name: t('manager.servicesConfig.sectionDetails'), key: 'new' },
    { name: t('manager.servicesConfig.shiftCreation'), key: 'shifts' },
  ];
  const [childIndex, setChildIndex] = useState(0);

  // STEP 1
  const [sectionName, setSectionName] = useState('');
  const [acronym, setAcronym] = useState('');
  const [sectionColor, setSectionColor] = useState<ColorName>('green');

  const [sectionSlotsTemplate, setSectionSlotsTemplate] = useState<
    SectionSlotTemplate[]
  >([]);

  const shouldNextButtonBeDisabled = useMemo(
    () =>
      (sectionSlotsTemplate.length === 0 && childIndex === 1) ||
      sectionName === '' ||
      acronym === '',
    [sectionSlotsTemplate.length, childIndex, sectionName, acronym],
  );

  // Function to add a shift type to the template
  const addShiftType = ({
    start_time,
    duration_minutes,
    dayIndexes,
    id_slot_label,
    rest_period,
    min_need,
    max_need,
  }: AddShiftTypeProps) => {
    const newSlots = dayIndexes.map(day_idx => ({
      start_time,
      duration_minutes,
      day_idx,
      id_slot_label,
      rest_period,
      min_need,
      max_need,
    }));

    setSectionSlotsTemplate(prevSlots => [...prevSlots, ...newSlots]);
  };

  const deleteSlot = (slotToDelete: ArrangedSectionSlot) => {
    setSectionSlotsTemplate(prevSlots =>
      prevSlots.filter(
        slot =>
          !(
            slot.start_time === slotToDelete.start_time &&
            slot.day_idx === slotToDelete.day_idx &&
            calculateEndTime(slot.start_time, slot.duration_minutes) ===
              slotToDelete.end_time &&
            slot.id_slot_label === slotToDelete.id_slot_label
          ),
      ),
    );
  };

  const newSectionMutation = useCreateSectionMutation(queryClient, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['sections', id] });
      navigate(`../sections/${data.id_section}`);
    },
    onError: (error: Error) => {
      // Handle the error as needed
    },
  });

  const createSection = () => {
    if (!id) {
      return;
    }
    const firstDayOfWeek = getFirstDayOfWeek(currentLocale);

    newSectionMutation.mutate({
      name: sectionName,
      acronym,
      color: sectionColor,
      section_slots_template:
        firstDayOfWeek === 1
          ? sectionSlotsTemplate
          : // normalize day idx if sunday is the first day of the week
            sectionSlotsTemplate.map(slot => ({
              ...slot,
              day_idx: (slot.day_idx + 6) % 7,
            })),
      id_itr: Number(id),
    });
  };

  const returnChildComponent = useMemo(() => {
    switch (childIndex) {
      case 0:
        return (
          <SectionDetails
            name={sectionName}
            setName={setSectionName}
            acronym={acronym}
            setAcronym={setAcronym}
            color={sectionColor}
            setColor={setSectionColor}
          />
        );
      case 1:
        return (
          <CreateShiftTemplate
            sectionSlotsTemplate={sectionSlotsTemplate}
            addShiftType={addShiftType}
            deleteSlot={deleteSlot}
            sectionColor={sectionColor}
            calculateEndTime={calculateEndTime}
          />
        );
      default:
        return null;
    }
  }, [acronym, childIndex, sectionColor, sectionName, sectionSlotsTemplate]);

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <div className="self-center">
          <VerticalProgressBar steps={children} childIndex={childIndex} />
        </div>
        <div className="w-full">
          <div className="p-8 flex flex-row justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold mb-2 text-gray-800">
                {children[childIndex].name}
              </h1>
              <p className="text-gray-500">
                {t('generic.stepXofY', {
                  current: childIndex + 1,
                  total: children.length,
                })}
              </p>
            </div>
            <div className="flex-shrink-0 mt-6">
              <div className="flex flex-row gap-2 justify-end">
                <YSButton
                  variant="ghost-primary"
                  onClick={() => navigate('..', { relative: 'path' })}
                >
                  {t('generic.cancel')}
                </YSButton>
                {
                  // if first step, don't show previous button
                  childIndex === 0 ? null : (
                    <YSButton
                      onClick={() => setChildIndex(childIndex - 1)}
                      variant="secondary"
                    >
                      {t('generic.back')}
                    </YSButton>
                  )
                }
                {childIndex === children.length - 1 ? (
                  <YSButton
                    onClick={createSection}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.create')}
                  </YSButton>
                ) : (
                  <YSButton
                    onClick={() => setChildIndex(childIndex + 1)}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.next')}
                  </YSButton>
                )}
              </div>
            </div>
          </div>
          {returnChildComponent}
        </div>
      </div>
    </div>
  );
}
