// tableUtils.js
import { parseIterationDates } from '@youshift/shared/utils';

export const toggleItrSelection = (itrId, selectedItrs, setSelectedItrs) => {
  const updatedSelectedItrs = new Set(selectedItrs);
  if (updatedSelectedItrs.has(itrId)) {
    updatedSelectedItrs.delete(itrId);
  } else {
    updatedSelectedItrs.add(itrId);
  }
  setSelectedItrs(updatedSelectedItrs);
};

export const createShiftLookup = shifts => {
  const lookup = {};
  shifts.forEach(shift => {
    lookup[shift.id_shift] = shift.base_id_shift ?? shift.id_shift;
  });
  return lookup;
};

export const createBaseShifts = (shifts, shiftLookup) => {
  const shiftsMap = {};
  shifts.forEach(shift => {
    const baseId = shiftLookup[shift.id_shift];
    if (!shiftsMap[baseId] || shift.id_shift > shiftsMap[baseId].id_shift) {
      shiftsMap[baseId] = { name: shift.name, acronym: shift.acronym };
    }
  });
  return shiftsMap;
};

export const calculateStatsPerSlot = (
  selectedItrs,
  itrStats,
  t,
  slot_types,
) => {
  const cumulative = {};
  selectedItrs.forEach(itr => {
    Object.entries(itrStats[itr]?.slot_type_counts || {})?.forEach(
      ([slotType, count]) => {
        cumulative[slotType] = (cumulative[slotType] || 0) + count;
      },
    );
  });

  const days = [
    t('calendars.mo'),
    t('calendars.tu'),
    t('calendars.we'),
    t('calendars.th'),
    t('calendars.fr'),
    t('calendars.sa'),
    t('calendars.su'),
  ];
  return days.map((day, i) => {
    const slot_id = slot_types.find(type => type.cycle_idx === i)?.id_slot_type || i;
    return {
      slotType: slot_id,
      name: day,
      value: cumulative[slot_id] || 0,
    };
  });
};

export const calculateStatsPerShift = (
  selectedItrs,
  itrStats,
  shiftLookup,
  baseShifts,
) => {
  const cumulative = {};
  const perItr = {};
  selectedItrs.forEach(itr => {
    Object.entries(itrStats[itr]?.shift_counts || {}).forEach(
      ([shiftId, count]) => {
        const baseId = shiftLookup[shiftId];
        if (!perItr[baseId]) {
          perItr[baseId] = {};
        }
        if (!perItr[baseId][itr]) {
          perItr[baseId][itr] = 0;
        }
        perItr[baseId][itr] += count;
        cumulative[baseId] = (cumulative[baseId] || 0) + count;
      },
    );
  });
  return Object.entries(cumulative).map(([idShift, value]) => ({
    idShift,
    name: baseShifts[idShift]?.acronym || baseShifts[idShift]?.name,
    value,
    perItr: perItr[idShift],
  }));
};

export const collectEventsAndVacations = (selectedItrs, itrStats) => {
  const events = [];
  const vacations = [];
  selectedItrs.forEach(itr => {
    itrStats[itr]?.slot_prefs.forEach(slotPref => {
      const date = new Date(slotPref.slot.start);
      const eventData = {
        idSlot: slotPref.id_slot_pref,
        date: date.toLocaleString(undefined, {
          day: '2-digit',
          month: '2-digit',
          timeZone: 'GMT',
        }),
      };
      if (slotPref.preference === 'E') {
        eventData.justification = slotPref.justification;
        events.push(eventData);
      } else if (slotPref.preference === 'V') {
        vacations.push(eventData);
      }
    });
  });
  return { events, vacations };
};

const calculatePercentage = (numerator, denominator) => {
  if (denominator === 0) {
    return '-';
  }
  return `${Math.round((numerator / denominator) * 100)}%`;
};

export const calculatePercentages = (selectedItrs, itrStats) => {
  let total_pos = 0;
  let total_neg = 0;
  let total_pos_respected = 0;
  let total_neg_respected = 0;
  selectedItrs.forEach(itr => {
    total_pos += itrStats[itr]?.itr_prefs.total_pos || 0;
    total_neg += itrStats[itr]?.itr_prefs.total_neg || 0;
    total_pos_respected += itrStats[itr]?.itr_prefs.pos_respected || 0;
    total_neg_respected
      += itrStats[itr]?.itr_prefs.total_neg
      - itrStats[itr]?.itr_prefs.neg_not_respected || 0;
  });
  return {
    positivePercentage: calculatePercentage(total_pos_respected, total_pos),
    negativePercentage: calculatePercentage(total_neg_respected, total_neg),
  };
};

export const mapItrIdToName = itrStats => Object.entries(itrStats).reduce(
  (
    acc,
    [
      id,
      {
        itr: { end_day, start_day, itr_type },
      },
    ],
  ) => {
    acc[id] = parseIterationDates(start_day, end_day, itr_type);
    return acc;
  },
  {},
);

export const mapItrIdToNameItrInfo = itrInfo => Object.entries(itrInfo).reduce(
  (acc, [id, { end_day, start_day, itr_type }]) => {
    acc[id] = parseIterationDates(start_day, end_day, itr_type);
    return acc;
  },
  {},
);
