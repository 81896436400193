/* eslint-disable react/require-default-props */
import {
  CheckCircleIcon,
  ClockIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import {
  SpecialEventWithoutDates,
  User,
  UserPreferenceType,
} from '@youshift/shared/types';
import {
  ColorIndex,
  ColorName,
  LabelIcon,
  dateToString,
  returnColor,
} from '@youshift/shared/utils';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type { ArrangedSectionSlot } from '../pages/Manager/IterationConfig/Sections/CreateNewSection';
import type { Epa } from '../pages/Manager/ManualAssignment';
import CustomCombobox from './ManualAssignment/Combobox';
import LabelIconComponent from './LabelIconComponent';
import SlotCapacity from './ManualAssignment/SlotCapacity';

interface SectionSlotProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  onClick: (slot: ArrangedSectionSlot) => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  children?: ReactNode;
  labelIcon: LabelIcon | undefined;
}

interface SectionSlotSelectProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  onClick: (slotId: number) => void;
  children?: ReactNode;
  selectedSlots: Set<number>;
  labelIcon: LabelIcon | undefined;
}

interface SectionSlotVirgueriaProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  maxNeedsArray: number[];
  epa: Epa | undefined;
  users: Record<number, User> | undefined;
  start: Date;
  end: Date;
  setError: Dispatch<SetStateAction<string | null>>;
  labelIcon: LabelIcon | undefined;
  showRestPeriod: boolean;
  showNeeds: boolean;
  selectedPeople: Set<string>;
}

const borderOfSpanningSlot = (
  spanning: string | boolean,
): React.CSSProperties => {
  if (spanning === 'start') {
    return { borderRightStyle: 'dashed', borderRightWidth: '3px' };
  } else if (spanning === 'end') {
    return { borderLeftStyle: 'dashed', borderLeftWidth: '3px' };
  } else return {};
};

export function SectionSlot({
  slot,
  sectionColor,
  shade,
  onClick,
  Icon,
  labelIcon,
  children,
}: SectionSlotProps) {
  const { t } = useTranslation();

  return (
    <div
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className="rounded-md flex flex-col justify-between relative gap-0.5"
      style={{
        backgroundColor: returnColor(sectionColor, shade),
        border: '1px solid gray',
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
      }}
    >
      <div className="flex flex-row justify-between  items-center">
        <button
          className="ml-0.5 text-gray-500"
          onClick={() => onClick(slot)}
          aria-label={t('generic.delete')}
        >
          <Icon className="h-4 w-4" />
        </button>
        <LabelIconComponent
          className="w-4 h-4 text-gray-500"
          icon={labelIcon}
        />
      </div>
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <p
          className={`text-center ${
            slot.duration_minutes < 480
              ? 'text-xs px-0.5 pb-1'
              : 'text-xs px-2 pb-1'
          }`}
        >
          {`${slot.spanning === 'end' ? '' : slot.start_time} - ${slot.end_time}`}
        </p>
        {/* {children} */}
      </div>
      <ShowNeeds minNeed={slot.min_need} maxNeed={slot.max_need} />
      <ShowRestPeriod restPeriod={slot.rest_period} />
    </div>
  );
}

export function ShowNeeds({
  minNeed,
  maxNeed,
}: {
  minNeed: number;
  maxNeed: number;
}) {
  return (
    <div className="flex flex-row justify-center items-center gap-1 mx-1">
      <UserMinusIcon className="h-3 w-3 text-gray-500" />
      <p className=" text-gray-800 text-xs whitespace-nowrap">{`${minNeed} - ${maxNeed}`}</p>
      <UserPlusIcon className="h-3 w-3 text-gray-500" />
    </div>
  );
}
export function ShowRestPeriod({ restPeriod }: { restPeriod: number }) {
  return (
    <div className="flex flex-row justify-center items-center gap-1 mx-1 pb-1">
      <ClockIcon className="h-3 w-3 text-gray-500" />
      <p className="text-gray-800 text-xs whitespace-nowrap">{`${restPeriod} h`}</p>
    </div>
  );
}

export function SectionSlotSelect({
  slot,
  sectionColor,
  shade,
  onClick,
  children,
  selectedSlots,
  labelIcon,
}: SectionSlotSelectProps) {
  const { t } = useTranslation();
  if (slot.id === undefined) {
    return null;
  }
  const selected = selectedSlots.has(slot.id);
  const color = returnColor(sectionColor, shade);
  const darkestColor = returnColor(sectionColor, 900);
  return (
    <button
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className="rounded-md flex flex-col items-center justify-around relative gap-0.5 mt-1"
      style={{
        backgroundColor: selected ? color : 'white',
        border: '1px solid',
        borderColor: darkestColor,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        ...borderOfSpanningSlot(slot.spanning),
      }}
      onClick={() => onClick(slot.id!)}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <LabelIconComponent className="w-4 h-4" icon={labelIcon} />
        <p
          className={`text-center ${
            slot.duration_minutes < 480
              ? 'text-xs px-0.5 pt-2 pb-1'
              : 'text-xs px-2 pt-2 pb-1'
          }`}
        >
          {`${slot.spanning === 'end' ? '' : slot.start_time} - ${slot.end_time}`}
        </p>
      </div>
      <CheckCircleIcon
        className={`${selected ? 'block' : 'invisible'} ${shade < 400 ? 'text-gray-700' : 'text-white'} w-8 h-8 mx-auto`}
      />
    </button>
  );
}

export function SectionSlotVirgueria({
  slot,
  sectionColor,
  shade,
  maxNeedsArray,
  epa,
  users,
  start,
  end,
  setError,
  labelIcon,
  showRestPeriod,
  showNeeds,
  selectedPeople,
}: SectionSlotVirgueriaProps) {
  if (slot.id === undefined || epa === undefined || users === undefined) {
    return null;
  }

  const { events, preferences, assignments } = epa;
  const { bySectionSlot: assignmentsBySectionSlot } = assignments;
  const { byPreferenceSlot: preferenceByPrefSlot } = preferences;
  const { byDate: eventsByDate } = events;

  const slotStart = dateToString(start, 'dd/mm/yyyy');
  const slotEnd = dateToString(end, 'dd/mm/yyyy');

  let eventsOfTheDay: SpecialEventWithoutDates[] = [];
  if (slotStart === slotEnd) {
    eventsOfTheDay = eventsByDate[slotStart] ?? [];
  } else {
    eventsOfTheDay = [
      ...(eventsByDate[slotStart] ?? []),
      ...(eventsByDate[slotEnd] ?? []),
    ];
  }
  const preferencesOfSlot = preferenceByPrefSlot[slot.id_pref_slot!] || [];
  const assignmentsOfSlot = assignmentsBySectionSlot[slot.id!] || [];

  // a slot will be entirely faded out if it's full and only has non-selected people
  // otherwise, individual comboboxes will be fadedOut
  const fadedOut =
    assignmentsOfSlot.length === maxNeedsArray.length &&
    assignmentsOfSlot.every(
      assignment => !selectedPeople.has(String(assignment.id_user)),
    );

  // Function to get user preference based on events or slot preferences
  // returns: number of points, Blocked, or Event Type
  const getUserPref = (id: number) => {
    // Check if the user has an event
    const event = eventsOfTheDay?.find(event => event.id_user === id);
    if (event) {
      return event.type; // Return event type if found (e.g., 'V', 'E')
    }

    // Check for user preferences in the slot
    const preference = preferencesOfSlot?.find(
      preference => preference.id_user === id,
    );
    if (preference) {
      // Return 'B' if preference is 'B', otherwise return the points value
      return preference.preference === UserPreferenceType.POINTS
        ? preference.points
        : preference.preference;
    }

    // Return 0 if no event or preference is found
    return 0;
  };

  // Map through users to construct the people array
  const people = Object.values(users)
    .map(({ id, firstname, lastname }) => {
      const userPref = getUserPref(id);
      // Determine preference and points based on the type of userPref
      const isString = typeof userPref === 'string';
      const preference = isString ? userPref : UserPreferenceType.POINTS;
      const points = isString ? 0 : userPref;

      // Check if the user is assigned and active in any slots
      const assigned = assignmentsOfSlot.some(
        assignment => assignment.id_user == id && assignment.active,
      );

      // Return the user object with calculated properties
      return {
        id,
        name: `${firstname} ${lastname}`,
        preference,
        points,
        assigned,
      };
    })
    .sort((a, b) => {
      // Prioritize those with `assigned` set to `true`
      if (a.assigned !== b.assigned) {
        return a.assigned ? -1 : 1; // `true` should come before `false`
      }

      // For people with the same `assigned` status, prioritize by `points` (higher points come first)
      if (a.points !== b.points) {
        return b.points - a.points; // Sort by points in descending order
      }

      // For people with the same `assigned` status and `points`, prioritize by preference
      const aHasEvent = a.preference !== UserPreferenceType.POINTS;
      const bHasEvent = b.preference !== UserPreferenceType.POINTS;

      if (aHasEvent !== bHasEvent) {
        return aHasEvent ? 1 : -1; // People with 'B', 'V', or 'E' come last
      }

      // If all other criteria are the same, sort alphabetically by `name`
      return a.name.localeCompare(b.name);
    });

  const color = returnColor(sectionColor, shade);
  const darkestColor = returnColor(sectionColor, 900);

  return (
    <div
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className={`rounded-md flex flex-col items-center justify-around relative gap-0.5 mt-1 ${fadedOut ? 'opacity-20' : ''}`}
      style={{
        backgroundColor: color,
        border: `1px solid ${darkestColor}`,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        ...borderOfSpanningSlot(slot.spanning),
      }}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <LabelIconComponent
          className="w-4 h-4 text-gray-500"
          icon={labelIcon}
        />
        <p
          className={`text-center ${
            slot.duration_minutes < 480
              ? 'text-xs px-0.5 pt-1 pb-0.5'
              : 'text-xs px-1 pt-1 pb-0.5'
          }`}
        >
          {`${slot.spanning === 'end' ? '' : slot.start_time} - ${slot.end_time}`}
        </p>
        {/* <SlotCapacity
          minNeed={slot.min_need}
          maxNeed={slot.max_need}
          current={people.filter(person => person.assigned).length}
        /> */}
      </div>
      {showNeeds && (
        <ShowNeeds minNeed={slot.min_need} maxNeed={slot.max_need} />
      )}
      {showRestPeriod && <ShowRestPeriod restPeriod={slot.rest_period} />}
      <div className="text-start w-full px-0.5 text-xs flex flex-col gap-1 my-1">
        {maxNeedsArray.map(needIdx => (
          <CustomCombobox
            people={people}
            needIdx={needIdx}
            slot={slot}
            setError={setError}
            fadedOut={
              people[needIdx].assigned &&
              !selectedPeople.has(String(people[needIdx].id))
            }
          />
        ))}
      </div>
    </div>
  );
}
