"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRevertResponseMutation = exports.useCancelResponseMutation = exports.useAcceptResponseMutation = exports.useRejectResponseMutation = exports.useCancelRequestMutation = exports.usePostResponseMutation = exports.useApproveExchangeResponseMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useApproveExchangeResponseMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_response = params.id_response, approved = params.approved;
            return (0, api_1.request)({
                url: "/manager/shift_exchange/".concat(id_response, "/approve_manager"),
                method: "patch",
                data: { approved: approved },
            });
        } }, options), queryClient);
};
exports.useApproveExchangeResponseMutation = useApproveExchangeResponseMutation;
var usePostResponseMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_request = params.id_request, id_shift_assignment = params.id_shift_assignment, type = params.type;
            return (0, api_1.request)({
                url: "/user/shift_exchange/request/".concat(id_request, "/response/new"),
                method: "post",
                data: {
                    id_shift_assignment: id_shift_assignment,
                    type: type,
                },
            });
        } }, options), queryClient);
};
exports.usePostResponseMutation = usePostResponseMutation;
var useCancelRequestMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_request = params.id_request;
            return (0, api_1.request)({
                url: "/user/shift_exchange/request/".concat(id_request, "/cancel_request"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useCancelRequestMutation = useCancelRequestMutation;
var useRejectResponseMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_request = params.id_request, id_response = params.id_response;
            return (0, api_1.request)({
                url: "/user/shift_exchange/request/".concat(id_request, "/response/").concat(id_response, "/requestor_reject_response"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useRejectResponseMutation = useRejectResponseMutation;
var useAcceptResponseMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_request = params.id_request, id_response = params.id_response;
            return (0, api_1.request)({
                url: "/user/shift_exchange/request/".concat(id_request, "/response/").concat(id_response, "/requestor_accept_response"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useAcceptResponseMutation = useAcceptResponseMutation;
var useCancelResponseMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_request = params.id_request, id_response = params.id_response;
            return (0, api_1.request)({
                url: "/user/shift_exchange/request/".concat(id_request, "/response/").concat(id_response, "/respondent_cancel_response"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useCancelResponseMutation = useCancelResponseMutation;
var useRevertResponseMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_request = params.id_request, id_response = params.id_response;
            return (0, api_1.request)({
                url: "/user/shift_exchange/request/".concat(id_request, "/response/").concat(id_response, "/requestor_revert_response"),
                method: "patch",
            });
        } }, options), queryClient);
};
exports.useRevertResponseMutation = useRevertResponseMutation;
