/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarDaysIcon,
} from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  BuildingOffice2Icon,
  CalendarDateRangeIcon,
  CheckIcon,
  Cog8ToothIcon,
  EyeIcon,
  RocketLaunchIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { parseIterationDates } from '@youshift/shared/utils';
import { adminQuery } from '@youshift/shared/hooks/jsQueries';

import ApproveGroup from '../../components/Admin/ApproveGroup';
import DeleteGroup from '../../components/Admin/DeleteGroup';
import Alert from '../../components/FormFeedback/Alert';
import { requireAdmin } from '../../utils/checks';

export const adminDashboardLoader = queryClient => async () => {
  await requireAdmin(queryClient);
  const query = adminQuery();
  return (
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

export default function AdminDashboard() {
  const { data } = useQuery(adminQuery());
  const [selectedGroup, setGroup] = useState({});
  const [approvegroupopen, setApproveGroupOpen] = useState(false);
  const [deletegroupopen, setDeleteGroupOpen] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [success, setSuccess] = useState('');
  const { t } = useTranslation();
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [adminComments, setAdminComments] = useState(
    data?.orgs.reduce((acc, org) => {
      org?.groups.forEach(group => {
        acc[group.id_group] = group.admin_comment || '';
      });
      return acc;
    }, {}),
  );

  const handleCommentChange = (id_group, newComment) => {
    setAdminComments(prevComments => ({
      ...prevComments,
      [id_group]: newComment,
    }));
  };

  const saveCommentMutation = useMutation({
    mutationFn: ({ id_group, new_comment }) => request({
      url: `/admin/group/${id_group}/edit_admin_comment`,
      method: 'patch',
      data: { new_comment },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin']);
    },
    onError: () => {
      // eslint-disable-next-line no-alert
      alert('Error al guardar el comentario del administrador');
    },
  });

  const toggleInNotionMutation = useMutation({
    mutationFn: id_group => request({
      url: `/admin/group/${id_group}/toggle_notion`,
      method: 'patch',
    }),
    onMutate: async id_group => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['admin']);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(['admin']);

      // Optimistically update to the new value
      queryClient.setQueryData(['admin'], oldData => ({
        ...oldData,
        orgs: oldData.orgs.map(org => ({
          ...org,
          groups: org.groups.map(group => (group.id_group === id_group
            ? { ...group, in_notion: !group.in_notion }
            : group)),
        })),
      }));

      return { previousData };
    },
    onError: (err, id_group, context) => {
      // Rollback to the previous value
      queryClient.setQueryData(['admin'], context.previousData);
      console.error('Error toggling inNotion', err);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(['admin']);
    },
  });

  const editStatusMutation = useMutation({
    mutationFn: ({ id_group, status }) => request({
      url: `/admin/group/${id_group}/edit_status`,
      method: 'patch',
      data: { new_status: status },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['admin']);
    },
    onError: () => {
      // eslint-disable-next-line no-alert
      alert('Error al cambiar el estado del grupo');
    },
  });

  const groupsCreatedLast7Days = data.orgs
    .reduce(
      (acc, org) => acc.concat(
        org.groups
          .filter(
            group => new Date(group.created)
              >= new Date(new Date().setDate(new Date().getDate() - 7)),
          )
          .map(group => ({ ...group, orgName: org.name })),
      ),
      [],
    )
    .sort((a, b) => new Date(b.created) - new Date(a.created));

  const stats = [
    {
      id: 1,
      name: 'Usuarios',
      stat: data.orgs.reduce(
        (acc, org) => acc
          + org.groups
            .filter(group => group.status === 'active')
            .reduce(
              (groupAcc, group) => groupAcc + group.num_users - group.num_mock_users,
              0,
            ),
        0,
      ),
      icon: UserIcon,
      changeType: 'increase',
    },
    {
      id: 2,
      name: 'Grupos',
      stat: data.orgs.reduce(
        (acc, org) => acc + org.groups.filter(group => group.status === 'active').length,
        0,
      ),
      change: groupsCreatedLast7Days.filter(
        group => group.status === 'active',
      ).length,
      icon: UserGroupIcon,
      changeType: 'increase',
    },
    {
      id: 3,
      name: 'Hospitales',
      stat: data.orgs.filter(org => org.groups.some(group => group.status === 'active')).length,
      icon: BuildingOffice2Icon,
      changeType: 'increase',
    },
  ];

  const status_classes = {
    INITIALIZATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    CONFIGURATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    RUNNING_SMARTASS: [
      'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      t('generic.itrStatuses.running'),
      ArrowPathIcon,
      'bg-yellow-500',
    ],
    ASSIGNMENT: [
      'text-violet-800 bg-violet-50 ring-violet-600/20',
      t('generic.itrStatuses.assignment'),
      CalendarDateRangeIcon,
      'bg-violet-600',
    ],
    PUBLISHED: [
      'text-teal-800 bg-teal-50 ring-teal-600/20',
      t('generic.itrStatuses.published'),
      CheckIcon,
      'bg-teal-600',
    ],
    CLOSED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.closed'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
    ARCHIVED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.archived'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
  };

  const impersonateGroupMutation = useMutation({
    mutationFn: id_group => request({
      url: `/admin/group/${id_group}/impersonate_manager`,
      method: 'post',
    }),
    onSuccess: () => {
      navigate('/manager');
    },
    onError: () => { },
  });

  // const priorityGroups = []
  // const today = new Date()
  // const limitDate = new Date()
  // limitDate.setDate(limitDate.getDate() + 15)

  const isPriority = (group, chain) => {
    if (!chain.last_itr || group.num_users - group.num_mock_users <= 1) {
      return;
    }

    const currentDate = new Date();
    const endDay = new Date(chain.last_itr.end_day);
    const nextStartDay = new Date(chain.last_itr.start_day);

    // Calculate the difference in days
    const differenceInDaysToEnd = (endDay - currentDate) / (1000 * 60 * 60 * 24);
    const differenceInDaysToStart = (nextStartDay - currentDate) / (1000 * 60 * 60 * 24);

    // Check for published calendar for the next 15 days
    if (differenceInDaysToEnd <= 15 && chain.last_itr.status !== 'published') {
      return 'The group has not published a calendar for the next 15 days.';
    }

    // Check for next calendar published with a 15-day advance notice
    if (
      differenceInDaysToStart <= 15
      && chain.last_itr.status !== 'published'
    ) {
      return "The group has not published the next month's calendar with a 15-day advance notice.";
    }
    // if (new Date(chain.last_itr.end_day) <= today) {
    //   return {
    //     level: 'failed-algo',
    //     note: 'La última iteración tiene fecha de finalización anterior a la actual.',
    //   }
    // }

    // if (chain.last_itr.status === 'configuracion' && chain.last_itr.end_day <= limitDate) {
    //   return {
    //     level: 'need-check-up',
    //     note: 'La última iteración está en configuración faltando 15 días o menos para que esa iteración comience.',
    //   }
    // }

    // if (chain.last_itr.status === 'published' && chain.last_itr.end_day <= limitDate) {
    //   return {
    //     level: 'running-algo',
    //     note: 'La última iteración es la iteración actual y aún no han creado una nueva iteración a falta de 15 días o menos para comenzar.',
    //   }
    // }
  };

  const tabs = [
    { name: 'Potential Users', filter: 'potential' },
    { name: 'Onboarding', filter: 'onboarding' },
    { name: 'Active Users', filter: 'active' },
    { name: 'Inactive Users', filter: 'inactive' },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].filter);

  return (
    <div className="mt-6 px-4 sm:px-6 lg:px-8">
      {approvegroupopen ? (
        <ApproveGroup
          open={approvegroupopen}
          setOpen={setApproveGroupOpen}
          setSuccess={setSuccess}
          group={selectedGroup}
          setGroup={setGroup}
        />
      ) : null}
      {deletegroupopen ? (
        <DeleteGroup
          open={deletegroupopen}
          setOpen={setDeleteGroupOpen}
          setSuccess={setSuccess}
          group={selectedGroup}
          setGroup={setGroup}
        />
      ) : null}
      {success ? <Alert success text={success} /> : null}
      <div>
        <h1 className="font-bold text-3xl text-center">{t('admin.stats')}</h1>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map(item => (
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pb-3 pt-5 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-blue-600 p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {item.stat}
                </p>
                <p
                  className={classNames(
                    item.changeType === 'increase'
                      ? 'text-green-600'
                      : 'text-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold',
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon
                      className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {item.changeType === 'increase' ? 'Increased' : 'Decreased'}
                    by
                  </span>
                  {item.change}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="flow-root w-2/3 mx-auto border border-blue-600 bg-blue-600/5 rounded-md mt-8 p-4">
        <h1 className="font-bold text-xl text-start mb-4">
          {t('admin.lastSevenDays')}
        </h1>
        <ul role="list" className="">
          {groupsCreatedLast7Days.length > 0 ? (
            groupsCreatedLast7Days.map((event, eventIdx) => (
              <li key={eventIdx}>
                <div className="relative pb-8">
                  {eventIdx !== groupsCreatedLast7Days.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span className="h-8 w-8 rounded-full flex items-center justify-center bg-teal-600">
                        <RocketLaunchIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-medium text-gray-900">
                            {event.name}
                          </span>
                          {' - '}
                          {event.orgName}
                          {' - '}
                          {event.owner.firstname}
                          {' '}
                          {event.owner.lastname}
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <time dateTime={event.created}>
                          {new Date(event.created).toLocaleString(undefined, {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                            timeZone: 'GMT',
                          })}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p>{t('admin.noGroupsInSevenDays')}</p>
          )}
        </ul>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {data.groups_pending_approval.length > 0 ? (
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="font-bold text-xlleading-6 text-gray-900">
                    {t('admin.newGroupRequest')}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    {t('admin.groupListPendingApproval')}
                  </p>
                </div>
              </div>
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        {t('admin.groupName')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Fecha de Inscripción
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t('admin.organization')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t('admin.cityCountry')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t('admin.manager')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {t('admin.approved')}
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                      >
                        <span className="sr-only">{t('generic.edit')}</span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          ) : null}
          {/* Display of all Organizations */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="mt-8 font-bold text-xl leading-6 text-gray-900">
                  {t('admin.organizations')}
                </h1>
              </div>
              <div className="mt-4 flex items-center gap-2">
                <input
                  id="showComments"
                  type="checkbox"
                  checked={showComments}
                  onChange={() => setShowComments(!showComments)}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />
                <label
                  htmlFor="showComments"
                  className="text-sm font-medium text-gray-700"
                >
                  Show Admin Comments
                </label>
              </div>
            </div>
            <div className="mt-5">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex justify-between" aria-label="Tabs">
                  {tabs.map(tab => (
                    <button
                      key={tab.name}
                      onClick={() => setCurrentTab(tab.filter)}
                      className={classNames(
                        tab.filter === currentTab
                          ? 'border-blue-500 text-blue-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium',
                      )}
                      aria-current={
                        tab.filter === currentTab ? 'page' : undefined
                      }
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="mt-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          Organización
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Gestor
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Nº de Usuarios
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Cadenas
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {data.orgs
                        .sort((a, b) => {
                          // Get the last group creation date for both organizations, or use a default old date if not available
                          const lastDateA = a.groups.length > 0
                            ? new Date(
                              a.groups[a.groups.length - 1]?.created
                              || '1970-01-01',
                            )
                            : new Date('1970-01-01');
                          const lastDateB = b.groups.length > 0
                            ? new Date(
                              b.groups[b.groups.length - 1]?.created
                              || '1970-01-01',
                            )
                            : new Date('1970-01-01');

                          // Compare the dates
                          return lastDateB - lastDateA;
                        })
                        .map(listed_org => {
                          const filteredGroups = listed_org.groups.filter(
                            group => group.status === currentTab,
                          );
                          if (filteredGroups.length === 0) {
                            return null;
                          }
                          return (
                            <Fragment key={listed_org.name}>
                              <tr className="border-t border-gray-200">
                                <th
                                  colSpan={5}
                                  scope="colgroup"
                                  className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                >
                                  {listed_org.name}
                                </th>
                              </tr>
                              {filteredGroups.map((group, groupIdx) => (
                                <>
                                  <tr
                                    key={group.id_group}
                                    className={classNames(
                                      groupIdx === 0
                                        ? 'border-gray-300'
                                        : 'border-gray-200',
                                      'border-t',
                                    )}
                                  >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3">
                                      <div className="flex flex-row gap-2 items-center">
                                        <input
                                          id="inNotion"
                                          type="checkbox"
                                          checked={group.in_notion}
                                          onChange={() => toggleInNotionMutation.mutate(
                                            group.id_group,
                                          )}
                                          className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600 cursor-pointer"
                                        />
                                        <Link
                                          className="text-gray-800 hover:text-gray-600"
                                          to={`../group/${group.id_group}`}
                                        >
                                          <p className="font-medium">
                                            {group.name}
                                            {` (id_group: ${group.id_group})`}
                                          </p>
                                          <p
                                            className={`${new Date(group.created) >= new Date(new Date().setDate(new Date().getDate() - 3)) ? 'text-red-600 font-bold' : ''}`}
                                          >
                                            {new Date(
                                              group.created,
                                            ).toLocaleString(undefined, {
                                              day: '2-digit',
                                              month: 'long',
                                              year: 'numeric',
                                              timeZone: 'GMT',
                                            })}
                                          </p>
                                        </Link>
                                      </div>
                                      {group.specialty && (
                                        <p>
                                          {t(`specialties.${group.specialty}`)}
                                        </p>
                                      )}
                                      <div className="flex flex-row gap-2 items-center mt-2">
                                        <select
                                          id="status"
                                          name="status"
                                          className={classNames(
                                            group.status === 'potential'
                                              ? 'bg-yellow-200'
                                              : group.status === 'active'
                                                ? 'bg-green-200'
                                                : group.status === 'onboarding'
                                                  ? 'bg-blue-200'
                                                  : 'bg-red-200',
                                            'block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6',
                                          )}
                                          value={group.status}
                                          onChange={e => editStatusMutation.mutate({
                                            id_group: group.id_group,
                                            status: e.target.value,
                                          })}
                                        >
                                          <option value="potential">
                                            Potential
                                          </option>
                                          <option value="onboarding">
                                            Onboarding
                                          </option>
                                          <option value="active">Active</option>
                                          <option value="inactive">
                                            Inactive
                                          </option>
                                        </select>
                                        <div className="flex flex-row items-center gap-1 text-gray-500">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                                            />
                                          </svg>

                                          <p>{group.sourcing_origin}</p>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      <a className="text-blue-600 hover:text-blue-900">
                                        {group.owner.firstname}
                                        {' '}
                                        {group.owner.lastname}
                                        <div className="flex flex-row gap-1 items-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-4 h-4 text-gray-600"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                                            />
                                          </svg>
                                          <p>{group.owner.email}</p>
                                        </div>
                                        <div className="flex flex-row gap-1 items-center">
                                          {group?.owner?.phone && (
                                            <>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-4 h-4 text-gray-600"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                                />
                                              </svg>
                                              <p>{group.owner.phone}</p>
                                            </>
                                          )}
                                        </div>
                                      </a>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {group.num_mock_users > 0
                                        ? `R: ${group.num_users - group.num_mock_users} | F: ${group.num_mock_users}`
                                        : group.num_users}
                                    </td>

                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {group.chains.length > 0 ? (
                                        group.chains.map(chain => {
                                          if (!chain?.last_itr) {
                                            return (
                                              <p>
                                                {t(
                                                  'admin.didntCreateIteration',
                                                )}
                                              </p>
                                            );
                                          }
                                          const StatusIcon = status_classes[
                                            chain?.last_itr?.status
                                          ][2];
                                          const priority = isPriority(
                                            group,
                                            chain,
                                          );
                                          return (
                                            <div
                                              className={`my-3 flex flex-col gap-1 ${priority ? 'bg-red-200' : ''}`}
                                            >
                                              <p className="flex flex-col items-start gap-1">
                                                <span
                                                  className={`inline-flex flex-shrink-0 items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${status_classes[chain?.last_itr?.status][0]}`}
                                                >
                                                  <StatusIcon
                                                    className={`h-5 w-5 ${status_classes[chain?.last_itr?.status][0]}`}
                                                    aria-hidden="true"
                                                  />
                                                  {`${status_classes[chain?.last_itr?.status][1]}`}
                                                </span>
                                                <div className="flex flex-row">
                                                  <CalendarDaysIcon className="h-5 w-5" />
                                                  {parseIterationDates(
                                                    chain.last_itr.start_day,
                                                    chain.last_itr.end_day,
                                                    chain.last_itr.itr_type,
                                                  )}
                                                </div>
                                              </p>
                                              {priority && (
                                                <p className="text-xs text-red-600 font-bold whitespace-normal">
                                                  {priority}
                                                </p>
                                              )}
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <p>{t('admin.didntCreateIteration')}</p>
                                      )}
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                      <button
                                        type="button"
                                        className="rounded bg-green-100 px-2 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-200"
                                        onClick={() => {
                                          impersonateGroupMutation.mutate(
                                            group.id_group,
                                          );
                                        }}
                                      >
                                        {t('admin.manageGroup')}
                                      </button>
                                    </td>
                                  </tr>
                                  {showComments ? (
                                    <tr className="border-t border-gray-200">
                                      <td
                                        colSpan={5}
                                        className="py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3"
                                      >
                                        <div className="flex flex-row gap-2">
                                          {/* <label htmlFor={`comment-${group.id_group}`} className="font-medium text-gray-700">Admin Comment</label> */}
                                          <textarea
                                            id={`comment-${group.id_group}`}
                                            name={`comment-${group.id_group}`}
                                            rows={1}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            value={
                                              adminComments[group.id_group]
                                            }
                                            onChange={e => handleCommentChange(
                                              group.id_group,
                                              e.target.value,
                                            )}
                                          />
                                          <button
                                            type="button"
                                            className="w-1/6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700"
                                            onClick={() => saveCommentMutation.mutate({
                                              id_group: group.id_group,
                                              new_comment:
                                                adminComments[group.id_group],
                                            })}
                                          >
                                            Save Comment
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ) : null}
                                </>
                              ))}
                            </Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
