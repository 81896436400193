import { ReactComponent as Logo } from '../assets/Logo/youshift_logotype.svg';

export default function Loading() {
  return (
    <main className="flex items-center justify-center min-h-screen bg-white px-6 py-12 lg:px-8">
      <div className="text-center max-w-5xl mx-auto">
        {/* <p className="mt-6 text-base font-semibold text-blue-600">YouShift</p> */}
        <Logo />
        {/* <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Loading...</h1> */}
      </div>
    </main>
  );
}
