import { Switch } from '@headlessui/react';

// Toggle component
interface ToggleProps {
  enabled: boolean;
  setEnabled: () => void;
}

export default function Toggle({ enabled, setEnabled }: ToggleProps) {
  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? 'bg-blue-600' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable</span>
          <span
            className={`${
              enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
