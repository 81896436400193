import {
  ChartBarIcon,
  InboxArrowDownIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  CalendarDateRangeIcon,
  CheckIcon,
  Cog8ToothIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import {
  getNotificationsQuery,
  loadUserQuery,
  userExchangeDashboardQuery,
  userIterationsQuery,
} from '@youshift/shared/hooks/jsQueries';
import { ItrStatuses } from '@youshift/shared/types';

import Notification from '../../components/Notification';
import { requireLoggedIn } from '../../utils/checks';
import { bgColor, textColor } from '../../utils/constantsLegacy';
import NotFound from '../NotFound';

const { v4: uuidv4 } = require('uuid');

export const userDashboardLoader = queryClient => async () => {
  const user = await requireLoggedIn(queryClient);
  const query = userExchangeDashboardQuery();
  const notificationQuery = getNotificationsQuery();
  const iterationQuery = userIterationsQuery();
  queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query));
  queryClient.getQueryData(notificationQuery.queryKey)
    ?? (await queryClient.fetchQuery(notificationQuery));
  queryClient.getQueryData(iterationQuery.queryKey)
    ?? (await queryClient.fetchQuery(iterationQuery));
  return {
    user,
    query,
    notificationQuery,
    iterationQuery,
  };
};

export default function UserDashboard() {
  const { data: user } = useQuery(loadUserQuery());
  const { data } = useQuery(userExchangeDashboardQuery());
  const { data: iterations } = useQuery(userIterationsQuery());
  const { t } = useTranslation();
  const { data: notificationQuery, refetch } = useQuery(
    getNotificationsQuery(),
  );

  const mergeShifts = () => {
    // Iterate over the 'itrs' object
    let allShifts = {};
    for (const itrKey in data.itrs) {
      const itr = data.itrs[itrKey];
      allShifts = { ...allShifts, ...itr.shifts };
    }
    return allShifts;
  };

  const allShifts = mergeShifts();

  const parseShiftOutputs = () => {
    const shiftOutputs = {};
    const shiftOutputsText = [];
    Object.keys(data.shift_outputs).forEach(itr => {
      data.shift_outputs[itr].forEach(output => {
        const date = new Date(
          Object.values(data.itrs[itr].slots).find(
            slot => slot.id_slot == output.id_slot,
          )?.start,
        );
        const currentDate = new Date();
        if (date > currentDate) {
          shiftOutputsText.push({
            text: `${date.toLocaleString(undefined, { day: '2-digit', month: 'short', timeZone: 'GMT' })} - ${allShifts[output.id_shift]?.acronym}`,
            id: output.id_output,
          });
        }
        if (!shiftOutputs[itr]) {
          shiftOutputs[itr] = [{ ...output, date }];
        } else {
          shiftOutputs[itr].push({ ...output, date });
        }
      });
    });
    return { shiftOutputs, shiftOutputsText };
  };

  const { shiftOutputs } = parseShiftOutputs();
  const textColors = {};
  const bgColors = {};

  Object.entries(allShifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length];
    bgColors[key] = bgColor[index % bgColor.length];
  });

  const adjustWeekDay = slot_prefs => {
    const weekdayAbbreviations = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6,
    };

    const startWeekDay = slot_prefs[0].start.substring(0, 3);
    const endWeekDay = slot_prefs[slot_prefs.length - 1].start.substring(0, 3);
    const startCount = weekdayAbbreviations[startWeekDay];
    const endCount = 6 - weekdayAbbreviations[endWeekDay];
    const fillersStart = Array(startCount)
      .fill(null)
      .map(() => ({
        id_slots: [uuidv4()],
        start: '',
      }));

    const fillersEnd = Array(endCount)
      .fill(null)
      .map(() => ({
        id_slots: [uuidv4()],
        start: '',
      }));

    const adjustedShiftsNeeds = [...fillersStart, ...slot_prefs, ...fillersEnd];
    return adjustedShiftsNeeds;
  };

  const [, setOpen] = useState(false);
  const [, setSelectedShift] = useState(null);
  // const [success, setSuccess] = useState(false);

  // Function to find the index of the iteration in the current month
  const findCurrentMonthIterationIndex = () => {
    const currentMonth = new Date().getUTCMonth();
    const currentYear = new Date().getUTCFullYear();

    const itrKeys = Object.keys(data.itrs);
    for (let i = 0; i < itrKeys.length; i++) {
      const itrStartDate = new Date(data.itrs[itrKeys[i]].start_day);
      if (
        itrStartDate.getUTCMonth() === currentMonth
        && itrStartDate.getUTCFullYear() === currentYear
      ) {
        return i;
      }
    }

    return 0; // default to 0 if no matching iteration is found
  };

  const [selectedItrIdx, setSelectedItrIdx] = useState(
    findCurrentMonthIterationIndex(),
  );

  const selectedItr = Object.keys(data.itrs)[
    selectedItrIdx % Object.keys(data.itrs).length
  ];
  const itrLength = Object.keys(data.itrs).length;

  // to display the name of selected itr
  const chainName = data.itrs[selectedItr]?.chain.chain_name;
  const startDate = data.itrs[selectedItr]?.start_day;
  const endDate = data.itrs[selectedItr]?.end_day;
  const itrType = data.itrs[selectedItr]?.itr_type;

  const dateRange = parseIterationDates(startDate, endDate, itrType);
  if (iterations.itrs.length < 1) {
    return (
      <NotFound
        text={t('user.dashboard.noItrsTitle')}
        description={t('user.dashboard.noItrsSubtitle')}
      />
    );
  }
  function getCurrentTime() {
    const now = new Date();
    const time = now.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      timezone: 'GMT',
    });
    const fullDate = now.toLocaleDateString(undefined, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timezone: 'GMT',
    });
    return { time, fullDate };
  }
  const { time, fullDate } = getCurrentTime();
  function todayShift() {
    const allDates = [];
    Object.values(shiftOutputs).forEach(entry => {
      const entryDates = entry.map(item => {
        const originalDateString = item.date;
        const date = new Date(originalDateString);
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
      });
      allDates.push(...entryDates);
    });
    if (allDates.includes(fullDate)) {
      return t('user.notifications.shiftToday');
    }
    return t('user.notifications.freeDay');
  }

  const itr = iterations.itrs[iterations.itrs.length - 1];
  const status_classes = {
    INITIALIZATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    CONFIGURATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    RUNNING_SMARTASS: [
      'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      t('generic.itrStatuses.running'),
      ArrowPathIcon,
      'bg-yellow-500',
    ],
    ASSIGNMENT: [
      'text-violet-800 bg-violet-50 ring-violet-600/20',
      t('generic.itrStatuses.verification'),
      CalendarDateRangeIcon,
      'bg-violet-600',
    ],
    PUBLISHED: [
      'text-teal-800 bg-teal-50 ring-teal-600/20',
      t('generic.itrStatuses.published'),
      CheckIcon,
      'bg-teal-600',
    ],
    CLOSED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.closed'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
    ARCHIVED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.archived'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
  };
  const StatusIcon = status_classes[itr.status][2];

  // Testing
  return (
    <div className="">
      <main className="mx-2 xl:mx-1  max-[890px]:my-8 sm:grid min-[890px]:grid-cols-10  flex flex-col rounded-lg sm:gap-12 content-center h-screen">
        <div className=" md:col-span-4  md:p-5 space-y-4 md:max-h-screen md:mt-8">
          <p className="text-blue-600 text-2xl font-bold">
            {t('user.dashboard.hello', { name: user.firstname })}
          </p>
          {/* <div className="bg-white drop-shadow-xl rounded-lg flex flex-col justify-center items-center">
            <p className="mt-4 md:mt-8 text-xl leading-relaxed text-gray-900 text-center ">{todayShift()}</p>
            <h3 className="mb-4 mt-4 text-md md:mb-8 text-center md:text-2xl font-bold leading-relaxed text-gray-900">
              {fullDate.charAt(0).toUpperCase() + fullDate.slice(1)}
            </h3>
          </div> */}
          <p className="mt-4 text-xl leading-relaxed text-gray-900">
            {todayShift()}
          </p>
          {itr ? (
            <div className="relative drop-shadow-md rounded-lg bg-white">
              <div className="relative flex w-full items-center justify-between space-x-6 px-6 py-3">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <div>
                      <span
                        className={classNames(
                          status_classes[itr.status][3],
                          'h-8 w-8 rounded-full flex items-center justify-center ring-white',
                        )}
                      >
                        <StatusIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <p className="font-medium text-gray-700">
                      {parseIterationDates(
                        itr.start_day,
                        itr.end_day,
                        itr.itr_type,
                      )}
                    </p>
                    <span
                      className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${status_classes[itr.status][0]}`}
                    >
                      {`${status_classes[itr.status][1]}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  {itr.locked && itr.status !== ItrStatuses.PUBLISHED ? (
                    <button
                      disabled
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    >
                      <LockClosedIcon
                        className="h-5 w-5 text-red-600"
                        aria-hidden="true"
                      />
                      {t('user.iterations.locked')}
                    </button>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                      to={
                        itr.status === ItrStatuses.PUBLISHED
                          ? `/user/${itr.id_itr}/results`
                          : `/user/${itr.id_itr}/preferences`
                      }
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold hover:bg-gray-50 text-gray-900"
                      state={{
                        itr_type: itr.itr_type,
                        start_day: itr.start_day,
                        end_day: itr.end_day,
                      }}
                    >
                      {itr.status === ItrStatuses.CONFIGURATION ? (
                        <>
                          <InboxArrowDownIcon
                            className="h-5 w-5 text-blue-600 "
                            aria-hidden="true"
                          />
                          {t('user.iterations.recordPreferences')}
                        </>
                      ) : (
                        <>
                          <ChartBarIcon
                            className="h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                          {t('user.iterations.checkResults')}
                        </>
                      )}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <p className="text-xl mt-1 md:text-2xl leading-relaxed text-gray-900 font-bold">
            {t('user.dashboard.notifications')}
          </p>
          {notificationQuery && Object.keys(notificationQuery).length > 0 ? (
            <div className="bg-white lg:p-3 drop-shadow-md rounded-lg flex flex-col items-center overflow-auto h-[450px] flex-grow w-full">
              {Object.keys(notificationQuery).map(key => (
                <Notification
                  id_notification={key}
                  type={notificationQuery[key].type}
                  hour={notificationQuery[key].created}
                  context={notificationQuery[key].context}
                  feed={data.feed}
                  refetchNotifications={refetch}
                  itrData={iterations}
                />
              ))}
            </div>
          ) : (
            <div className="bg-white lg:p-3 drop-shadow-md rounded-lg flex flex-col items-start justify-start overflow-auto h-[450px] w-full">
              <div className="flex flex-grow items-center justify-center w-full">
                <p className="text-center">
                  {t('user.notifications.upToDate')}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-1 md:col-span-6 md:p-2 ">
          <div id="calendar">
            {Object.keys(data.itrs).length > 0 ? (
              <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col mt-8 max-w-2xl min-w-[50%] h-auto">
                <div className="flex flex-row">
                  <p className="text-gray-600 pl-3 pt-3 pr-3 pb-2 font-semibold">
                    {chainName}
                    {' '}
                    -
                    {dateRange}
                  </p>
                  <div className="flex flex-row items-center justify-end gap-2">
                    <button
                      className="text-gray font-bold py-0 px-0 rounded mt-1 bg-blue-500 disabled:bg-blue-300"
                      onClick={() => setSelectedItrIdx(prevIdx => {
                        const { length } = Object.keys(data.itrs);
                        return (prevIdx - 1 + length) % length;
                      })}
                      disabled={selectedItrIdx === 0}
                    >
                      <span className="sr-only">+1</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white rounded"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                      </svg>
                    </button>
                    <button
                      className="text-gray font-bold py-0 px-0 rounded mt-1 bg-blue-500 disabled:bg-blue-300"
                      onClick={() => setSelectedItrIdx(prevIdx => {
                        const { length } = Object.keys(data.itrs);
                        return (prevIdx + 1) % length;
                      })}
                      disabled={selectedItrIdx === itrLength - 1}
                    >
                      <span className="sr-only">-1</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white rounded"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.mon">
                      L
                      <span className="sr-only sm:not-sr-only">unes</span>
                    </Trans>
                  </div>
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.tue">
                      M
                      <span className="sr-only sm:not-sr-only">artes</span>
                    </Trans>
                  </div>
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.wed">
                      M
                      <span className="sr-only sm:not-sr-only">iércoles</span>
                    </Trans>
                  </div>
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.thu">
                      J
                      <span className="sr-only sm:not-sr-only">ueves</span>
                    </Trans>
                  </div>
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.fri">
                      V
                      <span className="sr-only sm:not-sr-only">iernes</span>
                    </Trans>
                  </div>
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.sat">
                      S
                      <span className="sr-only sm:not-sr-only">ábado</span>
                    </Trans>
                  </div>
                  <div className="bg-white py-2">
                    <Trans i18nKey="calendars.sun">
                      D
                      <span className="sr-only sm:not-sr-only">omingo</span>
                    </Trans>
                  </div>
                </div>
                <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 flex-auto">
                  <div
                    className={`w-full grid grid-cols-7 grid-rows-${Math.ceil(Object.values(data.itrs[selectedItr].slots).length / 7)} gap-px`}
                  >
                    {adjustWeekDay(
                      Object.values(data.itrs[selectedItr].slots),
                    ).map(slot => {
                      const { start, id_slot } = slot;
                      // eslint-disable-next-line max-len
                      const output = shiftOutputs[selectedItr]?.find(
                        output => output.id_slot == id_slot,
                      );

                      return (
                        <div
                          key={slot.id_slot}
                          className={classNames(
                            start ? 'bg-white' : 'bg-gray-50 text-gray-500',
                            'relative py-2 px-3 flex flex-col justify-around h-32',
                          )}
                        >
                          {start ? (
                            <time dateTime={start} className="absolute top-2">
                              {start.slice(5, 7)}
                            </time>
                          ) : null}
                          <div className="flex flex-row justify-center">
                            {start ? (
                              <div className="flex flex-col gap-3">
                                {output ? (
                                  <button
                                    className={`items-center text-xs sm:text-sm text-center rounded-md ${bgColors[output.id_shift]} ${textColors[output.id_shift]} sm:py-2 sm:px-1.5 text-gray-600 disabled:opacity-50`}
                                    onClick={() => {
                                      setSelectedShift(output.id_output);
                                      setOpen(true);
                                    }}
                                    disabled={new Date(start) < new Date()}
                                  >
                                    {allShifts[output.id_shift].name}
                                  </button>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
}
