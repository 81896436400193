import { Menu, Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { classNames } from '@youshift/shared/utils';

import UserStatsChart from './UserStatsChart';
import {
  calculatePercentages,
  calculateStatsPerShift,
  calculateStatsPerSlot,
  collectEventsAndVacations,
  createBaseShifts,
  createShiftLookup,
  mapItrIdToName,
  toggleItrSelection,
} from './utils';

export function SingleUserStats({
  manager = false, stats, name, role,
}) {
  const { t } = useTranslation();
  const [selectedChain, setSelectedChain] = useState(Object.keys(stats)[0]);
  const {
    chain_name,
    itr_stats: itrStats,
    shifts,
    slot_types,
  } = stats[selectedChain] || {};
  const [selectedItrs, setSelectedItrs] = useState(
    new Set(Object.keys(itrStats)),
  );
  const shiftLookup = useMemo(() => createShiftLookup(shifts), [shifts]);
  const baseShifts = useMemo(
    () => createBaseShifts(shifts, shiftLookup),
    [shifts, shiftLookup],
  );
  const statsPerSlot = useMemo(
    () => calculateStatsPerSlot(selectedItrs, itrStats, t, slot_types),
    [selectedItrs, itrStats, t, slot_types],
  );
  const statsPerShift = useMemo(
    () => calculateStatsPerShift(selectedItrs, itrStats, shiftLookup, baseShifts),
    [selectedItrs, itrStats, shiftLookup, baseShifts],
  );
  const { events, vacations } = useMemo(
    () => collectEventsAndVacations(selectedItrs, itrStats),
    [selectedItrs, itrStats],
  );
  const { positivePercentage, negativePercentage } = useMemo(
    () => calculatePercentages(selectedItrs, itrStats),
    [selectedItrs, itrStats],
  );
  const itrIdToName = mapItrIdToName(itrStats);

  const generalStats = [
    {
      id: 1,
      name: t('user.stats.shifts'),
      value: statsPerShift
        .map(shift => shift.value)
        .reduce((acc, val) => acc + val, 0),
    },
    {
      id: 3,
      name: t('user.stats.pointsRespected'),
      value: positivePercentage,
      value2: negativePercentage,
    },
    { id: 2, name: t('user.stats.events'), value: events.length },
    { id: 4, name: t('user.stats.vacations'), value: vacations.length },
  ];

  if (Object.keys(stats).length === 0) {
    return <p>No stats</p>;
  }

  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-2 lg:px-4">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="flex flex-row justify-between items-center">
            <div className="text-left">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {manager
                  ? t('user.stats.personStats', {
                    name: name || t('generic.user'),
                  })
                  : t('user.stats.summary')}
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-600">
                {manager ? role || '' : t('user.stats.stats')}
              </p>
            </div>
            <div className="flex flex-row gap-2">
              {Object.keys(stats).length > 1 && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      {chain_name}
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Menu.Items
                    transition
                    className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      {Object.entries(stats).map(([id, chain]) => (
                        <Menu.Item key={id}>
                          {({ focus }) => (
                            <button
                              onClick={() => {
                                setSelectedItrs(
                                  new Set(Object.keys(stats[id].itr_stats)),
                                );
                                setSelectedChain(id);
                              }}
                              className={classNames(
                                focus ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm font-medium text-gray-900 text-start',
                              )}
                            >
                              {chain.chain_name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
              )}
              <Popover as="div" className="relative inline-block text-left">
                <div>
                  <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    <span>{t('generic.iterations')}</span>
                    {Object.keys(itrStats).length === selectedItrs.size ? (
                      <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                        {t('generic.all')}
                      </span>
                    ) : null}
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>

                <Popover.Panel
                  transition
                  className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <form className="space-y-4">
                    {Object.entries(itrIdToName).map(([id, name]) => (
                      <div key={id} className="flex items-center">
                        <input
                          id={`filter-${id}`}
                          name={`${id}[]`}
                          defaultValue={id}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={selectedItrs.has(id)}
                          onChange={() => toggleItrSelection(
                            id,
                            selectedItrs,
                            setSelectedItrs,
                          )}
                        />
                        <label
                          htmlFor={`filter-${id}`}
                          className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                        >
                          {name}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>
            </div>
          </div>
          <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {generalStats.map(stat => (
              <div key={stat.id} className="flex flex-col bg-teal-600/5 p-8">
                {stat.value2 !== undefined ? (
                  <div className="flex flex-row justify-around">
                    <dd className="text-xl md:text-3xl font-semibold tracking-tight text-green-600">
                      {stat.value}
                    </dd>
                    <dd className="text-xl md:text-3xl font-semibold tracking-tight text-red-600">
                      {stat.value2}
                    </dd>
                  </div>
                ) : (
                  <dd className="text-xl md:text-3xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                )}
                <dt className="text-sm font-semibold leading-6 text-teal-600">
                  {stat.name}
                </dt>
              </div>
            ))}
          </dl>
          <UserStatsChart
            shiftsData={statsPerShift}
            itrIdToName={itrIdToName}
          />
          <h2 className="mt-8 font-semibold text-xl text-blue-600">
            {t('user.stats.shiftsPerDay')}
          </h2>
          <dl className="mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-4 lg:grid-cols-7">
            {statsPerSlot.map(stat => (
              <div
                key={stat.slotType}
                className="flex flex-col bg-blue-600/5 p-8 gap-2"
              >
                <dt className="text-md font-semibold leading-6 text-blue-600 bg-gray-700/5 rounded-full py-2 px-4 mx-auto">
                  {stat.name}
                </dt>
                <dd className="text-xl md:text-3xl font-semibold tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
          <div className="grid grid-cols-2 gap-16 mt-8">
            <div>
              <h2 className="font-semibold text-xl text-yellow-600">
                {t('user.stats.shiftsPerService')}
              </h2>
              <dl className="mt-4 gap-0.5 overflow-hidden rounded-2xl text-center flex flex-col">
                {statsPerShift.length === 0 ? (
                  // Display this content when statsPerShift is empty
                  <div className="text-center text-gray-600 py-4">
                    {t('user.stats.noShifts')}
                  </div>
                ) : (
                  // Display the stats if statsPerShift is not empty
                  statsPerShift.map(stat => (
                    <div
                      key={stat.idShift}
                      className="flex flex-row justify-start items-center bg-yellow-600/5 p-8 gap-2"
                    >
                      <dt className="text-xl font-semibold text-yellow-600">
                        {stat.name}
                        :
                      </dt>
                      <dd className="text-xl md:text-2xl font-semibold tracking-tight text-gray-900">
                        {stat.value}
                      </dd>
                    </div>
                  ))
                )}
              </dl>
            </div>
            <div>
              {!manager && (
                <Link
                  to="../iterations/history"
                  className="text-xl bg-blue-600 text-center mx-auto block my-4 rounded-md font-semibold text-white p-4 w-full hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  relative="path"
                >
                  {t('generic.seeHistory')}
                </Link>
              )}
              <h2 className="font-semibold text-xl text-purple-600">
                {t('generic.vacations')}
              </h2>
              <div className="mt-4 gap-0.5 overflow-hidden rounded-2xl text-center flex flex-col bg-purple-600/5">
                {vacations.length === 0 ? (
                  <div className="text-center text-gray-600 py-4">
                    {t('user.stats.noVacations')}
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-2 p-5">
                    {vacations.map(event => (
                      <div
                        key={event.id}
                        className="bg-purple-600/10 flex items-center p-2 rounded-lg"
                      >
                        <p className="text-md font-semibold text-gray-900">
                          {event.date}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <h2 className="font-semibold text-xl mt-4 text-fuchsia-600">
                {t('generic.events')}
              </h2>
              <dl className="mt-4 gap-0.5 overflow-hidden rounded-2xl text-center flex flex-col">
                {events.length === 0 ? (
                  <div className="text-center text-gray-600 py-4">
                    {t('user.stats.noEvents')}
                  </div>
                ) : (
                  events.map(event => (
                    <div
                      key={event.id}
                      className="flex flex-row justify-start items-center bg-fuchsia-600/5 p-5 gap-2"
                    >
                      <dt className="text-md font-semibold text-fuchsia-600">
                        {event.date}
                        :
                      </dt>
                      <dd className="text-md font-semibold tracking-tight text-gray-900">
                        {event.justification}
                      </dd>
                    </div>
                  ))
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
