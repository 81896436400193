import { CursorArrowRippleIcon } from '@heroicons/react/24/outline';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { useCreateUserReqRule } from '@youshift/shared/hooks/mutations';
import {
  iterationQuery,
  itrUsersQuery,
  personnelQuery,
  sectionSlotsQuery,
  slotLabelsQuery,
} from '@youshift/shared/hooks/queries';
import {
  Iteration,
  Section,
  SectionsWithSlots,
  SlotLabel,
  User,
  UserRole,
} from '@youshift/shared/types';
import { getShadeMap } from '@youshift/shared/utils';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import Virgueria from '../../../../components/ManualAssignment/Virgueria';
import VerticalProgressBar from '../../../../components/VerticalProgressBar';
import { requireApproved, requireManager } from '../../../../utils/checks';
import { ArrangedSectionSlot } from '../Sections/CreateNewSection';
import DefineReqs from './DefineReqs';
import { Action, State } from './types';

type NewReqRuleLoader = {
  iteration: Iteration;
  sectionsWithSlots: SectionsWithSlots;
  shiftLabels: SlotLabel[];
  itrUsers: number[];
  users: Record<number, User>;
  roles: Record<number, UserRole>;
};

export const newReqRuleLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<NewReqRuleLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const { idItr } = params;
    // TODO: do i need to query the iteration all the time...? prob not
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    const sectionsWithSlots = await preLoadQuery(
      queryClient,
      sectionSlotsQuery(idItr),
    );
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));

    const itrUsers = Object.keys(
      await preLoadQuery(queryClient, itrUsersQuery(idItr)),
    ).map(Number);
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const { users, roles } = personnel;
    return {
      iteration,
      sectionsWithSlots,
      shiftLabels,
      users,
      roles,
      itrUsers,
    };
  };

export default function NewReqRule() {
  const { iteration, sectionsWithSlots, shiftLabels, itrUsers, roles, users } =
    useLoaderData() as NewReqRuleLoader;
  const [ruleName, setRuleName] = useState('');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);
  const [selectedSlots, setSelectedSlots] = useState(new Set<number>());
  const navigate = useNavigate();
  const children = [
    { name: t('manager.rulesConfig.ruleName'), key: 'name' },
    { name: t('manager.rulesConfig.selectSlots'), key: 'slots' },
    { name: t('manager.rulesConfig.defineReqs'), key: 'reqs' },
  ];
  const [childIndex, setChildIndex] = useState(0);

  const shouldNextButtonBeDisabled = useMemo(
    () => (selectedSlots.size === 0 && childIndex > 0) || ruleName === '',
    [selectedSlots, childIndex, ruleName],
  );

  const createRuleMutation = useCreateUserReqRule(queryClient, {
    onSuccess: () => {
      navigate('..', { relative: 'path' });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', iteration.id_itr],
      });
      queryClient.invalidateQueries({
        queryKey: ['userReqRules', iteration.id_itr],
      });
    },
    onError: (error: Error) => {},
  });

  const initialState: State = useMemo(() => {
    // Initialize the initialState object
    const state: State = {
      isHoursConfig: false,
      roles: {},
    };

    // Initialize roles with role_name and empty users array
    for (const roleId in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, roleId)) {
        state.roles[roleId] = {
          role_name: roles[roleId].name,
          users: [],
        };
      }
    }

    // Iterate over all users and filter only those whose id is in itrUsers
    Object.values(users).forEach(user => {
      const userId = user.id;

      // Process only users whose id is in itrUsers
      if (itrUsers.includes(userId)) {
        const roleId = user.id_user_role;

        // Create user object
        const userObj = {
          name: `${user.firstname} ${user.lastname}`,
          min_slots: 0,
          max_slots: 0,
          min_duration: 0,
          max_duration: 0,
          included: false,
          id_user: userId,
        };

        // Add user to the appropriate role
        if (roleId) {
          if (state.roles[roleId]) {
            state.roles[roleId].users.push(userObj);
          } else {
            state.roles[roleId] = {
              role_name: roles[roleId] ? roles[roleId].name : 'Unknown Role',
              users: [userObj],
            };
          }
        }
      }
    });

    return state;
  }, [itrUsers, users, roles]);
  const reducer = useCallback((state: State, action: Action): State => {
    switch (action.type) {
      // Toggles the `isHoursConfig` boolean flag
      case 'TOGGLE_HOURS_CONFIG':
        return { ...state, isHoursConfig: !state.isHoursConfig };

      // Toggles the inclusion of all users within a specified role
      case 'TOGGLE_ROLE': {
        return {
          ...state,
          roles: {
            ...state.roles,
            [action.id_role]: {
              ...state.roles[action.id_role],
              users: state.roles[action.id_role].users.map(user => ({
                ...user,
                included: action.payload.type === 'on', // Toggle the included flag for each user
                min_slots: 0,
                max_slots: 0,
                min_duration: 0,
                max_duration: 0,
              })),
            },
          },
        };
      }

      // Toggles the inclusion of a specific user based on their `id_user`
      case 'TOGGLE_USER': {
        // Find the role that contains the user we want to toggle
        const roleId = Object.keys(state.roles).find(roleId =>
          state.roles[Number(roleId)].users.some(
            user => user.id_user === action.id_user,
          ),
        )!;
        return {
          ...state,
          roles: {
            ...state.roles,
            [roleId]: {
              ...state.roles[Number(roleId)],
              users: state.roles[Number(roleId)].users.map(user =>
                user.id_user === action.id_user
                  ? {
                      ...user,
                      included: !user.included, // Toggle the included flag for the user
                      min_slots: 0,
                      max_slots: 0,
                      min_duration: 0,
                      max_duration: 0,
                    }
                  : user,
              ),
            },
          },
        };
      }

      // Updates the requirements (slots or duration) for all users within a specified role
      case 'UPDATE_ROLE_REQS': {
        return {
          ...state,
          roles: {
            ...state.roles,
            [action.id_role]: {
              ...state.roles[action.id_role],
              users: state.roles[action.id_role].users.map(user => ({
                ...user,
                ...action.payload, // Update the relevant fields (min/max slots, min/max duration) for each user
              })),
            },
          },
        };
      }

      // Updates the requirements (slots or duration) for a specific user based on their `id_user`
      case 'UPDATE_USER_REQS': {
        // Find the role that contains the user whose requirements we want to update
        const roleId = Object.keys(state.roles).find(roleId =>
          state.roles[Number(roleId)].users.some(
            user => user.id_user === action.id_user,
          ),
        )!;
        return {
          ...state,
          roles: {
            ...state.roles,
            [roleId]: {
              ...state.roles[Number(roleId)],
              users: state.roles[Number(roleId)].users.map(user =>
                user.id_user === action.id_user
                  ? {
                      ...user,
                      ...action.payload, // Update the relevant fields (min/max slots, min/max duration) for the user
                    }
                  : user,
              ),
            },
          },
        };
      }

      // Default case returns the current state if no action matches
      default:
        return state;
    }
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log(state);
  const createRule = () => {
    createRuleMutation.mutate({
      id_itr: iteration.id_itr,
      name: ruleName,
      id_section_slots: Array.from(selectedSlots),
      user_requirements: Object.values(state.roles).flatMap(role =>
        role.users
          .filter(user => user.included)
          .map(user => ({
            id_user: user.id_user,
            min_slots: user.min_slots,
            max_slots: user.max_slots,
            min_duration: user.min_duration,
            max_duration: user.max_duration,
          })),
      ),
    });
  };

  const toggleSlot = (slot: number | ArrangedSectionSlot) => {
    // this will always take a number
    const id = typeof slot === 'number' ? slot : slot.id!;
    setSelectedSlots(prevSelectedSlots => {
      const newSelectedSlots = new Set(prevSelectedSlots);
      if (newSelectedSlots.has(id)) {
        newSelectedSlots.delete(id);
      } else {
        newSelectedSlots.add(id);
      }
      return newSelectedSlots;
    });
  };

  // Add all slots from any section whose start date matches the provided day to the selected slots set
  const onDayClick = useCallback(
    (d: Date) => {
      const dayStart = new Date(d);
      dayStart.setUTCHours(0, 0, 0, 0);
      const dayEnd = new Date(d);
      dayEnd.setUTCHours(23, 59, 59, 999);

      const newSelectedSlots = new Set(selectedSlots);

      sectionsWithSlots.forEach(({ section_slots }) => {
        Object.values(section_slots).forEach(slot => {
          const slotStart = new Date(slot.start);
          if (slotStart >= dayStart && slotStart <= dayEnd) {
            newSelectedSlots.add(slot.id_section_slot);
          }
        });
      });

      setSelectedSlots(newSelectedSlots);
    },
    [selectedSlots, sectionsWithSlots, setSelectedSlots],
  );

  // Add all slots from the specified section to the selected slots set
  const onSectionClick = useCallback(
    (s: Section) => {
      const newSelectedSlots = new Set(selectedSlots);
      sectionsWithSlots.forEach(({ section, section_slots }) => {
        if (section.id_section === s.id_section) {
          Object.values(section_slots).forEach(slot => {
            newSelectedSlots.add(slot.id_section_slot);
          });
        }
      });
      setSelectedSlots(newSelectedSlots);
    },
    [setSelectedSlots, selectedSlots, sectionsWithSlots],
  );

  const returnChildComponent = useMemo(() => {
    switch (childIndex) {
      case 0:
        return (
          <div className="bg-white px-8 rounded-lg w-full mx-auto">
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-1"
                htmlFor="section-name"
              >
                {t('manager.rulesConfig.ruleName')}
              </label>
              <input
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                id="section-name"
                placeholder={t('manager.rulesConfig.namePlaceholder')}
                value={ruleName}
                onChange={e => setRuleName(e.target.value)}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="ml-3">
            <Virgueria
              start={iteration.start_day}
              end={iteration.end_day}
              sectionsWithSlots={sectionsWithSlots.map(
                ({ section, section_slots }) => ({ ...section, section_slots }),
              )}
              selectedSlots={selectedSlots}
              shadeMap={shadeMap}
              onClick={toggleSlot}
              version="rules"
              labels={shiftLabels}
              onDayClick={onDayClick}
              onSectionClick={onSectionClick}
            />
          </div>
        );
      case 2:
        return <DefineReqs state={state} dispatch={dispatch} />;
      default:
        return null;
    }
  }, [
    childIndex,
    iteration.end_day,
    iteration.start_day,
    onDayClick,
    onSectionClick,
    ruleName,
    sectionsWithSlots,
    selectedSlots,
    shadeMap,
    shiftLabels,
    state,
    t,
  ]);

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <div className="self-start mt-12">
          <VerticalProgressBar steps={children} childIndex={childIndex} />
        </div>
        <div className="w-full">
          <div className="p-8 flex flex-row justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold mb-2 text-gray-800">
                {children[childIndex].name}
              </h1>
              <p className="text-gray-500">
                {t('generic.stepXofY', {
                  current: childIndex + 1,
                  total: children.length,
                })}
              </p>
            </div>
            <div className="flex-shrink-0 mt-6">
              <div className="flex flex-row gap-2 justify-end">
                <YSButton
                  variant="ghost-primary"
                  onClick={() => navigate('..', { relative: 'path' })}
                >
                  {t('generic.cancel')}
                </YSButton>
                {
                  // if first step, don't show previous button
                  childIndex === 0 ? null : (
                    <YSButton
                      onClick={() => setChildIndex(childIndex - 1)}
                      variant="secondary"
                    >
                      {t('generic.back')}
                    </YSButton>
                  )
                }
                {childIndex === children.length - 1 ? (
                  <YSButton
                    onClick={createRule}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.create')}
                  </YSButton>
                ) : (
                  <YSButton
                    onClick={() => setChildIndex(childIndex + 1)}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.next')}
                  </YSButton>
                )}
              </div>
            </div>
          </div>
          {childIndex === 1 ? (
            <div className="flex flex-row gap-1 ml-8 -mt-4 justify-center">
              <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
              <p className="font-bold">
                {t('manager.rulesConfig.clickPrompt')}
              </p>
            </div>
          ) : null}
          {returnChildComponent}
        </div>
      </div>
    </div>
  );
}
