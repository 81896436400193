import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../assets/Logo/logo_lq.png';

export default function Maintenance() {
  const { t } = useTranslation();
  return (
    <main className="flex items-center justify-center min-h-screen bg-white px-6 py-12 lg:px-8">
      <div className="text-center max-w-5xl mx-auto">
        <img className="h-24 w-auto mx-auto" src={logo} alt="YouShift" />
        <p className="mt-6 text-base font-semibold text-blue-600">
          {t('maintenance.title')}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {t('maintenance.subtitle')}
        </h1>
        <div className="mt-6 text-base leading-7 text-gray-600 space-y-4">
          <p>{t('maintenance.line2')}</p>
          <p>
            {t('maintenance.line3')}
            {' '}
            <a
              href="https://wa.me/message/7FJ73LR2RA6JE1"
              className="text-green-600 underline"
            >
              Whatsapp
            </a>
            {' '}
            {t('auth.signUpManager.or')}
            {' '}
            <a
              href="mailto:tech@you-shift.com"
              className="text-blue-600 underline"
            >
              {t('helpCenter.email')}
            </a>
          </p>
        </div>
        <p className="mt-4 text-base leading-7 text-gray-600">
          {t('maintenance.closing')}
        </p>

        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('maintenance.backHome')}
          </Link>
          <Link to="/contact" className="text-sm font-semibold text-gray-900">
            {t('auth.signUpManager.contactUs')}
            <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  );
}
