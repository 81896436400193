import type {
  Section,
  SectionSlot,
  SpecialEvent,
} from '@youshift/shared/types';
import {
  BellAlertIcon,
  CalendarIcon,
  ClockIcon,
  MoonIcon,
  ShieldCheckIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { UserAssignmentErrors } from '../../pages/Manager/ManualAssignment';
import SectionSlotChip from '../Calendars/SectionSlotChip';
import { AssignmentCheckErrorType } from '../../utils/assignment_checks/types';

type Props = {
  userErrors: UserAssignmentErrors[number];
  sectionSlots: Record<number, SectionSlot>;
  sections: Record<number, Section>;
};

const getErrorIcon = (type: AssignmentCheckErrorType) => {
  switch (type) {
    case 'assignment_on_event':
      return <CalendarIcon className="h-5 w-5 text-amber-500" />;
    case 'assignment_on_justified_block':
      return <ShieldCheckIcon className="h-5 w-5 text-purple-500" />;
    case 'assignment_on_personal_block':
      return <UserCircleIcon className="h-5 w-5 text-blue-500" />;
    case 'assignment_rest_period_violation':
      return <MoonIcon className="h-5 w-5 text-red-500" />;
    case 'assignment_rests_24_hours':
      return <ClockIcon className="h-5 w-5 text-orange-500" />;
    default:
      return <BellAlertIcon className="h-5 w-5 text-gray-500" />;
  }
};

const UserErrors: React.FC<Props> = ({
  userErrors,
  sectionSlots,
  sections,
}) => {
  const { t } = useTranslation();
  return (
    <div className="border border-gray-200 rounded-lg mb-2 divide-y">
      {Object.entries(userErrors).map(([slotId, errors]) => {
        const sectionSlot = sectionSlots[Number(slotId)];
        const section = sections[sectionSlot.id_section];
        return (
          <div className="p-2">
            <SectionSlotChip
              small
              sectionName={section?.name}
              start={sectionSlot.start}
              end={sectionSlot.end}
            />
            {Object.entries(errors).map(([errorType, context]) => {
              return (
                <div className="flex flex-row gap-2 items-center mt-1.5 text-sm">
                  <div className="min-w-6">
                    {getErrorIcon(errorType as AssignmentCheckErrorType)}
                  </div>
                  {t(`manager.assignmentErrors.${errorType}`, {
                    event: 'events' in context ? context?.events[0] : undefined,
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default UserErrors;
