"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateNotificationSettingsMutation = exports.useSendInvitationsMutation = exports.useDeleteRoleMutation = exports.useEditRoleMutation = exports.useCreateRoleMutation = exports.useDeleteFakeUserMutation = exports.useEditFakeUserNameMutation = exports.useSendConvertFakeUserInvitationMutation = exports.useCreateFakeUserMutation = exports.useDeleteUserMutation = exports.useRevokeMembershipMutation = exports.useValidateUserMutation = exports.useVerifyUserMutation = exports.useAssignRoleMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useAssignRoleMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_user = params.id_user, id_user_role = params.id_user_role;
            return (0, api_1.request)({
                url: "manager/personnel/user/".concat(id_user, "/assign"),
                method: "post",
                data: { id_user_role: id_user_role },
            });
        } }, options), queryClient);
};
exports.useAssignRoleMutation = useAssignRoleMutation;
var useVerifyUserMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_user = params.id_user;
            return (0, api_1.request)({
                url: "/manager/personnel/user/".concat(id_user, "/verify"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useVerifyUserMutation = useVerifyUserMutation;
var useValidateUserMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_user = params.id_user;
            return (0, api_1.request)({
                url: "/manager/personnel/user/".concat(id_user, "/validate"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useValidateUserMutation = useValidateUserMutation;
var useRevokeMembershipMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_user = params.id_user;
            return (0, api_1.request)({
                url: "/manager/personnel/user/".concat(id_user, "/revoke_membership"),
                method: "post",
            });
        } }, options), queryClient);
};
exports.useRevokeMembershipMutation = useRevokeMembershipMutation;
var useDeleteUserMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/manager/personnel/user/".concat(params.id_user, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteUserMutation = useDeleteUserMutation;
var useCreateFakeUserMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/manager/personnel/user/mock/create",
                method: "post",
                data: { num_users: params.num_users },
            });
        } }, options), queryClient);
};
exports.useCreateFakeUserMutation = useCreateFakeUserMutation;
var useSendConvertFakeUserInvitationMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "manager/personnel/user/mock/".concat(params.id_user, "/conversion_invitation"),
                method: "post",
                data: { email: params.email },
            });
        } }, options), queryClient);
};
exports.useSendConvertFakeUserInvitationMutation = useSendConvertFakeUserInvitationMutation;
var useEditFakeUserNameMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "manager/personnel/user/mock/".concat(params.id_user, "/edit"),
                method: "patch",
                data: { firstname: params.firstname, lastname: params.lastname },
            });
        } }, options), queryClient);
};
exports.useEditFakeUserNameMutation = useEditFakeUserNameMutation;
var useDeleteFakeUserMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_user = params.id_user;
            return (0, api_1.request)({
                url: "/manager/personnel/user/mock/".concat(id_user, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteFakeUserMutation = useDeleteFakeUserMutation;
var useCreateRoleMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/manager/personnel/role/create",
                method: "post",
                data: {
                    name: params.name,
                    description: params.description,
                    sensitive_info: params.sensitive_info,
                },
            });
        } }, options), queryClient);
};
exports.useCreateRoleMutation = useCreateRoleMutation;
var useEditRoleMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/manager/personnel/role/".concat(params.id_user_role),
                method: "patch",
                data: {
                    name: params.name,
                    description: params.description,
                    sensitive_info: params.sensitive_info,
                },
            });
        } }, options), queryClient);
};
exports.useEditRoleMutation = useEditRoleMutation;
var useDeleteRoleMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/manager/personnel/role/".concat(params.id_user_role, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteRoleMutation = useDeleteRoleMutation;
var useSendInvitationsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)({
        mutationFn: function (params) {
            return (0, api_1.request)({
                url: "/manager/personnel/send_invitations",
                method: "post",
                data: { email_list: params.email_list },
            });
        },
    }, queryClient);
};
exports.useSendInvitationsMutation = useSendInvitationsMutation;
// Mutation to update notification settings
var useUpdateNotificationSettingsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (newNotificationsSetting) {
            return (0, api_1.request)({
                url: '/edit_profile',
                method: 'patch',
                data: { shift_exchange_notifications: newNotificationsSetting },
            });
        } }, options), queryClient);
};
exports.useUpdateNotificationSettingsMutation = useUpdateNotificationSettingsMutation;
