import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
  ClockIcon,
  PencilSquareIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { useEditNeedsMutation } from '@youshift/shared/hooks/mutations';
import {
  iterationQuery,
  sectionQuery,
  sectionsQuery,
  slotLabelsQuery,
} from '@youshift/shared/hooks/queries';
import {
  Iteration,
  Section,
  SectionSlot,
  SectionSlotsDict,
  SlotLabel,
} from '@youshift/shared/types';
import { dateToString } from '@youshift/shared/utils';
import { getShadeMap, returnColor } from '@youshift/shared/utils';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import DragCloseDrawer from '../../../../components/DragCloseDrawer';
import Alert from '../../../../components/FormFeedback/Alert';
import CreateSlot from '../../../../components/ItrConfig/CreateSlot';
import EditSectionModal from '../../../../components/ItrConfig/EditSectionModal';
import EditShiftLabels from '../../../../components/ItrConfig/EditShiftLabels';
import EditSlot from '../../../../components/ItrConfig/EditSlot';
import NavigateSectionsOrRules from '../../../../components/ItrConfig/NavigateSectionsOrRules';
import Virgueria from '../../../../components/ManualAssignment/Virgueria';
import Wrapper from '../../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../../utils/checks';
import { ArrangedSectionSlot } from './CreateNewSection';

type SectionLoader = {
  section: Section;
  section_slots: SectionSlotsDict;
  shiftLabels: SlotLabel[];
  iteration: Iteration;
  sections: Section[];
};
export const sectionLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<SectionLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined || params.idSection === undefined) {
      return null;
    }
    const { idItr, idSection } = params;
    // TODO: do i need to query the iteration all the time...? prob not
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));
    const { section, section_slots } = await preLoadQuery(
      queryClient,
      sectionQuery(idItr, idSection),
    );
    const sections = await preLoadQuery(
      queryClient,
      sectionsQuery(params.idItr),
    );
    return { iteration, section, section_slots, shiftLabels, sections };
  };

export type SectionSlotsReducerActionType =
  | {
      type: 'UPDATE_FIELD';
      field: keyof SectionSlot; // Restricts to valid fields in SectionSlot
      value: number; // Increment or decrement value
    }
  | {
      type: 'ADD_SLOT';
      slot: SectionSlot;
    }
  | {
      type: 'RESET_STATE';
      payload: SectionSlotsDict; // The new state to replace the current state
    };

export default function SectionConfig() {
  const { iteration, section, section_slots, shiftLabels, sections } =
    useLoaderData() as SectionLoader;
  // using useQuery here instead of loader to make invalidations work
  const { data: sectionSlotsData, isLoading } = useQuery({
    ...sectionQuery(iteration.id_itr, String(section.id_section)),
    initialData: { section, section_slots },
  });

  const { data: shiftLabelsData } = useQuery({
    ...slotLabelsQuery(iteration.id_itr),
    initialData: shiftLabels,
  });

  const [changesToSave, setChangesToSave] = useState<boolean>(false);
  const sectionSlotsReducer = (
    state: SectionSlotsDict,
    action: SectionSlotsReducerActionType,
  ): SectionSlotsDict => {
    switch (action.type) {
      case 'UPDATE_FIELD': {
        setChangesToSave(true);
        const { field, value } = action;

        return Object.fromEntries(
          Object.entries(state).map(([id, slot]) => {
            const updatedSlot = { ...slot };

            if (field === 'min_need') {
              // Update min_need and enforce constraints
              const newMinNeed = updatedSlot.min_need + value;
              updatedSlot.min_need = Math.max(newMinNeed, 0);

              // Adjust max_need only if min_need increases beyond max_need
              if (newMinNeed > updatedSlot.max_need) {
                updatedSlot.max_need = newMinNeed;
              }
            } else if (field === 'max_need') {
              // Update max_need and enforce constraints
              const newMaxNeed = updatedSlot.max_need + value;
              updatedSlot.max_need = Math.max(newMaxNeed, 1);

              // Adjust min_need only if max_need decreases below min_need
              if (newMaxNeed < updatedSlot.min_need) {
                updatedSlot.min_need = newMaxNeed;
              }
            } else if (field === 'rest_period') {
              // Generic field update (e.g., rest_period)
              updatedSlot.rest_period = Math.max(
                updatedSlot.rest_period + value,
                0,
              );
            }

            return [id, updatedSlot];
          }),
        );
      }
      case 'ADD_SLOT': {
        const { slot } = action;
        return { ...state, [slot.id_section_slot]: slot }; // Add the new slot to the state
      }
      case 'RESET_STATE': {
        return action.payload; // Replace the state with the new data
      }
      default:
        return state;
    }
  };
  const [sectionSlotsState, dispatch] = useReducer(
    sectionSlotsReducer,
    sectionSlotsData.section_slots,
  );

  const queryClient = useQueryClient();
  const { idItr } = useParams();

  const [isEditSectionModalOpen, setIsEditSectionModalOpen] = useState(false);
  const [isEditSlotModalOpen, setIsEditSlotModalOpen] =
    useState<boolean>(false);
  const [isNewSlotModalOpen, setIsNewSlotModalOpen] = useState<boolean>(false);
  const [slotToEdit, setSlotToEdit] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const shadeMap = useMemo(
    () => getShadeMap(shiftLabelsData || []),
    [shiftLabelsData],
  );

  const [success, setSuccess] = useState<boolean | string>(false);
  const [error, setError] = useState<boolean | string>(false);

  const editSlot = (slot: ArrangedSectionSlot | number) => {
    const id = typeof slot === 'number' ? slot : slot.id;
    setIsEditSlotModalOpen(true);
    setSlotToEdit(id);
  };

  useEffect(() => {
    dispatch({ type: 'RESET_STATE', payload: sectionSlotsData.section_slots });
  }, [sectionSlotsData]);

  const editNeeds = useEditNeedsMutation(queryClient, {
    onSuccess: () => {
      setChangesToSave(false);
      setSuccess(t('generic.saved'));
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  if (!sectionSlotsData) {
    return null;
  }
  const { color, name } = sectionSlotsData.section;

  return (
    <Wrapper>
      {error && <Alert success={false} text={error} />}
      {success && <Alert success={true} text={success} />}
      <div className="flex flex-row justify-between items-center">
        <div className="flex items-center gap-2">
          <div
            className="rounded-full w-6 h-6"
            style={{
              backgroundColor: returnColor(color),
            }}
          />
          <h1 className="text-2xl font-bold">{name}</h1>
          <button onClick={() => setIsEditSectionModalOpen(true)}>
            <PencilSquareIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        {sections ? (
          <NavigateSectionsOrRules
            items={sections.map(section => ({
              id: section.id_section,
              name: section.name,
            }))}
            currentId={section.id_section}
          />
        ) : null}
      </div>
      <div className="flex flex-row justify-between mt-2 items-center">
        {shiftLabelsData.length ? (
          <EditShiftLabels shiftLabels={shiftLabelsData} sectionColor={color} />
        ) : null}
        <YSButton
          classNames="flex flex-row gap-0.5 items-center"
          variant="primary"
          onClick={() => setIsNewSlotModalOpen(true)}
        >
          <PlusIcon className="text-white h-5 w-5" />
          {t('manager.servicesConfig.createSlot')}
        </YSButton>
      </div>
      <div className="flex flex-row gap-2">
        <Virgueria
          start={iteration.start_day}
          end={iteration.end_day}
          sectionsWithSlots={[
            {
              ...sectionSlotsData.section,
              section_slots: sectionSlotsState,
            },
          ]}
          shadeMap={shadeMap}
          onClick={editSlot}
          version="section"
          labels={shiftLabelsData}
        />
        <div className="mt-24 ml-2 flex flex-col justify-start gap-4 items-center">
          <YSButton
            classNames="pl-2 pr-2 pt-1 pb-1"
            disabled={!changesToSave}
            onClick={() =>
              editNeeds.mutate({
                id_itr: idItr,
                needs: Object.values(sectionSlotsState).map(
                  ({ id_section_slot, min_need, max_need, rest_period }) => ({
                    id_section_slot,
                    min_need,
                    max_need,
                    rest_period,
                  }),
                ),
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              className="w-6 h-6"
            >
              <path d="M20 7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828z"></path>
              <path d="M14 4v4H8V4m0 16v-8h8v8"></path>
            </svg>
          </YSButton>
          <div className="flex flex-col items-center justify-center relative">
            <span className="relative text-sm text-gray-500">
              {t('generic.max')}
            </span>
            <div className="flex flex-row items-center">
              <UserPlusIcon className="w-6 h-6 text-blue-600" />
              <div className="flex flex-col gap-2">
                <button
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'max_need',
                      value: 1,
                    })
                  }
                  className="relative text-blue-600"
                >
                  <PlusIcon className="text-blue-600 h-5 w-5" />
                </button>
                <button
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'max_need',
                      value: -1,
                    })
                  }
                  className="relative text-blue-600"
                >
                  <MinusIcon className="text-blue-600 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center relative">
            <span className="relative text-sm text-gray-500">
              {t('generic.min')}
            </span>
            <div className="flex flex-row items-center">
              <UserMinusIcon className="w-6 h-6 text-blue-600" />
              <div className="flex flex-col gap-2">
                <button
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'min_need',
                      value: 1,
                    })
                  }
                  className="relative text-blue-600"
                >
                  <PlusIcon className="text-blue-600 h-5 w-5" />
                </button>
                <button
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'max_need',
                      value: -1,
                    })
                  }
                  className="relative text-blue-600"
                >
                  <MinusIcon className="text-blue-600 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center relative">
            <span className="relative text-sm text-gray-500">
              {t('generic.restPeriod')}
            </span>
            <div className="flex flex-row items-center">
              <ClockIcon className="w-6 h-6 text-blue-600" />
              <div className="flex flex-col gap-2">
                <button
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'rest_period',
                      value: 1,
                    })
                  }
                  className="relative text-blue-600"
                >
                  <PlusIcon className="text-blue-600 h-5 w-5" />
                </button>
                <button
                  onClick={() =>
                    dispatch({
                      type: 'UPDATE_FIELD',
                      field: 'rest_period',
                      value: -1,
                    })
                  }
                  className="relative text-blue-600"
                >
                  <MinusIcon className="text-blue-600 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditSectionModal
        isOpen={isEditSectionModalOpen}
        onClose={() => setIsEditSectionModalOpen(false)}
        details={section}
      />
      {slotToEdit ? (
        <DragCloseDrawer
          open={isEditSlotModalOpen}
          setOpen={setIsEditSlotModalOpen}
          modalHeight={42}
        >
          <EditSlot
            setOpen={setIsEditSlotModalOpen}
            setSuccess={setSuccess}
            shiftLabels={shiftLabelsData}
            slot={sectionSlotsState[slotToEdit]}
            iteration={iteration}
            idSection={section.id_section}
            dispatch={dispatch}
          />
        </DragCloseDrawer>
      ) : null}
      {isNewSlotModalOpen ? (
        <DragCloseDrawer
          open={isNewSlotModalOpen}
          setOpen={setIsNewSlotModalOpen}
          modalHeight={42}
        >
          <CreateSlot
            setSuccess={setSuccess}
            setOpen={setIsNewSlotModalOpen}
            shiftLabels={shiftLabelsData}
            iteration={iteration}
            idSection={section.id_section}
            dispatch={dispatch}
          />
        </DragCloseDrawer>
      ) : null}
    </Wrapper>
  );
}
