"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientSourcingOrigin = exports.ExchangeResponseType = exports.ShiftAssignmentType = exports.ItrType = exports.ItrStatuses = exports.EventStatus = exports.EventType = exports.UserEventType = exports.ExchangeResponseStatus = exports.ExchangeRequestStatus = exports.UserPreferenceType = exports.IncompatibilityType = exports.RuleTypes = void 0;
var RuleTypes;
(function (RuleTypes) {
    RuleTypes["SECTION_USER_REQS"] = "SECTION_USER_REQS";
    RuleTypes["SLOT_LABEL_USER_REQS"] = "SLOT_LABEL_USER_REQS";
    RuleTypes["CUSTOM_USER_REQS"] = "CUSTOM_USER_REQS";
    RuleTypes["SINGLE_GROUP_INCOMP"] = "SINGLE_GROUP_INCOMP";
    RuleTypes["CROSS_GROUP_INCOMP"] = "CROSS_GROUP_INCOMP";
    RuleTypes["CROSS_SLOT_NEED"] = "CROSS_SLOT_NEED";
})(RuleTypes || (exports.RuleTypes = RuleTypes = {}));
var IncompatibilityType;
(function (IncompatibilityType) {
    IncompatibilityType["SINGLE_GROUP_INCOMP"] = "SINGLE_GROUP_INCOMP";
    IncompatibilityType["CROSS_GROUP_INCOMP"] = "CROSS_GROUP_INCOMP";
})(IncompatibilityType || (exports.IncompatibilityType = IncompatibilityType = {}));
// if changed also change in translation.json(s)
var UserPreferenceType;
(function (UserPreferenceType) {
    UserPreferenceType["POINTS"] = "P";
    UserPreferenceType["BLOCKED"] = "B";
    UserPreferenceType["UNAVAILABLE"] = "U";
})(UserPreferenceType || (exports.UserPreferenceType = UserPreferenceType = {}));
// if changed also change in translation.json(s)
var ExchangeRequestStatus;
(function (ExchangeRequestStatus) {
    ExchangeRequestStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ExchangeRequestStatus["CANCELED"] = "CANCELED";
    ExchangeRequestStatus["CANCELED_SYSTEM"] = "CANCELED_SYSTEM";
    ExchangeRequestStatus["COMPLETED"] = "COMPLETED";
})(ExchangeRequestStatus || (exports.ExchangeRequestStatus = ExchangeRequestStatus = {}));
var ExchangeResponseStatus;
(function (ExchangeResponseStatus) {
    ExchangeResponseStatus["PENDING_MANAGER"] = "PENDING_MANAGER";
    ExchangeResponseStatus["PENDING_DOCTOR"] = "PENDING_DOCTOR";
    ExchangeResponseStatus["APPROVED"] = "APPROVED";
    ExchangeResponseStatus["REJECTED_MANAGER"] = "REJECTED_MANAGER";
    ExchangeResponseStatus["REJECTED_DOCTOR"] = "REJECTED_DOCTOR";
    ExchangeResponseStatus["CANCELED"] = "CANCELED";
    ExchangeResponseStatus["CANCELED_SYSTEM"] = "CANCELED_SYSTEM";
})(ExchangeResponseStatus || (exports.ExchangeResponseStatus = ExchangeResponseStatus = {}));
var UserEventType;
(function (UserEventType) {
    UserEventType["EVENT"] = "E";
    UserEventType["VACATION"] = "V";
})(UserEventType || (exports.UserEventType = UserEventType = {}));
// change in translation.json if changed
var EventType;
(function (EventType) {
    EventType["HOLIDAY"] = "HOLIDAY";
    // EVENT = "EVENT",
    EventType["SEMINAR"] = "SEMINAR";
    EventType["PRACTICAL_TRAINING"] = "PRACTICAL_TRAINING";
    EventType["EXAM"] = "EXAM";
    EventType["OTHER"] = "OTHER";
})(EventType || (exports.EventType = EventType = {}));
// change in translation.json if changed
var EventStatus;
(function (EventStatus) {
    EventStatus["PENDING"] = "PENDING_APPROVAL";
    EventStatus["APPROVED"] = "APPROVED";
    EventStatus["REJECTED"] = "REJECTED";
})(EventStatus || (exports.EventStatus = EventStatus = {}));
var ItrStatuses;
(function (ItrStatuses) {
    ItrStatuses["INITIALIZATION"] = "INITIALIZATION";
    ItrStatuses["CONFIGURATION"] = "CONFIGURATION";
    ItrStatuses["RUNNING_SMARTASS"] = "RUNNING_SMARTASS";
    ItrStatuses["ASSIGNMENT"] = "ASSIGNMENT";
    ItrStatuses["PUBLISHED"] = "PUBLISHED";
    ItrStatuses["CLOSED"] = "CLOSED";
    ItrStatuses["ARCHIVED"] = "ARCHIVED";
})(ItrStatuses || (exports.ItrStatuses = ItrStatuses = {}));
var ItrType;
(function (ItrType) {
    ItrType["WEEK"] = "WEEK";
    ItrType["MONTH"] = "MONTH";
    ItrType["ABSTRACT"] = "ABSTRACT";
    ItrType["CALENDAR"] = "CALENDAR";
    ItrType["MONTHS_2"] = "2MONTHS";
    ItrType["MONTHS_6"] = "6MONTHS";
})(ItrType || (exports.ItrType = ItrType = {}));
var ShiftAssignmentType;
(function (ShiftAssignmentType) {
    // Pre-assigned
    ShiftAssignmentType["PREASSIGNED"] = "PREASSIGNED";
    // Assigned by the algorithm
    ShiftAssignmentType["ALGORITHM"] = "ALGORITHM";
    // Assigned by the manager
    ShiftAssignmentType["REASSIGNED"] = "REASSIGNED";
    // Result of an exchange
    ShiftAssignmentType["EXCHANGED"] = "EXCHANGED";
})(ShiftAssignmentType || (exports.ShiftAssignmentType = ShiftAssignmentType = {}));
var ExchangeResponseType;
(function (ExchangeResponseType) {
    ExchangeResponseType["ONE_FOR_ONE"] = "ONE_FOR_ONE";
    ExchangeResponseType["ONE_FOR_ZERO"] = "ONE_FOR_ZERO";
})(ExchangeResponseType || (exports.ExchangeResponseType = ExchangeResponseType = {}));
var ClientSourcingOrigin;
(function (ClientSourcingOrigin) {
    ClientSourcingOrigin["REFERRAL"] = "REFERRAL";
    ClientSourcingOrigin["BROWSER"] = "BROWSER";
    ClientSourcingOrigin["OUTREACH"] = "OUTREACH";
    ClientSourcingOrigin["HOSPITAL_MANAGEMENT"] = "HOSPITAL_MANAGEMENT";
    ClientSourcingOrigin["SOCIAL_MEDIA"] = "SOCIAL_MEDIA";
    ClientSourcingOrigin["OTHER"] = "OTHER";
})(ClientSourcingOrigin || (exports.ClientSourcingOrigin = ClientSourcingOrigin = {}));
