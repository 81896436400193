/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import {
  CheckIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/20/solid';
import {
  CalendarDaysIcon,
  Cog8ToothIcon,
  QueueListIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { parseIterationDates, classNames } from '@youshift/shared/utils';
import { profileQuery } from '@youshift/shared/hooks/jsQueries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { allChainsQuery, personnelQuery } from '@youshift/shared/hooks/queries';
import { ItrStatuses, User } from '@youshift/shared/types';

// import logo from '../../assets/Logo/youshift_logotype.svg';
import ManagerDashboardChecklist from '../../components/ManagerDashboardChecklist';
import { requireApproved, requireManager } from '../../utils/checks';

export const managerDashboardLoader =
  (queryClient: QueryClient) => async () => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    queryClient.getQueryData(profileQuery().queryKey) ??
      (await queryClient.fetchQuery(profileQuery()));

    await preLoadQuery(queryClient, personnelQuery());
    await preLoadQuery(queryClient, allChainsQuery());
    return null;
  };

export default function ManagerDashboard() {
  const { data } = useQuery(allChainsQuery());
  const itrs = data?.itrs ?? {};
  const chains = data?.chains ?? {};
  const { data: personnel } = useQuery(personnelQuery());

  const shouldShowCheckList =
    Object.keys(personnel?.roles ?? {}).length === 0 ||
    Object.keys(chains).length === 0;

  const { t } = useTranslation();

  if (!personnel || !chains) {
    return null;
  }

  const steps = [
    { id: '01', name: t('generic.config') },
    { id: '02', name: t('generic.assignment') },
    { id: '03', name: t('generic.publ') },
  ];

  // TODO: abstract and use enums
  const stepIdMapping = {
    [ItrStatuses.CONFIGURATION]: '01',
    [ItrStatuses.INITIALIZATION]: '01',
    [ItrStatuses.ASSIGNMENT]: '02',
    [ItrStatuses.PUBLISHED]: '03',
    [ItrStatuses.RUNNING_SMARTASS]: '-00', // TODO handle status
    [ItrStatuses.CLOSED]: '-01', // Add missing status
    [ItrStatuses.ARCHIVED]: '-02', // Add missing ARCHIVED status
  };

  const countUnverifiedUsers = (users: Record<number, User>) => {
    let unverifiedUsers = 0;
    let unverifiedMailUsers = 0;

    for (const user of Object.values(users)) {
      if (!user.user_is_verified) {
        unverifiedUsers++;
      }
      if (!user.mail_is_verified) {
        unverifiedMailUsers++;
      }
    }

    return [unverifiedUsers, unverifiedMailUsers];
  };

  const [unverifiedUsersCount, unverifiedMailCount] = countUnverifiedUsers(
    personnel?.users,
  );

  function getCurrentTime() {
    const now = new Date();
    const time = now.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'GMT',
    });
    const fullDate = now.toLocaleDateString(undefined, {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'GMT',
    });
    return { time, fullDate };
  }

  const { time, fullDate } = getCurrentTime();

  const buttonDisplay = (status: ItrStatuses) => {
    if (
      status === ItrStatuses.INITIALIZATION ||
      status === ItrStatuses.CONFIGURATION
    ) {
      return t('manager.dashboard.continueConfig');
    }
    if (status === ItrStatuses.PUBLISHED || status === ItrStatuses.ASSIGNMENT) {
      return t('manager.dashboard.checkResults');
    }
    // mas estados?
    return '';
  };

  return (
    <div className="">
      <main className="xl:mx-8 mx-12 max-[890px]:my-8 sm:grid min-[890px]:grid-cols-3 sm:grid-cols-2 flex flex-col gap-8 rounded-lg sm:gap-12 content-center min-h-screen">
        {/* Ajustes */}
        <div className="bg-white lg:p-6 ring-2 ring-gray-100 drop-shadow-xl ring-inset rounded-lg flex flex-col gap-6 py-6 justify-center items-center">
          <span className="rounded-lg p-3 ring-4 ring-white bg-teal-100 text-teal-600">
            <Cog8ToothIcon className="h-24" aria-hidden="true" />
          </span>
          <h2 className="text-3xl md:text-4xl font-semibold text-gray-900">
            <NavLink
              to="../profile"
              relative="path"
              className="focus:outline-none"
            >
              {/* Extend touch target to entire panel */}
              <span className="absolute inset-0" aria-hidden="true" />
              {t('manager.dashboard.settings')}
            </NavLink>
          </h2>
        </div>
        {shouldShowCheckList ? (
          // Logo
          <img
            className="lg:p-6 rounded-lg flex flex-col py-6 gap-6 justify-center items-center my-auto"
            // src={logo}
            alt="YouShift"
          />
        ) : (
          // Personal
          <div className="bg-white lg:p-6 ring-2 ring-gray-100 drop-shadow-xl ring-inset rounded-lg flex flex-col py-6 gap-6 justify-center items-center">
            <span className="inline-flex rounded-lg p-3 ring-4 ring-white bg-teal-100 text-teal-600">
              <UserIcon className="h-24" aria-hidden="true" />
            </span>
            <h2 className="text-3xl md:text-4xl font-semibold leading-6 text-gray-900">
              <NavLink
                to="../team"
                relative="path"
                className="focus:outline-none"
              >
                <span className="absolute inset-0" aria-hidden="true" />
                {t('manager.navigation.team')}
              </NavLink>
            </h2>
            {unverifiedMailCount !== 0 && unverifiedUsersCount !== 0 ? (
              <div className="bottom-3 flex flex-col text-center rounded-lg border border-blue-600 px-2 xl:px-4 py-1 shadow-sm focus-within:ring-2 focus-within:ring-offset-2">
                <p className="text-xs xl:text-sm text-gray-900">
                  {t('manager.dashboard.unverifiedMail', {
                    count: unverifiedMailCount,
                  })}
                </p>
                <p className="text-xs xl:text-sm text-gray-900">
                  {t('manager.dashboard.unverifiedUsers', {
                    count: unverifiedUsersCount,
                  })}
                </p>
              </div>
            ) : null}
          </div>
        )}
        {/* Hora */}
        <div className="max-[640px]:hidden border-t-4 border-t-blue-600 rounded-lg">
          <h2 className="my-8 text-center text-7xl font-semibold text-blue-600">
            {time}
          </h2>
          <h3 className="mb-8 mt-8 text-3xl font-semibold leading-relaxed text-gray-900">
            {fullDate.charAt(0).toUpperCase() + fullDate.slice(1)}
          </h3>
        </div>
        {/* Historial */}
        {shouldShowCheckList ? null : (
          <div className="bg-white lg:p-6 ring-2 ring-gray-100 drop-shadow-xl ring-inset rounded-lg flex flex-col gap-6 py-6 justify-center items-center">
            <span className="inline-flex  mt-6 rounded-lg p-3 ring-4 ring-white bg-teal-100 text-teal-600">
              <QueueListIcon className="h-24" aria-hidden="true" />
            </span>
            <h2 className="xl:my-12 lg:my-10 md:my-8 text-3xl md:text-4xl text-center font-semibold leading-10 text-gray-900">
              <NavLink
                to="../chains"
                relative="path"
                className="focus:outline-none"
              >
                <span className="absolute inset-0" aria-hidden="true" />
                {t('manager.chains.activeItrs')}
              </NavLink>
            </h2>
          </div>
        )}
        {/* Cadena activa */}
        {shouldShowCheckList ? (
          // <Link to="../chains/new" relative="path" className="flex flex-col justify-center items-center relative w-full col-span-2 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          //   <svg className="mx-auto h-24 w-24 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 33 32" aria-hidden="true">
          //     {/* <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
          //     <path d="M6.55157 2V4.25M16.7427 2V4.25M2.91187 17.75V6.5C2.91187 5.90326 3.14195 5.33097 3.55149 4.90901C3.96104 4.48705 4.5165 4.25 5.09569 4.25H18.1986C18.7778 4.25 19.3333 4.48705 19.7428 4.90901C20.1524 5.33097 20.3825 5.90326 20.3825 6.5V17.75M2.91187 17.75C2.91187 18.3467 3.14195 18.919 3.55149 19.341C3.96104 19.7629 4.5165 20 5.09569 20H18.1986C18.7778 20 19.3333 19.7629 19.7428 19.341C20.1524 18.919 20.3825 18.3467 20.3825 17.75M2.91187 17.75V10.25C2.91187 9.65326 3.14195 9.08097 3.55149 8.65901C3.96104 8.23705 4.5165 8 5.09569 8H18.1986C18.7778 8 19.3333 8.23705 19.7428 8.65901C20.1524 9.08097 20.3825 9.65326 20.3825 10.25V17.75M11.6472 11.75H11.6549V11.758H11.6472V11.75ZM11.6472 14H11.6549V14.008H11.6472V14ZM11.6472 16.25H11.6549V16.258H11.6472V16.25ZM9.46334 14H9.4711V14.008H9.46334V14ZM9.46334 16.25H9.4711V16.258H9.46334V16.25ZM7.27951 14H7.28728V14.008H7.27951V14ZM7.27951 16.25H7.28728V16.258H7.27951V16.25ZM13.831 11.75H13.8387V11.758H13.831V11.75ZM13.831 14H13.8387V14.008H13.831V14ZM13.831 16.25H13.8387V16.258H13.831V16.25ZM16.0148 11.75H16.0226V11.758H16.0148V11.75ZM16.0148 14H16.0226V14.008H16.0148V14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          //     <path d="M24.2647 18V30M30.0882 24H18.4412" stroke="#60a5fa" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          //   </svg>
          //   {/* </svg> */}
          //   <span className="mt-2 block text-2xl font-semibold text-gray-900">{t('manager.dashboard.createChain')}</span>
          // </Link>
          <ManagerDashboardChecklist
            rolesAreCreated={Object.keys(personnel?.roles ?? {}).length > 0}
            itrsAreCreated={Object.keys(chains).length > 0}
          />
        ) : (
          Object.values(chains).map(chain => {
            const activeItr = itrs[chain.itrs[chain.itrs.length - 1]];
            const currentStepId = stepIdMapping[activeItr.status];
            return (
              <div className="bg-white lg:p-6  py-8 col-span-2 ring-2 ring-gray-100 drop-shadow-xl ring-inset rounded-lg flex sm:flex-row flex-col justify-around items-center">
                <div className="flex flex-col mt-6">
                  <span className="inline-flex rounded-lg p-3 ring-4 ring-white bg-teal-100 text-teal-600 mx-auto">
                    <CalendarDaysIcon className="h-24" aria-hidden="true" />
                  </span>
                  <h2 className="mt-12 text-center mb-6 text-3xl md:text-4xl leading-10 font-semibold text-gray-900">
                    <NavLink
                      to={`../iteration/${activeItr.id_itr}`}
                      relative="path"
                      className="focus:outline-none"
                    >
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      <div className="flex flex-row items-center gap-2">
                        {chain.chain_name}
                        {activeItr.locked ? (
                          <LockClosedIcon className="h-5 w-5 mt-1.5 text-teal-500" />
                        ) : (
                          <LockOpenIcon className="h-5 w-5 mt-1.5 text-teal-500" />
                        )}
                      </div>
                    </NavLink>
                  </h2>
                  <h3 className="text-gray-600 text-sm mb-6 text-center">
                    {parseIterationDates(
                      activeItr.start_day,
                      activeItr.end_day,
                      activeItr.itr_type,
                    )}
                  </h3>
                </div>
                {/* State */}
                <nav aria-label="Progress">
                  <ol className="overflow-hidden">
                    {steps.map((step, stepIdx) => {
                      const isCurrent = step.id === currentStepId;
                      const isCompleted =
                        stepIdx < steps.findIndex(s => s.id === currentStepId);
                      return (
                        <li
                          key={step.id}
                          className={classNames(
                            stepIdx !== steps.length - 1 ? 'pb-10' : '',
                            'relative',
                          )}
                        >
                          {isCompleted ? (
                            <>
                              {stepIdx !== steps.length - 1 ? (
                                <div
                                  className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-600"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="group relative flex items-start">
                                <span className="flex h-9 items-center">
                                  <span
                                    className={classNames(
                                      'bg-blue-600 group-hover:bg-blue-800',
                                      'relative z-10 flex h-8 w-8 items-center justify-center rounded-full',
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </span>
                                <span className="ml-4 flex min-w-0 flex-col">
                                  <span className="text-sm font-medium">
                                    {step.name}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : isCurrent ? (
                            <>
                              {stepIdx !== steps?.length - 1 ? (
                                <div
                                  className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div
                                className="group relative flex items-start"
                                aria-current="step"
                              >
                                <span
                                  className="flex h-9 items-center"
                                  aria-hidden="true"
                                >
                                  <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                                    <span className="h-2.5 w-2.5 rounded-full bg-blue-600" />
                                  </span>
                                </span>
                                <span className="ml-4 flex min-w-0 flex-col">
                                  <span className="text-sm font-medium text-blue-600">
                                    {step.name}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              {stepIdx !== steps.length - 1 ? (
                                <div
                                  className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="group relative flex items-start">
                                <span
                                  className="flex h-9 items-center"
                                  aria-hidden="true"
                                >
                                  <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                    <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                  </span>
                                </span>
                                <span className="ml-4 flex min-w-0 flex-col">
                                  <span className="text-sm font-medium text-gray-500">
                                    {step.name}
                                  </span>
                                </span>
                              </div>
                            </>
                          )}
                        </li>
                      );
                    })}
                  </ol>
                </nav>
                <div className="flex flex-col content-around gap-8 sm:max-w-[33%] mx-8 my-6">
                  <div
                    // type="button"
                    className="rounded-md bg-teal-500  lg:px-6 lg:py-4 p-2 text-center text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    // to="../"
                  >
                    {buttonDisplay(activeItr.status)}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </main>
    </div>
  );
}
