import { Section } from '@youshift/shared/types';
import { returnColor } from '@youshift/shared/utils';
import React from 'react';

interface SectionLegendProps {
  sections: Section[];
}

export default function SectionLegend({
  sections,
}: SectionLegendProps): JSX.Element {
  return (
    <div className="mt-4 flex flex-wrap gap-2">
      {sections.map(section => (
        <div
          key={section.id_section}
          className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
        >
          {/* Circle for the section color */}
          <span
            className="w-5 h-5 rounded-full"
            style={{ backgroundColor: returnColor(section.color) }}
          />
          {/* Section name */}
          {section.name}
        </div>
      ))}
    </div>
  );
}
