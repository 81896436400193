import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClientSourcingOrigin } from '@youshift/shared/types';

import logo from '../../assets/Logo/logo_lq.png';
import Alert from '../../components/FormFeedback/Alert';
import SignUpSuccessful from './SignUpSuccessful';


const initialState = {
  firstname: '',
  lastname: '',
  country: '',
  province: '',
  organization: '',
  group_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  has_agreed_to_privacy_policy: false,
  phone: '',
  sourceOrigin: '',
  specialty: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'TOGGLE_PRIVACY_POLICY':
      return {
        ...state,
        has_agreed_to_privacy_policy: !state.has_agreed_to_privacy_policy,
      };
    case 'RESET':
      return {
        ...state,
        firstname: '',
        lastname: '',
        group_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        has_agreed_to_privacy_policy: false,
        phone: '',
        sourceOrigin: '',
        specialty: '',
      };
    default:
      return state;
  }
}

function getPlaces(data) {
  const places = [];

  function createNewPlace(data) {
    return {
      country: data.country,
      state: data.state,
      province: data.province,
    };
  }

  function isEqual(a, b) {
    return (
      a.country === b.country
      && a.state === b.state
      && a.province === b.province
    );
  }

  data.forEach(d => {
    const newPlace = createNewPlace(d);

    if (!places.filter(n => isEqual(n, newPlace)).length) {
      places.push(newPlace);
    }
  });

  return places;
}

export default function SignUpManager() {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [organizations, setOrganizations] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [optionSelectors, setOptionSelectors] = useState({
    country: [],
    province: [],
    organization: [],
  });
  const [displaySelectors, setDisplaySelectors] = useState({
    province: false,
    organization: false,
  });
  const [valueSelectors, setValueSelectors] = useState({
    province: null,
    organization: null,
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const specialties = t('specialties', { returnObjects: true });

  const updateOptionSelectors = (selectorUpdated, filter) => {
    let { provinceOptions, organizationOptions } = [];

    if (filter) {
      switch (selectorUpdated) {
        case 'COUNTRY':
          provinceOptions = provinces
            .filter(p => p.country.toLowerCase() === filter)
            .map(p => ({
              label: `${p.state} - ${p.province}`,
              id: `${p.state} - ${p.province}`.toLowerCase(),
            }));
          break;
        case 'PROVINCE':
          organizationOptions = organizations
            .filter(
              o => `${o.state} - ${o.province}`.toLowerCase() === filter,
            )
            .map(org => ({ label: org.name, id: org.id_org }));
          break;
        default:
          break;
      }
    }

    switch (selectorUpdated) {
      case 'COUNTRY':
        setOptionSelectors({
          country: optionSelectors.country,
          province: provinceOptions,
          organization: organizationOptions,
        });
        break;
      case 'PROVINCE':
        setOptionSelectors({
          country: optionSelectors.country,
          province: optionSelectors.province,
          organization: organizationOptions,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/orgs`,
        );
        const countries = [...new Set(response.data.map(d => d.country))];
        const countryOptions = countries.map(c => ({
          label: c,
          id: c.toLowerCase(),
        }));
        setOrganizations(response.data);
        setProvinces(getPlaces(response.data));
        setOptionSelectors({
          country: countryOptions,
          province: [],
          organization: [],
        });
      } catch (error) {
        setError(t('auth.signUpManager.organizationFetchError'));
      }
    };
    fetchData();
  }, [t]);

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;

  const signupAction = async event => {
    event.preventDefault();
    setSuccess(false);
    const {
      firstname,
      lastname,
      organization,
      group_name,
      phone,
      sourceOrigin,
      email,
      password,
      confirmPassword,
      has_agreed_to_privacy_policy,
      specialty,
    } = state;

    if (!passwordRegex.test(password)) {
      setError(t('auth.passwordRequirements'));
    } else if (!group_name) {
      setError(t('auth.groupNameError'));
    } else if (password !== confirmPassword) {
      setError(t('auth.passwordNoMatch'));
    } else if (!has_agreed_to_privacy_policy) {
      setError(t('auth.signUpManager.privacyPolicyError'));
    } else if (specialty === '') {
      setError(t('auth.signUpManager.specialtyError'));
    } else if (sourceOrigin === '') {
      setError(t('auth.signUpManager.sourceOriginError'));
    } else {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/manager/new`, {
          firstname,
          lastname,
          organization,
          group_name,
          phone,
          email,
          password,
          has_agreed_to_privacy_policy,
          language: localStorage.getItem('i18nextLng'),
          sourcing_origin: sourceOrigin,
          specialty,
        });
        setError(false);
        dispatch({ type: 'RESET' });
        setSuccess(t('auth.signUpManager.successMessage'));
      } catch (error) {
        setSuccess(false);
        switch (error.response?.data.type) {
          case 'GROUPNAME_NOT_AVAIL':
            setError(
              t('auth.signUpManager.groupnameNotAvailableError', {
                organization,
              }),
            );
            break;
          case 'USERNAME_NOT_AVAIL':
            setError(t('auth.signUpManager.emailNotAvailableError'));
            break;
          case 'ORG_NOT_FOUND':
            setError(t('auth.signUpManager.organizationNotFoundError'));
            break;
          default:
            setError(t('auth.signUpManager.genericError'));
            break;
        }
      }
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    dispatch({ type: 'UPDATE_FIELD', field: name, value });
  };

  const handleAutocomplete = (event, value, selector) => {
    if (value) {
      updateOptionSelectors(selector, value.id);
      switch (selector) {
        case 'ORGANIZATION':
          // update field organization with value.id or null.
          setValueSelectors({
            organization: value.label,
            province: valueSelectors.province,
          });
          dispatch({
            type: 'UPDATE_FIELD',
            field: 'organization',
            value: value.id,
          });
          break;
        case 'PROVINCE':
          setValueSelectors({ organization: null, province: value.label });
          setDisplaySelectors({
            organization: !!value.id,
            province: displaySelectors.province,
          });
          dispatch({
            type: 'UPDATE_FIELD',
            field: 'organization',
            value: null,
          });
          break;
        case 'COUNTRY':
          setDisplaySelectors({ organization: false, province: !!value.id });
          setValueSelectors({ organization: null, province: null });
          break;
        default:
          break;
      }
    } else {
      dispatch({ type: 'UPDATE_FIELD', field: 'organization', value: null });
      updateOptionSelectors(selector, null);

      switch (selector) {
        case 'PROVINCE':
          setDisplaySelectors({ organization: false, province: true });
          setValueSelectors({
            organization: null,
            province: valueSelectors.province,
          });
          break;
        case 'COUNTRY':
          setDisplaySelectors({ organization: false, province: false });
          setValueSelectors({ organization: null, provinces: null });
          break;
        default:
          break;
      }
    }
  };

  const handleTogglePrivacyPolicy = () => {
    dispatch({ type: 'TOGGLE_PRIVACY_POLICY' });
  };

  return success ? (
    <SignUpSuccessful
      h1={t('auth.signUpManager.successTitle')}
      h2={t('auth.signUpManager.successSubtitle')}
      h3={success}
    />
  ) : (
    <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-sm lg:max-w-none lg:w-[450px]">
        <div>
          <img className="h-12 w-auto" src={logo} alt="YouShift" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {t('auth.signUpManager.registrationTitle')}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {t('auth.signUpManager.or')}
            {' '}
            <Link
              to="/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {t('auth.signUpManager.loginLink')}
            </Link>
          </p>
        </div>
        <form className="space-y-6" onSubmit={signupAction}>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.firstName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="firstname"
                  id="first-name"
                  autoComplete="given-name"
                  value={state.firstname}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.lastName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="lastname"
                  id="last-name"
                  autoComplete="family-name"
                  value={state.lastname}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="gap-y-0 sm:col-span-6">
              <div className="sm:col-span-full">
                <p className="block text-sm font-medium leading-6 text-gray-900">
                  {t('generic.organization')}
                </p>
                <div className="mt-2">
                  <Autocomplete
                    disablePortal
                    id="country"
                    options={optionSelectors.country}
                    sx={{
                      width: '100%',
                      display: 'block',
                      borderRadius: '0.375rem',
                      borderWidth: '0px',
                      paddingTop: '0.375rem',
                      paddingBottom: '0.375rem',
                    }}
                    renderInput={params => (
                      <TextField {...params} label={t('generic.country')} />
                    )}
                    onChange={(event, value) => handleAutocomplete(event, value, 'COUNTRY')}
                  />
                </div>
              </div>

              {displaySelectors.province ? (
                <div className="sm:col-span-full">
                  <div className="mt-2">
                    <Autocomplete
                      disablePortal
                      id="provinces"
                      value={valueSelectors.province}
                      options={optionSelectors.province}
                      sx={{
                        width: '100%',
                        display: 'block',
                        borderRadius: '0.375rem',
                        borderWidth: '0px',
                        paddingTop: '0.375rem',
                        paddingBottom: '0.375rem',
                      }}
                      renderInput={params => (
                        <TextField {...params} label={t('generic.province')} />
                      )}
                      onChange={(event, value) => handleAutocomplete(event, value, 'PROVINCE')}
                    />
                  </div>
                </div>
              ) : null}

              {displaySelectors.organization ? (
                <div className="sm:col-span-full">
                  <div className="mt-2">
                    <Autocomplete
                      disablePortal
                      id="organization"
                      value={valueSelectors.organization}
                      options={optionSelectors.organization}
                      sx={{
                        width: '100%',
                        display: 'block',
                        borderRadius: '0.375rem',
                        borderWidth: '0px',
                        paddingTop: '0.375rem',
                        paddingBottom: '0.375rem',
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('generic.organization')}
                        />
                      )}
                      onChange={(event, value) => handleAutocomplete(event, value, 'ORGANIZATION')}
                    />
                  </div>
                </div>
              ) : null}
              <p className="mt-2 text-xs text-gray-600">
                {t('auth.signUpManager.organizationNotFoundLabel')}
                <Link
                  to="/contact"
                  className="font-medium text-blue-600 hover:text-blue-500 px-1"
                >
                  {t('auth.signUpManager.contactUs')}
                </Link>
                {t('auth.signUpManager.toAddOrganization')}
              </p>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="team"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.teamName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="group_name"
                  id="team"
                  value={state.group_name}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mt-2 col-span-full">
              <label
                htmlFor="specialty"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.specialty')}
              </label>
              <select
                id="specialty"
                name="specialty"
                className="block w-full mt-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                defaultValue={state.specialty} // Set default value
                onChange={e => dispatch({
                  type: 'UPDATE_FIELD',
                  field: 'specialty',
                  value: e.target.value,
                })}
              >
                <option value="" />
                {Object.entries(specialties).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Email and phone number */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('generic.email')}
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={state.email}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('generic.phoneNumber')}
              <span className="text-xs text-gray-500">
                {' '}
                (
                {t('generic.optional')}
                )
              </span>
            </label>
            <div className="mt-2">
              <input
                id="phone-number"
                name="phone"
                type="tel"
                value={state.phone}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.password')}
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={state.password}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('auth.signUpManager.confirmPassword')}
              </label>
              <div className="mt-2">
                <input
                  id="confirm-password"
                  name="confirmPassword"
                  type="password"
                  required
                  value={state.confirmPassword}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <label
              htmlFor="source-origin"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('auth.signUpManager.sourceOrigin')}
            </label>
            <select
              id="sourceOrigin"
              name="sourceOrigin"
              className="block w-full mt-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              defaultValue={state.sourceOrigin} // Set default value
              onChange={e => dispatch({
                type: 'UPDATE_FIELD',
                field: 'sourceOrigin',
                value: e.target.value,
              })}
            >
              <option value="" />
              <option value={ClientSourcingOrigin.REFERRAL}>
                {t('auth.signUpManager.referral')}
              </option>
              <option value={ClientSourcingOrigin.BROWSER}>
                {t('auth.signUpManager.browser')}
              </option>
              <option value={ClientSourcingOrigin.OUTREACH}>
                {t('auth.signUpManager.outreach')}
              </option>
              <option value={ClientSourcingOrigin.HOSPITAL_MANAGEMENT}>
                {t('auth.signUpManager.management')}
              </option>
              <option value={ClientSourcingOrigin.SOCIAL_MEDIA}>
                {t('auth.signUpManager.social_media')}
              </option>
              <option value={ClientSourcingOrigin.OTHER}>
                {t('generic.other')}
              </option>
            </select>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="privacy-policy"
                name="privacyPolicy"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={state.has_agreed_to_privacy_policy}
                onChange={handleTogglePrivacyPolicy}
              />
              <label
                htmlFor="privacy-policy"
                className="ml-2 block text-sm text-gray-900"
              >
                {t('auth.signUpManager.privacyPolicyLabel')}
                {' '}
                <Link to="/privacy" className="text-blue-600">
                  {t('auth.signUpManager.privacyPolicyLink')}
                </Link>
              </label>
            </div>
          </div>
          <div className="mt-6 -mb-4">
            {/* <Alert text={t('auth.signUpManager.registrationUnavailable')} /> */}
            {error ? <Alert text={error} /> : null}
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {t('auth.signUpManager.createAccount')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
