import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { UserRequirementRuleErrors } from '../../pages/Manager/ManualAssignment';

type Props = {
  ruleErrors: UserRequirementRuleErrors[number];
  userFullNameMap: Record<number, string>;
};

const UserReqTable: React.FC<Props> = ({ ruleErrors, userFullNameMap }) => {
  const deficits = ruleErrors.user_req_deficit || {};
  const surpluses = ruleErrors.user_req_surplus || {};

  const deficitUsers = Object.entries(deficits);
  const surplusUsers = Object.entries(surpluses);

  const { t } = useTranslation();

  // If no deficits or surpluses, render nothing (or you could return null)
  if (deficitUsers.length === 0 && surplusUsers.length === 0) {
    return null;
  }

  return (
    <div className="">
      {/* Deficit Table */}
      {deficitUsers.length > 0 && (
        <div className="my-2">
          <div className="flex flex-row items-center">
            <MinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-rose-500" />
            <h3 className="text-base font-semibold p-2">
              {t('manager.assignmentErrors.user_req_deficit_title')}
            </h3>
          </div>
          <div className=" bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('generic.name')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.min')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.missing')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {deficitUsers.map(
                    ([userId, { num_assignments, min_slots }]) => {
                      const missing = min_slots - num_assignments;
                      return (
                        <tr key={userId} className="hover:bg-gray-50">
                          <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                            {userFullNameMap[Number(userId)]}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {num_assignments}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {min_slots}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {missing}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Surplus Table */}
      {surplusUsers.length > 0 && (
        <div className="">
          <div className="flex flex-row items-center">
            <PlusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-yellow-500" />
            <h3 className="text-base font-semibold p-2">
              {t('manager.assignmentErrors.user_req_surplus_title')}
            </h3>
          </div>{' '}
          <div className="mb-6 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('generic.name')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.max')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.excess')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {surplusUsers.map(
                    ([userId, { num_assignments, max_slots }]) => {
                      const excess = num_assignments - max_slots;
                      return (
                        <tr key={userId} className="hover:bg-gray-50">
                          <td className="px-2 py-1whitespace-nowrap text-sm font-medium text-gray-900">
                            {userFullNameMap[Number(userId)]}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {num_assignments}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {max_slots}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {excess}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserReqTable;
