import { Outlet } from 'react-router-dom';

import { Footer, Header } from '../components/Landing/index';

export default function Landing() {
  return (
    <div className="bg-white" style={{ scrollBehavior: 'smooth' }}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}
