/* eslint-disable max-len */
/* eslint-disable guard-for-in */
import { QueryClient, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  NavLink,
  Outlet,
  useLoaderData,
  useOutletContext,
} from 'react-router-dom';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import {
  FetchUserExchangeDashboardResponse,
  userExchangeDashboardQuery,
} from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { Section } from '@youshift/shared/types';

import Alert from '../../../components/FormFeedback/Alert';
// import TutorialComponent from '../../../components/TutorialComponent';
// import { useTutorial } from '../../../utils/TutorialContext';
import { bgColor, textColor } from '../../../utils/constantsLegacy';
import NotFound from '../../NotFound';
import PostAnnouncement from './PostAnnouncement';

const { v4: uuidv4 } = require('uuid');

export const userShiftExchangeLoader =
  (queryClient: QueryClient) =>
  async (): Promise<FetchUserExchangeDashboardResponse> => {
    const data = await preLoadQuery(queryClient, userExchangeDashboardQuery());
    return data;
  };

// const initialSections = useLoaderData() as Section[];
// const { data: sections } = useQuery({
//   ...sectionsQuery(idItr || ''),
//   initialData: initialSections,
// });

function UserShiftExchange() {
  const initialShiftExchangeData =
    useLoaderData() as FetchUserExchangeDashboardResponse;
  const { data } = useQuery({
    ...userExchangeDashboardQuery(),
    initialData: initialShiftExchangeData,
  });

  const { t } = useTranslation();

  const tabs = [
    { name: t('generic.shiftExchange.feed'), href: 'feed' },
    { name: t('generic.shiftExchange.myExchanges'), href: 'requests' },
  ];

  const mergeSections = (): Record<number, Section> => {
    // Iterate over the 'itrs' object
    let allSections: Record<number, Section> = {};
    for (const id_itr in data) {
      allSections = { ...allSections, ...data[id_itr].sections };
    }
    return allSections;
  };
  const allSections = mergeSections();

  // const textColors = {};
  // const bgColors = {};
  // Object.entries(allSections).forEach(([key], index) => {
  //   textColors[key] = textColor[index % textColor.length];
  //   bgColors[key] = bgColor[index % bgColor.length];
  // });

  // const parseShiftOutputs = () => {
  //   const shiftOutputs = {};
  //   const shiftOutputsText = [];
  //   Object.keys(data).forEach(id_itr => {
  //     data.shift_outputs[itr].forEach(output => {
  //       const date = new Date(
  //         Object.values(data.itrs[itr].slots).find(
  //           slot => slot.id_slot == output.id_slot,
  //         )?.start,
  //       );
  //       const currentDate = new Date();
  //       if (date > currentDate) {
  //         shiftOutputsText.push({
  //           text: `${date.toLocaleString(undefined, { day: '2-digit', month: 'short', timeZone: 'GMT' })} - ${allShifts[output.id_shift]?.acronym}`,
  //           id: output.id_output,
  //         });
  //       }
  //       if (!shiftOutputs[itr]) {
  //         shiftOutputs[itr] = [{ ...output, date }];
  //       } else {
  //         shiftOutputs[itr].push({ ...output, date });
  //       }
  //     });
  //   });
  //   return { shiftOutputs, shiftOutputsText };
  // };

  // const { shiftOutputs, shiftOutputsText } = parseShiftOutputs();

  // const adjustWeekDay = slot_prefs => {
  //   const weekdayAbbreviations = {
  //     Mon: 0,
  //     Tue: 1,
  //     Wed: 2,
  //     Thu: 3,
  //     Fri: 4,
  //     Sat: 5,
  //     Sun: 6,
  //   };

  //   const startWeekDay = slot_prefs[0].start.substring(0, 3);
  //   const endWeekDay = slot_prefs[slot_prefs.length - 1].start.substring(0, 3);
  //   const startCount = weekdayAbbreviations[startWeekDay];
  //   const endCount = 6 - weekdayAbbreviations[endWeekDay];
  //   const fillersStart = Array(startCount)
  //     .fill(null)
  //     .map(() => ({
  //       id_slots: [uuidv4()],
  //       start: '',
  //     }));

  //   const fillersEnd = Array(endCount)
  //     .fill(null)
  //     .map(() => ({
  //       id_slots: [uuidv4()],
  //       start: '',
  //     }));

  //   const adjustedShiftsNeeds = [...fillersStart, ...slot_prefs, ...fillersEnd];
  //   return adjustedShiftsNeeds;
  // };

  const [open, setOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [success, setSuccess] = useState(false);

  // // Function to find the index of the iteration in the current month
  // const findCurrentMonthIterationIndex = () => {
  //   const currentMonth = new Date().getUTCMonth();
  //   const currentYear = new Date().getUTCFullYear();

  //   const itrKeys = Object.keys(data.itrs);
  //   for (let i = 0; i < itrKeys.length; i++) {
  //     const itrStartDate = new Date(data.itrs[itrKeys[i]].start_day);
  //     if (
  //       itrStartDate.getUTCMonth() === currentMonth &&
  //       itrStartDate.getUTCFullYear() === currentYear
  //     ) {
  //       return i;
  //     }
  //   }

  //   return 0; // default to 0 if no matching iteration is found
  // };

  // const [selectedItrIdx, setSelectedItrIdx] = useState(
  //   findCurrentMonthIterationIndex(),
  // );

  // const selectedItr = Object.keys(data.itrs)[
  //   selectedItrIdx % Object.keys(data.itrs).length
  // ];
  // const itrLength = Object.keys(data.itrs).length;

  // const parseRequestableShiftOutputsText = () => {
  //   const requestableList = [];
  //   shiftOutputsText.forEach(outputText => {
  //     if (data.requestable_shift_outputs.includes(outputText.id)) {
  //       requestableList.push(outputText);
  //     }
  //   });
  //   return requestableList;
  // };

  // const requestableShiftOutputsText = parseRequestableShiftOutputsText();

  // to display the name of selected itr
  // const chainName = data.itrs[selectedItr]?.chain.chain_name;
  // const startDate = data.itrs[selectedItr]?.start_day;
  // const endDate = data.itrs[selectedItr]?.end_day;
  // const itrType = data.itrs[selectedItr]?.itr_type;

  // const dateRange = parseIterationDates(startDate, endDate, itrType);

  // const { startTutorial } = useTutorial();

  // useEffect(() => {
  //   startTutorial('user_shift_exchange');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (Object.keys(data).length < 1) {
    return (
      <NotFound
        text={t('user.shiftExchange.noItrsTitle')}
        description={t('user.shiftExchange.noItrsSubtitle')}
      />
    );
  }

  return (
    <div className="md:mt-16">
      {/* <TutorialComponent /> */}
      <div className="xl:grid xl:grid-cols-2">
        <div className="sm:mr-6">
          <div className="block">
            <nav
              className="isolate flex divide-x rounded-lg shadow"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  id={tab.href}
                  className={classNames(
                    '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
                  )}
                >
                  <span>{tab.name}</span>
                </NavLink>
              ))}
            </nav>
          </div>
          {/* {open ? (
            <PostAnnouncement
              open={open}
              setOpen={setOpen}
              selectedShift={selectedShift}
              shifts={requestableShiftOutputsText}
              setSuccess={setSuccess}
            />
          ) : null} */}
          {success ? <Alert text={success} success /> : null}
          <Outlet context={data} />
        </div>
        {/* <div id="calendar">
          {Object.keys(data.itrs).length > 0 ? (
            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col mt-8 max-w-2xl min-w-[50%] h-auto">
              <div className="flex flex-row">
                <p className="text-gray-600 pl-3 pt-3 pr-3 pb-2 font-semibold">
                  {chainName} -{dateRange}
                </p>
                <div className="flex flex-row items-center justify-end gap-2">
                  <button
                    className="text-gray font-bold py-0 px-0 rounded mt-1 bg-blue-500 disabled:bg-blue-300"
                    onClick={() =>
                      setSelectedItrIdx(prevIdx => {
                        const { length } = Object.keys(data.itrs);
                        return (prevIdx - 1 + length) % length;
                      })
                    }
                    disabled={selectedItrIdx === 0}
                  >
                    <span className="sr-only">+1</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white rounded"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>
                  <button
                    className="text-gray font-bold py-0 px-0 rounded mt-1 bg-blue-500 disabled:bg-blue-300"
                    onClick={() =>
                      setSelectedItrIdx(prevIdx => {
                        const { length } = Object.keys(data.itrs);
                        return (prevIdx + 1) % length;
                      })
                    }
                    disabled={selectedItrIdx === itrLength - 1}
                  >
                    <span className="sr-only">-1</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white rounded"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.mon">
                    L<span className="sr-only sm:not-sr-only">unes</span>
                  </Trans>
                </div>
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.tue">
                    M<span className="sr-only sm:not-sr-only">artes</span>
                  </Trans>
                </div>
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.wed">
                    M<span className="sr-only sm:not-sr-only">iércoles</span>
                  </Trans>
                </div>
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.thu">
                    J<span className="sr-only sm:not-sr-only">ueves</span>
                  </Trans>
                </div>
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.fri">
                    V<span className="sr-only sm:not-sr-only">iernes</span>
                  </Trans>
                </div>
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.sat">
                    S<span className="sr-only sm:not-sr-only">ábado</span>
                  </Trans>
                </div>
                <div className="bg-white py-2">
                  <Trans i18nKey="calendars.sun">
                    D<span className="sr-only sm:not-sr-only">omingo</span>
                  </Trans>
                </div>
              </div>
              <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 flex-auto">
                <div
                  className={`w-full grid grid-cols-7 grid-rows-${Math.ceil(Object.values(data.itrs[selectedItr].slots).length / 7)} gap-px`}
                >
                  {adjustWeekDay(
                    Object.values(data.itrs[selectedItr].slots),
                  ).map(slot => {
                    const { start, id_slot } = slot;
                    // eslint-disable-next-line max-len
                    const output = shiftOutputs[selectedItr]?.find(
                      output => output.id_slot == id_slot,
                    );

                    return (
                      <div
                        key={slot.id_slot}
                        className={classNames(
                          start ? 'bg-white' : 'bg-gray-50 text-gray-500',
                          'relative py-2 px-3 flex flex-col justify-around h-32',
                        )}
                      >
                        {start ? (
                          <time dateTime={start} className="absolute top-2">
                            {start.slice(5, 7)}
                          </time>
                        ) : null}
                        <div className="flex flex-row justify-center">
                          {start ? (
                            <div className="flex flex-col gap-3">
                              {output ? (
                                <button
                                  className={`items-center text-xs sm:text-sm text-center rounded-md ${bgColors[output.id_shift]} ${textColors[output.id_shift]} sm:py-2 sm:px-1.5 text-gray-600 disabled:opacity-30`}
                                  onClick={() => {
                                    setSelectedShift(output.id_output);
                                    setOpen(true);
                                  }}
                                  disabled={
                                    new Date(start) < new Date() ||
                                    !data.requestable_shift_outputs.includes(
                                      output.id_output,
                                    )
                                  }
                                >
                                  {allShifts[output.id_shift].name}
                                </button>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div> */}
      </div>
    </div>
  );
}

export default UserShiftExchange;

export function useShiftExchangeContext() {
  return useOutletContext<FetchUserExchangeDashboardResponse>();
}
