import { useQueryClient } from '@tanstack/react-query';
import { useCreateSlotMutation } from '@youshift/shared/hooks/mutations';
import { Iteration, SlotLabel } from '@youshift/shared/types';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotDetailsBase from './SlotDetails';
import { SectionSlotsReducerActionType } from '../../pages/Manager/IterationConfig/Sections/SectionConfig';

export interface EditSlotProps {
  shiftLabels: SlotLabel[];
  iteration: Iteration;
  idSection: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSuccess: Dispatch<SetStateAction<boolean | string>>;
  dispatch: Dispatch<SectionSlotsReducerActionType>;
}

function CreateSlot({
  shiftLabels,
  iteration,
  idSection,
  setOpen,
  setSuccess,
  dispatch,
}: EditSlotProps) {
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [minPeople, setMinPeople] = useState<number>(0);
  const [maxPeople, setMaxPeople] = useState<number>(0);
  const [restHours, setRestHours] = useState<number>(0);
  const [error, setError] = useState<boolean | string>(false);

  const idItr = iteration.id_itr;

  const duration = useMemo(() => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    // Get the difference in milliseconds
    const differenceInMs = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to minutes
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));

    return differenceInMinutes;
  }, [endTime, startTime]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createSlotMutation = useCreateSlotMutation(queryClient, {
    onSuccess: data => {
      const { slot } = data;
      dispatch({ type: 'ADD_SLOT', slot });
      setSuccess(t('manager.servicesConfig.createSlotSuccess'));
      setError(false);
      setOpen(false);
    },
    onError: error => {
      setError(error?.message);
    },
  });

  const handleSubmit = () => {
    if (selectedLabelId !== null) {
      createSlotMutation.mutate({
        id_section: idSection,
        id_itr: idItr,
        id_slot_label: selectedLabelId,
        rest_period: restHours,
        duration_minutes: duration,
        start: startTime,
        min_need: minPeople,
        max_need: maxPeople,
      });
    }
  };

  const buttonDisabled = useMemo(
    () =>
      selectedLabelId === null ||
      startTime === '' ||
      endTime === '' ||
      maxPeople < minPeople ||
      maxPeople === 0,
    [selectedLabelId, startTime, endTime, maxPeople, minPeople],
  );

  return (
    <SlotDetailsBase
      selectedLabelId={selectedLabelId}
      setSelectedLabelId={setSelectedLabelId}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      minPeople={minPeople}
      setMinPeople={setMinPeople}
      maxPeople={maxPeople}
      setMaxPeople={setMaxPeople}
      restHours={restHours}
      setRestHours={setRestHours}
      duration={duration}
      shiftLabels={shiftLabels}
      buttonDisabled={buttonDisabled}
      onSubmit={handleSubmit}
      submitButtonLabel={t('generic.create')}
      error={error}
      itrStart={iteration.start_day}
      itrEnd={iteration.end_day}
    />
  );
}

export default CreateSlot;
