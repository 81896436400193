/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EventType,
  PreferenceSlot,
  Section,
  SectionSlot,
  ShiftAssignment,
  ShiftAssignmentType,
  SlotLabel,
  SpecialEvent,
  SpecialEventWithoutDates,
  UserPreference,
  UserPreferenceType,
} from '@youshift/shared/types';
import {
  LabelIcon,
  dateToString,
  eventBgColor,
  returnColor,
} from '@youshift/shared/utils';
import {
  CalendarTouchableOpacityProps,
  EventRenderer,
  ICalendarEventBase,
} from 'react-native-big-calendar';
import { useTranslation } from 'react-i18next';

import { backendToLocalDate } from './helpers';
import LabelIconComponent from '../components/LabelIconComponent';
import { iconTypes } from './constants';

export interface CalendarEvent extends ICalendarEventBase {
  type: 'USER_PREFERENCE' | 'SHIFT_ASSIGNMENT' | 'EVENT';
}

export interface CalendarShiftAssignments extends CalendarEvent {
  color: string; // Background color for the event
  icon: LabelIcon;
  section: string; // Section name
  assignmentType: ShiftAssignmentType;
}

export interface CalendarUserPreference extends CalendarEvent {
  id_pref_slot: number;
  preference: UserPreferenceType | null;
  icon: LabelIcon;
  justification: string | null;
  points: number;
  associatedSectionSlots: number[];
}

export interface CalendarSpecialEvent extends CalendarEvent {
  id_special_event: number;
}

export type CalendarEvents =
  | CalendarUserPreference // This extends CalendarEvent with additional fields
  | CalendarShiftAssignments
  | CalendarSpecialEvent;

export function isCalendarUserPreference(
  event: CalendarEvents,
): event is CalendarUserPreference {
  return event.type === 'USER_PREFERENCE';
}

export function isCalendarShiftAssignments(
  event: CalendarEvents,
): event is CalendarShiftAssignments {
  return event.type === 'SHIFT_ASSIGNMENT';
}

export function isCalendarSpecialEvent(
  event: CalendarEvents,
): event is CalendarSpecialEvent {
  return event.type === 'EVENT';
}

// workaround to find the width of an event that spans across days without using
// react native but using the native classes from the calendar library
type StyleObject = {
  [key: string]: any; // Allows dynamic keys with any value
  width?: number | string; // Specifically typing the width property
};

export type RecursiveStyle = StyleObject | StyleObject[];

export function findWidth(style: RecursiveStyle): number | string | undefined {
  if (Array.isArray(style)) {
    // If it's an array, recursively check each element
    for (const subStyle of style) {
      const width = findWidth(subStyle);
      if (width !== undefined) {
        return width;
      }
    }
  } else if (style && typeof style === 'object') {
    // If it's an object and has a width property, return it
    if ('width' in style) {
      return style.width;
    }
  }
  // Return undefined if no width is found
  return undefined;
}

type DaysCoveredResult = {
  fullDays: number;
  lastDayPercentage: number;
};

export function calculateDaysCovered(
  startDate: Date,
  endDate: Date,
): DaysCoveredResult {
  // Normalize start and end to the beginning of their respective days
  const startOfDay = new Date(startDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(endDate);
  endOfDay.setHours(0, 0, 0, 0);

  // Calculate the difference in days
  const dayDifference = Math.round(
    (endOfDay.getTime() - startOfDay.getTime()) / (1000 * 60 * 60 * 24),
  );

  // At least one full day (the start day)
  const fullDays = Math.max(0, dayDifference);

  // Calculate the percentage of the last day covered
  const totalHoursInDay = 24;
  const endHours = endDate.getHours() + endDate.getMinutes() / 60;
  const lastDayPercentage = Math.min(1, endHours / totalHoursInDay);

  return {
    fullDays,
    lastDayPercentage: parseFloat(lastDayPercentage.toFixed(2)),
  };
}

// Utility function for generating events by date
export const generateEventsByDate = (
  special_events: Record<string, SpecialEvent>,
) => {
  const events: Record<string, SpecialEventWithoutDates[]> = {};
  Object.values(special_events).forEach(event => {
    const { start, end, ...rest } = event;
    const start_date = new Date(start).setUTCHours(0, 0, 0, 0);
    const end_date = new Date(end).setUTCHours(23, 59, 59, 999);

    for (
      let date = new Date(start_date);
      date <= new Date(end_date);
      date.setDate(date.getDate() + 1)
    ) {
      const dateString = dateToString(date, 'dd/mm/yyyy');
      if (!events[dateString]) {
        events[dateString] = [];
      }
      events[dateString].push({ ...rest });
    }
  });
  return events;
};

// Utility function for generating timed preference slots
export const generateTimedPrefSlots = (
  pref_slots: Record<number, PreferenceSlot>,
  section_slots: Record<number, SectionSlot>,
  slot_labels: Record<number, SlotLabel>,
  userPrefs: Record<number, UserPreference>,
  eventsByDate: Record<string, SpecialEventWithoutDates[]>,
  shift_assignments: Record<number, ShiftAssignment>,
): CalendarUserPreference[] => {
  return Object.values(pref_slots)
    .filter(
      prefSlot =>
        prefSlot.section_slots.length > 0 &&
        prefSlot.section_slots.every(
          slotId =>
            !eventsByDate[
              dateToString(section_slots[slotId].start, 'dd/mm/yyyy')
            ] && !shift_assignments[slotId],
        ),
    )
    .map(prefSlot => {
      const { id_pref_slot, section_slots: associatedSectionSlots } = prefSlot;
      const times = associatedSectionSlots.map(slotId => {
        const sectionSlot = section_slots[slotId];
        return {
          start: new Date(sectionSlot.start),
          end: new Date(sectionSlot.end),
        };
      });

      const earliest_start = new Date(
        Math.min(...times.map(t => t.start.getTime())),
      );
      const startDate = new Date(
        ...backendToLocalDate(earliest_start.toISOString()),
      );

      const userPref = userPrefs[id_pref_slot];
      const label = slot_labels[prefSlot.id_slot_label];

      return {
        id_pref_slot,
        associatedSectionSlots,
        title: label.name || '',
        icon: label.icon,
        start: startDate,
        end: startDate,
        preference: userPref?.preference || null,
        points: userPref?.points || 0,
        justification: userPref?.justification || null,
        type: 'USER_PREFERENCE',
      } as CalendarUserPreference;
    });
};

// Add more utility functions as needed for shift assignments, events, etc.
export const generateShiftAssignments = (
  shift_assignments: Record<number, ShiftAssignment>,
  section_slots: Record<number, SectionSlot>,
  sections: Record<number, Section>,
  slot_labels: Record<number, SlotLabel>,
): CalendarShiftAssignments[] => {
  return Object.values(shift_assignments).map(assignment => {
    const sectionSlot = section_slots[assignment.id_section_slot];
    const section = sections[assignment.id_section];
    const label = slot_labels[sectionSlot.id_slot_label];
    return {
      title: label.name,
      start: new Date(sectionSlot.start),
      end: new Date(sectionSlot.end),
      icon: label.icon,
      color: returnColor(section.color),
      assignmentType: assignment.type,
      section: `${section.name}`,
      type: 'SHIFT_ASSIGNMENT',
    };
  });
};

/**
 * Generates an array of CalendarSpecialEvent from a record of special events.
 *
 * @param special_events - The record of special events to process
 * @returns An array of CalendarSpecialEvent objects
 */
export const generateCalendarEvents = (
  special_events: Record<number, SpecialEvent>,
): CalendarSpecialEvent[] => {
  return Object.values(special_events).map(event => {
    const { start, end, type, id_special_event, ...rest } = event;

    return {
      ...rest,
      id_special_event,
      title: type,
      start: new Date(...backendToLocalDate(start)),
      end: new Date(...backendToLocalDate(end)),
      type: 'EVENT',
    } as CalendarSpecialEvent;
  });
};

type ButtonProps = {
  delayPressIn: number;
  key: string;
  onPress: () => void;
  disabled: boolean;
};

const renderUserPreference = (
  event: CalendarUserPreference,
  buttonProps: ButtonProps,
) => {
  const { preference, icon } = event;
  return (
    <button
      {...buttonProps}
      className="relative z-30 bg-white border border-solid rounded-md text-xs p-1 mt-1 flex flex-row items-center gap-1"
    >
      <div
        className="h-3 w-3 rounded-full"
        style={{
          backgroundColor: preference
            ? eventBgColor(
                preference,
                false,
                preference === UserPreferenceType.POINTS
                  ? event.points
                  : undefined,
                50, // Default base points
              )
            : 'gray',
        }}
      />
      <LabelIconComponent icon={icon} className="w-4 h-4" />
      <p>{event.title}</p>
    </button>
  );
};

// Helper to render CalendarShiftAssignments
const renderShiftAssignments = (
  event: CalendarShiftAssignments,
  buttonProps: ButtonProps,
  style: RecursiveStyle,
) => {
  const startHours = dateToString(event.start, 'hh:mm');
  const endHours = dateToString(event.end, 'hh:mm');
  const covered = calculateDaysCovered(event.start, event.end);
  const totalWidth = findWidth(style as RecursiveStyle);
  const widthPerDay = (totalWidth as number) / (covered.fullDays + 1);
  const width =
    covered.fullDays * widthPerDay + widthPerDay * covered.lastDayPercentage;

  return (
    <button
      className="bg-white z-30 relative mt-2 border border-l-[16px] border-solid rounded-md flex items-center justify-start text-xs p-1"
      style={{
        borderLeftColor: event.color,
        borderColor: event.color,
        width: `${width}px`,
      }}
    >
      <IconDisplay type={event.assignmentType} />
      <div className="relative">
        <LabelIconComponent
          icon={event.icon}
          className="w-4 h-4 absolute -left-5"
        />
        {`${startHours}-${endHours}: ${event.title}`}
      </div>
    </button>
  );
};

// Helper to render CalendarSpecialEvent
const renderSpecialEvent = (
  event: CalendarSpecialEvent,
  style: RecursiveStyle,
) => {
  const e = event.title as EventType;
  const width = findWidth(style);
  return (
    <div
      style={{ width: `${width}px` }}
      className="flex flex-row flex-grow z-0 relative"
    >
      <div
        style={{
          backgroundColor: eventBgColor(e, true),
          borderRadius: '0.5rem',
          border: `1px solid ${eventBgColor(e, false)}`,
        }}
        className="w-full border-md mt-1 py-1.5 text-center font-semibold relative z-0"
      >
        {`Event: ${e}`}
      </div>
    </div>
  );
};

// Main customEventRenderer function
export const customEventRenderer: EventRenderer<CalendarEvents> = (
  event: CalendarEvents,
  touchableOpacityProps: CalendarTouchableOpacityProps,
) => {
  const { style, ...buttonProps } = touchableOpacityProps;

  if (event.type === 'USER_PREFERENCE') {
    return renderUserPreference(event as CalendarUserPreference, buttonProps);
  } else if (event.type === 'SHIFT_ASSIGNMENT') {
    return renderShiftAssignments(
      event as CalendarShiftAssignments,
      buttonProps,
      style as RecursiveStyle,
    );
  } else if (event.type === 'EVENT') {
    return renderSpecialEvent(
      event as CalendarSpecialEvent,
      style as RecursiveStyle,
    );
  }

  return <></>;
};

interface IconDisplayProps {
  type: ShiftAssignmentType;
}

export function IconDisplay({ type }: IconDisplayProps) {
  const { t } = useTranslation();

  const iconConfig = iconTypes[type];

  if (!iconConfig) return null;

  const { bgColor, Icon } = iconConfig;

  return (
    <p
      className={`absolute right-0 top-0 transform -translate-y-1/2 w-5 h-5 z-10 p-0.5 text-center ${bgColor} rounded-xl text-sm text-white`}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={t(`generic.${type}`)}
    >
      <Icon />
    </p>
  );
}
