import {
  CalendarDaysIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { FetchUserExchangeDashboardResponse } from '@youshift/shared/hooks/queries';
import {
  Section,
  ExchangeRequest,
  ExchangeResponseType,
  SectionSlot,
  ShiftAssignment,
  User,
} from '@youshift/shared/types';
import { dateToString, mergeRecords } from '@youshift/shared/utils';

import Alert from '../../../components/FormFeedback/Alert';
import PostResponse from './PostResponse';
import { useShiftExchangeContext } from './ShiftExchangeLayout';

function UserShiftExchangeFeed() {
  const data = useShiftExchangeContext();
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] =
    useState<ExchangeRequest | null>(null);
  const [requestSuccess, setRequestSuccess] = useState<string | null>(null);
  const [exchangeType, setExchangeType] = useState<ExchangeResponseType | null>(
    null,
  );

  const { t } = useTranslation();

  return (
    <>
      {open && exchangeType && selectedRequest ? (
        <PostResponse
          data={data}
          open={open}
          setOpen={setOpen}
          selectedRequest={selectedRequest}
          setRequestSuccess={setRequestSuccess}
          exchangeType={exchangeType}
        />
      ) : null}
      {requestSuccess ? <Alert text={requestSuccess} success /> : null}
      {Object.values(data).every(
        itr => Object.keys(itr.other_users_pending_requests).length === 0,
      ) ? (
        <p className="mt-6 text-center text-gray-600">
          {t('user.shiftExchange.emptyFeed')}
        </p>
      ) : null}
      {Object.entries(data).map(([id_itr, itr]) =>
        Object.entries(itr.other_users_pending_requests).map(
          ([id_request, other_user_request]) => {
            const request_shift_assignment =
              itr.other_users_requestable_shift_assigments[
                other_user_request.id_shift_assignment
              ];
            const request_section_slot =
              itr.section_slots[request_shift_assignment.id_section_slot];
            const request_section =
              itr.sections[request_section_slot.id_section];
            const request_user =
              itr.other_users[other_user_request.id_requestor];
            return (
              <ul
                key={id_request}
                className="space-y-4 mt-6 overflow-hidden bg-white m-4 shadow-md border border-slate-200 rounded-lg"
              >
                <li
                  key={other_user_request.id_request}
                  className="relative flex sm:flex-row flex-col justify-between gap-x-6 px-4 py-5  sm:px-6 sm:rounded-lg"
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div>
                      <p className="flex flex-row gap-1 items-center">
                        <CalendarDaysIcon className="h-4 text-blue-600" />
                        {dateToString(
                          request_section_slot.start,
                          'weekday-hour',
                        )}
                      </p>
                      <p className="flex flex-row gap-1 items-center">
                        <MapPinIcon className="h-4 text-teal-600" />
                        {request_section.name}
                      </p>
                      <p className="flex flex-row gap-1 items-center">
                        <UserIcon className="h-4 text-gray-600" />
                        {`${request_user.firstname} ${request_user.lastname}`}
                      </p>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
                    <div className="flex flex-row sm:items-end sm:gap-1 gap-4">
                      <button
                        type="button"
                        className="border border-teal-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-teal-400/50 sm:ml-3 sm:w-auto disabled:bg-gray-100 disabled:border-gray-100"
                        disabled={
                          other_user_request.allow_one_for_one === false
                        }
                        onClick={() => {
                          setSelectedRequest(other_user_request);
                          setExchangeType(ExchangeResponseType.ONE_FOR_ONE);
                          setOpen(true);
                        }}
                      >
                        {t('user.shiftExchange.trade')}
                      </button>
                      <button
                        type="button"
                        className="border border-blue-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue-400/50 sm:ml-3 sm:w-auto disabled:bg-gray-100 disabled:border-gray-100"
                        disabled={
                          other_user_request.allow_one_for_zero === false
                        }
                        onClick={() => {
                          setSelectedRequest(other_user_request);
                          setExchangeType(ExchangeResponseType.ONE_FOR_ZERO);
                          setOpen(true);
                        }}
                      >
                        {t('user.shiftExchange.accept')}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            );
          },
        ),
      )}
    </>
  );
}
export default UserShiftExchangeFeed;
