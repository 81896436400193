import {
  Bars4Icon,
  PlusIcon,
  RectangleGroupIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import { userReqRulesQuery } from '@youshift/shared/hooks/queries';
import { Rule } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { EmptyState } from '../../../../components/EmptyState';
import RuleTypeBadge from '../../../../components/ItrConfig/RuleTypeBadge';
import Wrapper from '../../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../../utils/checks';

type UserReqRulesLoader = { rules: Rule[] };
export const userReqRulesLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<UserReqRulesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const rules = await preLoadQuery(
      queryClient,
      userReqRulesQuery(params.idItr),
    );
    return { rules };
  };

const RuleSection = ({
  title,
  type,
  rules,
  t,
}: {
  title: string;
  type: string;
  rules: Rule[];
  t: (key: string) => string;
}) => (
  <>
    <h3 className="font-semibold">{title}</h3>
    {rules
      .filter(rule => rule.type === type)
      .map(rule => (
        <div
          key={rule.id_rule}
          className="bg-white rounded-lg border border-gray-200 p-3"
        >
          <div className="flex items-center justify-between space-x-3">
            <div className="flex flex-row gap-2">
              <RuleTypeBadge
                type={rule.type}
                string={t(`manager.rulesConfig.${rule.type}`)}
              />
              <span className="text-lg font-medium">{rule.name}</span>
            </div>
            <Link
              to={`${rule.id_rule}`}
              className="p-1 rounded-full border border-gray-300"
            >
              <Bars4Icon className="w-5 h-5 text-gray-400" />
            </Link>
          </div>
        </div>
      ))}
  </>
);

export default function UserReqRulesConfig() {
  const { idItr: id } = useParams();

  const { data: rules } = useQuery(userReqRulesQuery(id!));
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!rules?.length) {
    return (
      <Wrapper>
        <EmptyState
          title={t('manager.rulesConfig.createPrompt')}
          subtitle={t('manager.rulesConfig.definition')}
          buttonText={t('manager.rulesConfig.createRule')}
          Icon={RectangleGroupIcon}
          onClick={() => navigate('./new')}
        />
      </Wrapper>
    );
  }

  return (
    <div className="py-6 h-full flex">
      <div className="space-y-2 max-w-lg flex-grow mx-auto">
        <RuleSection
          title={t('manager.rulesConfig.sectionRules')}
          type="SECTION_USER_REQS"
          rules={rules}
          t={t}
        />
        <RuleSection
          title={t('manager.rulesConfig.labelRules')}
          type="SLOT_LABEL_USER_REQS"
          rules={rules}
          t={t}
        />
        <RuleSection
          title={t('manager.rulesConfig.customRules')}
          type="CUSTOM_USER_REQS"
          rules={rules}
          t={t}
        />
        <button
          onClick={() => navigate('./new')}
          className="flex w-full items-center justify-between bg-gray-100 rounded-lg p-3 text-gray-400"
        >
          <span>{t('manager.rulesConfig.createRule')}</span>
          <div
            className="p-1 rounded-full border border-gray-300"
            aria-label={t('manager.rulesConfig.createRule')}
          >
            <PlusIcon className="w-5 h-5" />
          </div>
        </button>
      </div>
    </div>
  );
}
