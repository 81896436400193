import { Navigate, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { statusQuery } from '@youshift/shared/hooks/jsQueries';
import { ItrStatuses } from '@youshift/shared/types';

import { useItrContext } from './IterationLayout';


// HANDLES ROUTING WHEN CLICKING ON AN ITERATION
function IterationStatus() {
  const { status } = useItrContext();

  // prevent people from going to any route
  if (status === ItrStatuses.CONFIGURATION) {
    return <Navigate to="configuration" />;
  }
  if (status === ItrStatuses.INITIALIZATION) {
    return <Navigate to="initialization" />;
  }
  if (status === ItrStatuses.RUNNING_SMARTASS) {
    return <Navigate to="running" />;
  }
  if (status === ItrStatuses.ASSIGNMENT || status === ItrStatuses.PUBLISHED
    || status === ItrStatuses.ARCHIVED) {
    return <Navigate to="assignment" />;
  }
}

export default IterationStatus;
