import { QueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  UserPrefsResponse,
  userPrefsQuery,
} from '@youshift/shared/hooks/queries';
import { ItrStatuses, ShiftAssignmentType } from '@youshift/shared/types';
import { parseIterationDates } from '@youshift/shared/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-big-calendar';
import {
  LoaderFunctionArgs,
  Navigate,
  useLoaderData,
  useNavigation,
  useParams,
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import SectionLegend from '../../components/Calendars/SectionLegend';
import ShiftOutputLegend from '../../components/Calendars/ShiftOutputLegend';
import {
  customEventRenderer,
  generateCalendarEvents,
  generateShiftAssignments,
} from '../../utils/calendar';
import { requireApproved, requireLoggedIn } from '../../utils/checks';
import { iconTypes } from '../../utils/constants';
import { backendToLocalDate } from '../../utils/helpers';
import i18n from '../../utils/i18n';

const { v4: uuidv4 } = require('uuid');

export const resultsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserPrefsResponse | null> => {
    const user = await requireLoggedIn(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const results = preLoadQuery(queryClient, userPrefsQuery(params.idItr));
    return results;
  };

function Results() {
  const { idItr: id } = useParams();
  const {
    itr,
    itr_user,
    sections,
    section_slots,
    pref_slots,
    user_prefs,
    shift_assignments,
    user_shift_reqs,
    associated_shift_req_rules,
    slot_labels,
    special_events,
  } = useLoaderData() as UserPrefsResponse;

  const { t } = useTranslation();
  const locale = i18n.language;

  // const { state } = useLocation();

  const navigation = useNavigation();

  // const [showPreferences, setShowPreferences] = useState(false);

  const stats = [
    {
      name: t('user.results.yourRole'),
      stat: '',
    },
    {
      name: t('user.results.numberOfShifts'),
      stat: Object.values(shift_assignments).length,
    },
    {
      name: t('user.results.pointsRespected'),
      stat: `${(
        (((itr_user?.pos_respected ?? 0) +
          ((itr_user?.total_neg ?? 0) - (itr_user?.neg_not_respected ?? 0))) *
          100) /
        ((itr_user?.total_pos ?? 0) + (itr_user?.total_neg ?? 0) || 1)
      ).toFixed(2)} %`,
      subtitle: t('user.results.statsSummary', {
        posRespected: itr_user?.pos_respected ?? 0,
        totalPos: itr_user?.total_pos ?? 0,
        negNotRespected:
          (itr_user?.total_neg ?? 0) - (itr_user?.neg_not_respected ?? 0),
        totalNeg: itr_user?.total_neg ?? 0,
      }),
    },
    {
      name: t('user.results.pointsAccumulated'),
      stat:
        `${(itr_user.points_not_used ?? 0) + (itr_user.neg_not_respected ?? 0)}*` ||
        0,
      subtitle: t('user.results.accumulatedPoints', {
        notUsed: itr_user.points_not_used || 0,
        notRespected: itr_user.neg_not_respected || 0,
      }),
    },
  ];

  const globalShiftsViewAllowed = false;

  const shiftAssignments = useMemo(
    () =>
      generateShiftAssignments(
        shift_assignments,
        section_slots,
        sections,
        slot_labels,
      ),
    [shift_assignments, section_slots, sections, slot_labels],
  );

  // const eventsByDate = useMemo(
  //   () => generateEventsByDate(special_events),
  //   [special_events],
  // );

  const events = useMemo(
    () => generateCalendarEvents(special_events),
    [special_events],
  );

  return (
    <>
      {itr.status !== ItrStatuses.PUBLISHED ? (
        <Navigate to="/user/iterations" replace />
      ) : null}
      <div className="space-y-12 sm:space-y-16 md:mt-16">
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-semibold leading-6 text-gray-900">
            <time>{`${t('user.results.title')}: ${parseIterationDates(itr.start_day, itr.end_day, itr.itr_type)}`}</time>
          </h1>
          <div className="flex flex-row justify-between mt-8 mb-6 align-middle">
            {/* <Switch.Group as="div" className="flex items-center gap-1">
            <Switch
              checked={showPreferences}
              onChange={() => setShowPreferences(!showPreferences)}
              className={classNames(
                showPreferences ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showPreferences ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
              <span className="font-medium text-gray-900">
                {t('user.results.showMyPreferences')}
              </span>
            </Switch.Label>
          </Switch.Group> */}
            {/* {globalShiftsViewAllowed && (
            <div className="flex items-center">
              <NavLink
                to="all"
                className="ml-6 rounded-md  bg-blue-600 flex flex-row py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                state={state}
              >
                {navigation.state === 'loading' ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : null}
                {t('user.results.globalShifts')}
              </NavLink>
            </div>
          )} */}
          </div>
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {t('user.results.inThisItr')}
            </h3>
            <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
              {stats.map(item => (
                <div key={item.name} className="px-4 py-5 sm:p-6">
                  <dt className="text-base font-normal text-gray-900">
                    {item.name}
                  </dt>
                  <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                      {item.stat}
                      <span className="ml-2 text-sm font-medium text-gray-500">
                        {item.subtitle}
                      </span>
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="flex flex-row justify-between mb-4 flex-wrap">
            <SectionLegend sections={Object.values(sections)} />
            <ShiftOutputLegend />
          </div>
          <Calendar
            locale={locale}
            events={[...shiftAssignments, ...events]}
            height={600}
            mode="month"
            date={new Date(...backendToLocalDate(itr.start_day))}
            renderEvent={customEventRenderer}
          />
          <Tooltip
            id="my-tooltip"
            style={{
              backgroundColor: 'gray',
              opacity: 0.5,
              padding: 5,
              borderRadius: 4,
            }}
          />
          {/* <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col mt-4">
          <div
            className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200
        text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none"
          >
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.mon">
                L<span className="sr-only sm:not-sr-only">unes</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.tue">
                M<span className="sr-only sm:not-sr-only">artes</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.wed">
                M<span className="sr-only sm:not-sr-only">iércoles</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.thu">
                J<span className="sr-only sm:not-sr-only">ueves</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.fri">
                V<span className="sr-only sm:not-sr-only">iernes</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.sat">
                S<span className="sr-only sm:not-sr-only">ábado</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.sun">
                D<span className="sr-only sm:not-sr-only">omingo</span>
              </Trans>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 flex-auto">
            <div
              className={`w-full grid grid-cols-7 grid-rows-${Math.ceil(slot_prefs.length / 7)} gap-px`}
            >
              {adjustWeekDay(slot_prefs).map(slot => {
                const { start, id_slot } = slot.slot;
                const output = shift_outputs[id_slot];
                const slotOff = slotsOff[id_slot];
                return (
                  <div
                    key={slot.id_slot_pref}
                    className={classNames(
                      start ? 'bg-white' : 'bg-gray-50 text-gray-500',
                      'relative py-2 px-3 flex flex-col justify-around h-32',
                    )}
                  >
                    {start ? (
                      <time dateTime={start} className="absolute top-2">
                        {start.slice(5, 7)}
                      </time>
                    ) : null}
                    <div className="flex flex-row justify-center">
                      {start ? (
                        <div className="flex flex-col gap-3">
                          {output ? (
                            <>
                              <IconDisplay type={output?.type} />
                              <span
                                className={`z-0 relative items-center text-xs sm:text-base text-center rounded-md ${bgColors[output.id_shift]} ${textColors[output.id_shift]} sm:py-2 sm:px-1.5 text-gray-600`}
                              >
                                {shifts[output.id_shift].name}
                                {output.point_award ? (
                                  <span className="w-5 h-5 absolute -top-3 -right-2 bg-green-600 rounded-xl flex items-center justify-center">
                                    <p className=" text-white text-xxs text-center margin-auto align-middle ">
                                      +{output.point_award}
                                    </p>
                                  </span>
                                ) : null}
                              </span>
                            </>
                          ) : null}
                          <Tooltip
                            id="my-tooltip"
                            style={{
                              backgroundColor: 'gray',
                              opacity: 0.5,
                              padding: 5,
                              borderRadius: 4,
                            }}
                          />
                          {slotOff ? <EventSquare e="X" small /> : null}
                          {showPreferences ? (
                            <EventSquare
                              e={slot.preference.toUpperCase()}
                              points={slot.points}
                              justPoints
                              small
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div> */}
          {/* {totalPointsAwarded ? (
          <p className="font-normal text-black text-xs my-2">
            {t('user.results.pointsRewarded', { points: totalPointsAwarded })}
          </p>
        ) : null} */}
          {/* <p className="font-normal text-black text-xs my-2">
          {t('user.results.pointsToAccumulate', {
            points: data.chain.max_saved_points_allowed,
          })}
        </p> */}
        </div>
      </div>
    </>
  );
}

export default Results;
