import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  CursorArrowRippleIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  useEditSlotGroupElts,
  useEditSlotGroupUserReqs,
} from '@youshift/shared/hooks/mutations';
import {
  itrUsersQuery,
  personnelQuery,
  sectionSlotsQuery,
  slotLabelsQuery,
  userReqRuleQuery,
  iterationQuery,
  userReqRulesQuery,
} from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  Iteration,
  Rule,
  UserRequirement,
  SectionsWithSlots,
  SlotLabel,
  User,
  UserRole,
  RuleTypes,
  Section,
} from '@youshift/shared/types';
import { getShadeMap } from '@youshift/shared/utils';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import Virgueria from '../../../../components/ManualAssignment/Virgueria';
import Wrapper from '../../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../../utils/checks';
import { ArrangedSectionSlot } from '../Sections/CreateNewSection';
import DefineReqs from './DefineReqs';
import { Action, State } from './types';
import NavigateSectionsOrRules from '../../../../components/ItrConfig/NavigateSectionsOrRules';
import RuleTypeBadge from '../../../../components/ItrConfig/RuleTypeBadge';

type UserReqRuleLoader = {
  iteration: Iteration;
  rule?: Rule;
  rules: Rule[];
  user_reqs?: Record<number, UserRequirement>;
  section_slots?: number[];
  sectionsWithSlots: SectionsWithSlots;
  shiftLabels: SlotLabel[];
  itrUsers: number[];
  users: Record<number, User>;
  roles: Record<number, UserRole>;
};

export const userReqRuleLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserReqRuleLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const { idItr, idRule } = params;
    // TODO: do i need to query the iteration all the time...? prob not
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    const sectionsWithSlots = await preLoadQuery(
      queryClient,
      sectionSlotsQuery(idItr),
    );
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));
    const rules = await preLoadQuery(queryClient, userReqRulesQuery(idItr));
    const userReqRule =
      idRule != null
        ? await preLoadQuery(queryClient, userReqRuleQuery(idItr, idRule))
        : undefined;

    const itrUsers = Object.keys(
      await preLoadQuery(queryClient, itrUsersQuery(idItr)),
    ).map(Number);
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const { users, roles } = personnel;
    return {
      ...userReqRule,
      iteration,
      sectionsWithSlots,
      shiftLabels,
      users,
      roles,
      itrUsers,
      rules,
    };
  };

function computeDifferences(initialState: State, currentState: State) {
  const addedUserRequirements = [];
  const editedUserRequirements = [];
  const deletedUsers = [];

  for (const roleId in currentState.roles) {
    if (Object.prototype.hasOwnProperty.call(currentState.roles, roleId)) {
      const currentRole = currentState.roles[roleId];
      const initialRole = initialState.roles[roleId] || { users: [] };

      // Create a map of initial users for quick lookup
      const initialUsersMap = Object.fromEntries(
        initialRole.users.map(user => [user.id_user, user]),
      );

      // Create a map of current users for reverse lookup
      const currentUsersMap = Object.fromEntries(
        currentRole.users.map(user => [user.id_user, user]),
      );

      // Iterate over all users in currentRole
      for (const user of currentRole.users) {
        const initialUser = initialUsersMap[user.id_user];

        if (!initialUser.included && user.included) {
          // User was not included before but is now included
          addedUserRequirements.push({
            id_user: user.id_user,
            min_slots: user.min_slots,
            max_slots: user.max_slots,
            ...(currentState.isHoursConfig && {
              min_duration: user.min_duration,
            }),
            ...(currentState.isHoursConfig && {
              max_duration: user.max_duration,
            }),
          });
        } else if (initialUser.included && !user.included) {
          // User was included before but is now not included
          deletedUsers.push(user.id_user);
        } else if (user.included) {
          // User is included in both states, check if requirements changed
          const requirementsChanged =
            initialUser.min_slots !== user.min_slots ||
            initialUser.max_slots !== user.max_slots ||
            initialUser.min_duration !== user.min_duration ||
            initialUser.max_duration !== user.max_duration;

          if (requirementsChanged) {
            editedUserRequirements.push({
              id_user: user.id_user,
              min_slots: user.min_slots,
              max_slots: user.max_slots,
              ...(currentState.isHoursConfig && {
                min_duration: user.min_duration,
              }),
              ...(currentState.isHoursConfig && {
                max_duration: user.max_duration,
              }),
            });
          }
          // If both are not included, no action needed
        }
      }
    }
  }

  return {
    addedUserRequirements,
    editedUserRequirements,
    deletedUsers,
  };
}

export default function UserReqRuleConfig() {
  const {
    rule,
    iteration,
    user_reqs,
    section_slots,
    sectionsWithSlots,
    shiftLabels,
    itrUsers,
    roles,
    users,
    rules,
  } = useLoaderData() as UserReqRuleLoader;
  const [activeTab, setActiveTab] = useState<'slots' | 'reqs'>('slots');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);
  const [selectedSlots, setSelectedSlots] = useState(
    section_slots ? new Set(section_slots) : new Set<number>(),
  );
  const [slotsEdited, setSlotsEdited] = useState(false);
  const [reqsEdited, setReqsEdited] = useState(false);
  const saveable =
    (slotsEdited && activeTab === 'slots') ||
    (reqsEdited && activeTab === 'reqs');

  const slotsArentEditable =
    rule?.type === RuleTypes.SECTION_USER_REQS ||
    rule?.type === RuleTypes.SLOT_LABEL_USER_REQS;

  // Add all slots from any section whose start date matches the provided day to the selected slots set
  const onDayClick = (d: Date) => {
    if (!slotsArentEditable) {
      const dayStart = new Date(d);
      dayStart.setUTCHours(0, 0, 0, 0);
      const dayEnd = new Date(d);
      dayEnd.setUTCHours(23, 59, 59, 999);

      const newSelectedSlots = new Set(selectedSlots);

      sectionsWithSlots.forEach(({ section_slots }) => {
        Object.values(section_slots).forEach(slot => {
          const slotStart = new Date(slot.start);
          if (slotStart >= dayStart && slotStart <= dayEnd) {
            newSelectedSlots.add(slot.id_section_slot);
          }
        });
      });

      setSelectedSlots(newSelectedSlots);
    }
  };

  // Add all slots from the specified section to the selected slots set
  const onSectionClick = (s: Section) => {
    if (!slotsArentEditable) {
      const newSelectedSlots = new Set(selectedSlots);
      sectionsWithSlots.forEach(({ section, section_slots }) => {
        if (section.id_section === s.id_section) {
          Object.values(section_slots).forEach(slot => {
            newSelectedSlots.add(slot.id_section_slot);
          });
        }
      });
      setSelectedSlots(newSelectedSlots);
    }
  };

  const initialState: State = useMemo(() => {
    // Initialize the initialState object
    const state: State = {
      isHoursConfig: Boolean(
        Object.values(user_reqs || {})[0]?.max_duration !== undefined,
      ),
      roles: {},
    };

    // Initialize roles with role_name and empty users array
    for (const roleId in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, roleId)) {
        state.roles[roleId] = {
          role_name: roles[roleId].name,
          users: [],
        };
      }
    }

    // Iterate over all users and filter only those whose id is in itrUsers
    Object.values(users).forEach(user => {
      const userId = user.id;

      // Process only users whose id is in itrUsers
      if (itrUsers.includes(userId)) {
        const roleId = user.id_user_role;

        // Check if user_reqs exists for the user
        const userReq = user_reqs ? user_reqs[userId] : undefined;
        const hasUserReq = userReq !== undefined;

        // Create user object
        const userObj = {
          name: `${user.firstname} ${user.lastname}`,
          min_slots: hasUserReq ? userReq.min_slots : 0,
          max_slots: hasUserReq ? userReq.max_slots : 0,
          min_duration:
            hasUserReq && userReq.min_duration ? userReq.min_duration : 0,
          max_duration:
            hasUserReq && userReq.max_duration ? userReq.max_duration : 0,
          included: hasUserReq,
          id_user: userId,
        };

        // Add user to the appropriate role
        if (roleId) {
          if (state.roles[roleId]) {
            state.roles[roleId].users.push(userObj);
          } else {
            state.roles[roleId] = {
              role_name: roles[roleId] ? roles[roleId].name : 'Unknown Role',
              users: [userObj],
            };
          }
        }
      }
    });

    return state;
  }, [itrUsers, users, roles, user_reqs]);
  const reducer = useCallback((state: State, action: Action): State => {
    setReqsEdited(true);
    switch (action.type) {
      // Toggles the `isHoursConfig` boolean flag
      case 'TOGGLE_HOURS_CONFIG':
        return { ...state, isHoursConfig: !state.isHoursConfig };

      // Toggles the inclusion of all users within a specified role
      case 'TOGGLE_ROLE': {
        return {
          ...state,
          roles: {
            ...state.roles,
            [action.id_role]: {
              ...state.roles[action.id_role],
              users: state.roles[action.id_role].users.map(user => ({
                ...user,
                included: action.payload.type === 'on', // Toggle the included flag for each user
                min_slots: 0,
                max_slots: 0,
                min_duration: 0,
                max_duration: 0,
              })),
            },
          },
        };
      }

      // Toggles the inclusion of a specific user based on their `id_user`
      case 'TOGGLE_USER': {
        // Find the role that contains the user we want to toggle
        const roleId = Object.keys(state.roles).find(roleId =>
          state.roles[Number(roleId)].users.some(
            user => user.id_user === action.id_user,
          ),
        )!;
        return {
          ...state,
          roles: {
            ...state.roles,
            [roleId]: {
              ...state.roles[Number(roleId)],
              users: state.roles[Number(roleId)].users.map(user =>
                user.id_user === action.id_user
                  ? {
                      ...user,
                      included: !user.included, // Toggle the included flag for the user
                      min_slots: 0,
                      max_slots: 0,
                      min_duration: 0,
                      max_duration: 0,
                    }
                  : user,
              ),
            },
          },
        };
      }

      // Updates the requirements (slots or duration) for all users within a specified role
      case 'UPDATE_ROLE_REQS': {
        return {
          ...state,
          roles: {
            ...state.roles,
            [action.id_role]: {
              ...state.roles[action.id_role],
              users: state.roles[action.id_role].users.map(user => ({
                ...user,
                ...action.payload, // Update the relevant fields (min/max slots, min/max duration) for each user
              })),
            },
          },
        };
      }

      // Updates the requirements (slots or duration) for a specific user based on their `id_user`
      case 'UPDATE_USER_REQS': {
        // Find the role that contains the user whose requirements we want to update
        const roleId = Object.keys(state.roles).find(roleId =>
          state.roles[Number(roleId)].users.some(
            user => user.id_user === action.id_user,
          ),
        )!;
        return {
          ...state,
          roles: {
            ...state.roles,
            [roleId]: {
              ...state.roles[Number(roleId)],
              users: state.roles[Number(roleId)].users.map(user =>
                user.id_user === action.id_user
                  ? {
                      ...user,
                      ...action.payload, // Update the relevant fields (min/max slots, min/max duration) for the user
                    }
                  : user,
              ),
            },
          },
        };
      }

      // Default case returns the current state if no action matches
      default:
        return state;
    }
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleSlot = (slot: number | ArrangedSectionSlot) => {
    if (slotsArentEditable) {
      const element = document.querySelector('.edit-disabled-text');
      if (element) {
        element.classList.add('text-red-500', 'font-bold', 'text-lg');
        setTimeout(() => {
          element.classList.remove('text-red-500', 'font-bold', 'text-lg');
        }, 1000);
      }
      return;
    }
    setSlotsEdited(true);
    // this will always take a number
    const id = typeof slot === 'number' ? slot : slot.id!;
    setSelectedSlots(prevSelectedSlots => {
      const newSelectedSlots = new Set(prevSelectedSlots);
      if (newSelectedSlots.has(id)) {
        newSelectedSlots.delete(id);
      } else {
        newSelectedSlots.add(id);
      }
      return newSelectedSlots;
    });
  };

  const editSlotGroupEltsMutation = useEditSlotGroupElts(queryClient, {
    onSuccess: () => {
      setSlotsEdited(false);
    },
    onError: () => {},
  });
  const editSlotGroupUserReqs = useEditSlotGroupUserReqs(queryClient, {
    onSuccess: () => {
      setReqsEdited(false);
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', iteration.id_itr],
      });
    },
    onError: () => {},
  });

  if (!rule) {
    return null;
  }

  const saveChanges = () => {
    if (activeTab === 'slots') {
      editSlotGroupEltsMutation.mutate({
        id_itr: iteration.id_itr,
        id_rule: rule.id_rule,
        id_section_slots: Array.from(selectedSlots),
      });
    } else if (activeTab === 'reqs') {
      const { addedUserRequirements, editedUserRequirements, deletedUsers } =
        computeDifferences(initialState, state);
      editSlotGroupUserReqs.mutate({
        id_itr: iteration.id_itr,
        id_rule: rule?.id_rule,
        added_user_requirements: addedUserRequirements,
        edited_user_requirements: editedUserRequirements,
        deleted_users: deletedUsers,
      });
    }
  };

  console.log(saveable, slotsArentEditable);

  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center">
        <div className="flex items-center gap-2">
          {/* <div className="rounded-full w-6 h-6" /> */}
          <RuleTypeBadge
            type={rule.type}
            string={t(`manager.rulesConfig.${rule.type}`)}
          />
          <h1 className="text-2xl font-bold">{rule?.name}</h1>
        </div>
        {rules ? (
          <NavigateSectionsOrRules
            currentId={rule.id_rule}
            items={rules.map(rule => ({ id: rule.id_rule, name: rule.name }))}
          />
        ) : null}
      </div>
      <div className="border-b border-gray-200 mb-3">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('slots')}
            className={`${
              activeTab === 'slots'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('manager.servicesConfig.needs')}
          </button>
          <button
            onClick={() => setActiveTab('reqs')}
            className={`${
              activeTab === 'reqs'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('manager.servicesConfig.reqs')}
          </button>
        </nav>
      </div>

      {/* Tab Content */}
      <>
        {activeTab === 'slots' && (
          <>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-1 justify-center items-center">
                {!slotsArentEditable ? (
                  <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
                ) : null}
                <p className="edit-disabled-text">
                  {slotsArentEditable
                    ? t('manager.rulesConfig.editDisabled', {
                        sectionOrLabel: rule.name,
                      })
                    : t('manager.rulesConfig.editEnabled', {
                        sectionOrLabel: rule?.name,
                      })}
                </p>
              </div>
              {slotsArentEditable ? null : (
                <YSButton
                  classNames="self-end"
                  onClick={saveChanges}
                  disabled={!saveable}
                >
                  {saveable
                    ? t('generic.saveChanges')
                    : t('generic.savedChanges')}
                </YSButton>
              )}
            </div>
            <Virgueria
              start={iteration.start_day}
              end={iteration.end_day}
              sectionsWithSlots={sectionsWithSlots
                .filter(({ section }) => {
                  return rule.type === RuleTypes.SECTION_USER_REQS
                    ? section.name === rule.name
                    : true;
                })
                .map(({ section, section_slots }) => ({
                  ...section,
                  section_slots,
                }))}
              selectedSlots={selectedSlots}
              shadeMap={shadeMap}
              onClick={toggleSlot}
              version="rules"
              labels={shiftLabels}
              onDayClick={onDayClick}
              onSectionClick={onSectionClick}
            />
          </>
        )}
        {activeTab === 'reqs' && (
          <div className="flex flex-col">
            <YSButton
              classNames="self-end"
              onClick={saveChanges}
              disabled={!saveable}
            >
              {saveable ? t('generic.saveChanges') : t('generic.savedChanges')}
            </YSButton>
            <DefineReqs state={state} dispatch={dispatch} />
          </div>
        )}
      </>
    </Wrapper>
  );
}
