import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/FormFeedback/Alert';

export default function Contact() {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [hospital, setHospital] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (!name || !lastname || !hospital || !message || !email || !phone) {
      setError(t('generic.formValidation'));
      return;
    }

    const isValidEmail = email => {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    if (!isValidEmail(email)) {
      setError(t('generic.invalidEmail'));
      return;
    }

    // Prepare the form data
    const formData = {
      firstname: name,
      lastname,
      email,
      phone,
      hospital,
      message,
      language: localStorage.getItem('i18nextLng'),
    };

    // Send the form data to the endpoint
    axios
      .post(`${process.env.REACT_APP_API_URL}/handle_contact_form`, formData)
      .then(() => {
        // Reset the form
        setName('');
        setEmail('');
        setPhone('');
        setHospital('');
        setMessage('');

        // Show success message to the user
        setSuccess(t('contact.successMessage'));
        setError(false);
      })
      .catch(() => {
        // Handle error
        setError(t('generic.error'));
      });
  };

  return (
    <div className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:pt-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gradient-to-t from-blue-600 to-teal-400 lg:w-1/2" />
            <h2 className="text-3xl mt-12 font-bold tracking-tight text-white">
              {t('contact.title')}
            </h2>
            <p className="mt-4 text-lg leading-8 text-white">
              {t('contact.howCanWeHelp')}
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-white">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon
                    className="h-7 w-6 text-white"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-gray-200"
                    href="mailto:info@you-shift.com"
                  >
                    {t('contact.email')}
                  </a>
                </dd>
              </div>
            </dl>
            <dl className="mt-5 space-y-4 text-base leading-7 text-white">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <PhoneIcon
                    className="h-7 w-6 text-white"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a className="hover:text-gray-200" href="tel:+34672354376">
                    +34 672 35 43 76
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="px-6 pb-14 pt-20 sm:pb-32 lg:px-8 lg:pt-48"
        >
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('generic.firstName')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('generic.lastName')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    value={lastname}
                    onChange={e => setlastname(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('generic.email')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('generic.phone')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="hospital"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('generic.hospital')}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="hospital"
                    id="hospital"
                    autoComplete="organization"
                    value={hospital}
                    onChange={e => setHospital(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contact.message')}
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            {error ? <Alert text={error} /> : null}
            {success ? <Alert success text={success} /> : null}
            <div className="my-6">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-semibold text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                {t('contact.sendMessage')}
              </button>
            </div>
            <p className="mb-2">
              {t('contact.scheduleCall')}
              {' '}
              <a
                target="_blank"
                href="https://calendly.com/you-shift/contacto"
                className="text-blue-600"
                rel="noreferrer"
              >
                {t('contact.scheduleCallLink')}
              </a>
              {' '}
              {t('contact.withUs')}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
