import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShiftAssignmentType } from '@youshift/shared/types';

import { iconTypes } from '../../utils/constants';

export default function ShiftOutputLegend() {
  const { t } = useTranslation();
  return (
    <div className="mt-4 flex flex-row gap-2">
      {Object.keys(iconTypes).map(type => {
        const typedType = type as ShiftAssignmentType;
        return (
          <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
            <p
              className={`relative w-5 h-5 p-0.5 text-center ${iconTypes[typedType].bgColor} rounded-xl text-sm text-white`}
            >
              {React.createElement(iconTypes[typedType].Icon)}
            </p>
            {t(`generic.${type.toLowerCase()}`)}
          </span>
        );
      })}
    </div>
  );
}
