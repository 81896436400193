import { Dialog, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '@youshift/shared/api';

import Alert from '../../components/FormFeedback/Alert';

export default function EditChain({
  open, setOpen, setSuccess, chain,
}) {
  const cancelButtonRef = useRef(null);
  const [name, setName] = useState(chain?.chain_name || '');
  const [vdays, setVdays] = useState(chain?.min_cons_holidays || '');
  const [vpoints, setVpoints] = useState(chain?.holiday_point_penalty || '');
  const [maxSavedPoints, setMaxSavedPoints] = useState(
    chain?.max_saved_points_allowed || 0,
  );
  const [autoExchange, setAutoExchange] = useState(
    chain?.auto_approve_shift_exchange || false,
  );
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const editChain = useMutation({
    mutationFn: () => {
      const data = {
        chain_name: name,
        min_cons_holidays: Number(vdays),
        holiday_point_penalty: Number(vpoints),
        max_saved_points_allowed: Number(maxSavedPoints),
        auto_approve_shift_exchange: autoExchange,
      };
      request({
        url: `manager/chains/${chain.id_chain}`,
        method: 'patch',
        data,
      });
    },
    onSuccess: () => {
      setError(null);
      setSuccess(t('manager.chains.success'));
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['chains', 'active'] });
    },
    onError: () => {
      setError(t('generic.error'));
      setSuccess(null);
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                    >
                      {t('manager.iterationLayouts.editParameters')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="name"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('generic.name')}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={chain?.name}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="job-title"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.chains.minConsHolidays')}
                          </label>
                          <input
                            id="vdays"
                            name="vdays"
                            type="number"
                            value={vdays}
                            onChange={e => setVdays(e.target.value)}
                            autoComplete="off"
                            min="0"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={chain?.vdays}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="job-title"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.chains.pointsSpentOnHolidays')}
                          </label>
                          <input
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            id="vpoints"
                            name="vpoints"
                            type="number"
                            value={vpoints}
                            onChange={e => setVpoints(e.target.value)}
                            min="0"
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="job-title"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.chains.maxPointsSavedAllowed')}
                          </label>
                          <input
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            id="maxSavedPoints"
                            name="maxSavedPoints"
                            type="number"
                            value={maxSavedPoints}
                            onChange={e => setMaxSavedPoints(e.target.value)}
                            min="0"
                          />
                        </div>
                        <div className="relative flex items-center rounded-md rounded-t-none px-3 py-4 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="autoExchange"
                            className="block text-xs font-medium text-gray-900 mr-2"
                          >
                            {t('manager.chains.autoApprovedShiftExchange')}
                          </label>
                          <input
                            className="form-checkbox text-blue-600 border-gray-300 rounded focus:ring-blue-500 h-4 w-4"
                            id="autoExchange"
                            name="autoExchange"
                            type="checkbox"
                            checked={autoExchange}
                            onChange={e => setAutoExchange(e.target.checked)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    onClick={() => editChain.mutate()}
                  >
                    {t('generic.save')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
