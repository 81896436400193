import { SlotLabel } from '@youshift/shared/types';

import LabelIconComponent from '../LabelIconComponent';

export default function ShiftLabelLegend({ labels }: { labels: SlotLabel[] }) {
  return (
    <div className="flex flex-row gap-2">
      {labels.map(label => {
        return (
          <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
            <LabelIconComponent
              icon={label.icon}
              className="w-6 h-6 text-gray-500"
            />
            {label.name}
          </span>
        );
      })}
    </div>
  );
}
