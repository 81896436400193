import useMeasure from 'react-use-measure';
import {
  motion,
  useMotionValue,
  useAnimate,
  useDragControls,
} from 'framer-motion';
import React, { ReactNode } from 'react';

interface VerticalDragCloseDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: ReactNode;
}

function VerticalDragCloseDrawer({
  open,
  setOpen,
  children,
}: VerticalDragCloseDrawerProps) {
  const [scope, animate] = useAnimate();
  const [drawerRef, { width }] = useMeasure(); // Change to width measurement

  const x = useMotionValue(0);
  const controls = useDragControls();

  const handleClose = async () => {
    animate(scope.current, {
      opacity: [1, 0],
    });

    const xStart = typeof x.get() === 'number' ? x.get() : 0;

    await animate('#drawer', {
      x: [xStart, width], // Animate horizontally
    });

    setOpen(false);
  };

  return (
    <div>
      {open && (
        <motion.div
          ref={scope}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={handleClose}
          className="fixed inset-0 z-50 bg-neutral-950/10"
        >
          <motion.div
            id="drawer"
            ref={drawerRef}
            onClick={e => e.stopPropagation()}
            initial={{ x: '100%' }} // Start position for the drawer
            animate={{ x: '0%' }} // Animate to fully visible
            transition={{
              ease: 'easeInOut',
            }}
            className="absolute right-0 h-full w-1/4 overflow-hidden bg-white flex flex-row"
            style={{ x }}
            drag="x" // Allow dragging along x-axis
            dragControls={controls}
            onDragEnd={() => {
              if (x.get() >= 100) {
                handleClose();
              }
            }}
            dragListener={false}
            dragConstraints={{
              left: 0,
              right: 0,
            }}
            dragElastic={{
              left: 0,
              right: 0.5,
            }}
          >
            <div className="left-0 right-0 top-0 z-10 flex justify-center bg-white p-4">
              <button
                onPointerDown={e => {
                  controls.start(e);
                }}
                className="h-14 w-2 cursor-grab touch-none rounded-full bg-neutral-700 active:cursor-grabbing place-self-center"
              />
            </div>
            <div className="relative z-0 h-full overflow-y-scroll p-4 pt-12 w-full">
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

export default VerticalDragCloseDrawer;
