import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export default function Legal() {
  const { t } = useTranslation();

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 text-justify">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t('legal.title')}
        </h1>
        <p className="mt-6 leading-8">{t('legal.content1')}</p>
        <div className="mt-10 max-w-2xl">
          <p>
            <strong className="font-semibold text-gray-900">
              {t('legal.holderInfo')}
            </strong>
            {' '}
            {t('legal.holderInfoDetails')}
          </p>
          <div className="mt-6 ml-6">
            {t('legal.holderName')}
            {' '}
            <br />
            CIF: B56196066
            {' '}
            <br />
            Sector Músicos 14, 28760, Tres Cantos, Madrid
            {' '}
            <br />
            +34 644 327 002
            {' '}
            <br />
            info@you-shift.com
            {' '}
            <br />
          </div>
          <p className="mt-8">
            <strong className="font-semibold text-gray-900">
              {t('legal.websitePurpose')}
            </strong>
            {' '}
            {t('legal.websitePurposeDetails')}
          </p>
          <p className="mt-6">
            <strong className="font-semibold text-gray-900">
              {t('legal.websiteUse')}
            </strong>
            {' '}
            {t('legal.websiteUseDetails')}
          </p>
          <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-blue-600"
                aria-hidden="true"
              />
              <span>{t('legal.websiteUseContent1')}</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-blue-600"
                aria-hidden="true"
              />
              <span>{t('legal.websiteUseContent2')}</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-blue-600"
                aria-hidden="true"
              />
              <span>{t('legal.websiteUseContent3')}</span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-blue-600"
                aria-hidden="true"
              />
              <span>{t('legal.websiteUseContent4')}</span>
            </li>
          </ul>
        </div>

        <p className="mt-6">
          <strong className="font-semibold text-gray-900">
            {t('legal.thirdPartyLinks')}
          </strong>
          {' '}
          {t('legal.thirdPartyLinksDetails')}
        </p>
        <p className="mt-6">
          <strong className="font-semibold text-gray-900">
            {t('legal.personalData')}
          </strong>
          {' '}
          {t('legal.personalDataDetails')}
        </p>
        <p className="mt-6">
          <strong className="font-semibold text-gray-900">
            {t('legal.intellectualProperty')}
          </strong>
          {' '}
          {t('legal.intellectualPropertyDetails')}
        </p>
        <p className="mt-6">
          <strong className="font-semibold text-gray-900">
            {t('legal.modifications')}
          </strong>
          {' '}
          {t('legal.modificationsDetails')}
        </p>
        <p className="mt-6">{t('legal.contactInfo')}</p>
        <p className="mt-6">{t('legal.lastUpdated')}</p>
      </div>
    </div>
  );
}
