import { RuleType } from '@youshift/shared/types';

function getBackgroundColor(type: RuleType): string {
  switch (type) {
    case 'SECTION_USER_REQS':
      return '#6ee7b7'; // emerald 300
    case 'SLOT_LABEL_USER_REQS':
      return '#c4b5fd'; // violet 300
    case 'CUSTOM_USER_REQS':
      return '#f9a8d4'; // pink 300
    case 'SINGLE_GROUP_INCOMP':
    case 'CROSS_GROUP_INCOMP':
      return '#fcd34d'; // amber 300
    case 'CROSS_SLOT_NEED':
      return '#fdba74'; // orange 300
    default:
      return '#FFFFFF'; // Default: White
  }
}

export default function RuleTypeBadge({
  type,
  string,
}: {
  type: RuleType;
  string: string;
}) {
  const color = getBackgroundColor(type);
  return (
    <div className="flex flex-row items-center">
      <p
        className="px-2 py-1 text-xs font-medium rounded-full"
        style={{ background: color }}
      >
        {string}
      </p>
    </div>
  );
}
