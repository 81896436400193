import { useQuery } from '@tanstack/react-query';
import { adminStatsQuery } from '@youshift/shared/hooks/jsQueries';

import LineChart from '../../components/Admin/LineChart';
import WelcomeBanner from '../../components/Admin/WelcomeBanner';

export const adminStatsLoader = queryClient => async () => {
  // await requireAdmin(queryClient
  const query = adminStatsQuery();
  return (
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

function AdminStats() {
  const { data } = useQuery(adminStatsQuery());

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button
                <FilterButton />
                Datepicker built with flatpickr
                <Datepicker /> */}
              </div>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {/* Line chart (Acme Plus) */}
              <LineChart
                title="Group sign ups per day"
                data={data.group_sign_ups}
              />
              <LineChart
                title="User sign ups per day"
                data={data.user_sign_ups}
              />
              <LineChart title="Retention" data={data.retention} />
              <LineChart
                title="Logins Per Day (User)"
                data={data.user_logins}
              />
              <LineChart
                title="Logins Per Day (Manager)"
                data={data.manager_logins}
              />
              <LineChart
                title="# of Algorithm runs"
                data={data.successful_algorithm_executions}
              />
              <LineChart
                title="# of published iterations"
                data={data.published_itrs}
              />
              <LineChart
                title="# of shifts assigned"
                data={data.shift_outputs}
              />
              <LineChart title="% of points respected" />
              <LineChart
                title="# of exchange requests"
                data={data.exchange_requests}
              />
              <LineChart
                title="# of exchange responses"
                data={data.exchange_responses}
              />
              <LineChart
                title="# of completed exchange requests"
                data={data.completed_exchange_requests}
              />
              {/* Line chart (Acme Advanced) */}
              {/* <DashboardCard02 /> */}
              {/* Line chart (Acme Professional) */}
              {/* <DashboardCard03 /> */}
              {/* Bar chart (Direct vs Indirect) */}
              {/* <DashboardCard04 /> */}
              {/* Line chart (Real Time Value) */}
              {/* <DashboardCard05 /> */}
              {/* Doughnut chart (Top Countries) */}
              {/* <DashboardCard06 /> */}
              {/* Table (Top Channels) */}
              {/* <DashboardCard07 /> */}
              {/* Line chart (Sales Over Time) */}
              {/* <DashboardCard08 /> */}
              {/* Stacked bar chart (Sales VS Refunds) */}
              {/* <DashboardCard09 /> */}
              {/* Card (Customers) */}
              {/* <DashboardCard10 /> */}
              {/* Card (Reasons for Refunds) */}
              {/* <DashboardCard11 /> */}
              {/* Card (Recent Activity) */}
              {/* <DashboardCard12 /> */}
              {/* Card (Income/Expenses) */}
              {/* <DashboardCard13 /> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminStats;
