import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteFakeUserMutation,
  useVerifyUserMutation,
} from '@youshift/shared/hooks/mutations';
import { User } from '@youshift/shared/types';
import { PersonnelReturn } from '@youshift/shared/hooks/queries';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext, useRouteLoaderData } from 'react-router-dom';

import Alert from '../../../components/FormFeedback/Alert';
import AssignRole from '../../../components/Team/AssignRole';
import ConvertFakeUser from '../../../components/Team/ConvertFakeUser';
import CreateFakeUsers from '../../../components/Team/CreateFakeUsers';
import EditFakeUserName from '../../../components/Team/EditFakeUserName';
import DeleteUser from '../../../components/Team/DeleteUser';
import InvitePersonnel from '../../../components/Team/InvitePersonnel';
import RevokeMembership from '../../../components/Team/RevokeMembership';
import ValidateUser from '../../../components/Team/ValidateUser';
import { usePersonnelContext } from '../../../layouts/TeamLayout';
import DeleteFakeUserModal from '../../../components/Team/DeleteFakeUser';

export default function Personnel() {
  const personnel = usePersonnelContext();
  const thisManager = useRouteLoaderData('manager');

  // User that all modals are taking an action on.
  const [user, setUser] = useState<User | null>(null);

  // Open states for modals
  const [invitePersonnelOpen, setInvitePersonnelOpen] =
    useState<boolean>(false);
  const [assignRoleOpen, setAssignRoleOpen] = useState<boolean>(false);
  const [validateUserOpen, setValidateUserOpen] = useState<boolean>(false);

  // Delete a user that has not been verified
  const [deleteUserOpen, setDeleteUserOpen] = useState<boolean>(false);

  // Fake users
  const [createFakeUsersOpen, setCreateFakeUsersOpen] =
    useState<boolean>(false);
  const [editFakeUserNameOpen, setEditFakeUserNameOpen] =
    useState<boolean>(false);
  const [convertFakeUserOpen, setConvertFakeUserOpen] =
    useState<boolean>(false);
  const [deleteFakeUserOpen, setDeleteFakeUserOpen] = useState<boolean>(false);

  const [revokeMembershipOpen, setRevokeMembershipOpen] =
    useState<boolean>(false);

  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const verifyUserMutation = useVerifyUserMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['personnel'] });
      setSuccess(t('manager.team.personnel.acceptedUser'));
      setUser(null);
    },
    onError: () => {
      setError(t('manager.team.personnel.verifyUserError'));
      setSuccess(null);
    },
  });

  /**
   * Gets the name of a role given its id_user_role.
   * @param id_user_role - The ID of the role to look up, or null if no role assigned
   * @returns The name of the role if found, or a "no role" message if null
   */
  function getRoleName(id_user_role: number | null): string {
    return id_user_role
      ? personnel?.roles[id_user_role].name
      : t('manager.team.personnel.noRole');
  }

  return (
    <div className="mt-6 px-4 sm:px-6 lg:px-8">
      <InvitePersonnel
        open={invitePersonnelOpen}
        setOpen={setInvitePersonnelOpen}
        setSuccess={setSuccess}
      />
      {assignRoleOpen && user ? (
        <AssignRole
          open={assignRoleOpen}
          setOpen={setAssignRoleOpen}
          setSuccess={setSuccess}
          user={user}
          setUser={setUser}
          roles={personnel?.roles}
        />
      ) : null}
      {validateUserOpen && user ? (
        <ValidateUser
          open={validateUserOpen}
          setOpen={setValidateUserOpen}
          setSuccess={setSuccess}
          user={user}
          setUser={setUser}
        />
      ) : null}
      {revokeMembershipOpen && user ? (
        <RevokeMembership
          open={revokeMembershipOpen}
          setOpen={setRevokeMembershipOpen}
          setSuccess={setSuccess}
          setError={setError}
          user={user}
        />
      ) : null}
      <CreateFakeUsers
        open={createFakeUsersOpen}
        setOpen={setCreateFakeUsersOpen}
        setSuccess={setSuccess}
      />
      {editFakeUserNameOpen && user ? (
        <EditFakeUserName
          open={Boolean(editFakeUserNameOpen)}
          idUser={user.id}
          setOpen={setEditFakeUserNameOpen}
          setSuccess={setSuccess}
          setUser={setUser}
        />
      ) : null}
      {convertFakeUserOpen && user ? (
        <ConvertFakeUser
          open={convertFakeUserOpen}
          idUser={user.id}
          setOpen={setConvertFakeUserOpen}
          setSuccess={setSuccess}
        />
      ) : null}
      {deleteFakeUserOpen && user ? (
        <DeleteFakeUserModal
          open={deleteFakeUserOpen}
          setOpen={setDeleteFakeUserOpen}
          user={user}
          setUser={setUser}
        />
      ) : null}
      {deleteUserOpen && user ? (
        <DeleteUser
          open={deleteUserOpen}
          setOpen={setDeleteUserOpen}
          setSuccess={setSuccess}
          user={user}
          setUser={setUser}
        />
      ) : null}
      <div className="sm:flex sm:items-center justify-end">
        <div className="my-4 sm:mt-0 sm:ml-16">
          <button
            type="button"
            className="block rounded-md bg-gray-500 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            onClick={() => {
              setCreateFakeUsersOpen(true);
            }}
          >
            {t('manager.team.createFakeUsers.createUsers')}
          </button>
        </div>
        <div className="my-4 sm:mt-0 sm:ml-2">
          <button
            type="button"
            className="block rounded-md bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => {
              setInvitePersonnelOpen(true);
            }}
          >
            {t('manager.team.personnel.inviteUsers')}
          </button>
        </div>
      </div>
      {error ? <Alert success={false} text={error} /> : null}
      {success ? <Alert success text={success} /> : null}
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pt-2 pb-24 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                  >
                    {t('generic.firstName')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t('generic.role')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t('manager.team.personnel.verifiedUser')}
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">{t('generic.edit')}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {personnel?.users &&
                  Object.values(personnel.users)
                    .sort((user_a, user_b) =>
                      `${user_a.firstname} ${user_a.lastname}`.localeCompare(
                        `${user_b.firstname} ${user_b.lastname}`,
                      ),
                    )
                    .map(listed_user => (
                      <tr key={listed_user.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {listed_user.is_mock ? (
                            <div className="whitespace-nowrap text-sm text-gray-500 flex flex-row gap-2 items-center">
                              <Link
                                to={`${listed_user.id}`}
                                state={{
                                  name: `${listed_user.firstname} ${listed_user.lastname}`,
                                  role: getRoleName(listed_user.id_user_role),
                                  previous: 'personnel',
                                }}
                              >
                                {listed_user.firstname} {listed_user.lastname}
                              </Link>
                              <button
                                onClick={() => {
                                  setUser(listed_user);
                                  setEditFakeUserNameOpen(true);
                                }}
                                aria-label={t(
                                  'manager.team.personnel.editName',
                                )}
                              >
                                <PencilIcon
                                  className="h-5 w-5 text-blue-600"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setUser(listed_user);
                                  setConvertFakeUserOpen(true);
                                }}
                                aria-label={t(
                                  'manager.team.personnel.convertToRealUser',
                                )}
                              >
                                <ArrowPathIcon
                                  className="h-5 w-5 text-blue-600"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setUser(listed_user);
                                  setDeleteFakeUserOpen(true);
                                }}
                                aria-label={t(
                                  'manager.team.personnel.deleteFakeUser',
                                )}
                              >
                                <TrashIcon
                                  className="h-5 w-5 text-red-600"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          ) : (
                            <Link
                              to={`${listed_user.id}`}
                              state={{
                                name: `${listed_user.firstname} ${listed_user.lastname}`,
                                role: getRoleName(listed_user.id_user_role),
                                previous: 'personnel',
                              }}
                            >
                              {listed_user.firstname} {listed_user.lastname}
                            </Link>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {listed_user.id_user_role
                            ? personnel.roles[listed_user.id_user_role].name
                            : t('manager.team.personnel.noRole')}
                        </td>
                        {listed_user.is_mock ? (
                          <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                              <p className="bg-gray-200 p-1.5 rounded-md">
                                {t('manager.team.createFakeUsers.demo')}
                              </p>
                            </div>
                          </td>
                        ) : listed_user.user_is_verified ? (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                              <CheckBadgeIcon
                                className="h-7 w-7 text-green-600"
                                aria-hidden="true"
                              />
                            </div>
                          </td>
                        ) : (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                              <XCircleIcon
                                className="h-7 w-7 text-red-600"
                                aria-hidden="true"
                              />
                            </div>
                          </td>
                        )}
                        {listed_user.user_is_verified ? (
                          listed_user.id_user_role !== -1 ? (
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                              <Popover className="relative">
                                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-5 text-gray-900">
                                  <span>{t('generic.manage')}</span>
                                  <ChevronDownIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/3 px-4">
                                    <div className="shrink rounded-xl bg-white p-3 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                      <div className="relative rounded-xl p-2 hover:bg-gray-100">
                                        <button
                                          type="button"
                                          className="font-semibold text-gray-900"
                                          onClick={() => {
                                            setUser(listed_user);
                                            setAssignRoleOpen(true);
                                          }}
                                        >
                                          {t(
                                            'manager.team.personnel.changeRole',
                                          )}
                                          <span className="absolute inset-0" />
                                        </button>
                                      </div>
                                      <div className="relative rounded-xl p-2 hover:bg-gray-100">
                                        <Link
                                          className="font-semibold text-gray-900"
                                          to={`${listed_user.id}`}
                                          state={{
                                            name: `${listed_user.firstname} ${listed_user.lastname}`,
                                            role: getRoleName(
                                              listed_user.id_user_role,
                                            ),
                                            previous: 'personnel',
                                          }}
                                        >
                                          {t('manager.team.personnel.seeStats')}
                                          <span className="absolute inset-0" />
                                        </Link>
                                      </div>
                                      <div className="relative rounded-xl p-2 hover:bg-gray-100">
                                        <button
                                          type="button"
                                          className="font-semibold text-gray-900"
                                          onClick={() => {
                                            setUser(listed_user);
                                            setRevokeMembershipOpen(true);
                                          }}
                                        >
                                          {t(
                                            'manager.team.revokeMembership.revokeMembership',
                                          )}
                                          <span className="absolute inset-0" />
                                        </button>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </td>
                          ) : (
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                              <button
                                type="button"
                                className="rounded bg-blue-50 px-2 py-1 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
                                onClick={() => {
                                  setUser(listed_user);
                                  setAssignRoleOpen(true);
                                }}
                              >
                                {t('manager.team.personnel.assignRole')}
                              </button>
                            </td>
                          )
                        ) : (
                          <td className="relative whitespace-nowrap flex flex-row gap-3 justify-end py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <button
                              type="button"
                              className="rounded bg-green-100 px-2 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-200"
                              onClick={() => {
                                verifyUserMutation.mutate({
                                  id_user: listed_user.id,
                                });
                              }}
                            >
                              {t('manager.team.personnel.verifyUser')}
                            </button>
                            <button
                              type="button"
                              className="rounded bg-red-100 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                              onClick={() => {
                                setUser(user);
                                setDeleteUserOpen(true);
                              }}
                            >
                              {t('manager.team.personnel.deleteUser')}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
