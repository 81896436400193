const posts = [
  {
    title:
      'Cuatro estudiantes españoles de Harvard desarrollan una plataforma de asignación de guardias médicas',
    href: 'https://www.medicosypacientes.com/articulo/cuatro-estudiantes-espanoles-de-harvard-desarrollan-una-plataforma-de-asignacion-de-guardias-medicas/',
    description:
      'Lucía Vives, Mar Grech, Jota Chamorro y Adolfo Requero, cuatro jóvenes españoles estudiantes de la Universidad de Harvard, se han unido para crear una plataforma de guardias médicas. Médicos y Pacientes entrevista a los creadores de Youshift, una plataforma cuyo objetivo es asignar las guardias médicas de forma justa y eficiente mediante un algoritmo que tiene en cuenta las preferencias de los profesionales y un reparto equitativo...',
    datetime: '2020-03-16',
    image:
      'https://www.medicosypacientes.com/wp-content/uploads/2023/09/Logo-Medicos-y-Pacientes-544.png',
  },
  {
    title:
      'YouShift, el programa de control de guardias de cuatro españoles: "Hemos entendido qué es vocación"',
    href: 'https://www.consalud.es/industria/innovadora/youshift-programa-control-guardias-cuatro-espanoles-ya-entendemos-es-vocacion_135518_102.html',
    description:
      'Los creadores de YouShift, cuatro jovenes españoles que se conocieron en Harvard, atienden a ConSalud.es para hablar sobre todo lo que rodea a su programa de control de guardias',
    datetime: '2020-03-16',
    image:
      'https://www.consalud.es/uploads/static/consalud-2023/dist/logos/logo-1200x630.png',
  },
  {
    title:
      'Un algoritmo acaba con la discusión del reparto de guardias entre médicos',
    href: 'https://www.redaccionmedica.com/secciones/tecnologia/un-algoritmo-acaba-con-la-discusion-del-reparto-de-guardias-entre-medicos-1427',
    description:
      'Un grupo de estudiantes españoles ha dado con la tecla para digitalizar la asignación de las guardias, en pro de un sistema más sencillo, equitativo y eficaz. Se trata de una aplicación informática que tiene en cuenta tanto las necesidades de un Servicio',
    datetime: '2023-12-09',
    image: 'https://www.redaccionmedica.com/images/logo_rm.svg',
  },
  {
    title:
      'Un estudiante cántabro en Harvard, Jota Chamorro Matilla, crea junto a otros tres jóvenes una plataforma de guardias médicas',
    href: 'https://www.comcantabria.es/actualidad/un-estudiante-cantabro-en-harvard-jota-chamorro-matilla-crea-junto-a-otros-tres-jovenes-una-plataforma-de-guardias-medicas/',
    description:
      'Jota Chamorro Matilla es un  joven cántabro que estudia Ingeniería Informática en la universidad americana de Harvard  y que  junto a tres compañeros también españoles,  Lucía Vives, Mar Grech y Adolfo Requero,  han  creado una plataforma de guardias médicas denominada Youshift cuyo  objetivo es asignar las guardias de forma justa y eficiente mediante un algoritmo que tiene en cuenta las preferencias de los profesionales y un reparto equitativo.   La herramienta  ya  funciona y en esta entrevista para el COM Cantabria, Jota Chamorro, hijo de la pediatra y vocal del Colegio, Montserrat Matilla, nos cuenta los pormenores de este proyecto vinculado a la práctica médica  y de  sus planes de futuro.',
    datetime: '2020-03-16',
    image:
      'https://www.comcantabria.es/wp-content/uploads/2020/03/LOGOCOM4w.jpg',
  },
];

export default function Press() {
  return (
    <div className="bg-white pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            ¡YouShift en las noticias!
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Lee lo que dicen sobre nosotros...
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 border-t border-gray-200 pt-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map(post => (
            <article
              key={post.id}
              className="flex max-w-xl flex-col items-start justify-centers"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                  {post.description}
                </p>
              </div>
              <div className="relative mt-8 flex items-center self-center gap-x-4">
                <img
                  src={post.image}
                  alt=""
                  className="max-h-16 max-w-xs mx-auto"
                />
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
