"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteUserPrefMutation = exports.useEditUserPrefMutation = exports.useEditItrPrefsMutation = exports.useExcludeItrUsersMutation = exports.useIncludeItrUsersMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useIncludeItrUsersMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_users = params.id_users, id_itr = params.id_itr;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/users/include"),
                method: "post",
                data: { id_users: id_users },
            });
        } }, options), queryClient);
};
exports.useIncludeItrUsersMutation = useIncludeItrUsersMutation;
var useExcludeItrUsersMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_users = params.id_users, id_itr = params.id_itr;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/users/exclude"),
                method: "post",
                data: { id_users: id_users },
            });
        } }, options), queryClient);
};
exports.useExcludeItrUsersMutation = useExcludeItrUsersMutation;
// export const useEditUserPrefsMutation = (
//   queryClient: QueryClient,
//   options?: UseMutationOptions<{}, Error, EditUserPrefsParams>
// ) => {
//   return useMutation<{}, Error, EditUserPrefsParams>(
//     {
//       mutationFn: (params: EditUserPrefsParams) => {
//         const { id_itr, itr_preference, user_prefs } = params;
//         return request<{}>({
//           url: `user/itrs/${id_itr}/prefs/edit_user_pref`,
//           method: "patch",
//           data: {
//             itr_preference,
//             user_prefs,
//           },
//         });
//       },
//       ...options,
//     },
//     queryClient
//   );
// };
var useEditItrPrefsMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, itr_preference = params.itr_preference;
            return (0, api_1.request)({
                url: "user/itrs/".concat(id_itr, "/prefs/edit_itr_pref"),
                method: "patch",
                data: __assign({}, itr_preference),
            });
        } }, options), queryClient);
};
exports.useEditItrPrefsMutation = useEditItrPrefsMutation;
var useEditUserPrefMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, user_pref = params.user_pref;
            return (0, api_1.request)({
                url: "user/itrs/".concat(id_itr, "/prefs/edit_user_pref"),
                method: "patch",
                data: __assign({}, user_pref),
            });
        } }, options), queryClient);
};
exports.useEditUserPrefMutation = useEditUserPrefMutation;
var useDeleteUserPrefMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_pref_slot = params.id_pref_slot;
            return (0, api_1.request)({
                url: "user/".concat(id_itr, "/prefs/delete_user_pref"),
                method: "delete",
                data: {
                    id_pref_slot: id_pref_slot,
                },
            });
        } }, options), queryClient);
};
exports.useDeleteUserPrefMutation = useDeleteUserPrefMutation;
