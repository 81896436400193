import { Disclosure } from '@headlessui/react';
import {
  CheckBadgeIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { Section, SectionSlot } from '@youshift/shared/types';

import UserReqTable from './UserReqTable';
import {
  CrossGroupIncompatibilityErrors,
  SingleGroupIncompatibilityErrors,
  UserAssignmentErrors,
  UserRequirementRuleErrors,
} from '../../pages/Manager/ManualAssignment';
import SingleIncompTable from './SingleIncompTable';
import CrossGroupTable from './CrossGroupTable';
import UserErrors from './UserErrors';

type Props = {
  ruleErrors?: UserRequirementRuleErrors[number];
  singleIncompErrors?: SingleGroupIncompatibilityErrors[number];
  crossIncompErrors?: CrossGroupIncompatibilityErrors[number];
  userFullNameMap: Record<number, string>;
  name?: string;
  noErrors: boolean;
  sections?: Record<number, Section>;
  sectionSlots?: Record<number, SectionSlot>;
  userErrors?: UserAssignmentErrors[number];
};

const TableDisclosure: React.FC<Props> = ({
  ruleErrors,
  userFullNameMap,
  name,
  noErrors,
  singleIncompErrors,
  crossIncompErrors,
  sections,
  sectionSlots,
  userErrors,
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          {/* Title Bar */}
          <Disclosure.Button
            disabled={noErrors}
            className={`flex justify-start gap-1 mb-2 items-center w-full px-4 py-2 text-lg font-medium text-left ${noErrors ? 'bg-green-100' : 'bg-gray-100 hover:bg-gray-200'} text-gray-900 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75`}
          >
            {noErrors ? (
              <CheckBadgeIcon className="text-teal-600 w-7 h-7 font-bold" />
            ) : (
              <div className="flex flex-row gap-1 justify-center items-center">
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-gray-500`}
                />
                <ExclamationCircleIcon className="w-6 h-6 justify-center items-center rounded-full text-red-600 mr-3" />
              </div>
            )}
            <span>{name}</span>
          </Disclosure.Button>

          {/* Collapsible Content */}
          <Disclosure.Panel className="text-sm text-gray-700">
            {ruleErrors && (
              <UserReqTable
                ruleErrors={ruleErrors}
                userFullNameMap={userFullNameMap}
              />
            )}
            {sections && sectionSlots && (
              <>
                {singleIncompErrors && (
                  <SingleIncompTable
                    incompErrors={singleIncompErrors}
                    userFullNameMap={userFullNameMap}
                    sections={sections}
                    sectionSlots={sectionSlots}
                  />
                )}
                {crossIncompErrors && (
                  <CrossGroupTable
                    slots={crossIncompErrors}
                    userFullNameMap={userFullNameMap}
                    sections={sections}
                    sectionSlots={sectionSlots}
                  />
                )}
                {userErrors && (
                  <UserErrors
                    userErrors={userErrors}
                    sectionSlots={sectionSlots}
                    sections={sections}
                  />
                )}
              </>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default TableDisclosure;
