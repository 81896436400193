import { CheckIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import { classNames } from '@youshift/shared/utils';

export default function ManagerDashboardChecklist({
  rolesAreCreated,
  itrsAreCreated,
}) {
  const steps = [
    {
      name: t('manager.dashboard.checklistInviteUsers'),
      description: t('manager.dashboard.checklistInviteUsersDescription'),
      href: '../team/roles',
      status: !rolesAreCreated ? 'current' : 'complete',
    },
    {
      name: t('manager.dashboard.checklistCreateChain'),
      description: t('manager.dashboard.checklistCreateChainDescription'),
      href: '../chains',
      status: !itrsAreCreated
        ? rolesAreCreated
          ? 'current'
          : 'next'
        : 'complete',
    },
  ];
  return (
    <nav
      aria-label="Progress"
      className="flex flex-col items-center relative w-full col-span-3 rounded-lg p-12 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
    >
      <h2 className="text-4xl font-bold text-gray-900 mb-6">
        {t('manager.dashboard.welcomeToYouShift')}
      </h2>
      <ol role="list" className="overflow-hidden py-3">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'pb-10' : '',
              'relative',
            )}
          >
            {step.status === 'complete' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-blue-600"
                    aria-hidden="true"
                  />
                ) : null}
                <NavLink
                  to={step.href}
                  className="group relative flex items-start"
                >
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-600">
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col border border-gray-100 shadow-lg rounded-md py-5 px-10">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </NavLink>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <NavLink
                  to={step.href}
                  className="group relative flex items-start"
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white group-hover:bg-blue-100">
                      {/* <span className="h-2.5 w-2.5 rounded-full bg-blue-600" /> */}
                      <p className="font-bold text-blue-600">{stepIdx + 1}</p>
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col border border-gray-100 shadow-lg rounded-md py-5 px-10">
                    <span className="text-sm font-medium text-blue-600">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </NavLink>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <NavLink
                  to={step.href}
                  className="group relative flex items-start"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      {/* <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" /> */}
                      <p className="font-bold text-gray-600">{stepIdx + 1}</p>
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col border border-gray-100 shadow-lg rounded-md py-5 px-10">
                    <span className="text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </NavLink>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
