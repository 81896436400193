import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { request } from '@youshift/shared/api';

import Alert from '../../components/FormFeedback/Alert';

// ERROR

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SWITCH_TYPE':
      if (action.value === 'MONTH') {
        return {
          ...state,
          type: 'MONTH',
          month: '',
          start_day: '',
          end_day: '',
        };
      }
      return {
        ...state,
        type: 'CALENDAR',
        start_day: '',
        end_day: '',
        month: '',
      };

    default:
      return state;
  }
};

export default function NewChain() {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  const [state, dispatch] = useReducer(formReducer, {
    name: '',
    type: 'MONTH',
    month: '',
    start_day: '',
    end_day: '',
    vdays: '0',
    vpoints: '0',
    maxSavedPoints: '0',
    autoExchange: false,
  });

  const handleInputChange = event => {
    if (event.target.type === 'date') {
      const dateString = event.target.valueAsDate
        ? event.target.valueAsDate.toISOString().substring(0, 10)
        : '';
      dispatch({
        type: 'SET_FIELD',
        field: event.target.name,
        value: dateString,
      });
    } else if (event.target.name === 'type') {
      dispatch({
        type: 'SWITCH_TYPE',
        value: event.target.value,
      });
    } else {
      dispatch({
        type: 'SET_FIELD',
        field: event.target.name,
        value: event.target.value,
      });
    }
  };

  const handleCheckboxChange = event => {
    dispatch({
      type: 'SET_FIELD',
      field: event.target.name,
      value: event.target.checked,
    });
  };

  const { isLoading, mutate } = useMutation({
    mutationFn: params => request({ url: '/manager/itrs/create', method: 'post', data: params }),
    onSuccess: res => {
      setSuccess(true);
      navigate(`/manager/iteration/${res.id_itr}`);
    },
    onError: e => {
      setError(t('manager.chains.errorCreating'));
    },
  });

  const createChain = event => {
    event.preventDefault();
    const params = state.type === 'MONTH'
      ? {
        chain_name: state.name,
        itr_type: state.type,
        min_cons_holidays: state.vdays,
        holiday_point_penalty: state.vpoints,
        auto_approve_shift_exchange: state.autoExchange,
        max_saved_points_allowed: state.maxSavedPoints,
        month: state.month,
      }
      : {
        chain_name: state.name,
        itr_type: state.type,
        min_cons_holidays: state.vdays,
        holiday_point_penalty: state.vpoints,
        start_day: state.start_day,
        end_day: state.end_day,
        auto_approve_shift_exchange: state.autoExchange,
        max_saved_points_allowed: state.maxSavedPoints,
      };
    mutate(params);
  };

  function getCurrentMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  }

  const monthInputTest = document.createElement('input');

  try {
    monthInputTest.type = 'month';
  } catch (e) { }

  return (
    <form onSubmit={createChain}>
      <div className="space-y-12 sm:space-y-16 md:mt-16">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t('manager.chains.createChain')}
          </h2>
          {error ? <Alert text={error} /> : null}

          <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                {t('manager.chains.chainName')}
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={t('manager.chains.placeholder')}
                  value={state.name}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
              <label
                htmlFor="location"
                className="block text-sm font-medium leading-6 text-gray-900 self-center"
              >
                {t('manager.chains.period')}
              </label>
              <select
                id="type"
                name="type"
                value={state.type}
                onChange={handleInputChange}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              >
                <option value="MONTH">{t('generic.monthly')}</option>
                <option value="CALENDAR">{t('generic.custom')}</option>
              </select>
            </div>

            {state.type === 'MONTH' ? (
              <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-gray-900 self-center"
                >
                  {t('manager.chains.selectMonth')}
                  {monthInputTest.type !== 'month' ? (
                    <span className="text-gray-500"> (yyyy-mm)</span>
                  ) : null}
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  pattern="[0-9]{4}-[0-9]{2}"
                  value={state.month}
                  onChange={handleInputChange}
                  min={getCurrentMonth()}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            ) : (
              <>
                <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium leading-6 text-gray-900 self-center"
                  >
                    {t('manager.chains.selectStartDate')}
                  </label>
                  <input
                    type="date"
                    id="start_day"
                    name="start_day"
                    value={state.start_day}
                    max={state.end_day}
                    onChange={handleInputChange}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium leading-6 text-gray-900 self-center"
                  >
                    {t('manager.chains.selectEndDate')}
                  </label>
                  <input
                    type="date"
                    id="end_day"
                    name="end_day"
                    value={state.end_day}
                    onChange={handleInputChange}
                    min={state.start_day}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </>
            )}
            <div>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg my-6 bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                      <span>{t('generic.advancedConfig')}</span>
                      <ChevronUpIcon
                        className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {t('profile.itrConfigTitle')}
                      </h2>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                        <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                          {t('manager.chains.minConsHolidays')}
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                          <input
                            id="vdays"
                            name="vdays"
                            type="number"
                            value={state.vdays}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                            autoComplete="off"
                            min="0"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                        <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                          {t('manager.chains.pointsSpentOnHolidays')}
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                          <input
                            id="vpoints"
                            name="vpoints"
                            type="number"
                            value={state.vpoints}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                            min="0"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                        <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                          {t('manager.chains.maxPointsSavedAllowed')}
                        </label>
                        <input
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                          id="maxSavedPoints"
                          name="maxSavedPoints"
                          type="number"
                          value={state.maxSavedPoints}
                          onChange={handleInputChange}
                          min="0"
                        />
                      </div>
                      <hr />
                      <h2 className="text-base font-semibold leading-7 text-gray-900 mt-4">
                        {t('profile.exchangeConfigTitle')}
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t('profile.exchangeConfigSubtitle')}
                      </p>
                      <div className="sm:grid sm:grid-cols-3 sm:items-center sm:justify-center sm:gap-4 sm:py-6">
                        <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                          {t('manager.chains.autoApprovedShiftExchange')}
                        </label>
                        <input
                          className="form-checkbox text-blue-600 border-gray-300 rounded focus:ring-blue-500 h-4 w-4"
                          id="autoExchange"
                          name="autoExchange"
                          type="checkbox"
                          checked={state.autoExchange}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link
          to="/manager/chains"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t('generic.cancel')}
        </Link>
        <button
          type="submit"
          className="inline-flex justify-center rounded-md disabled:bg-blue-200 bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          disabled={
            success
            || isLoading
            || state.name === ''
            || (state.type === 'MONTH' && state.month === '')
            || (state.type === 'CALENDAR'
              && (state.start_day === '' || state.end_day === ''))
          }
        >
          {isLoading || success ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : null}
          {t('manager.chains.create')}
        </button>
      </div>
    </form>
  );
}
