import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { SlotLabel } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';

import SlotDetailsBase from './SlotDetails';

export type AddShiftTypeProps = {
  dayIndexes: number[];
  id_slot_label: number;
  rest_period: number;
  min_need: number;
  max_need: number;
  duration_minutes: number;
  start_time: string;
};

export interface CreateSlotTypeProps {
  addShiftType: (props: AddShiftTypeProps) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  days: string[];
  shiftLabels: SlotLabel[];
}

function CreateSlotType({
  setOpen,
  addShiftType,
  shiftLabels,
  days,
}: CreateSlotTypeProps) {
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [minPeople, setMinPeople] = useState<number>(0);
  const [maxPeople, setMaxPeople] = useState<number>(0);
  const [restHours, setRestHours] = useState<number>(0);
  const [daysOfWeek, setDaysOfWeek] = useState<boolean[]>(Array(7).fill(false));
  const [extra24h, setExtra24h] = useState<number>(0);

  const [error, setError] = useState<boolean | string>(false);
  const [success, setSuccess] = useState<boolean | string>(false);

  const { t } = useTranslation();

  // in minutes
  const duration = useMemo(() => {
    if (startTime === '' || endTime === '') {
      return 0;
    }
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    let durationHours = endHour - startHour;
    let durationMinutes = endMinute - startMinute;

    // Check if startTime and endTime are the same, implying a 24-hour duration
    if (startHour === endHour && startMinute === endMinute && extra24h === 0) {
      setExtra24h(1);
      // return 24 * 60; // Exactly 24 hours
    }

    if (durationMinutes < 0) {
      durationMinutes += 60;
      durationHours -= 1;
    }

    if (durationHours < 0) {
      durationHours += 24;
    }

    return durationHours * 60 + durationMinutes + extra24h * 24 * 60;
  }, [endTime, startTime, extra24h]);

  const handleSubmit = () => {
    if (selectedLabelId !== null) {
      addShiftType({
        dayIndexes: daysOfWeek
          .map((day, index) => (day ? index : -1))
          .filter(index => index !== -1),
        start_time: startTime,
        duration_minutes: duration,
        rest_period: restHours,
        min_need: minPeople,
        max_need: maxPeople,
        id_slot_label: selectedLabelId,
      });
      setOpen(false);
    }
  };

  const buttonDisabled = useMemo(
    () =>
      selectedLabelId === null ||
      startTime === '' ||
      endTime === '' ||
      maxPeople < minPeople ||
      maxPeople === 0 ||
      daysOfWeek.every(day => !day),
    [selectedLabelId, startTime, endTime, maxPeople, minPeople, daysOfWeek],
  );

  return (
    <SlotDetailsBase
      selectedLabelId={selectedLabelId}
      setSelectedLabelId={setSelectedLabelId}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      minPeople={minPeople}
      setMinPeople={setMinPeople}
      maxPeople={maxPeople}
      setMaxPeople={setMaxPeople}
      restHours={restHours}
      setRestHours={setRestHours}
      daysOfWeek={daysOfWeek}
      setDaysOfWeek={setDaysOfWeek}
      days={days}
      duration={duration}
      shiftLabels={shiftLabels}
      buttonDisabled={buttonDisabled}
      onSubmit={handleSubmit}
      setExtra24h={setExtra24h}
      submitButtonLabel={t('generic.create')}
      error={error}
    />
  );
}

export default CreateSlotType;
