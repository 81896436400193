import React from 'react';

import LineChart02 from './charts/LineChart02';

function LineChart({ title, data }) {
  function convertDateToMMDDYYYY(dateString) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, add 1 to get correct month
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const chartData = {
    labels: data?.labels.map(dateString => convertDateToMMDDYYYY(dateString)),
    datasets: [
      {
        label: 'Current',
        data: data?.data,
        borderColor: '#3b82f6',
        fill: false,
        borderWidth: 2,
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 3,
        pointBackgroundColor: '#3b82f6',
        pointHoverBackgroundColor: '#3b82f6',
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        clip: 20,
      },
    ],
  };

  // Calculate the sum of the array
  const sum = data?.data.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );

  // Calculate the sum of the array excluding the last element
  const sumExcludingLast = data?.data
    .slice(0, -1)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  // Calculate the percentage change
  let percentageChange = 0;
  if (sumExcludingLast !== 0) {
    percentageChange = ((sum - sumExcludingLast) / sumExcludingLast) * 100;
  }

  percentageChange = Number.isNaN(percentageChange)
    ? 'N/A'
    : `${parseFloat(percentageChange.toFixed(2))}%`;

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex items-center">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {title}
        </h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <LineChart02
        data={chartData}
        width={595}
        height={248}
        sum={sum}
        percentageChange={percentageChange}
      />
    </div>
  );
}

export default LineChart;
