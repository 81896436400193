import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../../assets/Logo/logo_lq.png';

export default function SignUpNonManager() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col justify-center py-12 lg:flex-none">
      <div className="mx-auto w-full max-w-lg px-3">
        <div>
          <img className="h-24 w-auto my-0 mx-auto" src={logo} alt="YouShift" />
        </div>
        <p className="mt-12 mb-24 text-lg text-center">
          {t('auth.signUpNonManager.infoText')}
        </p>
      </div>
      <div className="mx-auto bg-white">
        <div className="relative isolate overflow-hidden bg-gradient-to-b from-blue-600 to-blue-400 px-6 py-12 text-center shadow-2xl sm:px-16">
          <h2 className="mx-auto max-w-xl font-bold tracking-tight text-white sm:text-2xl">
            {t('auth.signUpNonManager.notUsingYouShift')}
          </h2>
          <p className="mx-auto mt-2 max-w-xl text-xl leading-8 text-white font-semibold">
            {t('auth.signUpNonManager.transformShiftScheduling')}
          </p>
          <p className="mx-auto mt-8 max-w-xl text-lg leading-8 text-gray-100 text-left">
            {t('auth.signUpNonManager.learnMore')}
          </p>
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <Link
              to="/contact"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              {t('generic.contactUs')}
            </Link>
            <Link to="/" className="text-sm font-semibold leading-6 text-white">
              {t('generic.backHome')}
              {' '}
              <span aria-hidden="true">→</span>
            </Link>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#93fae8" />
                <stop offset={1} stopColor="#29BBA1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}
