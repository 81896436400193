import {
  CrossGroupIncompatibilityRule,
  SectionSlot,
  ShiftAssignment,
  SingleGroupIncompatibilityRule,
  SpecialEvent,
  UserPreference,
  UserPreferenceType,
  UserRequirement,
} from '@youshift/shared/types';

import {
  AssignmentCheckErrorType,
  AssignmentOnJustifiedBlockErrorContext,
  AssignmentOnPersonalBlockErrorContext,
  AssignmentOnSpecialEventErrorContext,
  AssignmentRestPeriodViolationErrorContext,
  AssignmentRests24HoursErrorContext,
  CrossGroupIncompErrorContext,
  SingleGroupIncompDeficitErrorContext,
  SingleGroupIncompSurplusErrorContext,
  UserReqDeficitErrorContext,
  UserReqSurplusErrorContext,
} from './types';

const datetimeRangeOverlap = (
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date,
) => start1 <= end2 && end1 >= start2;

function findAssignmentsWithinRange(
  user_assignment: ShiftAssignment,
  single_user_assignments: ShiftAssignment[],
  section_slots: Record<number, SectionSlot>,
  minutes_difference: number,
): number[] {
  const section_slot = section_slots[user_assignment.id_section_slot];
  // Find all slots that start after minStartTime
  const assignments_within_range = Object.values(single_user_assignments)
    .filter(
      assignment =>
        user_assignment !== assignment && // Exclude current assignment
        new Date(section_slot.end).getTime() + minutes_difference * 60 * 1000 >
          new Date(section_slots[assignment.id_section_slot].start).getTime(),
    )
    .map(assignment => assignment.id_section_slot);

  return assignments_within_range;
}

export function findOverlappingSectionSlots(
  base_section_slot: SectionSlot,
  section_slots: Record<number, SectionSlot>,
): number[] {
  const overlapping_section_slots = Object.values(section_slots)
    .filter(section_slot =>
      datetimeRangeOverlap(
        new Date(base_section_slot.start),
        new Date(base_section_slot.end),
        new Date(section_slot.start),
        new Date(section_slot.end),
      ),
    )
    .map(section_slot => section_slot.id_section_slot);
  return overlapping_section_slots;
}

/**
 * Checks if a user's assignments satisfy their minimum and maximum requirements for a set of section slots.
 *
 * @param user_requirement - The user's requirements containing min_slots and max_slots
 * @param rule_section_slots - Array of section slot IDs to check assignments against
 * @param single_user_assignments - Array of all assignments for this specific user
 * @returns Either false if requirements are satisfied, or an error object containing:
 *   - type: USER_REQ_DEFICIT if below min_slots, USER_REQ_SURPLUS if above max_slots
 *   - context: Contains the actual number of assignments and the min/max requirement that was violated
 */
export const checkUserReqs = (
  user_requirement: UserRequirement,
  rule_section_slots: number[],
  single_user_assignments: ShiftAssignment[],
):
  | {
      type:
        | AssignmentCheckErrorType.USER_REQ_DEFICIT
        | AssignmentCheckErrorType.USER_REQ_SURPLUS;
      context: UserReqDeficitErrorContext | UserReqSurplusErrorContext;
    }
  | false => {
  const assignments_on_rule_slots = single_user_assignments.filter(assignment =>
    rule_section_slots.includes(assignment.id_section_slot),
  );

  if (user_requirement.min_slots > assignments_on_rule_slots.length) {
    const deficit_error_context: UserReqDeficitErrorContext = {
      num_assignments: assignments_on_rule_slots.length,
      min_slots: user_requirement.min_slots,
    };
    return {
      type: AssignmentCheckErrorType.USER_REQ_DEFICIT,
      context: deficit_error_context,
    };
  }
  if (user_requirement.max_slots < assignments_on_rule_slots.length) {
    const surplus_error_context: UserReqSurplusErrorContext = {
      num_assignments: assignments_on_rule_slots.length,
      max_slots: user_requirement.max_slots,
    };
    return {
      type: AssignmentCheckErrorType.USER_REQ_SURPLUS,
      context: surplus_error_context,
    };
  }
  return false;
};

/**
 * Checks if a user has been assigned to a shift on a slot where they have a blocking preference.
 * A blocking preference can be either "unavailable" (justified block) or "points" (personal block).
 *
 * Trigger:
 * - User is assigned to a section_slot
 *
 * @param assignment - The shift assignment to check
 * @param section_slot - The section slot the assignment is on
 * @param user_preference_on_slot - The user's preference for this slot
 * @returns AssignmentCheckError - Object containing:
 *   - type: SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK if user has an "unavailable" preference,
 *          SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK if user has a "points" preference
 *   - context: AssignmentOnJustifiedBlockErrorContext or AssignmentOnPersonalBlockErrorContext
 *   - false if no blocking preference exists
 */
export const checkAssignmentOnBlockingUserPreference = (
  assignment: ShiftAssignment,
  section_slot: SectionSlot,
  user_preference_on_slot: UserPreference,
):
  | {
      type:
        | AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK
        | AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK;
      context:
        | AssignmentOnJustifiedBlockErrorContext
        | AssignmentOnPersonalBlockErrorContext;
    }
  | false => {
  // No conflict if user has no preference on this slot.
  if (!user_preference_on_slot) {
    return false;
  }
  if (assignment.id_section_slot !== section_slot.id_section_slot) {
    throw new Error(
      'section_slot mismatch in checkAssignmentOnBlockingUserPreference for assignment',
    );
  }
  if (assignment.id_user !== user_preference_on_slot.id_user) {
    throw new Error(
      'user mismatch in checkAssignmentOnBlockingUserPreference for assignment',
    );
  }
  if (user_preference_on_slot.id_pref_slot !== section_slot.id_pref_slot) {
    throw new Error(
      'preference_slot mismatch in checkAssignmentOnBlockingUserPreference for assignment',
    );
  }
  if (user_preference_on_slot.preference === UserPreferenceType.UNAVAILABLE) {
    const error_context: AssignmentOnJustifiedBlockErrorContext = {
      id_preference_slot: user_preference_on_slot.id_pref_slot,
    };
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK,
      context: error_context,
    };
  }
  UserPreferenceType;
  if (user_preference_on_slot.preference === UserPreferenceType.POINTS) {
    const error_context: AssignmentOnPersonalBlockErrorContext = {
      id_preference_slot: user_preference_on_slot.id_pref_slot,
    };
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK,
      context: error_context,
    };
  }
  return false;
};

/**
 * Checks if a shift assignment conflicts with any special events (e.g. vacation) for the assigned user
 *
 * @param assignment - The shift assignment to check
 * @param section_slot - The section slot the assignment is for
 * @param single_user_events - List of special events for the assigned user
 * @returns Either false (no conflict) or an object containing:
 *   - type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT
 *   - context: AssignmentOnSpecialEventErrorContext with details about overlapping events
 */
export const checkAssignmentOnSpecialEvent = (
  assignment: ShiftAssignment,
  section_slot: SectionSlot,
  single_user_events: SpecialEvent[],
):
  | {
      type: AssignmentCheckErrorType;
      context: AssignmentOnSpecialEventErrorContext;
    }
  | false => {
  if (assignment.id_section_slot !== section_slot.id_section_slot) {
    throw new Error(
      'section_slot mismatch in checkAssignmentOnEvent for assignment',
    );
  }
  if (
    !single_user_events.every(event => event.id_user === assignment.id_user)
  ) {
    throw new Error('user mismatch in checkAssignmentOnEvent for events');
  }

  // No conflict if user has no special events.
  if (!single_user_events) {
    return false;
  }
  const overlapping_events = single_user_events.filter(event =>
    datetimeRangeOverlap(
      new Date(event.start),
      new Date(event.end),
      new Date(section_slot.start),
      new Date(section_slot.end),
    ),
  );
  if (overlapping_events.length > 0) {
    const error_context: AssignmentOnSpecialEventErrorContext = {
      events: overlapping_events,
    };
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT,
      context: error_context,
    };
  }
  return false;
};

export const checkAssignmentRestPeriod = (
  user_assignment: ShiftAssignment,
  single_user_assignments: ShiftAssignment[],
  section_slots: Record<number, SectionSlot>,
):
  | {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION;
      context: AssignmentRestPeriodViolationErrorContext;
    }
  | false => {
  const section_slot = section_slots[user_assignment.id_section_slot];

  const assignments_within_range = findAssignmentsWithinRange(
    user_assignment,
    single_user_assignments,
    section_slots,
    section_slot.rest_period,
  );

  if (assignments_within_range.length > 0) {
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION,
      context: {
        conflicting_assignments: assignments_within_range,
      },
    };
  }
  return false;
};

/**
 * Checks if a user has been assigned to a shift within 24 hours of the start of another shift.
 * This is also known as a "double shift" or "doblete" violation.
 *
 * @param user_assignment - The shift assignment to check for 24 hour rest violations
 * @param single_user_assignments - Array of all shift assignments for this user
 * @param section_slots - Dictionary mapping section slot IDs to their SectionSlot objects
 * @returns Error object with conflicting assignments if violation found, false otherwise
 */
export const checkAssignmentRests24Hours = (
  user_assignment: ShiftAssignment,
  single_user_assignments: ShiftAssignment[],
  section_slots: Record<number, SectionSlot>,
):
  | {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_RESTS_24_HOURS;
      context: AssignmentRests24HoursErrorContext;
    }
  | false => {
  const section_slot = section_slots[user_assignment.id_section_slot];

  if (section_slot.rest_period > 24 * 60) {
    // If the rest period is greater than 24 hours, then we don't need to check for double shifts
    // since violations will be caught by the rest period check.
    return false;
  }
  const assignments_within_range = findAssignmentsWithinRange(
    user_assignment,
    single_user_assignments,
    section_slots,
    24 * 60, // 24 hours
  );

  if (assignments_within_range.length > 0) {
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_RESTS_24_HOURS,
      context: {
        conflicting_assignments: assignments_within_range,
      },
    };
  }
  return false;
};

/**
 * Checks if assignments on a single slot and its overlapping slots violate the single group incompatibility rule.
 * A single group incompatibility rule specifies minimum and maximum number of users
 * from a specific user group that can be assigned simultaneously to a slot and its overlapping slots.
 *
 * @param assignments_on_single_slot - Array of ShiftAssignments for a specific section slot
 * @param assignments_on_overlapping_slots - Array of ShiftAssignments for slots that overlap with the target slot
 * @param single_group_incomp_rule - The single group incompatibility rule to check against
 * @returns Error object with context if rule is violated (too many/few users from group assigned), false otherwise
 */
export const checkSingleGroupIncomp = (
  assignments_on_single_slot: ShiftAssignment[],
  assignments_on_overlapping_slots: ShiftAssignment[],
  single_group_incomp_rule: SingleGroupIncompatibilityRule,
):
  | {
      type:
        | AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS
        | AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT;
      context:
        | SingleGroupIncompSurplusErrorContext
        | SingleGroupIncompDeficitErrorContext;
    }
  | false => {
  const group_users_assigned_on_slot: number[] = [];
  for (const assignment of assignments_on_single_slot) {
    if (single_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  for (const assignment of assignments_on_overlapping_slots) {
    if (single_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  if (
    group_users_assigned_on_slot.length > single_group_incomp_rule.max_simult
  ) {
    const surplus_error_context: SingleGroupIncompSurplusErrorContext = {
      assigned_users: group_users_assigned_on_slot,
      max_simult: single_group_incomp_rule.max_simult,
    };
    return {
      type: AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS,
      context: surplus_error_context,
    };
  }

  if (
    group_users_assigned_on_slot.length < single_group_incomp_rule.min_simult
  ) {
    const deficit_error_context: SingleGroupIncompDeficitErrorContext = {
      assigned_users: group_users_assigned_on_slot,
      min_simult: single_group_incomp_rule.min_simult,
    };
    return {
      type: AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT,
      context: deficit_error_context,
    };
  }
  return false;
};

/**
 * Checks if assignments on a single slot violate the cross group incompatibility rule.
 * A cross group incompatibility rule specifies that users from two different groups
 * cannot be assigned simultaneously to the same slot.
 *
 * @param assignments_on_single_slot - Array of ShiftAssignments for a specific section slot
 * @param cross_group_incomp_rule - The cross group incompatibility rule to check against
 * @returns Object containing error type and context if rule is violated, false otherwise
 */
export const checkCrossGroupIncomp = (
  assignments_on_single_slot: ShiftAssignment[],
  assignments_on_overlapping_slots: ShiftAssignment[],
  cross_group_incomp_rule: CrossGroupIncompatibilityRule,
):
  | {
      type: AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY;
      context: CrossGroupIncompErrorContext;
    }
  | false => {
  const group_1_users_assigned_on_slot: number[] = [];
  for (const assignment of assignments_on_single_slot) {
    if (cross_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_1_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  for (const assignment of assignments_on_overlapping_slots) {
    if (cross_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_1_users_assigned_on_slot.push(assignment.id_user);
    }
  }

  const group_2_users_assigned_on_slot: number[] = [];
  for (const assignment of assignments_on_single_slot) {
    if (
      cross_group_incomp_rule.user_group_secondary.includes(assignment.id_user)
    ) {
      group_2_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  for (const assignment of assignments_on_overlapping_slots) {
    if (
      cross_group_incomp_rule.user_group_secondary.includes(assignment.id_user)
    ) {
      group_2_users_assigned_on_slot.push(assignment.id_user);
    }
  }

  if (
    group_1_users_assigned_on_slot.length > 0 &&
    group_2_users_assigned_on_slot.length > 0
  ) {
    const error_context: CrossGroupIncompErrorContext = {
      assigned_users_group_1: group_1_users_assigned_on_slot,
      assigned_users_group_2: group_2_users_assigned_on_slot,
    };
    return {
      type: AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY,
      context: error_context,
    };
  }
  return false;
};
