import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  itrUsersQuery,
  ItrUsersResponse,
  personnelQuery,
} from '@youshift/shared/hooks/queries';
import { User, UserRole } from '@youshift/shared/types';
import { useEditUserPreferencesConfig } from '@youshift/shared/hooks/mutations';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PreferencesCounter } from '../../../components/PreferencesCounter';
import { requireApproved, requireManager } from '../../../utils/checks';
import { YSButton } from '../../../components/Buttons';
import Alert from '../../../components/FormFeedback/Alert';

interface UserPreferences {
  name: string;
  points: number;
  jokers: number;
}

interface RolePreferences {
  role_name: string;
  userPrefs: Record<number, UserPreferences>;
}

type State = Record<number, RolePreferences>;

type Action =
  | {
      type: 'UPDATE_ROLE_PREFS';
      id_role: number;
      payload: {
        points?: number;
        jokers?: number;
      };
    }
  | {
      type: 'UPDATE_USER_PREFS';
      id_user: number;
      payload: {
        points?: number;
        jokers?: number;
      };
    };

type PreferencesLoader = {
  itrUsers: ItrUsersResponse;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
};

export const preferencesConfigLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<PreferencesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const { idItr } = params;
    const itrUsers = await preLoadQuery(queryClient, itrUsersQuery(idItr));
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const { users, roles } = personnel;
    return {
      users,
      roles,
      itrUsers,
    };
  };

export function PreferencesConfig() {
  const { itrUsers, roles, users } = useLoaderData() as PreferencesLoader;
  const itr_users_list = Object.keys(itrUsers).map(Number);
  const { idItr } = useParams();
  const [changesToSave, setChangesToSave] = useState(false);
  const [error, setError] = useState<boolean | string>(false);
  const [success, setSuccess] = useState<boolean | string>(false);
  const queryClient = useQueryClient();
  const initialState: State = useMemo(() => {
    // Initialize the initialState object
    const state: State = {};

    // Initialize roles with role_name and empty users array
    for (const roleId in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, roleId)) {
        state[roleId] = {
          role_name: roles[roleId].name,
          userPrefs: {},
        };
      }
    }

    // Iterate over all users and filter only those whose id is in itrUsers
    Object.values(users).forEach(user => {
      const userId = user.id;

      // Process only users whose id is in itrUsers
      if (itr_users_list.includes(userId)) {
        const roleId = user.id_user_role;

        // Create user object
        const userObj = {
          name: `${user.firstname} ${user.lastname}`,
          points: itrUsers[user.id].base_points,
          jokers: itrUsers[user.id].base_jokers,
        };

        // Add user to the appropriate role
        if (roleId) {
          if (state[roleId]) {
            state[roleId].userPrefs[user.id] = userObj;
          } else {
            state[roleId] = {
              role_name: roles[roleId] ? roles[roleId].name : 'Unknown Role',
              userPrefs: { [user.id]: userObj },
            };
          }
        }
      }
    });

    return state;
  }, [users, roles, itr_users_list, itrUsers]);
  const reducer = useCallback((state: State, action: Action): State => {
    setChangesToSave(true);
    switch (action.type) {
      // Updates the points or jokers for all users within a specified role
      case 'UPDATE_ROLE_PREFS': {
        const updatedRole = state[action.id_role];
        if (!updatedRole) return state;

        return {
          ...state,
          [action.id_role]: {
            ...updatedRole,
            userPrefs: Object.keys(updatedRole.userPrefs).reduce(
              (acc, userId) => {
                acc[Number(userId)] = {
                  ...updatedRole.userPrefs[Number(userId)],
                  ...action.payload, // Apply points or jokers updates
                };
                return acc;
              },
              {} as State[0]['userPrefs'],
            ),
          },
        };
      }

      // Updates the points or jokers for a specific user based on their `id_user`
      case 'UPDATE_USER_PREFS': {
        const updatedState = { ...state };

        for (const roleId in updatedState) {
          if (Object.prototype.hasOwnProperty.call(updatedState, roleId)) {
            const role = updatedState[roleId];

            if (role.userPrefs[action.id_user]) {
              role.userPrefs[action.id_user] = {
                ...role.userPrefs[action.id_user],
                ...action.payload, // Update points or jokers for the user
              };
              break;
            }
          }
        }

        return updatedState;
      }

      // Default case returns the current state if no action matches
      default:
        return state;
    }
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);
  const rolePreferences = useMemo(
    () =>
      Object.entries(state).reduce(
        (acc, [roleId, { userPrefs }]) => {
          const points = Object.values(userPrefs).every(
            user => user.points === Object.values(userPrefs)[0].points,
          )
            ? Object.values(userPrefs)[0].points
            : // check the case where req is currently NaN for all users (numeric input is blank; possibly because the role numeric input is blank)
              Object.values(userPrefs).every(user => Number.isNaN(user.points))
              ? NaN
              : // if the req is different for at least one user; this is the case where the role numeric input is disabled
                undefined;

          const jokers = Object.values(userPrefs).every(
            user => user.jokers === Object.values(userPrefs)[0].jokers,
          )
            ? Object.values(userPrefs)[0].jokers
            : Object.values(userPrefs).every(user => Number.isNaN(user.jokers))
              ? NaN
              : undefined;

          acc[roleId] = {
            points,
            jokers,
          };
          return acc;
        },
        {} as Record<
          string,
          {
            points: number | undefined | typeof NaN;
            jokers: number | undefined | typeof NaN;
          }
        >,
      ),
    [state],
  );

  const { t } = useTranslation();

  const editUserPreferencesConfig = useEditUserPreferencesConfig(queryClient, {
    onSuccess: () => {
      setSuccess(t('manager.preferencesConfig.success'));
      setError(false);
      setChangesToSave(false);
    },
    onError: (error: Error) => {
      setSuccess(false);
      setError(t('generic.error'));
    },
  });

  const saveChanges = () => {
    editUserPreferencesConfig.mutate({
      id_itr: idItr,
      preference_configs: Object.values(state).flatMap(rolePreferences =>
        Object.entries(rolePreferences.userPrefs).map(([userId, userPref]) => ({
          id_user: Number(userId),
          base_points: userPref.points,
          base_jokers: userPref.jokers,
        })),
      ),
    });
  };

  return (
    <div className="mt-4">
      {error && <Alert success={false} text={error} />}
      {success && <Alert success={true} text={success} />}
      <YSButton onClick={saveChanges} disabled={!changesToSave}>
        {t('generic.saveChanges')}
      </YSButton>
      {Object.entries(state).map(([roleId, { userPrefs, role_name }]) => (
        <Disclosure
          key={roleId}
          defaultOpen={
            rolePreferences[roleId].points === undefined ||
            rolePreferences[roleId].jokers === undefined ||
            !rolePreferences[roleId]
          }
        >
          {({ open }) => (
            <>
              <div className="grid grid-cols-3 border border-gray-400 py-3 rounded-md mt-5">
                <div className="flex flex-row items-center gap-4 pl-3 border-r-2 border-r-teal-500">
                  <Disclosure.Button
                    as="button"
                    className="flex flex-row items-center"
                  >
                    <ChevronRightIcon
                      className={`${open ? 'rotate-90 transform' : ''} w-5 h-5`}
                    />
                  </Disclosure.Button>
                  {role_name}
                </div>
                <div className="flex flex-col justify-center items-center gap-1 border-r border-r-gray-600">
                  <p className="text-xs text-gray-600">
                    {t('manager.rolesConfig.points')}
                  </p>
                  <PreferencesCounter
                    value={rolePreferences[roleId].points}
                    setValue={value =>
                      dispatch({
                        type: 'UPDATE_ROLE_PREFS',
                        id_role: Number(roleId),
                        payload: { points: value },
                      })
                    }
                    disabled={rolePreferences[roleId].points === undefined}
                  />
                </div>
                <div className="flex flex-col justify-center items-center gap-1">
                  <p className="text-xs text-gray-600">
                    {t('manager.rolesConfig.wildcards')}
                  </p>
                  <PreferencesCounter
                    value={rolePreferences[roleId].jokers}
                    setValue={value =>
                      dispatch({
                        type: 'UPDATE_ROLE_PREFS',
                        id_role: Number(roleId),
                        payload: { jokers: value },
                      })
                    }
                    disabled={rolePreferences[roleId].jokers === undefined}
                  />
                </div>
              </div>
              <Disclosure.Panel
                as="div"
                className="grid grid-cols-3 mt-3 items-center gap-y-4"
              >
                {Object.entries(userPrefs).map(([userId, user]) => (
                  <>
                    <div
                      key={userId}
                      className="flex flex-row items-center gap-2 ml-8"
                    >
                      <p className="border-l-2 border-l-blue-600 pl-8">
                        {user.name}
                      </p>
                    </div>
                    <div className="flex flex-row justify-center">
                      <PreferencesCounter
                        value={user.points}
                        setValue={value =>
                          dispatch({
                            type: 'UPDATE_USER_PREFS',
                            id_user: Number(userId),
                            payload: { points: value },
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-row justify-center">
                      <PreferencesCounter
                        value={user.jokers}
                        setValue={value =>
                          dispatch({
                            type: 'UPDATE_USER_PREFS',
                            id_user: Number(userId),
                            payload: { jokers: value },
                          })
                        }
                      />
                    </div>
                  </>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
