import React, { useState } from 'react';

function SearchBar({
  data,
  setFilteredData,
  placeholder,
  noResultsMessage,
  filterFunction,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setLocalFilteredData] = useState(data);

  const handleSearch = e => {
    setSearchTerm(e.target.value);
    const newFilteredData = data.filter(item => filterFunction(item, e.target.value));
    setFilteredData(newFilteredData);
    setLocalFilteredData(newFilteredData);
  };

  return (
    <>
      <div className="mb-10 w-1/2">
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
          <input
            type="search"
            name="search"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      {searchTerm && filteredData.length === 0 && (
        <div className="text-gray-500 text-sm">{noResultsMessage}</div>
      )}
    </>
  );
}

export default SearchBar;
