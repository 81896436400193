/* eslint-disable max-len */
import { Dialog, Transition } from '@headlessui/react';
import { InboxIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../FormFeedback/Alert';

export default function InterestPopup({ open, setOpen }) {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [hospital, setHospital] = useState('');
  const [error, setError] = useState(false);

  const cancelButtonRef = useRef(null);

  const isValidEmail = email => {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const sendInfoEmail = () => {
    if (!isValidEmail(email)) {
      setError(t('landing.interestPopup.errorInvalidEmail'));
      return;
    }

    setError(false);
    const formData = {
      name,
      email,
      hospital,
      language: localStorage.getItem('i18nextLng'),
    };

    // Send the form data to the endpoint
    axios
      .post(`${process.env.REACT_APP_API_URL}/handle_info_request`, formData)
      .then(() => {
        // Reset the form
        setName('');
        setEmail('');
        setHospital('');

        setError(false);
        setOpen(false);
      })
      .catch(() => {
        // Handle error
        setError(t('generic.error'));
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10">
                      <InboxIcon
                        className="h-6 w-6 text-teal-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {t('landing.interestPopup.title')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                          <div>
                            <label
                              htmlFor="full-name"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              {t('generic.fullName')}
                            </label>
                            <div className="mt-2.5">
                              <input
                                type="text"
                                name="full-name"
                                id="full-name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              {t('generic.email')}
                            </label>
                            <div className="mt-2.5">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-2 mb-4">
                            <label
                              htmlFor="hospital"
                              className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                              {t('generic.hospital')}
                            </label>
                            <div className="mt-2.5">
                              <input
                                type="text"
                                name="hospital"
                                id="hospital"
                                value={hospital}
                                onChange={e => setHospital(e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {error ? <Alert text={error} /> : null}
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      sendInfoEmail();
                    }}
                  >
                    {t('generic.sendButton')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
