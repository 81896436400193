import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';

import { SingleUserStats } from '../../../components/Stats/SingleUserStats';
import { requireApproved, requireManager } from '../../../utils/checks';
import NoData from '../NoData';

export const getStatsManagerQuery = id => ({
  queryKey: ['stats', id],
  queryFn: async () => request({
    url: `manager/personnel/user/${id}/fetch_chain_stats`,
    method: 'get',
  }),
});
export const statsManagerLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  const query = getStatsManagerQuery(params.id);
  return (
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

export default function UserStatsManager() {
  const { idItr: id } = useParams();

  const { data: stats } = useQuery(getStatsManagerQuery(id));

  const { state } = useLocation();
  const { name, role, previous } = state ?? {};

  const { t } = useTranslation();

  return (
    <div>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <NavLink
                to="/manager"
                className="text-gray-400 hover:text-blue-600 cursor-pointer"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0 cursor-pointer"
                  aria-hidden="true"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <NavLink
                to={previous === 'personnel' ? '..' : '/manager/stats'}
                relative="path"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-blue-600"
              >
                {previous === 'personnel'
                  ? t('manager.personnel')
                  : t('generic.stats')}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div
                className="ml-4 text-sm font-medium text-blue-600 hover:text-blue-700 cursor-pointer"
                aria-current="page"
              >
                {name || t('generic.user')}
              </div>
            </div>
          </li>
        </ol>
      </nav>
      <div className="h-1 w-full mt-6 bg-blue-600" />
      {!Object.keys(stats).length ? (
        <NoData
          text={t('manager.stats.noDataText')}
          description={t('manager.stats.noDataDescription')}
        />
      ) : (
        <SingleUserStats manager stats={stats} name={name} role={role} />
      )}
    </div>
  );
}
