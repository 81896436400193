/* eslint-disable no-console */
import axios from 'axios';
import {
  useState, createContext, useContext, useMemo,
} from 'react';

export const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [managerLevel, setManagerLevel] = useState(0);

  function getToken() {
    const userToken = localStorage.getItem('token');
    return userToken && userToken;
  }

  const [token, setToken] = useState(getToken());

  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  }

  function removeToken() {
    localStorage.removeItem('token');
    setToken(null);
    setIsLoggedIn(false);
  }

  // Add an Axios interceptor to send the token with each request
  axios.interceptors.request.use(
    config => {
      const tokenToSend = getToken() || token;
      if (tokenToSend) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${tokenToSend}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  // useEffect(() => {
  //   // Fetch the user data from the API
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/load_user`)
  //     .then(response => {
  //       setUser(response.data)
  //     })
  //     .catch(error => error)
  // }, [])

  const value = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      managerLevel,
      setManagerLevel,
      setToken: saveToken,
      token,
      removeToken,
    }),
    [isLoggedIn, setIsLoggedIn, managerLevel, setManagerLevel, token],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
