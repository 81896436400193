import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { YSButton } from '../Buttons';

type NavigationItem = {
  id: number;
  name: string;
};

type NavigationProps = {
  items: NavigationItem[];
  currentId: number;
};

const NavigateSectionsOrRules: React.FC<NavigationProps> = ({
  items,
  currentId,
}) => {
  const navigate = useNavigate();

  const currentIndex = items.findIndex(item => item.id === currentId);
  const isFirst = currentIndex === 0;
  const isLast = currentIndex === items.length - 1;

  const previousItem = !isFirst ? items[currentIndex - 1] : null;
  const nextItem = !isLast ? items[currentIndex + 1] : null;

  const handleNavigation = (id: number | null) => {
    if (id) {
      navigate(`../${id}`, { relative: 'path', state: items });
    }
  };
  const { t } = useTranslation();

  return (
    <div className="flex">
      <YSButton
        variant="ghost-secondary"
        onClick={() => navigate('..', { relative: 'path' })}
      >
        <p>{t('generic.back')}</p>
      </YSButton>
      <div className="w-[1px] bg-gray-400" />
      <YSButton<NavigationItem[]>
        variant="ghost-secondary"
        classNames={`flex flex-row gap-0.5 items-center text-sm -mr-5 ${isFirst ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={() => handleNavigation(previousItem?.id ?? null)}
        disabled={isFirst}
      >
        <ArrowLongLeftIcon className="h-6 w-6 text-teal-600" />
        <p>{previousItem ? previousItem.name : t('generic.previous')}</p>
      </YSButton>
      <YSButton
        variant="ghost-secondary"
        classNames={`flex flex-row gap-0.5 items-center text-sm ${isLast ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={() => handleNavigation(nextItem?.id ?? null)}
        disabled={isLast}
      >
        <p>{nextItem ? nextItem.name : t('generic.next')}</p>
        <ArrowLongRightIcon className="h-6 w-6 text-teal-600" />
      </YSButton>
    </div>
  );
};

export default NavigateSectionsOrRules;
