/* eslint-disable max-len */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { parseIterationDates } from '@youshift/shared/utils';
import {
  Chain,
  Iteration,
  ItrStatusType,
  ItrStatuses,
} from '@youshift/shared/types';
import { preLoadQuery } from '@youshift/shared/hooks';
import { iterationQuery, statusQuery } from '@youshift/shared/hooks/queries';
import {
  useDeleteResultsMutation,
  usePublishResultsMutation,
  useUnpublishResultsMutation,
  useArchiveItrMutation,
  useUnarchiveItrMutation,
  useEditMessageMutation,
  useAssignmentToConfigMutation,
  useRunAlgMutation,
  useConfigToAssignmentMutation,
} from '@youshift/shared/hooks/mutations';

import Alert from '../components/FormFeedback/Alert';
// import ConfigurationPreview from '../components/Iteration/ConfigurationPreview';
import Steps from '../components/Iteration/Steps';
import Toggle from '../components/Iteration/Toggle';
import DeleteItr from '../pages/Manager/IterationConfig/DeleteItr';
import ArchiveConfirmation from '../pages/Manager/IterationVerification/ArchiveConfirmation';
import PublishOrDelete from '../pages/Manager/IterationVerification/PublishOrDelete';
import Unpublish from '../pages/Manager/IterationVerification/Unpublish';
import { useAppOptions } from '../utils/AppOptionsContext';
import { requireManager } from '../utils/checks';
import Wrapper from '../components/Wrapper';
import { YSButton } from '../components/Buttons';

type ItrContextType = {
  status: ItrStatusType | undefined;
  locked: boolean;
  errors: undefined;
  infeasible: undefined;
  nameAndDate: string;
};

type IterationLoaderReturn = {
  status: ItrStatusType;
  iteration: Iteration;
};

export const iterationLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<IterationLoaderReturn | null> => {
    await requireManager(queryClient);
    if (params.idItr === undefined) {
      return null;
    }
    const iteration = await preLoadQuery(
      queryClient,
      iterationQuery(params.idItr),
    );
    const status = await preLoadQuery(queryClient, statusQuery(params.idItr));
    return { iteration, status };
  };

export default function IterationLayout() {
  const { idItr: id } = useParams();
  const {
    iteration: {
      start_day,
      end_day,
      locked,
      id_itr,
      message: itrMessage,
      chain,
      itr_type,
    },
    status: loaderStatus,
  } = useLoaderData() as IterationLoaderReturn;
  const { data: status } = useQuery({
    ...statusQuery(id || ''),
    initialData: loaderStatus,
  });
  const { t } = useTranslation();
  const { appOptions } = useAppOptions();
  const [deleteItrOpen, setDeleteItrOpen] = useState(false);
  const [unpublishOpen, setUnpublishOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  // const [configPreviewOpen, setConfigPreviewOpen] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [publish, setPublish] = useState(false);
  const [published, setPublished] = useState(status === ItrStatuses.PUBLISHED);
  const [archived, setArchived] = useState(status === ItrStatuses.ARCHIVED);
  const [success, setSuccess] = useState<string | boolean>(false);
  const [toggleEnabled, setToggleEnabled] = useState(locked ?? false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [message, setMessage] = useState(itrMessage);
  const [messageSaved, setMessageSaved] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteResults = useDeleteResultsMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      navigate(`/manager/iteration/${id}/configuration`);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const publishResults = usePublishResultsMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.setQueryData(['status', id], ItrStatuses.PUBLISHED);
      setPublished(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const unpublishResults = useUnpublishResultsMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.setQueryData(['status', id], ItrStatuses.ASSIGNMENT);
      setPublished(false);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const archiveItr = useArchiveItrMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.setQueryData(['status', id], ItrStatuses.ARCHIVED);
      setArchived(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const unarchiveItr = useUnarchiveItrMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.setQueryData(['status', id], ItrStatuses.PUBLISHED);
      setArchived(false);
      setPublished(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const editMessage = useEditMessageMutation(queryClient, {
    onSuccess: () => {
      setMessageSaved(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const assignmentToConfig = useAssignmentToConfigMutation(queryClient, {
    onSuccess: data => {
      queryClient.setQueryData(['status', id], ItrStatuses.CONFIGURATION);
      navigate(`/manager/iteration/${id}/configuration/roles`);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const runAlgorithm = useRunAlgMutation(queryClient, {
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      // queryClient.invalidateQueries({ queryKey: ['status', id] });
      // navigate(`/manager/iteration/${id}/running`);
    },
    onError: e => {
      // switch (e.response.data.type) {
      //   // case 'PRE_EXEC_ERRORS':
      //   //   setError(
      //   //     'La iteración está mal configurada. Apareceran errores más concretos más adelante',
      //   //   );
      //   //   break;
      //   case 'ITR_UNLOCKED':
      //     setError(t('manager.iterationLayouts.itrUnlocked'));
      //     break;
      //   default:
      //     setError(t('generic.error'));
      //     break;
      // }
    },
  });

  const configToAssignment = useConfigToAssignmentMutation(queryClient, {
    onSuccess: () => {
      queryClient.setQueryData(['status', id], ItrStatuses.ASSIGNMENT);
      navigate(`/manager/iteration/${id}/assignment/manual`);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const configuration = status === ItrStatuses.CONFIGURATION;
  const initialization = status === ItrStatuses.INITIALIZATION;
  const assignment = status === ItrStatuses.ASSIGNMENT;
  // const verification = status === 'verification';

  const nameAndDate = `${chain.chain_name}: ${parseIterationDates(start_day, end_day, itr_type)}`;

  return (
    <Wrapper>
      {/* {configPreviewOpen ? (
        <ConfigurationPreview
          open={configPreviewOpen}
          setOpen={setConfigPreviewOpen}
        />
      ) : null} */}
      <PublishOrDelete
        open={warningOpen}
        setOpen={setWarningOpen}
        deleteResults={deleteResults}
        publishResults={publishResults}
        publish={publish}
      />
      <Unpublish
        open={unpublishOpen}
        setOpen={setUnpublishOpen}
        unpublishResults={unpublishResults}
      />
      <DeleteItr
        open={deleteItrOpen}
        setOpen={setDeleteItrOpen}
        id_itr={id_itr}
        setSuccess={setSuccess}
      />
      <ArchiveConfirmation
        open={archiveOpen}
        setOpen={setArchiveOpen}
        archiveItr={archiveItr}
      />
      <div className="flex flex-row justify-between mb-3 items-center">
        <p className="text-2xl text-blue-600 font-bold">{nameAndDate}</p>
        {configuration ? (
          <div className="flex flex-row gap-2">
            <YSButton onClick={() => setDeleteItrOpen(true)} variant="delete">
              {t('manager.iterationLayouts.delete')}
            </YSButton>
            <YSButton onClick={() => configToAssignment.mutate({ id_itr: id })}>
              {t('manager.iterationLayouts.manual')}
            </YSButton>
          </div>
        ) : null}
        {assignment ? (
          <div className="flex flex-row gap-2">
            <YSButton
              onClick={() => assignmentToConfig.mutate({ id_itr: id })}
              variant="outline-primary"
            >
              {t('manager.iterationLayouts.backToConfig')}
            </YSButton>
            <YSButton
              onClick={() => {
                setPublish(true);
                setWarningOpen(true);
              }}
            >
              {t('manager.iterationLayouts.publish')}
            </YSButton>
          </div>
        ) : null}
        {published && !archived ? (
          <div className="flex flex-row gap-2">
            <YSButton
              variant="ghost-secondary"
              onClick={() => setArchiveOpen(true)}
            >
              {t('generic.archive')}
            </YSButton>
            <YSButton
              variant="outline-primary"
              onClick={() => setUnpublishOpen(true)}
            >
              {t('generic.unpublish')}
            </YSButton>
          </div>
        ) : null}
        {archived ? (
          <YSButton onClick={() => unarchiveItr.mutate({ id_itr: id })}>
            {t('generic.unarchive')}
          </YSButton>
        ) : null}
      </div>
      {archived ? (
        <div className="bg-gray-400 h-8 rounded-md flex justify-center items-center text-white font-bold">
          {t('generic.archived')}
        </div>
      ) : null}
      <Steps
        currentStep={status}
        setOpen={() => {}}
        // setOpen={setConfigPreviewOpen}
      />
      {error ? <Alert text={error} success={false} /> : null}
      {configuration || assignment ? (
        <div className="mt-5 my-5 flex justify-end">
          <Toggle enabled={toggleEnabled} setEnabled={setToggleEnabled} />
        </div>
      ) : null}
      {success ? <Alert success text={success} /> : null}
      {published ? (
        <Alert success text={t('manager.iterationLayouts.success')} />
      ) : null}
      {configuration || published ? (
        <div className="flex items-end mb-5">
          <div className="relative flex-1">
            <label
              htmlFor="message"
              className="absolute -top-1 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              {t('manager.iterationLayouts.message')}
            </label>
            <div className="mt-2">
              <textarea
                name="message"
                id="message"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder={t('manager.iterationLayouts.messagePlaceholder')}
                maxLength={250}
                rows={1}
                value={message || undefined}
                onChange={e => {
                  setMessageSaved(false);
                  setMessage(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            type="button"
            disabled={messageSaved || message === itrMessage}
            className="ml-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400"
            onClick={() => editMessage.mutate()}
          >
            {t('generic.save')}
          </button>
        </div>
      ) : null}
      {configuration && !locked ? (
        <p className="text-red-600 -mt-2 text-center mb-2">
          {t('manager.participantsConfig.locked')}
        </p>
      ) : null}
      <Outlet
        context={
          {
            status,
            locked,
            errors: undefined,
            infeasible: undefined,
            nameAndDate,
          } satisfies ItrContextType
        }
      />
    </Wrapper>
  );
}

export function useItrContext() {
  return useOutletContext<ItrContextType>();
}
