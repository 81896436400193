import { ShiftAssignment, SpecialEvent } from '@youshift/shared/types';

// Types of errors that can occur during assignment checks.
/**
 * Each error type defined in AssignmentCheckErrorType enum must have a corresponding
 * Error interface that defines the structure of the error context data for that specific type.
 * This ensures consistent error handling and type safety throughout the application.
 */
export enum AssignmentCheckErrorType {
  USER_REQ_DEFICIT = 'user_req_deficit',
  USER_REQ_SURPLUS = 'user_req_surplus',
  CROSS_GROUP_INCOMPATIBILITY = 'cross_group_incompatibility',
  SINGLE_GROUP_INCOMPATIBILITY_SURPLUS = 'single_group_incompatibility_surplus',
  SINGLE_GROUP_INCOMPATIBILITY_DEFICIT = 'single_group_incompatibility_deficit',
  SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK = 'assignment_on_justified_block',
  SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK = 'assignment_on_personal_block',
  SHIFT_ASSIGNMENT_ON_EVENT = 'assignment_on_event',
  SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION = 'assignment_rest_period_violation',
  SHIFT_ASSIGNMENT_RESTS_24_HOURS = 'assignment_rests_24_hours',
  DEFAULT = 'default',
}

// Falta de turnos asignados
// '{{user}} tiene {{numAssignments}} y necesita mínimo {{minSlots}}'
export interface UserReqDeficitErrorContext {
  num_assignments: number;
  min_slots: number;
}

// Exceso de turnos asignados
// '{{user}} tiene {{numAssignments}} y puede hacer máximo {{maxSlots}}'
export interface UserReqSurplusErrorContext {
  num_assignments: number;
  max_slots: number;
}

// no subtitle
// '{{datetime}}: {{assigned_users_group_1 (list)}} y {{assigned_users_group_2 (list)}} no pueden estar juntos.'
export interface CrossGroupIncompErrorContext {
  assigned_users_group_1: number[];
  assigned_users_group_2: number[];
}

// Exceso de usuarios en incompatibilidad
// '{{datetime}}: hay {{numUsers}} personas y puede haber máximo {{maxSimult}} a la vez.'
export interface SingleGroupIncompSurplusErrorContext {
  assigned_users: number[];
  max_simult: number;
}

// Exceso de usuarios en incompatibilidad
// '{{datetime}}: hay {{numUsers}} personas y tiene que haber mínimo {{minSimult}} a la vez.'
export interface SingleGroupIncompDeficitErrorContext {
  assigned_users: number[];
  min_simult: number;
}

// '{{sectionSlotDate}} - {{section}}: conflicto con {{type[0]}}'
export interface AssignmentOnSpecialEventErrorContext {
  events: SpecialEvent[];
}

// '{{sectionSlotDate}}: conflicto con bloqueo justificado.'
export interface AssignmentOnJustifiedBlockErrorContext {
  id_preference_slot: number;
}

// '{{sectionSlotDate}}: conflicto con bloqueo personal.'
export interface AssignmentOnPersonalBlockErrorContext {
  id_preference_slot: number;
}

// '{{sectionSlotDate}}: no se puede respetar la libranza a causa de los turnos {{chips}}.'
export interface AssignmentRestPeriodViolationErrorContext {
  // id_section_slots
  conflicting_assignments: number[];
}

// '{{sectionSlotDate}}: no se respeta un mínimo de 24 horas con el siguiente turno.'
export interface AssignmentRests24HoursErrorContext {
  // id_section_slots
  conflicting_assignments: number[];
}
