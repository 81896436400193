import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '@youshift/shared/api';
import {
  SectionSlot,
  ExchangeResponseType,
  ExchangeRequest,
  ShiftAssignment,
  Section,
  User,
} from '@youshift/shared/types';
import { dateToString, mergeRecords } from '@youshift/shared/utils';
import { usePostResponseMutation } from '@youshift/shared/hooks/mutations';
import { FetchUserExchangeDashboardResponse } from '@youshift/shared/hooks/queries';

import Alert from '../../../components/FormFeedback/Alert';

export default function PostResponse({
  data,
  open,
  setOpen,
  selectedRequest,
  setRequestSuccess,
  exchangeType,
}: {
  data: FetchUserExchangeDashboardResponse;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRequest: ExchangeRequest;
  setRequestSuccess: (success: string | null) => void;
  exchangeType: ExchangeResponseType;
}) {
  const [error, setError] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const all_my_future_active_shift_assignments: Record<
    number,
    ShiftAssignment
  > = Object.entries(data).reduce((acc, [_, itr]) => {
    const activeShiftAssignments = Object.fromEntries(
      Object.entries(itr.my_shift_assignments).filter(
        ([_, shift_assignment]) => {
          const sectionSlot =
            itr.section_slots[shift_assignment.id_section_slot];
          return (
            shift_assignment.active && new Date(sectionSlot.end) > new Date()
          );
        },
      ),
    );
    return { ...acc, ...activeShiftAssignments };
  }, {});

  const [selectedShiftAssignment, setSelectedShiftAssignment] =
    useState<ShiftAssignment | null>(
      exchangeType == ExchangeResponseType.ONE_FOR_ONE
        ? Object.values(all_my_future_active_shift_assignments)[0]
        : null,
    );

  const postResponseMutation = usePostResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      setRequestSuccess(t('user.shiftExchange.successNewResponse'));
      setError(null);
      setOpen(false);
    },
    onError: () => {
      setError(t('user.shiftExchange.errorNewResponse'));
      setRequestSuccess(null);
    },
  });

  const all_sections = useMemo(
    () =>
      mergeRecords<Section>(
        data as unknown as Record<
          number,
          { [key: string]: Record<number, Section> }
        >,
        'sections',
      ),
    [data],
  );

  const all_section_slots = useMemo(
    () =>
      mergeRecords<SectionSlot>(
        data as unknown as Record<
          number,
          { [key: string]: Record<number, SectionSlot> }
        >,
        'section_slots',
      ),
    [data],
  );

  const other_user_request_shift_assignment = Object.values(data).find(
    itr =>
      selectedRequest.id_shift_assignment in
      itr.other_users_requestable_shift_assigments,
  )?.other_users_requestable_shift_assigments[
    selectedRequest.id_shift_assignment
  ];
  if (!other_user_request_shift_assignment) {
    console.log('ERROR', other_user_request_shift_assignment);
    return null;
  }
  const request_section_slot =
    all_section_slots[other_user_request_shift_assignment.id_section_slot];
  const request_section = all_sections[request_section_slot.id_section];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-center">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {t('user.shiftExchange.postResponse')}
                    </Dialog.Title>
                  </div>
                </div>
                {exchangeType == ExchangeResponseType.ONE_FOR_ONE ? (
                  <div className="mt-2 flex flex-row items-center gap-6 justify-between">
                    <h2 className="font-semibold">
                      {t('user.shiftExchange.selectedShiftTrade')}
                    </h2>
                    <div className="items-center">
                      <Listbox
                        value={selectedShiftAssignment?.id_shift_assignment}
                        onChange={id_shift_assignment =>
                          setSelectedShiftAssignment(
                            id_shift_assignment
                              ? all_my_future_active_shift_assignments[
                                  id_shift_assignment
                                ]
                              : null,
                          )
                        }
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                            <span className="block truncate">
                              {selectedShiftAssignment &&
                                `${dateToString(
                                  all_section_slots[
                                    selectedShiftAssignment.id_section_slot
                                  ].start,
                                  'weekday-hour',
                                )} - ${all_sections[selectedShiftAssignment.id_section]?.acronym}`}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {Object.values(
                                all_my_future_active_shift_assignments,
                              ).map(shiftAssignment => {
                                const section_slot =
                                  all_section_slots[
                                    shiftAssignment.id_section_slot
                                  ];
                                return (
                                  <Listbox.Option
                                    key={shiftAssignment.id_shift_assignment}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`
                                    }
                                    value={shiftAssignment.id_shift_assignment}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                        >
                                          {`${dateToString(
                                            section_slot.start,
                                            'weekday-hour',
                                          )} - ${all_sections[shiftAssignment.id_section]?.acronym}`}{' '}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                );
                              })}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                ) : null}
                {error ? <Alert success={false} text={error} /> : null}{' '}
                <div className="flex flex-col min-w-0 gap-x-4">
                  <div className="mt-2 flex flex-row items-center justify-between">
                    <div className="font-semibold">
                      {t('user.shiftExchange.shiftRequested')}
                    </div>
                    <div>
                      {dateToString(request_section_slot.start, 'weekday-hour')}
                      {' - '}
                      {request_section.name}
                    </div>
                  </div>
                  <p className="mt-2">
                    {t('user.shiftExchange.approvalWarning')}
                  </p>
                </div>
                <div className="mt-5 sm:mt-4 flex gap-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto disabled:bg-blue-200"
                    onClick={() =>
                      postResponseMutation.mutate({
                        id_request: selectedRequest.id_request,
                        id_shift_assignment:
                          selectedShiftAssignment?.id_shift_assignment,
                        type: exchangeType,
                      })
                    }
                  >
                    {t('generic.sendButton')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
