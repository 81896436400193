import { useQuery, QueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import { allChainsQuery } from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';

import { requireApproved, requireManager } from '../../utils/checks';

export const chainsLoader = (queryClient: QueryClient) => async () => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  const data = await preLoadQuery(queryClient, allChainsQuery());
  return data;
};

export default function ChainsHistory() {
  const { data: { itrs, chains } = { itrs: {}, chains: [] } } =
    useQuery(allChainsQuery());

  const { t } = useTranslation();
  // TODO: abstract this

  const status_classes = {
    INITIALIZATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
    ],
    CONFIGURATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
    ],
    RUNNING_SMARTASS: [
      'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      t('generic.itrStatuses.running'),
    ],
    ASSIGNMENT: [
      'text-violet-800 bg-violet-50 ring-violet-600/20',
      t('generic.itrStatuses.assignment'),
    ],
    PUBLISHED: [
      'text-teal-800 bg-teal-50 ring-teal-600/20',
      t('generic.itrStatuses.published'),
    ],
    CLOSED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.closed'),
    ],
    ARCHIVED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.archived'),
    ],
  };

  return (
    <div className="space-y-6 md:mt-16 px-2 sm:px-4">
      <div className="flex flex-row justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
          {t('manager.chains.history')}
        </h3>
      </div>
      <ul className="mt-2">
        {Object.values(chains).map(chain => (
          <div className="border-b border-b-gray-400/50">
            <p className="text-blue-600 font-bold mt-4">{chain.chain_name}</p>
            {Object.values(chain.itrs).map(id_itr => (
              <li
                key={chain.id_chain}
                className="flex items-center justify-between gap-x-6 py-3"
              >
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {parseIterationDates(
                        itrs[id_itr].start_day,
                        itrs[id_itr].end_day,
                        itrs[id_itr].itr_type,
                      )}
                    </p>
                    <p
                      className={classNames(
                        status_classes[itrs[id_itr].status][0],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {status_classes[itrs[id_itr].status][1]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <Link
                    to={`../iteration/${itrs[id_itr].id_itr}`}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  >
                    {t('manager.chains.seeItr')}
                    <span className="sr-only">,{chain.chain_name}</span>
                  </Link>
                </div>
              </li>
            ))}
          </div>
        ))}
      </ul>
    </div>
  );
}
