import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CalendarDateRangeIcon,
  CalendarDaysIcon,
  CheckIcon,
  Cog8ToothIcon,
  EyeIcon,
  PlusIcon,
  SparklesIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import { ItrStatuses } from '@youshift/shared/types';

import NewItr from './NewItr';

export default function ItrStates({ activeItrs }) {
  const { t } = useTranslation();

  const status_classes = {
    INITIALIZATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    CONFIGURATION: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
      Cog8ToothIcon,
      'bg-blue-600',
    ],
    RUNNING_SMARTASS: [
      'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      t('generic.itrStatuses.running'),
      ArrowPathIcon,
      'bg-yellow-500',
    ],
    ASSIGNMENT: [
      'text-violet-800 bg-violet-50 ring-violet-600/20',
      t('generic.itrStatuses.assignment'),
      CalendarDateRangeIcon,
      'bg-violet-600',
    ],
    PUBLISHED: [
      'text-teal-800 bg-teal-50 ring-teal-600/20',
      t('generic.itrStatuses.published'),
      CheckIcon,
      'bg-teal-600',
    ],
    CLOSED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.closed'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
    ARCHIVED: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.archived'),
      ArchiveBoxIcon,
      'bg-gray-600',
    ],
  };

  const [open, setOpen] = useState(false);

  const lastItr = activeItrs[activeItrs.length - 1];
  const showCreateNew = lastItr?.status === ItrStatuses.PUBLISHED || !activeItrs.length;

  return (
    <div className="flow-root">
      {open ? (
        <NewItr
          open={open}
          setOpen={setOpen}
          itrId={lastItr.id_itr}
          itrType={lastItr.itr_type}
          itrEnd={lastItr.end_day}
        />
      ) : null}
      <ul className="m-6 mb-0">
        {activeItrs.map((itr, itrIdx) => {
          const StatusIcon = status_classes[itr.status][2];
          return (
            <li key={itr.id_itr}>
              <div className="relative pb-8">
                {itrIdx !== activeItrs.length - 1 ? (
                  <span
                    className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <Link
                  className="relative flex space-x-3 hover:bg-gray-200 py-1.5 px-1 rounded-md"
                  to={`../iteration/${itr.id_itr}`}
                >
                  <div>
                    <span
                      className={classNames(
                        status_classes[itr.status][3],
                        'h-8 w-8 rounded-full flex items-center justify-center ring-white',
                      )}
                    >
                      <StatusIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between items-center space-x-4">
                    <div>
                      <p className="font-medium text-gray-900">
                        {parseIterationDates(
                          itr.start_day,
                          itr.end_day,
                          itr.itr_type,
                        )}
                      </p>
                    </div>
                    <span
                      className={`inline-flex flex-shrink-0 items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${status_classes[itr.status][0]}`}
                    >
                      {`${status_classes[itr.status][1]}`}
                    </span>
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </Link>
              </div>
            </li>
          );
        })}
        {showCreateNew ? (
          <div className="relative pb-8">
            <button
              className="relative flex space-x-3 outline-dashed outline-1 outline-offset-2 outline-blue-600 rounded-md items-center justify-center hover:bg-blue-600/20"
              onClick={() => setOpen(true)}
            >
              <div>
                <span className="h-8 w-8 rounded-full flex items-center justify-center">
                  <PlusIcon
                    className="h-5 w-5 text-blue-600"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between items-center space-x-4">
                <div>
                  <p className="font-medium text-gray-900 text-sm mr-3">
                    {t('manager.iterationLayouts.createNextItr')}
                  </p>
                </div>
              </div>
            </button>
          </div>
        ) : null}
      </ul>
    </div>
  );
}
