import React from 'react';
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from 'react-number-format';

// Counter component
interface MinMaxCounterProps {
  value: number | undefined;
  setValue: (value: number) => void;
  disabled?: boolean;
}

export function PreferencesCounter({
  value,
  setValue,
  disabled = false,
}: MinMaxCounterProps) {
  const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    if (sourceInfo.source === 'event' && sourceInfo.event) {
      setValue(parseInt(values.value, 10));
    }
  };

  return (
    <div className="flex border border-gray-600 space-x-0 rounded-md">
      <NumericFormat
        value={disabled ? '' : value}
        valueIsNumericString
        onValueChange={handleChange}
        allowNegative={false}
        disabled={disabled}
        className={`rounded-md border-none w-16 text-center focus:ring-transparent ${disabled && 'bg-gray-100'}`}
      />
    </div>
  );
}
