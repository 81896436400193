"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelIcons = exports.returnColor = exports.colorIndices = exports.colorPalette = void 0;
exports.colorPalette = {
    violet: {
        50: "rgba(247, 232, 255, 0.5)",
        100: "rgba(243, 220, 251, 0.5)",
        200: "rgba(239, 190, 248, 0.5)",
        300: "rgba(235, 159, 245, 0.5)",
        400: "rgba(227, 127, 240, 0.5)",
        500: "rgba(219, 95, 230, 1)",
        600: "rgba(213, 63, 222, 0.5)",
        700: "rgba(198, 40, 203, 0.5)",
        800: "rgba(179, 23, 177, 0.5)",
        900: "rgba(151, 17, 145, 0.5)",
    },
    purple: {
        50: "rgba(245, 237, 254, 0.5)",
        100: "rgba(234, 216, 252, 0.5)",
        200: "rgba(211, 174, 248, 0.5)",
        300: "rgba(187, 131, 243, 0.5)",
        400: "rgba(163, 88, 239, 0.5)",
        500: "rgba(139, 45, 232, 1)",
        600: "rgba(122, 25, 214, 0.5)",
        700: "rgba(99, 12, 192, 0.5)",
        800: "rgba(76, 9, 165, 0.5)",
        900: "rgba(54, 7, 128, 0.5)",
    },
    blue: {
        50: "rgba(238, 248, 255, 0.5)",
        100: "rgba(211, 234, 253, 0.5)",
        200: "rgba(166, 212, 250, 0.5)",
        300: "rgba(120, 190, 248, 0.5)",
        400: "rgba(74, 168, 246, 0.5)",
        500: "rgba(28, 146, 244, 1)",
        600: "rgba(27, 124, 214, 0.5)",
        700: "rgba(22, 101, 186, 0.5)",
        800: "rgba(16, 79, 155, 0.5)",
        900: "rgba(10, 57, 122, 0.5)",
    },
    green: {
        50: "rgba(241, 255, 232, 0.5)",
        100: "rgba(219, 251, 199, 0.5)",
        200: "rgba(182, 247, 139, 0.5)",
        300: "rgba(144, 244, 86, 0.5)",
        400: "rgba(107, 242, 34, 0.5)",
        500: "rgba(77, 238, 11, 1)",
        600: "rgba(62, 199, 7, 0.5)",
        700: "rgba(45, 157, 5, 0.5)",
        800: "rgba(32, 115, 4, 0.5)",
        900: "rgba(19, 81, 4, 0.5)",
    },
    turquoise: {
        50: "rgba(230, 248, 248, 0.5)",
        100: "rgba(199, 238, 238, 0.5)",
        200: "rgba(161, 229, 229, 0.5)",
        300: "rgba(123, 221, 221, 0.5)",
        400: "rgba(85, 213, 213, 0.5)",
        500: "rgba(47, 204, 204, 1)",
        600: "rgba(39, 175, 175, 0.5)",
        700: "rgba(31, 145, 145, 0.5)",
        800: "rgba(25, 116, 116, 0.5)",
        900: "rgba(18, 87, 87, 0.5)",
    },
    yellow: {
        50: "rgba(254, 248, 227, 0.5)",
        100: "rgba(253, 239, 181, 0.5)",
        200: "rgba(251, 227, 136, 0.5)",
        300: "rgba(248, 214, 89, 0.5)",
        400: "rgba(245, 200, 43, 0.5)",
        500: "rgba(243, 185, 0, 1)",
        600: "rgba(219, 159, 0, 0.5)",
        700: "rgba(184, 130, 0, 0.5)",
        800: "rgba(148, 102, 0, 0.5)",
        900: "rgba(112, 74, 0, 0.5)",
    },
    orange: {
        50: "rgba(255, 243, 227, 0.5)",
        100: "rgba(255, 226, 181, 0.5)",
        200: "rgba(255, 195, 136, 0.5)",
        300: "rgba(255, 156, 89, 0.5)",
        400: "rgba(255, 117, 26, 0.5)",
        500: "rgba(255, 84, 0, 1)",
        600: "rgba(219, 71, 0, 0.5)",
        700: "rgba(184, 58, 0, 0.5)",
        800: "rgba(148, 45, 0, 0.5)",
        900: "rgba(112, 31, 0, 0.5)",
    },
    red: {
        50: "rgba(255, 227, 227, 0.5)",
        100: "rgba(255, 189, 189, 0.5)",
        200: "rgba(255, 157, 157, 0.5)",
        300: "rgba(255, 112, 112, 0.5)",
        400: "rgba(255, 71, 71, 0.5)",
        500: "rgba(255, 31, 31, 1)",
        600: "rgba(219, 24, 24, 0.5)",
        700: "rgba(184, 19, 19, 0.5)",
        800: "rgba(148, 14, 14, 0.5)",
        900: "rgba(112, 8, 8, 0.5)",
    },
};
exports.colorIndices = [
    50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
];
var returnColor = function (color, shade) {
    return exports.colorPalette[color][shade || 500];
};
exports.returnColor = returnColor;
exports.LabelIcons = [
    "sun", // Morning Shift
    "moon", // Night Shift
    "star", // Late Evening or Premium Shift
    "bolt", // Emergency or On-Call Shift
    "bell", // High-Alert or Reminder Shift
    "fire", // High-Intensity or Critical Shift
    "truck", // Logistics or Delivery Shift
    "shield-check", // Security Shift
    "calendar", // Scheduled or Recurring Shift
    "clock", // Anytime or Flexible Timing Shift
    "computer-desktop", // Office or Tech Shift
    "flag", // Supervisory or Leadership Shift
    "trophy", // High-Performance or Key Milestone Shift
    "puzzle-piece", // Collaborative or Team-Oriented Shift
    "wrench-screwdriver", // Maintenance or Repair Shift
    "globe-alt", // Remote or Global Shift
    "eye", // Monitoring or Observation Shift
    "gift", // Holiday or Seasonal Shift
    "megaphone", // Outreach or Public-Facing Shift
    "beaker", // Research or Lab Shift
    "musical-note", // Creative or Event Shift
    "archive-box", // Storage or Inventory Shift
    "arrow-up-tray", // Pickup Shift
    "arrow-down-tray", // Delivery Shift
    "chat-bubble-left", // Customer Support or Communication Shift
    "scale", // Decision-Making or Evaluation Shift
]; // Ensure the array is immutable
