/* eslint-disable react/require-default-props */
import type {
  Section,
  SectionSlotsDict,
  SectionWithSlots,
  SlotLabel,
  User,
} from '@youshift/shared/types';
import {
  arrangeSectionSlots,
  ColorIndex,
  dateToString,
  divideSlotsIntoWeeks,
  getCustomDateRange,
  getDifferenceInHours,
  getDifferenceInMinutes,
  returnColor,
} from '@youshift/shared/utils';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

import {
  SectionSlot,
  SectionSlotSelect,
  SectionSlotVirgueria,
} from '../SectionSlot';
import { ArrangedSectionSlot } from '../../pages/Manager/IterationConfig/Sections/CreateNewSection';
import type { Epa } from '../../pages/Manager/ManualAssignment';
import i18n from '../../utils/i18n';

type VirgueriaProps = {
  start: string;
  end: string;
  sectionsWithSlots: SectionWithSlots[];
  shadeMap: Record<number, ColorIndex>;
  children?: ReactNode;
  onClick: (slot: ArrangedSectionSlot | number) => void;
  version: 'rules' | 'section' | 'virgueria';
  selectedSlots?: Set<number>;
  epa?: Epa;
  users?: Record<number, User> | undefined;
  setError?: Dispatch<SetStateAction<string | null>>;
  labels: SlotLabel[];
  showRestPeriod?: boolean;
  showNeeds?: boolean;
  onDayClick?: (d: Date) => void;
  onSectionClick?: (s: Section) => void;
  selectedPeople?: Set<string>;
};

export default function Virgueria({
  start,
  end,
  sectionsWithSlots,
  shadeMap,
  children,
  onClick,
  version,
  selectedSlots,
  epa,
  users,
  setError,
  labels,
  showRestPeriod,
  showNeeds,
  onDayClick,
  onSectionClick,
  selectedPeople,
}: VirgueriaProps) {
  const locale = i18n.language;

  const weeks = getCustomDateRange(start, end, locale);
  const RESOLUTION = 1;
  const singleSectionView: boolean = sectionsWithSlots.length === 1;
  const isRules = version === 'rules';
  const isVirgueria = version === 'virgueria';

  return (
    <div className="w-full">
      {weeks.map((week, weekIdx) => (
        <div
          className="grid auto-cols-auto auto-rows-min mt-4 mb-2 pb-2"
          style={{
            gridTemplateColumns: singleSectionView
              ? 'repeat(7, 1fr)'
              : 'minmax(75px, auto) repeat(7, 1fr)',
          }}
        >
          {singleSectionView ? null : <p />}
          {week.map((day, dayIdx) => (
            <button
              className={`text-white p-1 bg-gray-400 border mb-1 border-gray-500 ${dayIdx === 0 ? 'rounded-l-lg' : dayIdx === week.length - 1 ? 'rounded-r-lg' : ''} rounded-`}
              onClick={() => onDayClick && onDayClick(day)}
            >
              <p className="text-center font-bold">
                {dateToString(day, 'weekday', locale)}
              </p>
            </button>
          ))}
          {sectionsWithSlots.map((section, idx) => {
            const slotsPerWeek = divideSlotsIntoWeeks(
              weeks,
              section.section_slots,
            );
            return (
              <>
                {singleSectionView ? null : (
                  <button
                    className={`flex justify-center items-center border-b-2 ${idx === Object.values(sectionsWithSlots).length - 1 ? 'rounded-b-md' : idx === 0 ? 'rounded-t-md' : ''}`}
                    style={{ backgroundColor: returnColor(section.color) }}
                    onClick={() => onSectionClick && onSectionClick(section)}
                  >
                    <p className="font-semibold">{section.acronym}</p>
                  </button>
                )}
                <div className="col-span-7 grid text-center auto-rows-min">
                  {arrangeSectionSlots(slotsPerWeek[weekIdx]).map(
                    (row, rowIndex) => (
                      <div
                        key={rowIndex}
                        className="grid"
                        style={{
                          gridTemplateColumns: `repeat(${168 / RESOLUTION}, minmax(5px, 1fr))`,
                        }}
                      >
                        {row.map(slot => {
                          const diffFromStart = getDifferenceInHours(
                            week[0],
                            slot.start,
                          );
                          const duration = getDifferenceInHours(
                            slot.start,
                            slot.end,
                          );
                          const shade = shadeMap[slot.id_slot_label];
                          const icon = labels.find(
                            label => slot.id_slot_label == label.id_slot_label,
                          )?.icon;
                          const arrangedSectionSlot = {
                            start_position: diffFromStart + 1,
                            end_position: diffFromStart + 1 + duration,
                            start_time: dateToString(slot.start, 'hh:mm'),
                            end_time: dateToString(slot.end, 'hh:mm'),
                            duration_minutes: getDifferenceInMinutes(
                              slot.start,
                              slot.end,
                            ),
                            min_need: slot.min_need,
                            max_need: slot.max_need,
                            id_pref_slot: slot.id_pref_slot,
                            rest_period: slot.rest_period,
                            id_slot_label: slot.id_slot_label,
                            id: slot.id_section_slot,
                            spanning: slot.spanning,
                          };
                          // [0,1,...,max_need-1]
                          const maxNeedsArray = Array.from(
                            { length: slot.max_need },
                            (_, idx) => idx,
                          );
                          return isRules ? (
                            <SectionSlotSelect
                              slot={arrangedSectionSlot}
                              sectionColor={section.color}
                              shade={shade}
                              onClick={onClick}
                              selectedSlots={selectedSlots || new Set()}
                              labelIcon={icon}
                            />
                          ) : isVirgueria && setError ? (
                            <SectionSlotVirgueria
                              slot={arrangedSectionSlot}
                              start={slot.start}
                              end={slot.end}
                              sectionColor={section.color}
                              shade={shade}
                              maxNeedsArray={maxNeedsArray}
                              epa={epa}
                              users={users}
                              setError={setError}
                              labelIcon={icon}
                              showRestPeriod={Boolean(showRestPeriod)}
                              showNeeds={Boolean(showNeeds)}
                              selectedPeople={selectedPeople || new Set()}
                            />
                          ) : (
                            <SectionSlot
                              slot={arrangedSectionSlot}
                              sectionColor={section.color}
                              shade={shade}
                              onClick={onClick}
                              Icon={PencilSquareIcon}
                              labelIcon={icon}
                            />
                          );
                        })}
                      </div>
                    ),
                  )}
                </div>
              </>
            );
          })}
        </div>
      ))}
    </div>
  );
}
