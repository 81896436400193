import axios from 'axios';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';

import logo from '../../assets/Logo/logo_lq.png';
import Alert from '../../components/FormFeedback/Alert';
import SignUpSuccessful from './SignUpSuccessful';

const initialState = {
  firstname: '',
  lastname: '',
  password: '',
  confirmPassword: '',
  has_agreed_to_privacy_policy: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'TOGGLE_PRIVACY_POLICY':
      return {
        ...state,
        has_agreed_to_privacy_policy: !state.has_agreed_to_privacy_policy,
      };
    case 'RESET':
      return {
        ...state,
        firstname: '',
        lastname: '',
        group_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        has_agreed_to_privacy_policy: false,
      };
    default:
      return state;
  }
}

export default function SignUpUser() {
  const { t, i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const currentLocale = i18n.language;

  const {
    email, group_name, id_group, id_org, org_name,
  } = useLoaderData();

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;

  const signupAction = async event => {
    event.preventDefault();
    setSuccess(false);
    const {
      firstname,
      lastname,
      password,
      confirmPassword,
      has_agreed_to_privacy_policy,
    } = state;

    if (!passwordRegex.test(password)) {
      setError(t('auth.passwordRequirements'));
    } else if (password !== confirmPassword) {
      setError(t('auth.passwordNoMatch'));
    } else if (!has_agreed_to_privacy_policy) {
      setError(t('auth.signUpManager.privacyPolicyError'));
    } else {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/user/new`, {
          firstname,
          lastname,
          id_org,
          id_group,
          email,
          password,
          has_agreed_to_privacy_policy,
          language: currentLocale,
        });
        setError(false);
        dispatch({ type: 'RESET' });
        setSuccess(t('auth.signUpManager.registrationSuccessUser'));
      } catch (error) {
        setSuccess(false);
        switch (error.response?.data.type) {
          case 'USERNAME_NOT_AVAIL':
            setError(t('auth.signUpManager.emailNotAvailableError'));
            break;
          case 'ORG_NOT_FOUND':
            setError(t('auth.signUpManager.organizationNotFoundError'));
            break;
          default:
            setError(t('auth.signUpManager.genericError'));
            break;
        }
      }
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    dispatch({ type: 'UPDATE_FIELD', field: name, value });
  };

  const handleTogglePrivacyPolicy = () => {
    dispatch({ type: 'TOGGLE_PRIVACY_POLICY' });
  };

  return success ? (
    <SignUpSuccessful
      h1={t('auth.signUpManager.successMessage')}
      h2={t('auth.signUpManager.successSubtitle')}
      h3={success}
    />
  ) : (
    <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img className="h-12 w-auto" src={logo} alt="Logo" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {t('auth.signUpManager.register')}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {t('auth.signUpManager.or')}
            {' '}
            <Link
              to="/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {t('auth.signUpManager.loginLink')}
            </Link>
          </p>
        </div>
        <div className="mt-6 -mb-4">
          {error ? <Alert text={error} /> : null}
        </div>
        <form className="space-y-6" onSubmit={signupAction}>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.firstName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="firstname"
                  id="first-name"
                  autoComplete="given-name"
                  value={state.firstname}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.lastName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="lastname"
                  id="last-name"
                  autoComplete="family-name"
                  value={state.lastname}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-full">
              <label
                htmlFor="organization"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.organization')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="organization"
                  id="organization"
                  value={org_name}
                  className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  disabled
                />
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="team"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('generic.teamName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="group_name"
                  id="team"
                  value={group_name}
                  className="block w-full rounded-md border-0 py-1.5 bg-gray-100 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  disabled
                />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('generic.email')}
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                className="block w-full bg-gray-100 text-gray-600 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                disabled
              />
            </div>
          </div>
          <div className="space-y-1">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('generic.password')}
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                value={state.password}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="space-y-1">
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {t('auth.signUpManager.confirmPassword')}
            </label>
            <div className="mt-2">
              <input
                id="confirm-password"
                name="confirmPassword"
                type="password"
                required
                value={state.confirmPassword}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="privacy-policy"
                name="privacyPolicy"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={state.has_agreed_to_privacy_policy}
                onChange={handleTogglePrivacyPolicy}
              />
              <label
                htmlFor="privacy-policy"
                className="ml-2 block text-sm text-gray-900"
              >
                {t('auth.signUpManager.privacyPolicyLabel')}
                {' '}
                <Link to="/privacy" className="text-blue-600">
                  {t('auth.signUpManager.privacyPolicyLink')}
                </Link>
              </label>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {t('auth.signUpManager.createAccount')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
